import {
  Flex,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import ButtonType from "components/Button";
import FormInput from "components/form/FormInput";
import useWithToast from "hooks/useWithToast";
import { FormProvider, useForm } from "react-hook-form";
import { listCoinSubTypeBCoin, listCoinSubTypeSphere, listCoinSubTypeOrb } from "../constant";
import FormLabelComponent from "components/form/FormLabel";
import FormSelect from "components/form/FormSelect";
import { yupResolver } from "@hookform/resolvers/yup";
import schemaUpdatePriority from "./schema";
import { updateCoinConsumption } from "api/coinConsumption.api";

export default function UpdateCoinConsumption({
  isOpen,
  onClose,
  dataDetail,
  handleCreateOrUpdateSuccess,
}) {
  const toast = useToast();
  const { showToastSuccess } = useWithToast();

  const form = useForm({
    resolver: yupResolver(schemaUpdatePriority()),
    defaultValues: {
      case: dataDetail?.case_name || "",
      coin_type: dataDetail?.system_coin || "",
      transaction_type: dataDetail?.change_type === "DECREASE" ? "Consumption" : "Acquisition",
      first_priority: dataDetail?.first_priority || '',
      second_priority: dataDetail?.second_priority || '',
      third_priority: dataDetail?.third_priority || '',
      fourth_priority: dataDetail?.fourth_priority || '',
    },
  });

  const {
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = form;
  
  const onSubmit = async (data) => {
    try {
      const dataSubmit = {
        first_priority: data?.first_priority || null,
        second_priority: data?.second_priority || null,
        third_priority: data?.third_priority || null,
        fourth_priority: data?.fourth_priority || null,
      }
      const res = await updateCoinConsumption(dataDetail?.id, dataSubmit)
      if (res?.status === 200) {
        showToastSuccess({
          title: "Update priority successfully!",
        });
        handleCreateOrUpdateSuccess()
        onClose()
      } else {
        toast({
          description: res?.data?.message || res?.data?.messages[0],
          status: 'error',
          position: 'bottom-right',
        })
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const formPriority = (system) => {
    switch (system) {
      case "BCOIN":
        return (
          dataDetail?.change_type === "DECREASE" ? (
            <Grid templateColumns="repeat(2, 1fr)" gap={4}>
              <Flex gap={2} alignItems="center">
                <Text>1</Text>
                <FormSelect
                  wrapperProps={{
                    w: "100%",
                  }}
                  maxW="100%"
                  name="first_priority"
                  label=""
                  placeholder="Select a coin sub-type"
                  options={listCoinSubTypeBCoin}
                  onChange={(e) => {
                    setValue("first_priority", e.target.value);
                  }}
                  isRequired
                />
              </Flex>
              <Flex gap={2} alignItems="center">
                <Text>2</Text>
                <FormSelect
                  wrapperProps={{
                    w: "100%",
                  }}
                  maxW="100%"
                  name="second_priority"
                  label=""
                  placeholder="Select a coin sub-type"
                  options={listCoinSubTypeBCoin}
                  onChange={(e) => {
                    setValue("second_priority", e.target.value);
                  }}
                  isRequired
                />
              </Flex>
              <Flex gap={2} alignItems="center">
                <Text>3</Text>
                <FormSelect
                  wrapperProps={{
                    w: "100%",
                  }}
                  maxW="100%"
                  name="third_priority"
                  label=""
                  placeholder="Select a coin sub-type"
                  options={listCoinSubTypeBCoin}
                  onChange={(e) => {
                    setValue("third_priority", e.target.value);
                  }}
                  isRequired
                />
              </Flex>
              <Flex gap={2} alignItems="center">
                <Text>4</Text>
                <FormSelect
                  wrapperProps={{
                    w: "100%",
                  }}
                  maxW="100%"
                  name="fourth_priority"
                  label=""
                  placeholder="Select a coin sub-type"
                  options={listCoinSubTypeBCoin}
                  onChange={(e) => {
                    setValue("fourth_priority", e.target.value);
                  }}
                  isRequired
                />
              </Flex>
            </Grid>
          ) : (
            <Grid templateColumns="repeat(2, 1fr)" gap={4}>
              <Flex gap={2} alignItems="center">
                <Text>1</Text>
                <FormSelect
                  wrapperProps={{
                    w: "100%",
                  }}
                  maxW="100%"
                  name="first_priority"
                  label=""
                  placeholder="Select a coin sub-type"
                  options={listCoinSubTypeBCoin}
                  onChange={(e) => {
                    setValue("first_priority", e.target.value);
                  }}
                  isRequired
                />
              </Flex>
              <Flex gap={2} alignItems="center"></Flex>
            </Grid>
          )
        )
      case "SPHERE":
        return (
          dataDetail?.change_type === "DECREASE" ? (
            <Grid templateColumns="repeat(2, 1fr)" gap={4}>
              <Flex gap={2} alignItems="center">
                <Text>1</Text>
                <FormSelect
                  wrapperProps={{
                    w: "100%",
                  }}
                  maxW="100%"
                  name="first_priority"
                  label=""
                  placeholder="Select a coin sub-type"
                  options={listCoinSubTypeSphere}
                  onChange={(e) => {
                    setValue("first_priority", e.target.value);
                  }}
                  isRequired
                />
              </Flex>
              <Flex gap={2} alignItems="center">
                <Text>2</Text>
                <FormSelect
                  wrapperProps={{
                    w: "100%",
                  }}
                  maxW="100%"
                  name="second_priority"
                  label=""
                  placeholder="Select a coin sub-type"
                  options={listCoinSubTypeSphere}
                  onChange={(e) => {
                    setValue("second_priority", e.target.value);
                  }}
                  isRequired
                />
              </Flex>
            </Grid>
          ) : (
            <Grid templateColumns="repeat(2, 1fr)" gap={4}>
              <Flex gap={2} alignItems="center">
                <Text>1</Text>
                <FormSelect
                  wrapperProps={{
                    w: "100%",
                  }}
                  maxW="100%"
                  name="first_priority"
                  label=""
                  placeholder="Select a coin sub-type"
                  options={listCoinSubTypeSphere}
                  onChange={(e) => {
                    setValue("first_priority", e.target.value);
                  }}
                  isRequired
                />
              </Flex>
              <Flex gap={2} alignItems="center"></Flex>
            </Grid>
          )
        )
      case "ORB":
        return (
          dataDetail?.change_type === "DECREASE" ? (
            <Grid templateColumns="repeat(2, 1fr)" gap={4}>
              <Flex gap={2} alignItems="center">
                <Text>1</Text>
                <FormSelect
                  wrapperProps={{
                    w: "100%",
                  }}
                  maxW="100%"
                  name="first_priority"
                  label=""
                  placeholder="Select a coin sub-type"
                  options={listCoinSubTypeOrb}
                  onChange={(e) => {
                    setValue("first_priority", e.target.value);
                  }}
                  isRequired
                />
              </Flex>
              <Flex gap={2} alignItems="center">
                <Text>2</Text>
                <FormSelect
                  wrapperProps={{
                    w: "100%",
                  }}
                  maxW="100%"
                  name="second_priority"
                  label=""
                  placeholder="Select a coin sub-type"
                  options={listCoinSubTypeOrb}
                  onChange={(e) => {
                    setValue("second_priority", e.target.value);
                  }}
                  isRequired
                />
              </Flex>
            </Grid>
          ) : (
            <Grid templateColumns="repeat(2, 1fr)" gap={4}>
              <Flex gap={2} alignItems="center">
                <Text>1</Text>
                <FormSelect
                  wrapperProps={{
                    w: "100%",
                  }}
                  maxW="100%"
                  name="first_priority"
                  label=""
                  placeholder="Select a coin sub-type"
                  options={listCoinSubTypeOrb}
                  onChange={(e) => {
                    setValue("first_priority", e.target.value);
                  }}
                  isRequired
                />
              </Flex>
              <Flex gap={2} alignItems="center"></Flex>
            </Grid>
          )
        )
      default:
        break;
    }
  }

  const renderForm = () => {
    return (
      <FormProvider {...form}>
        <form id="exchange-form" onSubmit={handleSubmit(onSubmit)}>
          <Grid templateColumns="repeat(1, 1fr)" gap={4}>
            <FormInput
              type="string"
              maxW="100%"
              name="case"
              label="Case"
              disabled
            />
            <Flex gap={4}>
              <FormInput
                wrapperProps={{
                  w: "100%",
                }}
                type="string"
                maxW="100%"
                name="coin_type"
                label="Coin Type"
                disabled
              />
              <FormInput
                wrapperProps={{
                  w: "100%",
                }}
                type="string"
                maxW="100%"
                name="transaction_type"
                label="Transaction Type"
                disabled
              />
            </Flex>
            <Flex flexDirection="column" gap={4}>
              <FormLabelComponent
                m="0px"
                title="Priority"
                isRequired
              />
              {formPriority(dataDetail?.system_coin)}
            </Flex>
          </Grid>
        </form>
      </FormProvider>
    );
  };

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxW="685px" overflowY="auto">
        <ModalCloseButton />
        <ModalBody p="24px" minW="500px">
          {renderForm()}
        </ModalBody>
        <ModalFooter w="100%" textAlign="center" margin="auto" gap="16px">
          <ButtonType
            mt={4}
            btnType="primary-new-outline"
            type="submit"
            m="auto"
            w="100%"
            h="46px"
            fontSize="16px"
            borderRadius="5px"
            onClick={() => {
              onClose();
            }}
          >
            CANCEL
          </ButtonType>
          <ButtonType
            mt={4}
            btnType="primary"
            type="submit"
            m="auto"
            w="100%"
            h="46px"
            fontSize="16px"
            borderRadius="5px"
            form="exchange-form"
            isLoading={isSubmitting}
          >
            SAVE
          </ButtonType>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
