import { Flex,Grid } from "@chakra-ui/react";
import FormSelect from "components/form/FormSelect";
import { FormProvider, useForm } from "react-hook-form";
import {
  OPTIONS_RARITY,
  TRAIT_NFT_OPTIONS,
} from "constants/constants";

export default function ConditionNFT({
  isLoading,
  params,
  setParams,
}) {
  const form = useForm();
  const { handleSubmit, setValue } = form;

  const onSubmit = (data) => {
    if (!isLoading) {
      const filteredData = Object.fromEntries(
        Object.entries(data).filter(([_, value]) => value !== "")
      );
      const updatedParams = { ...params, ...filteredData, page: 1 };
      Object.keys(data).forEach((key) => {
        if (data[key] === "") delete updatedParams[key];
      });
      setParams(updatedParams);
    }
  };

  return (
    <Flex
      gap={4}
      flexWrap="wrap"
      justifyContent="space-between"
      w="100%"
      alignItems="flex-end"
    >
      <FormProvider {...form}>
        <form
          style={{
            maxWidth: "100%",
            width: "100%"
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Grid templateColumns="repeat(2, 1fr)" gap={4}>
            <FormSelect
              name="trait"
              label="Trait"
              options={[
                { label: "All", value: "" },
                ...TRAIT_NFT_OPTIONS,
              ]}
              onChange={(e) => {
                setValue("trait", e.target.value);
                handleSubmit(onSubmit)();
              }}
            />
            <FormSelect
              name="rarity"
              label="Rarity"
              options={[{ label: "All", value: "" }, ...OPTIONS_RARITY]}
              onChange={(e) => {
                setValue("rarity", e.target.value);
                handleSubmit(onSubmit)();
              }}
            />
          </Grid>
        </form>
      </FormProvider>
    </Flex>
  );
}
