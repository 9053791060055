import {
	Flex,
	useColorModeValue,
	useDisclosure,
	useToast,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import Paginate from "components/Paginate";
import TableCustom from "components/Tables/CustomTable";
import { useCallback, useEffect, useState } from "react";
import ConditionNonNft from "./condition";
import RowNftGacha from "./row";
import { getGachaHistory, getGachaItemType } from "api/gacha.api";
import { TOKEN_TYPE_LABEL } from "constants/constants";

export default function TableGachaHistory() {
	const [isLoading, setIsLoading] = useState(false);
	const [dataDetail, setDataDetail] = useState(null);

	const [totalPage, setTotalPage] = useState(0);
	const [params, setParams] = useState({
		page: 1,
		limit: 10,
	});
	const toast = useToast();
	const [listGachaHistory, setListGachaHistory] = useState([]);
	const [listType, setListType] = useState([]);

	const labels = [
		"No.",
		"Gacha Name",
		"User",
		"DateTime",
		"Type",
		"Name",
		"Image",
		"Chain",
		"Rarity",
		// "",
	];
	const textColor = useColorModeValue("#000000", "white");

	const { isOpen, onOpen, onClose } = useDisclosure();

	const handleClose = () => {
		onClose();
		setDataDetail(null);
	};

	const getListMachine = useCallback(
		async (newParams = {}) => {
			try {
				setIsLoading(true);
				const { data } = await getGachaHistory({
					...params,
					...newParams,
				});
				if (data?.success) {
					const res = data?.data;
					setListGachaHistory(res?.records);
					setTotalPage(res?.total_page);
				}
			} catch (error) {
				toast({
					description: error?.message || error?.messages[0],
					status: "error",
					position: "bottom-right",
				});
			} finally {
				setIsLoading(false);
			}
		},
		[params]
	);

	const handleChangePage = (page) => {
		setParams({
			...params,
			page,
		});
	};

	useEffect(() => {
		getListMachine();
	}, [getListMachine]);

	const onChangeLimit = (limit) => {
		setParams({
			...params,
			page: 1,
			limit,
		});
	};

	const getListTypes = useCallback(
		async () => {
			try {
				setIsLoading(true);
				const { data } = await getGachaItemType();
				if (data?.success) {
					const res = data?.data?.map((type) => ({
						label: TOKEN_TYPE_LABEL[type],
						value: type
					}));
					setListType(res);
				}
			} catch (error) {
				toast({
					description: error?.message || error?.messages[0],
					status: "error",
					position: "bottom-right",
				});
			} finally {
				setIsLoading(false);
			}
		},
		[]
	);

	useEffect(() => {
		getListTypes()
	}, [])

	return (
		<Flex flexDirection="column">
			<Card px="0px">
				<CardHeader px="24px" mb="12px" display="flex" flexDirection="column" gap="20px">
					<ConditionNonNft
						isLoading={isLoading}
						setParams={setParams}
						params={params}
						listType={listType}
					/>
				</CardHeader>
				<CardBody overflowX="auto">
					<TableCustom
						labels={labels}
						isLoading={isLoading}
						isCheck={false}
						dataRow={listGachaHistory}
					>
						<>
							{listGachaHistory?.map((item) => {
								return (
									<RowNftGacha
										key={item?.id}
										item={item}
									/>
								);
							})}
						</>
					</TableCustom>
				</CardBody>
				<Paginate
					page={params?.page}
					pageCount={totalPage}
					limit={params.limit}
					onChangeLimit={onChangeLimit}
					handleChangePage={handleChangePage}
				/>
			</Card>
		</Flex>
	);
}
