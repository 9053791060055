import {
  Flex,
  Box,
  useToast,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
  Grid
 } from '@chakra-ui/react';
import { Select as ChakraSelect, chakraComponents } from "chakra-react-select";
import React from 'react'
import debounce from "lodash/debounce";
import TableCustom from "components/Tables/CustomTable";
import ButtonType from "components/Button";
import UserKOLRow from "./row"
import { useState, useCallback } from "react";
import { getUserInfo, createUserKOLs } from "api/userKOL.api";
import { FormProvider, useForm } from "react-hook-form";

export default function FormCreateKOL(props) {
  const textColor = useColorModeValue("#000000", "white");
  const toast = useToast();
  const [userOptions, setUserOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState(null);
  const [error, setError] = useState("");

  const {
    userKOLs,
    setUserKOLs,
    isOpen,
    onClose,
    handleCreateSuccess
  } = props

  const labels = [
    "ID",
    "EMAIL",
    "NOTE",
    ""
  ];
  const form = useForm({});

  const {
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = form;

  const handleDelete = (item) => {
    const filteredData = userKOLs.filter((user) => user.user_id !== item.user_id);
    setUserKOLs(filteredData);
  }

  const fetchUsers = async (inputValue) => {
    setIsLoading(true);
    try {
      const params = {
        page: 1,
        limit: 999999,
        keyword: inputValue,
        status: "ACTIVE",
        isExcludeUserKOL: true
      };
      const { data } = await getUserInfo(params);
      if (data?.data?.records?.length > 0) {
        const options = data?.data?.records?.map((user) => {
          return {
            value: user?.id,
            label: user?.email,
          };
        });
        setUserOptions(options);
      } else {
        toast({
          description: "User not found or already verified",
          status: "warning",
          position: "top",
        });
        setUserOptions([]);
      }
    } catch (error) {
      setUserOptions([]);
    } finally {
      setIsLoading(false);
    }
  };


  const debouncedFetchUsers = useCallback(
    debounce((inputValue) => fetchUsers(inputValue), 300),
    []
  );

  const handleInputChange = (inputValue) => {
    if (inputValue.length > 0) {
      debouncedFetchUsers(inputValue);
    } else {
      setUserOptions([]);
    }
  };

  const handleSelectChange = (selectedOption) => {
    if(selectedOption.length == 0) {
      setValue("userKOLs", []);
    }
    setUserKOLs((prev) => [
      ...prev,
      ...selectedOption.map(({ value, label, ...rest }) => ({
        user_id: value,
        email: label,
        note: "",
      }))
    ])
    setInputValue("");
  }

  const onSubmit = async () => {
    if (error) return;
    let dataSubmit = userKOLs
    try {
      const res = await createUserKOLs(dataSubmit);
      if (res?.data?.success) {
        toast({
          title: "User KOL Created.",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
        handleCreateSuccess();
        onClose()
        setUserKOLs([])
      } else {
        toast({
          description: res?.data?.message || res?.data?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxW="900px" overflowY="auto" maxHeight="90vh" overflow="hidden">
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >
          {"Assign New Verification Badge(s)"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px" height="calc(100% - 150px)" overflowY="auto">
          <FormProvider {...form}>
            <form id="present-box-form" onSubmit={handleSubmit(onSubmit)}>
              <Flex direction="column" w="100%" gap={4}>
                <Grid templateColumns="repeat(1, 1fr)" gap={4}>
                  <Flex direction="column" gap={4}>
                    <Flex alignItems="center" gap={6} width="100%">
                      <Box width="100%">
                        <ChakraSelect
                          options={userOptions}
                          placeholder="Search for user(s)"
                          value={inputValue}
                          onChange={handleSelectChange}
                          onInputChange={handleInputChange}
                          isLoading={isLoading}
                          noOptionsMessage={() => null}
                          chakraStyles={{
                            placeholder: (provided) => ({
                              ...provided,
                              color: "gray.400",
                            }),
                            control: (provided) => ({
                              ...provided,
                              cursor: "text",
                            }),
                          }}
                          components={{
                            Option: ({ children, ...props }) => (
                              <chakraComponents.Option {...props}>
                                <Flex alignItems="center">
                                  <Box mr={2}>{children}</Box>
                                </Flex>
                              </chakraComponents.Option>
                            ),
                            DropdownIndicator: () => null,
                          }}
                          isMulti
                        />
                      </Box>
                    </Flex>
                    <TableCustom
                      labels={labels}
                      dataRow={userKOLs}
                      isCheck={false}
                    >
                      {userKOLs?.map((item, index) => {
                        return (
                          <UserKOLRow
                            key={index}
                            item={item}
                            index={index}
                            userKOLs={userKOLs}
                            setUserKOLs={setUserKOLs}
                            error={error}
                            setError={setError}
                            handleDelete={() => handleDelete(item)}
                          />
                        );
                      })}
                    </TableCustom>
                  </Flex>
                </Grid>
              </Flex>
            </form>
          </FormProvider>
        </ModalBody>
        <ModalFooter
          borderTop="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          gap="20px"
        >
          <ButtonType
            mt={4}
            btnType="primary-new-outline"
            m="auto"
            w="100%"
            h="46px"
            borderRadius="5px"
            form="notice-form"
            onClick={onClose}
          >
            Cancel
          </ButtonType>
          <ButtonType
            mt={4}
            type="submit"
            m="auto"
            w="100%"
            h="46px"
            borderRadius="5px"
            form="present-box-form"
            isLoading={isSubmitting}
            disabled={!userKOLs.length}
          >
            Add
          </ButtonType>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
