import {
  Box,
  Flex,
  Text,
  useToast,
} from "@chakra-ui/react";
import { getGameFunction, updateGameFunction } from "api/system";
import ButtonType from "components/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import TableCustom from "components/Tables/CustomTable";
import { useEffect, useState } from "react";
import GameFunctionsRow from "./row";

const labels = [
  "Feature",
  "Image",
  "URL",
  "Web",
  "Android",
  "Ios",
];

export default function GameFunction({ isHasPermissionEdit }) {
  const toast = useToast();
  const [isEdit, setIsEdit] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getListGame = async () => {
    try {
      const { data } = await getGameFunction();
      if (data?.success) {
        const res = data?.data;
        setDataTable(res)
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  useEffect(() => {
    getListGame();
  }, []);

  const handleSwitchFunctions = async (id, game) => {
    try {
      setIsLoading(true);
      const res = await updateGameFunction(id, game);
      if (res?.data?.success) {
        toast({
          title: "Update success.",
          description: "Update success.",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
        getListGame();
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  return (
    <Flex flexDirection="column" w="100%">
      <Card p="0px">
        <CardHeader mb="20px" px="22px" w="100%" display="flex" alignItems="center" justifyContent="space-between">
          <Text
            fontSize="xl"
            fontWeight="bold"
            whiteSpace="nowrap"
          >
            Hide/Show Game Functions
          </Text>
          {isHasPermissionEdit && (
            <Flex>
              {!isEdit ? (
                <ButtonType
                  text="EDIT"
                  w="100px"
                  fontSize="12px"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsEdit(!isEdit);
                  }}
                />
              ) : (
                <ButtonType
                  text="SAVE"
                  w="100px"
                  fontSize="12px"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsEdit(!isEdit);
                  }}
                />
              )}
            </Flex>
          )}
        </CardHeader>
        <CardBody 
          overflowX="auto"
          overflowY="scroll"
          height="500px"
          opacity={isEdit ? 1 : 0.5}
          pointerEvents={isEdit ? 'auto' : 'none'}
          overflow="visible"
          px="22px"
        >
          <TableCustom
            labels={labels}
            isLoading={isLoading}
            isCheck={false}
            dataRow={dataTable}
            isSticky={true}
          >
            <>
              {dataTable?.map((item) => {
                return (
                  <GameFunctionsRow
                    key={item?.id}
                    item={item}
                    handleSwitchFunctions={handleSwitchFunctions}
                  />
                );
              })}
            </>
          </TableCustom>
        </CardBody>
      </Card>
    </Flex>
  );
}
