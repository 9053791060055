import {
  Box,
  Flex,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import FormInput from "components/form/FormInput";
import { useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import ButtonType from "components/Button";
import schemaPosition from "./schema";
import { SIZE_POSITION } from "views/DigitalFigure/constants";
import FormSelect from "components/form/FormSelect";

export default function SetPosition({
  isOpen,
  onClose,
  dataEdit,
  listTypeRoom,
  listPosition,
  setListPosition,
  coordinates,
}) {
  const textColor = useColorModeValue("#000000", "white");
  const toast = useToast();
  const form = useForm({
    resolver: yupResolver(schemaPosition())
  });
  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
    watch,
    setError,
  } = form;

  const idEdit = useMemo(() => {
    return dataEdit?.pos;
  }, [dataEdit]);

  useEffect(() => {
    if (dataEdit) {
      reset({
        ...dataEdit,
      });
    } else {
      reset();
    }
  }, [dataEdit]);

  const onSubmit = async (data, e) => {
    e.preventDefault();
    try {
      if (idEdit) {
        let listPos = []
        listPosition?.forEach((pos) => {
          if (Number(pos?.pos) === Number(data?.position)) {
            listPos.push({
              pos: data?.position,
              type: data?.type,
              size: `${data?.size}`,
              position_x: pos.position_x,
              position_y: pos.position_y,
            })
          } else {
            listPos.push(pos)
          }
        })
        setListPosition(listPos)
        onClose()
      } else {
        if (listPosition?.some((pos) => Number(pos?.pos) === Number(data?.position) || pos?.pos === data?.position)) {
          setError("position", { message: `Position has been duplicated` })
        } else {
          const dataSubmit = {
            pos: data?.position,
            type: data?.type,
            size: `${data?.size}`,
            position_x: coordinates.x,
            position_y: coordinates.y,
          }
          setListPosition(prev => [
            ...prev,
            dataSubmit,
          ])
          onClose()
        }
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  useEffect(() => {
    if (dataEdit?.pos) {
      reset({
        position: dataEdit?.pos,
        position_x: dataEdit?.position_x,
        position_y: dataEdit?.position_y,
        size: dataEdit?.size,
        type: dataEdit?.type,
      });
    } else {
      reset({
        position: '',
        size: 100,
      });
    }
  }, [reset, dataEdit]);

  const renderForm = () => {
    return (
      <FormProvider {...form}>
        <form id="position-form" onSubmit={handleSubmit(onSubmit)}>
          <Flex direction="column" w="100%" gap={4}>
            <Grid templateColumns="repeat(1, 1fr)" gap={4}>
              <Flex direction="column" gap={4}>
                <Box display="flex" flexDirection="column" gap="8px">
                  <FormInput
                    maxW="100%"
                    type="number"
                    min={1}
                    name="position"
                    label="Position ID"
                    placeholder="Position"
                    isRequired
                    disabled={idEdit}
                  />
                </Box>
              </Flex>
              <Flex direction="row" gap={4}>
                <FormSelect
                  name="size"
                  wrapperProps={{
                    w: "100%",
                  }}
                  label="Size"
                  defaultValue={SIZE_POSITION[0].value}
                  options={SIZE_POSITION}
                  isRequired
                />
                <FormSelect
                  name="type"
                  wrapperProps={{
                    w: "100%",
                  }}
                  label="Type"
                  defaultValue={listTypeRoom[0].value}
                  options={listTypeRoom}
                  isRequired
                />
              </Flex>
            </Grid>
          </Flex>
        </form>
      </FormProvider>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxW="700px" overflowY="auto">
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >
          NEW POSITION
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px">{renderForm()}</ModalBody>
        <Flex px="24px" pb="24px" justifyContent="center">
          <Box
            position="relative"
            width="500px"
            height="500px"
            backgroundColor="#ccc"
            border="1px dashed #000"
            borderRadius="8px"
          >
            <Box
              position='absolute'
              top="50%"
              left="50%"
              transform="translate(-50%, -50%)"
              background="#72ff65"
              border="1px solid #0eaa00"
              width={`${(500 * watch("size") / 100)}px`}
              height={`${(500 * watch("size") / 100)}px`}
              borderRadius="8px"
            >
            </Box>
          </Box>
        </Flex>
        <ModalFooter
          borderTop="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          gap="20px"
        >
          <ButtonType
            mt={4}
            btnType="primary-new-outline"
            m="auto"
            w="100%"
            h="46px"
            borderRadius="5px"
            form="position-form"
            onClick={onClose}
          >
            Cancel
          </ButtonType>
          <ButtonType
            mt={4}
            type="submit"
            m="auto"
            w="100%"
            h="46px"
            borderRadius="5px"
            form="position-form"
            isLoading={isSubmitting}
          >
            SAVE
          </ButtonType>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
