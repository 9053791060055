import {
  Box,
  Flex,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  Tabs,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { createRoomBg, updateRoomBg } from "api/digitaFigure.api";
import ButtonType from "components/Button";
import FormCheckbox from "components/form/FormCheckbox";
import FormInput from "components/form/FormInput";
import FormLabelComponent from "components/form/FormLabel";
import FormTextAria from "components/form/FormTextAria";
import FormUpload from "components/form/FormUpload";
import { NUMBER } from "constants/enum";
import { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import styled from "styled-components";
import { uploadImageToServer } from "utils/uploadImage";
import SelectGroupLicensor from "views/DigitalFigure/components/SelectGroupLicensor";
import { ITEM_RESTRICTIONS, TRANS_LABEL } from "views/DigitalFigure/constants";
import { ERROR_CREATE_EVENT } from "views/Event/EventManagement/constant";
import ModalSelectCountries from "views/Notice/createAndUpdate/ModalSelectCountries";
import schemaViewBG from "./schema";

const TextError = styled.div`
  padding: ${props => props.padding || '0 24px'};
  color: #E53E3E;
  font-size: 16px;
`

export default function CreateAndUpdateViewBg({
  isOpen,
  onClose,
  dataDetail,
  handleCreateOrUpdateSuccess,
  countries,
  listLicensors,
}) {
  const [tabIndex, setTabIndex] = useState(0);
  const [trans, setTrans] = useState("en");
  const [nameImage, setNameImage] = useState("");
  const [checkedIds, setCheckedIds] = useState([])
  const textColor = useColorModeValue("#000000", "white");
  const toast = useToast();
  const form = useForm({
    resolver: yupResolver(schemaViewBG(trans))
  });
  const {
    handleSubmit,
    reset,
    setValue,
    watch,
    clearErrors,
    formState: { errors, isSubmitting },
  } = form;

  const { 
    isOpen: isOpenSelectCountry, 
    onOpen: onOpenSelectCountry, 
    onClose: onCloseSelectCountry, 
  } = useDisclosure();

  const disableWhenJP = useMemo(() => {
    return trans === "jp";
  }, [trans]);

  const idEdit = useMemo(() => {
    return dataDetail?.id;
  }, [dataDetail]);

  useEffect(() => {
    if (dataDetail) {
      reset({
        ...dataDetail,
      });
    } else {
      reset();
    }
  }, [dataDetail]);

  const handleTabsChange = (index) => {
    setTabIndex(index);
    if (index === 1) {
      setTrans("jp");
    } else {
      setTrans("en");
    }
  };

  const transLabel = (label) => {
    return TRANS_LABEL[trans][label];
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();
    const listDigital = checkedIds?.map((relate) => ({
      id: relate?.id
    }))
    const dataSubmit = {
      name: data?.name,
      description: data?.description,
      licensor_id: data?.project,
      count: data?.count,
      item_restriction: data?.item_restriction,
      image_url: data?.asset_url,
      country_targets: data?.specify_target_countries ? data?.country_targets : [],
      digital_figure_id: listDigital?.length > 0 ? listDigital[0]?.id : null,
      translations: [
        {
          lang_key: "EN",
          name: data?.name,
          description: data?.description
        },
        {
          lang_key: "JP",
          name: data?.name_jp,
          description: data?.description_jp
        }
      ]
    }
    try {
      if (idEdit) {
        const res = await updateRoomBg(dataDetail?.id, dataSubmit);
        if (res?.data?.success) {
          toast({
            title: "Room BG Edited Successfully.",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
          handleCreateOrUpdateSuccess();
          onClose()
        } else {
          toast({
            description: ERROR_CREATE_EVENT[res?.data?.message] || ERROR_CREATE_EVENT[res?.data?.messages[0]] || res?.data?.messages[0],
            status: "error",
            position: "bottom-right",
          });
        }
      } else {
        const res = await createRoomBg(dataSubmit);
        if (res?.data?.success) {
          toast({
            title: "Room BG Created Successfully.",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
          handleCreateOrUpdateSuccess();
          onClose()
        } else {
          toast({
            description: ERROR_CREATE_EVENT[res?.data?.message] || ERROR_CREATE_EVENT[res?.data?.messages[0]] || res?.data?.messages[0],
            status: "error",
            position: "bottom-right",
          });
        }
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const handleImageChange = async (event) => {
    const image = event[0];
    await uploadImageToServer(image, toast, setValue, "asset_url", setNameImage);
  };

   const handleDragImage = async (file) => {
    if (file.length > NUMBER.ONE) {
      toast({
        description: "The number of uploaded images is greater than one.",
        status: "error",
        position: "bottom-right",
      });
      return false;
    }
    const image = file && file[0];
    await uploadImageToServer(image, toast, setValue, "asset_url", setNameImage);
  };

  const deleteImage = () => {
    setValue('asset_url', null)
  }

  useEffect(() => {
    if (dataDetail?.id) {
      const itemJP = dataDetail?.translations?.find(
        (item) => item?.lang_key === "JP"
      );
      reset({
        name: dataDetail?.name,
        name_jp: itemJP?.name,
        description: dataDetail?.description,
        description_jp: itemJP?.description,
        asset_url: dataDetail?.image_url,
        related: dataDetail?.digital_figure ? [dataDetail?.digital_figure] : [],
        licensor: dataDetail?.licensor?.name,
        project: dataDetail?.licensor_id,
        count: dataDetail?.count,
        item_restriction: dataDetail?.item_restriction,
        country_targets: dataDetail?.country_targets || [],
        specify_target_countries: dataDetail?.country_targets?.length > 0,
      });
      setCheckedIds(dataDetail?.country_targets || [])
    } else {
      reset({
        country_targets: []
      });
    }
  }, [reset, dataDetail]);

  const handleCheckboxChangeAll = (isChecked) => {
    const newCheckedIds = isChecked ? countries.map((country) => country.country_code) : [];
    setCheckedIds(newCheckedIds);
    setValue("country_targets", newCheckedIds);
    clearErrors("country_targets");
  };

  const selectedCountries = useMemo(() => {
    const countryItems = watch("country_targets") ? watch("country_targets") : [];
    return countryItems?.length > 0 ? countryItems : checkedIds;
  }, [watch("country_targets")]);

  const renderForm = () => {
    return (
      <FormProvider {...form}>
        <form id="machine-form" onSubmit={handleSubmit(onSubmit)}>
          <Tabs
            mb={4}
            index={tabIndex}
            variant="unstyled"
            onChange={handleTabsChange}
          >
            <TabList gap={2}>
              <Tab
                fontSize="16px"
                fontWeight="700"
                border="1px solid #EEEFF2"
                boxShadow="none !important"
                borderRadius="5px"
                color={textColor}
                _selected={{
                  color: "white",
                  backgroundImage: "linear-gradient(310deg,#2152ff,#21d4fd)",
                }}
              >
                English
              </Tab>
              <Tab
                fontSize="16px"
                fontWeight="700"
                border="1px solid #EEEFF2"
                boxShadow="none !important"
                borderRadius="5px"
                color={textColor}
                _selected={{
                  color: "white",
                  backgroundImage: "linear-gradient(310deg,#2152ff,#21d4fd)",
                }}
              >
                Japanese
              </Tab>
            </TabList>
          </Tabs>
          <Flex direction="column" w="100%" gap={4}>
            <Grid emplateColumns="repeat(1, 1fr)" gap={4}>
              <Flex direction="column" gap={4}>
                {trans === "en" ? (
                  <Box display="flex" flexDirection="column" gap="8px">
                    <FormInput
                      maxW="100%"
                      name="name"
                      label={transLabel("name")}
                      placeholder={transLabel("enterName")}
                      isRequired
                    />
                    <FormTextAria
                      label={transLabel("description")}
                      placeholder={transLabel("enterDesc")}
                      name="description"
                      defaultValue=''
                      h="100px"
                      borderRadius="8px"
                    />
                  </Box>
                ) : (
                  <Flex flexDirection="column" gap="8px">
                    <FormInput
                      maxW="100%"
                      name="name_jp"
                      label={transLabel("name")}
                      placeholder={transLabel("enterName")}
                      isRequired
                    />
                    <FormTextAria
                      label={transLabel("description")}
                      placeholder={transLabel("enterDesc")}
                      name="description_jp"
                      defaultValue=''
                      h="100px"
                      borderRadius="8px"
                    />
                  </Flex>
                )}
                <>
                  <FormUpload
                    label="Select image file to upload"
                    title={transLabel("asset")}
                    name="asset_url"
                    type='IMAGE'
                    disabled={disableWhenJP}
                    accept={"image/*"}
                    isRequired
                    textButton={nameImage}
                    handleOnChange={(e) =>
                      handleImageChange(e)
                    }
                    handleDragImage={(val) =>
                      handleDragImage(val)
                    }
                    deleteImage={() => deleteImage()}
                  />
                  {!watch("asset_url") && (
                    <Text>*Currently support .png, .jpg less than 200KB </Text>
                  )}
                  <SelectGroupLicensor trans={trans} listLicensors={listLicensors} setValue={setValue} watch={watch}/>
                  <Flex gap="16px" w="100%">
                    <FormInput
                      wrapperProps={{
                        w: "100%",
                      }}
                      type="number"
                      min={0}
                      name="count"
                      label={transLabel("count")}
                      placeholder={transLabel("enterValue")}
                      isRequired
                      disabled={disableWhenJP}
                    />
                    <Box w="100%"></Box>
                  </Flex>
                  <Flex direction="column" gap="12px" w="100%" padding="8px 0">
                    <FormLabelComponent
                      m="0px"
                      title={transLabel("restriction")}
                      isRequired
                    />
                    <Grid templateColumns='repeat(2, 1fr)' gap='16px'>
                      {ITEM_RESTRICTIONS?.map(mode => (
                        <FormCheckbox
                          key={mode.value}
                          name={"item_restriction"}
                          label={mode.label}
                          isChecked={watch("item_restriction") === mode.value}
                          onChange={() => {
                            setValue("item_restriction", mode.value);
                          }}
                          wrapperProps={{
                            display: "flex",
                            alignItems: "center",
                            margin: "0"
                          }}
                        />)
                      )}
                    </Grid>
                    {errors?.item_restriction && <TextError padding="0">{transLabel("required")}</TextError>}
                  </Flex>
                  <Flex alignItems="center" gap="8px">
                    <FormLabelComponent
                      m="0px"
                      title={transLabel("targetCountry")}
                    />
                    <FormCheckbox
                      name={`specify_target_countries`}
                      wrapperProps={{
                        display: "flex",
                        alignItems: "center",
                        margin: "0"
                      }}
                    />
                  </Flex>
                  {watch("specify_target_countries") && (
                    <>
                      <Flex
                        direction="column"
                        gap="12px"
                        w="100%"
                        padding="8px 0"
                      >
                        <Flex alignItems="center" gap="8px">
                          <FormLabelComponent
                            m="0px"
                            title={transLabel("countries")}
                            isRequired
                          />
                          <Text p="4px 10px" bg="#a7e6ff" borderRadius="20px">
                            {checkedIds?.length}/{countries?.length}{" "}
                            {transLabel("selected")}
                          </Text>
                        </Flex>
                        <Grid templateColumns="repeat(2, 1fr)" gap="16px">
                          <FormCheckbox
                            name={`all_country`}
                            label={transLabel("all")}
                            wrapperProps={{
                              display: "flex",
                              alignItems: "center",
                              margin: "0",
                            }}
                            isChecked={
                              selectedCountries?.length === countries?.length &&
                              countries?.length > 0
                            }
                            onChange={(e) =>
                              handleCheckboxChangeAll(e.target.checked)
                            }
                          />
                        </Grid>
                      </Flex>
                      <FormInput
                        type="text"
                        label=""
                        placeholder={transLabel("allCountries")}
                        onClick={onOpenSelectCountry}
                        disabled={
                          (selectedCountries?.length === countries?.length &&
                            countries?.length > 0)
                        }
                        name="country_targets"
                        value=""
                        autoComplete="off"
                        renderIconSearch
                        isReadOnly
                      />
                    </>
                  )}
                </>
              </Flex>
            </Grid>
          </Flex>
        </form>
      </FormProvider>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxW="700px" overflowY="auto">
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >
          {idEdit ? "Edit Room BG" : "New Room BG"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px">{renderForm()}</ModalBody>
        {errors?.name_jp?.message && trans === "en" && <TextError>Please check the form again in the Japanese section.</TextError>}
        <ModalFooter
          borderTop="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          gap="20px"
        >
          <ButtonType
            mt={4}
            btnType="primary-new-outline"
            m="auto"
            w="100%"
            h="46px"
            borderRadius="5px"
            form="machine-form"
            onClick={onClose}
          >
            {transLabel("cancel")}
          </ButtonType>
          <ButtonType
            mt={4}
            type="submit"
            m="auto"
            w="100%"
            h="46px"
            borderRadius="5px"
            form="machine-form"
            isLoading={isSubmitting}
          >
            {transLabel("save")}
          </ButtonType>
        </ModalFooter>
      </ModalContent>
      {isOpenSelectCountry && (
        <ModalSelectCountries
          isOpen={isOpenSelectCountry}
          onClose={onCloseSelectCountry}
          setCheckedIds={setCheckedIds}
          checkedIds={checkedIds}
          setValue={setValue}
          clearErrors={clearErrors}
          watch={watch}
          countries={countries}
        />
      )}
    </Modal>
  );
}
