import {
  Flex,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import ButtonType from "components/Button";
import { FormProvider, useForm } from "react-hook-form";
import { SmallCloseIcon } from "@chakra-ui/icons";
import FormSelect from "components/form/FormSelect";
import { updateBackgroundAllocation, updateDigitalFigureAllocation, updateRoomBgAllocation, updateRoomItemsAllocation } from "api/digitaFigure.api";

export default function ModalAllocateFigure({
  isOpen,
  onClose,
  checkedIds,
  setCheckedIds,
  handleCreateOrUpdateSuccess,
  typeAllocation,
  type,
  title,
  titleSelect
}) {
  const toast = useToast();
  const textColor = useColorModeValue("#000000", "white");
  const form = useForm();

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = form;

  const onSubmit = async (data) => {
    const listIds = checkedIds?.map((check) => check?.id)
    const dataSubmit = {
      ids: listIds,
      allocation: data?.allocation,
    };
    try {
      let res 
      if (type === "viewBg") {
        res = await updateBackgroundAllocation(dataSubmit);
      } else if (type === "roomBg") { 
        res = await updateRoomBgAllocation(dataSubmit);
      } else if (type === "roomItem") { 
        res = await updateRoomItemsAllocation(dataSubmit);
      } else {
        res = await updateDigitalFigureAllocation(dataSubmit);
      }
      if (res?.data?.success) {
        toast({
          title: "Transfer Successfully!",
          description: "Transfer Successfully!",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
        setCheckedIds([])
        handleCreateOrUpdateSuccess()
        onClose()
      } else {
        toast({
          description: res?.data?.message || res?.data?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const filterChecked = (itemRemove) => {
    const dataFilter = checkedIds?.filter((check) => check?.id !== itemRemove?.id)
    if (dataFilter?.lenght === 0) {
      onClose()
    }
    setCheckedIds(dataFilter)
  }

  const renderForm = () => {
    return (
      <FormProvider {...form}>
        <form id="machine-form" onSubmit={handleSubmit(onSubmit)}>
          <Text fontSize={16} fontWeight={600}>{titleSelect}</Text>
          {checkedIds?.length > 0 && (
            <Flex gap="4px" flexWrap="wrap" mt={4} pb={4}>
              {checkedIds?.map((check, index) => (
                <Flex
                  key={index}
                  padding="4px 8px"
                  borderRadius="6px"
                  background="#9bff82"
                  alignItems="center"
                  gap="4px"
                >
                  <Text fontSize="14px" fontWeight="400">{check?.name}</Text>
                  <SmallCloseIcon onClick={() => filterChecked(check)}/>
                </Flex>
              ))}
            </Flex>
          )}
          <Grid templateColumns="repeat(1, 1fr)" gap={4}>
            <FormSelect
              name="allocation"
              label="Allocation"
              defaultValue={typeAllocation[0]?.value}
              options={typeAllocation}
            />
          </Grid>
        </form>
      </FormProvider>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxW="800px" overflowY="auto">
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >
          {title}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px">{renderForm()}</ModalBody>
        <ModalFooter
          borderTop="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          gap="20px"
        >
          <ButtonType
            mt={4}
            type="submit"
            m="auto"
            h="46px"
            borderRadius="5px"
            form="machine-form"
            isLoading={isSubmitting}
            onClick={() => {
              handleSubmit(onSubmit);
            }}
          >
            ALLOCATE
          </ButtonType>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
