import { Flex, Icon, Td, Text, Tr } from "@chakra-ui/react";
import { EditIcon } from "components/Icons/EditIcon";
import { CheckIcon } from "components/Icons/CheckIcon";
import { useState } from "react";
import FormInput from "components/form/FormInput";
import schemaApDistribution from "./schema";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { RARITY } from "views/PrizeSettings/constant";

export default function CostFusionRow ({
  item,
  onSave,
  isHasPermissionEdit,
}) {
  const [isEdit, setIsEdit] = useState(false);
  const form = useForm({
    resolver: yupResolver(schemaApDistribution()),
    defaultValues: {
      marker_a_sold: item?.thresholds[0] ? item?.thresholds[0]?.point : "",
      marker_a_slope: item?.thresholds[0] ? item?.thresholds[0]?.multiplier : "",
      marker_b_sold: item?.thresholds[1] ? item?.thresholds[1]?.point : "",
      marker_b_slope: item?.thresholds[1] ? item?.thresholds[1]?.multiplier : "",
      marker_c_sold: item?.thresholds[2] ? item?.thresholds[2]?.point : "",
      marker_c_slope: item?.thresholds[2] ? item?.thresholds[2]?.multiplier : "",
      marker_d_sold: item?.thresholds[3] ? item?.thresholds[3]?.point : "",
      marker_d_slope: item?.thresholds[3] ? item?.thresholds[3]?.multiplier : "",
    }
  });
  const {
    setValue,
  } = form;
  const onSubmit = form.handleSubmit((data) => {
    if (onSave) {
      onSave(item?.rarity, [
        {
          ...item?.thresholds[0],
          multiplier: data?.marker_a_slope,
          point: data?.marker_a_sold,
        },
        {
          ...item?.thresholds[1],
          multiplier: data?.marker_b_slope,
          point: data?.marker_b_sold,
        },
        {
          ...item?.thresholds[2],
          multiplier: data?.marker_c_slope,
          point: data?.marker_c_sold,
        },
        {
          ...item?.thresholds[3],
          multiplier: data?.marker_d_slope,
          point: data?.marker_d_sold,
        }
      ]);
    }
    setIsEdit(false);
  });

  const exceptThisSymbols = ["e", "E", "+", "-", "."];

  return (
    <>
      { isEdit ? (
        <FormProvider {...form}>
          <Tr>
            <Td width="10%">
              <Flex gap="10px" justifyContent="center">
                <Icon
                  fontSize="30px"
                  borderRadius="8px"
                  cursor="pointer"
                  bg="#07CF84"
                  as={CheckIcon}
                  onClick={onSubmit}
                />
              </Flex>
            </Td>
            <Td
              width="20%"
              fontSize="sm"
              fontWeight={400}
              whiteSpace="nowrap"
            >
              {RARITY[item?.rarity]}
            </Td>
            <Td>
              <Flex alignItems="center" gap="8px">
                <FormInput
                  label=""
                  name="marker_a_sold"
                  type="number"
                  placeholder="Enter number bcoin"
                  onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                  onChange={(e) => {
                    setValue("marker_a_sold", e.target.value.replace(/[^0-9]/g, ""));
                  }}
                />
                /
                <FormInput
                  label=""
                  name="marker_a_slope"
                  type="number"
                  placeholder="Enter number sphere"
                  onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                  onChange={(e) => {
                    setValue("marker_a_slope", e.target.value.replace(/[^0-9]/g, ""));
                  }}
                />
              </Flex>
            </Td>
            <Td>
              <Flex alignItems="center" gap="8px">
                <FormInput
                  label=""
                  name="marker_b_sold"
                  type="number"
                  placeholder="Enter number bcoin"
                  onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                  onChange={(e) => {
                    setValue("marker_b_sold", e.target.value.replace(/[^0-9]/g, ""));
                  }}
                />
                /
                <FormInput
                  label=""
                  name="marker_b_slope"
                  type="number"
                  placeholder="Enter number sphere"
                  onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                  onChange={(e) => {
                    setValue("marker_b_slope", e.target.value.replace(/[^0-9]/g, ""));
                  }}
                />
              </Flex>
            </Td>
            <Td>
              <Flex alignItems="center" gap="8px">
                <FormInput
                  label=""
                  name="marker_c_sold"
                  type="number"
                  placeholder="Enter number bcoin"
                  onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                  onChange={(e) => {
                    setValue("marker_c_sold", e.target.value.replace(/[^0-9]/g, ""));
                  }}
                />
                /
                <FormInput
                  label=""
                  name="marker_c_slope"
                  type="number"
                  placeholder="Enter number sphere"
                  onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                  onChange={(e) => {
                    setValue("marker_c_slope", e.target.value.replace(/[^0-9]/g, ""));
                  }}
                />
              </Flex>
            </Td>
            <Td>
              <Flex alignItems="center" gap="8px">
                <FormInput
                  label=""
                  name="marker_d_sold"
                  type="number"
                  placeholder="Enter number bcoin"
                  onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                  onChange={(e) => {
                    setValue("marker_d_sold", e.target.value.replace(/[^0-9]/g, ""));
                  }}
                />
                /
                <FormInput
                  label=""
                  name="marker_d_slope"
                  type="number"
                  placeholder="Enter number sphere"
                  onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                  onChange={(e) => {
                    setValue("marker_d_slope", e.target.value.replace(/[^0-9]/g, ""));
                  }}
                />
              </Flex>
            </Td>
          </Tr>
        </FormProvider>
      ) : (
        <Tr>
          <Td width="10%">
            <Flex gap="10px" justifyContent="center">
              {isHasPermissionEdit && (
                <Icon
                  fontSize="30px"
                  borderRadius="8px"
                  p="7px"
                  cursor="pointer"
                  bg="#1480FF"
                  as={EditIcon}
                  onClick={() => setIsEdit(true)}
                />
              )}
            </Flex>
          </Td>
          <Td
            width="20%"
            fontSize="sm"
            fontWeight={400}
            whiteSpace="nowrap"
          >
            {RARITY[item?.rarity]}
          </Td>
          <Td
            width="35%"
            fontSize="sm"
            fontWeight={400}
            whiteSpace="nowrap"
          >
            <Flex gap={4}>
              <Text w="100%" textAlign="center" p="2px 0" bg="#ffd13c" borderRadius="12px">
                {item?.thresholds[0]?.point || 0}AP
              </Text>
              /
              <Text w="100%" textAlign="center" p="2px 0" bg="#00ce89" borderRadius="12px">
                {item?.thresholds[0]?.multiplier || 0}
              </Text>
            </Flex>
          </Td>
          <Td
            width="35%"
            fontSize="sm"
            fontWeight={400}
            whiteSpace="nowrap"
          >
            <Flex gap={4}>
              <Text w="100%" textAlign="center" p="2px 0" bg="#ffd13c" borderRadius="12px">
                {item?.thresholds[1]?.point || 0}AP
              </Text>
              /
              <Text w="100%" textAlign="center" p="2px 0" bg="#00ce89" borderRadius="12px">
                {item?.thresholds[1]?.multiplier || 0}
              </Text>
            </Flex>
          </Td>
          <Td
            width="35%"
            fontSize="sm"
            fontWeight={400}
            whiteSpace="nowrap"
          >
            <Flex gap={4}>
              <Text w="100%" textAlign="center" p="2px 0" bg="#ffd13c" borderRadius="12px">
                {item?.thresholds[2]?.point || 0}AP
              </Text>
              /
              <Text w="100%" textAlign="center" p="2px 0" bg="#00ce89" borderRadius="12px">
                {item?.thresholds[2]?.multiplier || 0}
              </Text>
            </Flex>
          </Td>
          <Td
            width="35%"
            fontSize="sm"
            fontWeight={400}
            whiteSpace="nowrap"
          >
            <Flex gap={4}>
              <Text w="100%" textAlign="center" p="2px 0" bg="#ffd13c" borderRadius="12px">
                {item?.thresholds[3]?.point || 0}AP
              </Text>
              /
              <Text w="100%" textAlign="center" p="2px 0" bg="#00ce89" borderRadius="12px">
                {item?.thresholds[3]?.multiplier || 0}
              </Text>
            </Flex>
          </Td>
        </Tr>
      )}
    </>
  );
}
