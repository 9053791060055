import {
  Box,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import NFTsCardHeader from "views/NFTsManagement/components/panel/NFTsCardHeader";
import Freeplay from "./Freeplay";
import BattleArena from "./BattleArena";
import SinglePlay from "./SinglePlay";
import CoopArena from "./CoopArena";
import { listPrizeTypeApi } from "stores/prize/action";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { prizeSelector } from "stores/prize/selector";
import { TYPE_PRIZE } from "constants/constants";
import { PERMISSIONS } from "constants/permissions";
import { usePermission } from "hooks/usePermission";

const TAB_NAMES = ["Singleplay", "Battle Arena", "Co-op Arena", "Freeplay"];

export default function NewGameMode() {
  const { isHasPermissionEdit } = usePermission(PERMISSIONS.GAME_CONTROL_GAME_MODE);
  const dispatch = useDispatch();
  const { listPrizeType } = useSelector(prizeSelector);
  const [prizeTypes, setListPrizeTypes] = useState(() => "");

  useEffect(() => {
    if (listPrizeType?.length) {
      let arr = [{ label: "All", value: "" }];
      listPrizeType?.map((item) => {
        return (arr = [...arr, { label: TYPE_PRIZE[item], value: item }]);
      });
      setListPrizeTypes(() => arr);
    }
  }, [listPrizeType]);

  const tabBg = useColorModeValue("gray.50", "gray.800");
  const activeTabBg = useColorModeValue("white", "gray.700");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const activeTabColor = useColorModeValue("blue.600", "blue.300");

  const getListPrizeType = () => {
    dispatch(listPrizeTypeApi());
  };

  useEffect(() => {
    getListPrizeType();
  }, []);

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <Card px="24px">
        <NFTsCardHeader title="Game Mode" />
        <Tabs
          isLazy
          variant="enclosed"
        >
          <TabList borderBottom={`1px solid ${borderColor}`}>
            {TAB_NAMES.map((name) => (
              <Tab
                key={name}
                fontWeight="semibold"
                _selected={{
                  color: activeTabColor,
                  bg: activeTabBg,
                  borderBottom: "3px solid",
                  borderBottomColor: activeTabColor,
                  marginBottom: "-1px",
                }}
                _hover={{
                  color: activeTabColor,
                }}
                bg={tabBg}
                borderTopRadius="md"
                mr={1}
                py={3}
                px={5}
              >
                {name}
              </Tab>
            ))}
          </TabList>
          <Box mt={4}>
            <TabPanels>
              <TabPanel padding="0 0 24px 0">
                <SinglePlay type="PVE_SINGLE_PLAY" prizeTypes={prizeTypes} isHasPermissionEdit={isHasPermissionEdit}/>
              </TabPanel>
              <TabPanel padding="0 0 24px 0">
                <BattleArena type="PVP" prizeTypes={prizeTypes} isHasPermissionEdit={isHasPermissionEdit}/>
              </TabPanel>
              <TabPanel padding="0 0 24px 0">
                <CoopArena type="CO_OP" prizeTypes={prizeTypes} isHasPermissionEdit={isHasPermissionEdit}/>
              </TabPanel>
              <TabPanel padding="0 0 24px 0">
                <Freeplay type="PVE_FREE_PLAY" isHasPermissionEdit={isHasPermissionEdit}/>
              </TabPanel>
            </TabPanels>
          </Box>
        </Tabs>
      </Card>
    </Flex>
  );
}
