import http from "./http";
import END_POINT from "./constants";
import { convertParams, omitParams } from "utils/object";

export const getDataGauge = (p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.RARITY}?${params}`);
};

export const updateDataEnergyGauge = (idEdit, data, option = {}) => {
  return http.put(`${END_POINT.RARITY}/energy-gauge/${idEdit}`, data, option);
};

export const updateDataLifeGauge = (idEdit, data, option = {}) => {
  return http.put(`${END_POINT.RARITY}/life-gauge/${idEdit}`, data, option);
};
