import {
  FormLabel,
  Text,
  Grid,
  Image,
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
  TagLabel,
  Tag,
  useToast,
} from "@chakra-ui/react";
import { getDayLLLL } from "utils/time";
import { lowerCase, upperFirst } from "lodash";
import { DISTRIBUTE_CONDITION } from "./constant";
import TableCustom from "components/Tables/CustomTable";
import { useState, useEffect } from "react";
import RowGift from "./createAndUpdate/rowGift"
import { getEventById } from "api/presentBox.api";

export default function DetailPresentBox({
  isOpen,
  onClose,
  dataDetail,
}) {
  const textColor = useColorModeValue("#000000", "white");
  const [gifts, setGifts] = useState([]);
  const [event, setEvent] = useState([]);
  const toast = useToast();
  const labels = [
    "NAME",
    "TYPE",
    "SUB TYPE",
    "VALUE",
    ""
  ];

  useEffect(() => {
    const gifts = dataDetail?.gifts;

      if(gifts.length) {
        let resourceData = {};
        const nftData = {};
        let card = [];

        gifts.forEach((item) => {
          if (item.resource) {
            resourceData[item.user_id] = item.resource;
          }
          if (item.nft_items) {
            nftData[item.user_id] = item.nft_items;
          }
          if(item.event_invitation_cards){
            card = item.event_invitation_cards
          }
        });

        if(dataDetail?.multiple_item_recipient_type === "SAME") {
          resourceData = Object.fromEntries([Object.entries(resourceData)[0]]);
        }

        const giftsData = [
          ...card.map((item) => ({
            type: "Invitation Card",
            name: item?.card_name,
            value: item?.amount,
          })),
          ...Object.entries(resourceData).flatMap(([userId, resources]) =>
            Object.keys(resources)
              .filter((key) => resources[key] > 0)
              .map((key) => ({
                type: "Point & Currency",
                name: key,
                value: resources[key],
                user_id: userId || "Unknown",
              }))
          ),
          ...Object.entries(nftData).flatMap(([userId, nfts]) =>
            Object.keys(nfts)
              .map((key) => {
                const name = nfts[key].nft_detail_hunter.name || nfts[key].nft_detail_gauntlet.name || nfts[key].nft_detail_bounty_ball.name || "Unknown";
                return {
                  type: "NFT",
                  name: name,
                  value: 1,
                  user_id: userId || "Unknown",
                }})
          ),
        ];

        setGifts(giftsData);
      }
  }, [dataDetail]);

  const eventDetail = async () => {
    try {
      const { data } = await getEventById(dataDetail?.condition_setting?.event_id);
      if (data?.success) {
        const res = data?.data;
        setEvent(res);
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  }

  useEffect(() => {
    if(dataDetail?.condition_setting?.event_id) {
      eventDetail();
    }
  }, [])

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxW="1000px" overflowY="auto" maxHeight="90vh" overflow="auto">
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >
          DETAIL PRESENT BOX
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px" height="calc(100% - 70px)" overflowY="auto">
          <Grid templateColumns="repeat(1, 1fr)" gap={4}>
            <div>
              <FormLabel fontWeight="700" fontSize="16px" color={textColor}>
                Mgmt Name
              </FormLabel>
              <Text
                ml={2}
                mt={4}
                fontSize="16px"
                fontWeight="400"
                color={textColor}
              >
                {dataDetail?.mgmt_name}
              </Text>
            </div>
            <div>
              <FormLabel fontWeight="700" fontSize="16px" color={textColor}>
                Name
              </FormLabel>
              <Text
                ml={2}
                mt={4}
                fontSize="16px"
                fontWeight="400"
                color={textColor}
              >
                {dataDetail?.name_en}
              </Text>
            </div>
            <div>
              <FormLabel fontWeight="700" fontSize="16px" color={textColor}>
                Description
              </FormLabel>
              <Text
                ml={2}
                mt={4}
                fontSize="16px"
                fontWeight="400"
                color={textColor}
              >
                {dataDetail?.description_en}
              </Text>
            </div>
            <div>
              <FormLabel fontWeight="700" fontSize="16px" color={textColor}>
                Distribution Types
              </FormLabel>
              <Text
                ml={2}
                mt={4}
                fontSize="16px"
                fontWeight="400"
                color={textColor}
              >
                {upperFirst(lowerCase(dataDetail?.distribution_type))}
              </Text>
            </div>
            {
              dataDetail?.distribution_type === "CONDITION" && (
              <>
                <div>
                  <FormLabel fontWeight="700" fontSize="16px" color={textColor}>
                    Distribution Condition
                  </FormLabel>
                  <Text
                    ml={2}
                    mt={4}
                    fontSize="16px"
                    fontWeight="400"
                    color={textColor}
                  >
                    {DISTRIBUTE_CONDITION.find(item => item.value === dataDetail?.distribution_condition)?.label}
                  </Text>
                </div>
                <div>
                  <FormLabel fontWeight="700" fontSize="16px" color={textColor}>
                    Event
                  </FormLabel>
                  <Text
                    ml={2}
                    mt={4}
                    fontSize="16px"
                    fontWeight="400"
                    color={textColor}
                  >
                    {event?.name}
                  </Text>
                </div>
                {
                  dataDetail?.distribution_condition === "EVENT_TOP_RANK" && (
                    <>
                      <div>
                        <FormLabel fontWeight="700" fontSize="16px" color={textColor}>
                          From
                        </FormLabel>
                        <Text
                          ml={2}
                          mt={4}
                          fontSize="16px"
                          fontWeight="400"
                          color={textColor}
                        >
                          {dataDetail?.condition_setting?.from_rank}
                        </Text>
                      </div>
                      <div>
                        <FormLabel fontWeight="700" fontSize="16px" color={textColor}>
                          To
                        </FormLabel>
                        <Text
                          ml={2}
                          mt={4}
                          fontSize="16px"
                          fontWeight="400"
                          color={textColor}
                        >
                           {dataDetail?.condition_setting?.to_rank}
                        </Text>
                      </div>
                    </>
                  )
                }
              </>
              )
            }
            <div>
              <FormLabel fontWeight="700" fontSize="16px" color={textColor}>
                Distribution Date
              </FormLabel>
              <Text
                ml={2}
                mt={4}
                fontSize="16px"
                fontWeight="400"
                color={textColor}
              >
                 {getDayLLLL(dataDetail?.distribution_start_date * 1000)}
              </Text>
            </div>
            <div>
              <FormLabel fontWeight="700" fontSize="16px" color={textColor}>
                Recipients
              </FormLabel>
              <Text
                ml={2}
                mt={4}
                mb={2}
                fontSize="16px"
                fontWeight="400"
                color={textColor}
              >
                 {dataDetail?.recipient_type}
              </Text>
              {
                dataDetail?.recipient_type !== "ALL" && (
                  dataDetail?.receivers?.map((receiver) => (
                    <Tag key={receiver.id} colorScheme="teal" mr={2} mb={2}>
                      <TagLabel>{receiver.email}</TagLabel>
                    </Tag>
                  ))
                )
              }
            </div>
            <div>
              <FormLabel fontWeight="700" fontSize="16px" color={textColor}>
                Select Present Box Items
              </FormLabel>
              {dataDetail?.multiple_item_recipient_type === 'SAME' || dataDetail?.multiple_item_recipient_type === null ? (
                gifts.length > 0 && (
                  <TableCustom
                  labels={labels}
                  dataRow={gifts}
                  isCheck={false}
                >
                  {gifts?.map((item, index) => {
                    return (
                      <RowGift
                        key={index}
                        item={item}
                        handleDelete
                        isDetail={true}
                      />
                    );
                  })}
                </TableCustom>
                )
              ) : (
                dataDetail?.receivers?.map((receiver) => (
                  <>
                    <Tag key={receiver.id} colorScheme="teal" mr={2} mb={2} mt={2}>
                      <TagLabel>{receiver.email}</TagLabel>
                    </Tag>
                    {gifts.filter((gift) => gift.user_id === receiver.id).length > 0 && (
                      <TableCustom
                        labels={labels}
                        dataRow={gifts.filter((gift) => gift.user_id === receiver.id)}
                        isCheck={false}
                      >
                        {gifts.filter((gift) => gift.user_id === receiver.id)?.map((item, index) => {
                          return (
                            <RowGift
                              key={index}
                              item={item}
                              handleDelete
                              isDetail={true}
                            />
                          );
                        })}
                      </TableCustom>
                    )}
                  </>
                ))
              )}
            </div>
            <div>
              <FormLabel fontWeight="700" fontSize="16px" color={textColor}>
                Receipt Expiration Settings
              </FormLabel>
              <Text
                ml={2}
                mt={4}
                fontSize="16px"
                fontWeight="400"
                color={textColor}
              >
                 {dataDetail?.is_no_expiration ? 'No Expiration' : getDayLLLL(dataDetail?.expired_date * 1000)}
              </Text>
            </div>
          </Grid>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
