import {
  Flex,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { getInquiries } from "api/system";
import ButtonType from "components/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import TableCustom from "components/Tables/CustomTable";
import { useCallback, useEffect, useState } from "react";
import ActionInquiries from "./action";
import Paginate from "components/Paginate";
import InquiriesCreateAndUpdate from "./components/createAndUpdate";
import InquiriesRow from "./row";

const labels = [
  "",
  "inquiry (ENGLISH)",
  "inquiry (JAPANESE)",
];

export default function Inquiries({ isHasPermissionEdit }) {
  const toast = useToast();
  const [isEdit, setIsEdit] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [dataDetail, setDataDetail] = useState(null);
  const [totalPage, setTotalPage] = useState(0);
  const [checkedIds, setCheckedIds] = useState([]);
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
  });
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const textColor = useColorModeValue("#000000", "white");

  const handleOpenCreateOrUpdate = (data = null) => {
    setDataDetail(data);
    onOpen();
  };

  const handleClose = () => {
    onClose();
    setDataDetail(null);
  };

  const handleCreateOrUpdateSuccess = useCallback(() => {
    handleClose();
    setDataDetail(null);
    setParams({
      ...params,
      page: 1,
    });
  }, [params]);

  const getListInquiries = useCallback(
    async (newParams = {}) => {
      try {
        setIsLoading(true);
        const { data } = await getInquiries({
          ...params,
          ...newParams,
        });
        if (data?.success) {
          const res = data?.data;
          setDataTable(res?.records);
          setTotalPage(res?.total_page);
        }
      } catch (error) {
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      } finally {
        setIsLoading(false);
      }
    },
    [params]
  );

  const handleChangePage = (page) => {
    setParams({
      ...params,
      page,
    });
  };

  const onChangeLimit = (limit) => {
    setParams({
      ...params,
      page: 1,
      limit,
    });
  };

  useEffect(() => {
    getListInquiries();
  }, [getListInquiries]);

  const onChangeChecked = (isChecked) => {
    if (isChecked) {
      setCheckedIds(dataTable);
    } else {
      setCheckedIds([]);
    }
  };

  return (
    <Flex flexDirection="column" w="100%">
      <Card p="0px">
        <CardHeader mb="20px" px="22px" w="100%" display="flex" alignItems="center" justifyContent="space-between">
          <Text
            fontSize="xl"
            fontWeight="bold"
            whiteSpace="nowrap"
          >
            Inquiries
          </Text>
          {isHasPermissionEdit && (
            <Flex>
              {!isEdit ? (
                <ButtonType
                  text="EDIT"
                  w="100px"
                  fontSize="12px"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsEdit(!isEdit);
                  }}
                />
              ) : (
                <ButtonType
                  text="SAVE"
                  w="100px"
                  fontSize="12px"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsEdit(!isEdit);
                  }}
                />
              )}
            </Flex>
          )}
        </CardHeader>
        <Flex
          flexDirection="column"
          opacity={isEdit ? 1 : 0.5}
          pointerEvents={isEdit ? 'auto' : 'none'}
        >
          <CardHeader px="22px" mb="20px" w="100%">
            <Flex
              w="100%"
              gap={4}
              direction={{
                base: "column",
                md: "row",
              }}
              justifyContent={{
                base: "flex-start",
                md: "space-between",
              }}
              alignItems="flex-start"
            >
              <Text
                color={textColor}
                fontSize="lg"
                fontWeight="bold"
                whiteSpace="nowrap"
              >
                Inquiries List
              </Text>
              <ActionInquiries
                handleOpenCreateOrUpdate={handleOpenCreateOrUpdate}
                handleCreateOrUpdateSuccess={handleCreateOrUpdateSuccess}
                checkedIds={checkedIds}
                setCheckedIds={setCheckedIds}
              />
            </Flex>
          </CardHeader>
          <CardBody overflowX="auto" >
            <TableCustom
              labels={labels}
              isLoading={isLoading}
              isCheck
              isChecked={dataTable?.length > 0 && checkedIds.length === dataTable?.length}
              onChangeChecked={onChangeChecked}
              dataRow={dataTable}
            >
              <>
                {dataTable?.map((item, index) => {
                  return (
                    <InquiriesRow
                      key={item?.id}
                      handleOpenCreateOrUpdate={() => {
                        handleOpenCreateOrUpdate(item);
                      }}
                      setCheckedIds={setCheckedIds}
                      checkedIds={checkedIds}
                      item={item}
                    />
                  );
                })}
              </>
            </TableCustom>
          </CardBody>
          <Paginate
            page={params?.page}
            pageCount={totalPage}
            limit={params.limit}
            onChangeLimit={onChangeLimit}
            handleChangePage={handleChangePage}
          />
        </Flex>
      </Card>
      {isOpen && (
        <InquiriesCreateAndUpdate
          isOpen={isOpen}
          onClose={handleClose}
          dataDetail={dataDetail}
          handleCreateOrUpdateSuccess={handleCreateOrUpdateSuccess}
        />
      )}
    </Flex>
  );
}
