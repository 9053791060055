import END_POINT from "./constants";
import http from "./http";
import { convertParams, omitParams } from "utils/object";

export const getTreasureBox = (p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.TREASURE_BOX}?${params}`);
};

export function updateTreasureBox(id, data) {
  return http.put(`${END_POINT.TREASURE_BOX}/${id}`, data);
}

export function createTreasureBox(data) {
  return http.post(`${END_POINT.TREASURE_BOX}`, data);
}

export const getTreasureBoxConfig = () => {
  return http.get(`${END_POINT.TREASURE_BOX}/config`);
};

export function updateTreasureBoxConfig(data) {
  return http.put(`${END_POINT.TREASURE_BOX}/config`, data);
}
