import END_POINT from "./constants";
import http from "./http";
import { convertParams, omitParams } from "utils/object";

export const getListUserKOL = (p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.USER_KOL}?${params}`);
};

export const createUserKOLs = (data, option = {}) => {
  return http.post(END_POINT.USER_KOL, data, option);
};

export function updateUserKOL(id, data) {
  return http.put(`${END_POINT.USER_KOL}/${id}`, data);
}

export const deleteUserKOLs = (ids, option = {}) => {
  return http.delete(`${END_POINT.USER_KOL}`, ids, option);
};

export function getUserInfo(p = {}) {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.USER_INFO}?${params}`);
}
