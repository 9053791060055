import {
  Flex,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import Paginate from "components/Paginate";
import TableCustom from "components/Tables/CustomTable";
import { useCallback, useEffect, useState } from "react";
import PlayerLevelRow from "./row";
import { getPlayerLevelXp, importPlayerLevelXp } from "api/playerLevel.api";
import ActionWalletManagement from "./action";
import useWithToast from "hooks/useWithToast";
import ModalImportFile from "components/Modal/ModalImportFile";

export default function PlayerLevelXp({ isHasPermissionEdit }) {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
  });
  const [wallets, setWallets] = useState([]);
  const [isLoadingImport, setIsLoadingImport] = useState(false);
  const { showToastSuccess, showToastFail } = useWithToast();

  const {
    isOpen: isOpenImportCSV,
    onOpen: onOpenImportCSV,
    onClose: onCloseImportCSV,
  } = useDisclosure();

  const handleImport = async (file) => {
    setIsLoadingImport(true);
    try {
      const { data } =await importPlayerLevelXp(file);
      if (data?.success) {
        onCloseImportCSV();
        showToastSuccess({
          title: "Imported data successfully.",
        });
        await getListPlayerLevel()
      } else {
        const message = data?.message?.content || data?.messages[0];
        showToastFail({
          title: message,
        });
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    } finally {
      setIsLoadingImport(false);
    }
  };

  const labels = ["LEVEL ", "XP REQUIRED"];
  const textColor = useColorModeValue("#000000", "white");

  const getListPlayerLevel = useCallback(
    async (newParams = {}) => {
      try {
        setIsLoading(true);
        const { data } = await getPlayerLevelXp({
          ...params,
          ...newParams,
        });
        if (data?.success) {
          const res = data?.data;
          setWallets(res?.records);
          setTotalPage(res?.total_page);
        }
      } catch (error) {
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      } finally {
        setIsLoading(false);
      }
    },
    [params]
  );

  const handleChangePage = (page) => {
    setParams({
      ...params,
      page,
    });
  };

  useEffect(() => {
    getListPlayerLevel();
  }, [getListPlayerLevel]);

  const onChangeLimit = (limit) => {
    setParams({
      ...params,
      page: 1,
      limit,
    });
  };

  return (
    <Card px="0px">
      <CardHeader px="22px" mb="12px">
        <Flex
          w="100%"
          gap={4}
          direction={{
            base: "column",
            md: "row",
          }}
          justifyContent={{
            base: "flex-start",
            md: "space-between",
          }}
          alignItems="flex-start"
        >
          <Text
            color={textColor}
            fontSize="lg"
            fontWeight="bold"
            whiteSpace="nowrap"
          >
            Player Level
          </Text>
          <ActionWalletManagement onOpenImportCSV={onOpenImportCSV} isHasPermissionEdit={isHasPermissionEdit}/>
        </Flex>
      </CardHeader>
      <CardBody overflowX="auto">
        <TableCustom
          labels={labels}
          isLoading={isLoading}
          isCheck={false}
          dataRow={wallets}
        >
          <>
            {wallets?.map((item) => {
              return (
                <PlayerLevelRow
                  key={item?.id}
                  item={item}
                />
              );
            })}
          </>
        </TableCustom>
      </CardBody>
      <Paginate
        page={params?.page}
        pageCount={totalPage}
        limit={params.limit}
        onChangeLimit={onChangeLimit}
        handleChangePage={handleChangePage}
      />
      {isOpenImportCSV && (
        <ModalImportFile
          isOpen={isOpenImportCSV}
          onClose={onCloseImportCSV}
          handleImport={handleImport}
          isLoading={isLoadingImport}
        />
      )}
    </Card>
  );
}
