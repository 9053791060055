import { containSpecialUserNameRegex, containSpecialUserNameJapanRegex } from "utils/regex";
import * as yup from "yup";

const schemaStandardEvent = (transType) =>
  yup.object().shape({
    name: yup
      .string()
      .trim()
      .matches(containSpecialUserNameRegex, "Name should not contain special characters")
      .required("Required")
      .max(255, "Maximum limit of 255 characters."),
    name_jp: yup
      .string()
      .trim()
      .required("Required")
      .matches(containSpecialUserNameJapanRegex, "Name should not contain special characters")
      .max(255, "Maximum limit of 255 characters."),
    description: yup
      .string()
      .trim()
      .max(1000, "Maximum limit of 1000 characters."),
    description_jp: yup
      .string()
      .trim()
      .max(1000, "Maximum limit of 1000 characters."),
    image_url: yup
      .string()
      .trim()
      .required("Required"),
    asset_url: yup
      .string()
      .trim()
      .required("Required"),
    serial_code: yup
      .array()
      .nullable()
      .min(1, 'Required'),
    licensor: yup
      .string()
      .trim()
      .required("Required"),
    project: yup
      .string()
      .trim()
      .required("Required"),
    count: yup
      .number()
      .transform((_, originalValue) => {
        return originalValue === "" ? undefined : Number(originalValue);
      })
      .test("is-required", "Required", function (value) {
        return transType !== "en" || (typeof value === "number" && !isNaN(value));
      })
      .max(99999999, "Count must be at most 8 characters")
      .integer("Must be an integer")
      .positive("Count must be greater than 0"),
    country_targets: yup
      .array()
      .when("specify_target_countries", {
        is: true,
        then: (schema) => schema.min(1, "Required"),
      }),
  });

export default schemaStandardEvent;
