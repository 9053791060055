import * as yup from "yup";
const schemaRole = () =>
  yup.object().shape({
    name: yup
      .string()
      .trim()
      .required("Required")
      .max(255, "Maximum limit of 255 characters."),
  });

export default schemaRole;
