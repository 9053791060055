import { Flex, Icon, Image, Stack, Td, Text, Tr, useColorModeValue } from "@chakra-ui/react";
import CheckBoxType from "components/Checkbox";
import { EditIcon } from "components/Icons/EditIcon";
import { ViewIcon } from "components/Icons/ViewIcon";
import { useMemo } from "react";

const DigitalManagementRow = ({ item, index, checkedIds, setCheckedIds, toggle, isHasPermissionEdit }) => {
  const textColor = useColorModeValue("#000000", "white");

  const isChecked = useMemo(() => {
    return checkedIds.some((itemCheck) => itemCheck?.id === item?.id);
  }, [item?.id, checkedIds]);

  const handleCheckboxChange = () => {
    if (isChecked) {
      setCheckedIds(
        checkedIds.filter((itemCheck) => itemCheck?.id !== item?.id)
      );
    } else {
      setCheckedIds([...checkedIds, item]);
    }
  };
  
  return (
    <Tr>
      <Td width="10px">
        <CheckBoxType
          isChecked={isChecked}
          onChange={handleCheckboxChange}
          colorScheme="teal"
          disabled={item?.allocation !== 'NONE' ? true : false}
        />
      </Td>
      <Td>
        <Flex gap={6} align="center">
          <Icon
            fontSize="30px"
            borderRadius="8px"
            cursor="pointer"
            as={ViewIcon}
            onClick={() => toggle('modalDetailViewBg', item)}
          />
          {isHasPermissionEdit && (
            <Icon
              onClick={() => toggle("modalCreateAndEdit", item)}
              fontSize="30px"
              borderRadius="8px"
              p="7px"
              cursor="pointer"
              bg="#1480FF"
              as={EditIcon}
              pointerEvents={item?.allocation !== "NONE" ? "none" : "auto"}
              opacity={item?.allocation !== "NONE" ? 0.5 : 1}
            />
          )}
        </Flex>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {index}
        </Text>
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <Image
            src={item?.image_url}
            w="100px"
            alt={item?.image_url}
          />
        </Stack>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.name}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.licensor?.name}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.licensor?.project}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.count}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.allocation}
        </Text>
      </Td>
    </Tr>
  );
};

export default DigitalManagementRow;
