export const SITE_LABEL = {
  EMAIL: 'Email',
  GMAIL: 'Gmail',
  DM2: 'Oasys',
  IMMUTABLE: 'Immutable',
}

export const ERROR_SYSTEM = {
  INVALID_ACCOUNT: "Invalid Account",
  SYSTEM_MAINTENANCE_MARKETPLACE_SCHEDULE_TIME_INVALID: "End time must be after start time",
  TEMPLATE_IS_REQUIRED: "Template is required",
  LANGUAGE_IS_REQUIRED: "Language is required",
  NAME_IS_REQUIRED: "Name is required",
  SUBJECT_IS_REQUIRED: "Subject is required",
  CONTENT_IS_REQUIRED: "Content is required",
  TEMPLATE_NOT_FOUND: "Template not found",
  MISSING_REQUITED_LANGUAGES: "Missing required languages",
  MAIL_SETTINGS_NOT_FOUND: "Main setting not found",
  PASSWORD_IS_REQUIRED: "Password is required",
  PASSWORD_IS_NOT_VALID: "Password is not valid",
  USER_NAME_IS_REQUIRED: "Username is required",
  PORT_IS_REQUIRED: "Port is required",
  HOST_IS_REQUIRED: "Host is required",
  SENDER_IS_REQUIRED: "Sender is required",
  TO_IS_REQUIRED: "To is required",
  TO_MUST_NOT_EMPTY: "To must not empty",
  FROM_IS_REQUIRED: "From is required",
  SETTING_PAYMENT_FINCODE_NOT_FOUND: "Setting payment fincode not found",
  SETTING_PAYMENT_SLASH_NOT_FOUND: "Setting payment slash not found",
  EMAIL_IS_REQUIRED: "Email is required",
  NOT_PERMIT_CHANGE_START_TIME_WHEN_MAINTENANCE_STARTED: "Not permit change start time when maintenance started",
  GAME_SITE_MAINTENANCE_TIME_INVALID_START_OR_END_TIME_IS_NULL: "Game site maintenance time invalid start or end time is null",
  REGISTRATION_SITE_MAINTENANCE_TIME_INVALID_START_OR_END_TIME_IS_NULL: "Registration site maintenance time invalid start or end time is null",
  MARKETPLACE_SITE_MAINTENANCE_TIME_INVALID_START_OR_END_TIME_IS_NULL: "Marketplace site maintenance time invalid start or end time is null",
}
