import { ExchangeRateIcon } from "components/Icons/ExchangeRateIcon";
import { GameControlIcon } from "components/Icons/GameControlIcon";
import { GauntletIcon } from "components/Icons/GauntletIcon";
import { DocumentIcon, HomeIcon, PersonIcon } from "components/Icons/Icons";
import { ManagementGenesis } from "components/Icons/ManagementGenesis";
import { RoleIcon } from "components/Icons/RoleIcon";
import { WalletManagementIcon } from "components/Icons/WalletManagementIcon";
import { FaUsers } from "react-icons/fa";
import { GiVendingMachine } from "react-icons/gi";
import AdminManagement from "views/AdminManagement";
import SignInBasic from "views/Authentication/SignIn/SignInBasic.js";
import SignInCover from "views/Authentication/SignIn/SignInCover.js";
import SignInIllustration from "views/Authentication/SignIn/SignInIllustration.js";
import SignUpBasic from "views/Authentication/SignUp/SignUpBasic.js";
import SignUpCover from "views/Authentication/SignUp/SignUpCover.js";
import SignUpIllustration from "views/Authentication/SignUp/SignUpIllustration.js";
import Contact from "views/Contact";
import DigitalManagement from "views/DigitalFigure/ListDigitalFigure";
import EventManagementNew from "views/Event/EventManagement";
import ExchangeRateSetting from "views/ExchangeRateSetting";
import MachineGamePlay from "views/Machine/MachineGamePlay";
import MachineMap from "views/Machine/MachineMap";
import MachineSettings from "views/Machine/MachineSettings";
import NewGameMode from "views/NewGameMode";
import NftBountyBallManagementPage from "views/NFTsManagement/BountyBallNFTs";
import NftGauntletManagementPage from "views/NFTsManagement/GauntletNFTs";
import NftHunterManagementPage from "views/NFTsManagement/HunterNFTs";
import NftImportManagementPage from "views/NFTsManagement/NFTsImport";
import PrizeSettings from "views/PrizeSettings";
import RoleSystemPage from "views/RoleSystem";
import UserKOLManagement from "views/Users/KOLManagement";
import UserManagement from "views/Users/UserManagement";
import WalletManagement from "views/WalletManagement";
import { DigitalFigureIcon } from "components/Icons/DigitalFigure";
import { NoticeIcon } from "components/Icons/Notice";
import { PresentBoxBlackIcon } from "components/Icons/PresentBoxBlackIcon";
import { StatisticsIcon } from "components/Icons/StatisticsIcon";
import { AdsIcon } from "components/Icons/AdsIcon";
import { PERMISSIONS } from "constants/permissions";
import AdminWalletPage from "views/AdminWallet";
import Ads from "views/Ads";
import DashboardPage from "views/Dashboard";
import DFViewBG from "views/DigitalFigure/DFViewBG";
import FusionAndAwaken from "views/FusionAwaken";
import GachaHistory from "views/GachaManagement/GachaHistory";
import GachaManagement from "views/GachaManagement/GachaManagement";
import LargeRewardContainer from "views/GachaManagement/LargeRewardContainer";
import NonNftGachaManagement from "views/GachaManagement/NonNftGachaManagement";
import LoginBonus from "views/LoginBonus";
import NftOwnerManagementPage from "views/NFTsManagement/OwnerNFTs";
import MonthlyPayoutSettingsPage from "views/NFTsManagement/OwnerNFTs/MonthlyPayoutSettings";
import Notice from "views/Notice";
import PresentBoxHistory from "views/PresentBoxHistory";
import PresentBoxMangement from "views/PresentBoxManagement";
import SettingSystem from "views/SettingSystem";
import RoomItems from "views/DigitalFigure/RoomItems";
// import ShopPackage from "views/ShopPackage";
import PlayerLevel from "views/PlayerLevel";
import GaugeManagement from "views/GaugeManagement";
import DailyRewardPool from "views/DailyRewardPool";
import XpReward from "views/XpReward";
import ApDistribution from "views/ApDistribution";
import RoomBG from "views/DigitalFigure/RoomBG";
import TreasureBoxManagement from "views/TreasureBoxManagement";
import Licensors from "views/DigitalFigure/Licensors";
import CoinConsumption from "views/CoinConsumption";

const dashRoutes = [
  {
    name: "Dashboard",
    path: "/dashboard/default",
    icon: <HomeIcon color="inherit" />,
    authIcon: <HomeIcon color="inherit" />,
    collapse: false,
    component: DashboardPage,
    layout: "/admin",
    permission: PERMISSIONS.DASHBOARD,
  },
  {
    name: "PAGES",
    category: "pages",
    isHide: true,
    items: [
      {
        name: "Authentication",
        path: "/authentication",
        icon: <PersonIcon color="inherit" />,
        collapse: true,
        items: [
          {
            name: "Sign In",
            path: "/authentication/sign-in",
            collapse: true,
            authIcon: <DocumentIcon color="inherit" />,
            items: [
              {
                name: "Basic",
                secondaryNavbar: true,
                component: SignInBasic,
                path: "/authentication/sign-in/basic",
                layout: "/auth",
              },
              {
                name: "Cover",
                component: SignInCover,
                path: "/authentication/sign-in/cover",
                layout: "/auth",
              },
              {
                name: "Illustration",
                component: SignInIllustration,
                path: "/authentication/sign-in/illustration",
                layout: "/auth",
              },
            ],
          },
          {
            name: "Sign Up",
            path: "/authentication/sign-up",
            collapse: true,
            authIcon: <DocumentIcon color="inherit" />,
            items: [
              {
                name: "Basic",
                secondaryNavbar: true,
                component: SignUpBasic,
                path: "/authentication/sign-up/basic",
                layout: "/auth",
              },
              {
                name: "Cover",
                component: SignUpCover,
                path: "/authentication/sign-up/cover",
                layout: "/auth",
              },
              {
                name: "Illustration",
                component: SignUpIllustration,
                path: "/authentication/sign-up/illustration",
                layout: "/auth",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Machine Management",
    path: "/machine",
    icon: <GiVendingMachine color="inherit" />,
    authIcon: <GiVendingMachine color="inherit" />,
    collapse: true,
    items: [
      {
        name: "Machines Map",
        path: "/machine/machine-map",
        component: MachineMap,
        layout: "/admin",
        permission: PERMISSIONS.MACHINE_MAP,
      },
      // {
      //   name: "Real Machines State",
      //   path: "/machine/real-machines-state",
      //   component: MachineState,
      //   layout: "/admin",
      // },
      {
        name: "Machine Settings",
        path: "/machine/machine-settings",
        component: MachineSettings,
        layout: "/admin",
        permission: PERMISSIONS.MACHINE_SETTING,
      },
      {
        name: "Gameplay Type",
        path: "/machine/gameplay",
        component: MachineGamePlay,
        layout: "/admin",
        permission: PERMISSIONS.MACHINE_GAMEPLAY_TYPE,
      },
    ],
  },
  // {
  //   name: "Prizes",
  //   path: "/prize",
  //   icon: <GiPresent color="inherit" width="16px" heigth="16px" />,
  //   authIcon: <GiPresent color="inherit" width="16px" heigth="16px" />,
  //   collapse: true,
  //   items: [
  //     {
  //       name: "Prize Settings",
  //       path: "/prize/prize-settings",
  //       component: PrizeSettings,
  //       layout: "/admin",
  //     },
  //     {
  //       name: "Project Category",
  //       path: "/prize/project-category",
  //       component: ProjectCategory,
  //       layout: "/admin",
  //     },
  //     {
  //       name: "Play Fee",
  //       path: "/prize/play-fee",
  //       component: PlayFee,
  //       layout: "/admin",
  //     },
  //     {
  //       name: "Revenue Sharing",
  //       path: "/prize/revenue-sharing",
  //       component: RevenueSharing,
  //       layout: "/admin",
  //     },
  //   ],
  // },
  {
    name: "User Management",
    path: "/user",
    icon: <FaUsers color="inherit" />,
    authIcon: <FaUsers color="inherit" />,
    component: UserManagement,
    collapse: true,
    items: [
      {
        name: "User Management",
        path: "/user/user-management",
        component: UserManagement,
        layout: "/admin",
        permission: PERMISSIONS.USER_MANAGEMENT_USER_MANAGEMENT,
      },
      {
        name: "Verification Badge Management",
        path: "/user/user-kol-management",
        component: UserKOLManagement,
        layout: "/admin",
        permission: PERMISSIONS.USER_MANAGEMENT_VERIFICATION_BADGE,
      },
      // {
      //   name: "Player Management",
      //   path: "/user/player-management",
      //   component: PlayerManagement,
      //   layout: "/admin",
      // },
      // {
      //   name: "Player Level Settings",
      //   path: "/user/player-level-settings",
      //   component: UserManagement,
      //   layout: "/admin",
      // },
    ],
  },
  // {
  //   name: "Events",
  //   path: "/event",
  //   icon: <MdEventNote color="inherit" />,
  //   authIcon: <MdEventNote color="inherit" />,
  //   component: EventManagement,
  //   collapse: true,
  //   items: [
  //     // {
  //     //   name: "Events Management",
  //     //   path: "/event/event-management",
  //     //   component: EventManagement,
  //     //   layout: "/admin",
  //     // },
  //     {
  //       name: "Events Management",
  //       path: "/event/new-event-management",
  //       component: EventManagementNew,
  //       layout: "/admin",
  //     },
  //     {
  //       name: "Event Rule",
  //       path: "/event/event-rule",
  //       component: RulesManagement,
  //       layout: "/admin",
  //     },
  //     {
  //       name: "Event Ticket",
  //       path: "/event/event-ticket",
  //       component: EventTicket,
  //       layout: "/admin",
  //     },
  //   ],
  // },
  {
    name: "Game Control Management",
    path: "/game-control",
    icon: <GameControlIcon color="inherit" />,
    authIcon: <GameControlIcon color="inherit" />,
    collapse: true,
    items: [
      {
        name: "Prize Management",
        path: "/game-control/prize-settings",
        component: PrizeSettings,
        layout: "/admin",
        permission: PERMISSIONS.GAME_CONTROL_PRIZE,
      },
      {
        name: "Events Management",
        path: "/game-control/new-event-management",
        component: EventManagementNew,
        layout: "/admin",
        permission: PERMISSIONS.GAME_CONTROL_EVENT,
      },
      // {
      //   name: "Skill Rank",
      //   path: "/game-control/skill-rank",
      //   component: SkillRankPage,
      //   layout: "/admin",
      // },
      // {
      //   name: "Skill Point Settings",
      //   path: "/game-control/skill-point-settings",
      //   component: SkillPointSettings,
      //   layout: "/admin",
      // },
      // {
      //   name: "Hunter Off-chain",
      //   path: "/game-control/off-chain",
      //   component: HunterOffChainPage,
      //   layout: "/admin",
      // },
      // {
      //   name: "Level Required",
      //   path: "/game-control/level-required",
      //   component: LevelRequiredPage,
      //   layout: "/admin",
      // },
      // {
      //   name: "Experience Point Reward",
      //   path: "/game-control/experience-point",
      //   component: GameExperiencePoint,
      //   layout: "/admin",
      // },
      {
        name: "Game Mode",
        path: "/game-control/game-mode",
        component: NewGameMode,
        layout: "/admin",
        permission: PERMISSIONS.GAME_CONTROL_GAME_MODE,
      },
      // {
      //   name: "Login Bonus",
      //   path: "/game-control/login-bonus",
      //   component: LoginBonus,
      //   layout: "/admin",
      // },
    ],
  },
  {
    name: "Admin Account",
    path: "/system",
    icon: <RoleIcon color="inherit" />,
    authIcon: <RoleIcon color="inherit" />,
    collapse: true,
    items: [
      {
        name: "Admin Management",
        path: "/system/admin-management",
        layout: "/admin",
        component: AdminManagement,
        permission: PERMISSIONS.ADMIN_ACCOUNT_LIST,
      },
      {
        name: "Role System",
        path: "/system/role-system",
        layout: "/admin",
        component: RoleSystemPage,
        permission: PERMISSIONS.ADMIN_ACCOUNT_ROLE,
      },
    ],
  },
  {
    name: "NFTs Management",
    path: "/nfts",
    icon: <ManagementGenesis color="inherit" />,
    authIcon: <ManagementGenesis color="inherit" />,
    collapse: true,
    items: [
      {
        name: "Hunter NFTs",
        path: "/nfts/hunter-nfts-management",
        component: NftHunterManagementPage,
        layout: "/admin",
        permission: PERMISSIONS.NFT_MANAGEMENT_HUNTER,
      },
      {
        name: "Gauntlet NFTs",
        path: "/nfts/gauntlet-nfts-management",
        component: NftGauntletManagementPage,
        layout: "/admin",
        permission: PERMISSIONS.NFT_MANAGEMENT_GAULET,
      },
      {
        name: "Bounty Ball NFTs",
        path: "/nfts/bounty-ball-nfts-management",
        component: NftBountyBallManagementPage,
        layout: "/admin",
        permission: PERMISSIONS.NFT_MANAGEMENT_BOUNTY_BALL,
      },
      // {
      //   name: "Genesis NFTs",
      //   path: "/nfts/genesis-nfts-management",
      //   component: NftGenesisManagementPage,
      //   layout: "/admin",
      // },
      {
        name: "Monthly Payout Settings",
        nameParent: "Owner NFTs",
        path: "/nfts/owner-nfts-management/monthly-payout-settings",
        component: MonthlyPayoutSettingsPage,
        layout: "/admin",
        isHide: true,
        permission: PERMISSIONS.NFT_MANAGEMENT_OWNER,
      },
      {
        name: "Owner NFTs",
        path: "/nfts/owner-nfts-management",
        component: NftOwnerManagementPage,
        layout: "/admin",
        permission: PERMISSIONS.NFT_MANAGEMENT_COLLABS,
      },
      {
        name: "Import",
        path: "/nfts/import",
        component: NftImportManagementPage,
        layout: "/admin",
        permission: PERMISSIONS.NFT_MANAGEMENT_IMPORT,
      },
    ],
  },
  {
    name: "Gacha Management",
    path: "/gacha",
    icon: <GauntletIcon color="inherit" />,
    authIcon: <GauntletIcon color="inherit" />,
    collapse: true,
    items: [
      {
        name: "Gacha Management",
        path: "/gacha/gacha-management",
        component: GachaManagement,
        layout: "/admin",
        permission: PERMISSIONS.GACHA_MANAGEMENT,
      },
      {
        name: "Large Reward Container Setup",
        path: "/gacha/large-reward",
        component: LargeRewardContainer,
        layout: "/admin",
        permission: PERMISSIONS.GACHA_MANAGEMENT,
      },
      {
        name: "Non-NFT Gacha Items Management",
        path: "/gacha/non-nft-gacha",
        component: NonNftGachaManagement,
        layout: "/admin",
        permission: PERMISSIONS.GACHA_MANAGEMENT,
      },
      {
        name: "Gacha History",
        path: "/gacha/gacha-history",
        component: GachaHistory,
        layout: "/admin",
        permission: PERMISSIONS.GACHA_MANAGEMENT,
      },
    ],
  },
  {
    name: "Contact",
    path: "/contact",
    icon: <StatisticsIcon color="inherit" />,
    authIcon: <StatisticsIcon color="inherit" />,
    collapse: true,
    items: [
      {
        name: "Contact List",
        path: "/contact/list",
        component: Contact,
        layout: "/admin",
        permission: PERMISSIONS.CONTACT,
      },
      // {
      //   name: "Contact Template",
      //   path: "/contact/template",
      //   component: ContactTemplate,
      //   layout: "/admin",
      // },
    ],
  },
  {
    name: "Game Params Management",
    path: "/params",
    icon: <ExchangeRateIcon color="inherit" />,
    authIcon: <ExchangeRateIcon color="inherit" />,
    component: LoginBonus,
    collapse: true,
    items: [
      {
        name: "Login Bonus",
        path: "/params/login-bonus",
        component: LoginBonus,
        layout: "/admin",
        permission: PERMISSIONS.GAME_PARAMS_LOGIN_REWARD,
      },
      {
        name: "Exchange Settings",
        path: "/params/exchange-rate-settings",
        component: ExchangeRateSetting,
        layout: "/admin",
        permission: PERMISSIONS.GAME_PARAMS_EXCHANGE_RATE,
      },
      {
        name: "Fusion & Awaken",
        path: "/params/fusion-awaken",
        component: FusionAndAwaken,
        layout: "/admin",
        permission: PERMISSIONS.GAME_PARAMS_FUSION_AND_AWAKEN,
      },
      // {
      //   name: "Shop Package",
      //   path: "/params/shop-package",
      //   component: ShopPackage,
      //   layout: "/admin",
      //   permission: PERMISSIONS.GAME_PARAMS_SHOP_PACKAGE,
      // },
      {
        name: "Daily Reward Pool",
        path: "/params/daily-reward",
        component: DailyRewardPool,
        layout: "/admin",
        permission: PERMISSIONS.GAME_PARAMS_DAILY_REWARD_POOL,
      },
      {
        name: "Player Level",
        path: "/params/player-level",
        component: PlayerLevel,
        layout: "/admin",
        permission: PERMISSIONS.GAME_PARAMS_PLAYER_LEVEL,
      },
      {
        name: "Life Gauge & Energy Gauge",
        path: "/params/gauge-management",
        component: GaugeManagement,
        layout: "/admin",
        permission: PERMISSIONS.GAME_PARAMS_LIFE_GAUGE_AND_ENERGY_GAUGE,
      },
      {
        name: "XP Rewards",
        path: "/params/xp-reward",
        component: XpReward,
        layout: "/admin",
        permission: PERMISSIONS.GAME_PARAMS_XP_REWARD,
      },
      {
        name: "AP Distribution",
        path: "/params/ap-distribution",
        component: ApDistribution,
        layout: "/admin",
        permission: PERMISSIONS.GAME_PARAMS_AP_DISTRIBUTION,
      },
      {
        name: "Treasure Box",
        path: "/params/treasure-box",
        component: TreasureBoxManagement,
        layout: "/admin",
        permission: PERMISSIONS.GAME_PARAMS_TREASURE_BOX,
      },
    ],
  },
  {
    name: "Settings",
    path: "/setting",
    icon: <WalletManagementIcon color="inherit" />,
    authIcon: <WalletManagementIcon color="inherit" />,
    component: WalletManagement,
    collapse: true,
    items: [
      {
        name: "System",
        path: "/setting/management",
        component: SettingSystem,
        layout: "/admin",
        permission: PERMISSIONS.SETTING_SYSTEM,
      },
      {
        name: "Admin Wallet",
        path: "/setting/admin-wallet",
        component: AdminWalletPage,
        layout: "/admin",
        permission: PERMISSIONS.SETTING_ADMIN_WALLET,
      },
      {
        name: "Coin Consumption Priority",
        path: "/setting/coin-consumption",
        component: CoinConsumption,
        layout: "/admin",
        permission: PERMISSIONS.SETTING_COIN_CONSUMPTION_PRIORITY,
      },
      // {
      //   name: "Manage Wallet",
      //   path: "/setting/manage-wallet",
      //   component: WalletManagement,
      //   layout: "/admin",
      //   permission: PERMISSIONS.SETTING_SERVICE_CHANGE,
      // },
    ],
  },
  {
    name: "Digital Figure Management",
    path: "/digital",
    icon: <DigitalFigureIcon color="inherit" />,
    authIcon: <DigitalFigureIcon color="inherit" />,
    component: DigitalManagement,
    collapse: true,
    items: [
      {
        name: "Digital Figures",
        path: "/digital/digital-figures",
        component: DigitalManagement,
        layout: "/admin",
        permission: PERMISSIONS.DIGITAL_MANAGEMENT_DIGITAL_FIGURE,
      },
      {
        name: "DF View BG",
        path: "/digital/df-view",
        component: DFViewBG,
        layout: "/admin",
        permission: PERMISSIONS.DIGITAL_MANAGEMENT_DF_VIEW_BG,
      },
      {
        name: "Room Items",
        path: "/digital/room-items",
        component: RoomItems,
        layout: "/admin",
        permission: PERMISSIONS.DIGITAL_MANAGEMENT_ROOM_ITEM,
      },
      {
        name: "Room BG",
        path: "/digital/room-bg",
        component: RoomBG,
        layout: "/admin",
        permission: PERMISSIONS.DIGITAL_MANAGEMENT_ROOM,
      },
      {
        name: "Licensors",
        path: "/digital/licensors",
        component: Licensors,
        layout: "/admin",
        permission: PERMISSIONS.DIGITAL_MANAGEMENT_PARTNER,
      },
    ],
  },
  {
    name: "Present Box Management",
    path: "/present-box",
    icon: <PresentBoxBlackIcon color="inherit" />,
    authIcon: <PresentBoxBlackIcon color="inherit" />,
    collapse: true,
    items: [
      {
        name: "Present Box",
        path: "/present-box/management",
        component: PresentBoxMangement,
        layout: "/admin",
        permission: PERMISSIONS.PRESENT_BOX_MANAGEMENT,
      },
      {
        name: "Present Box History",
        path: "/present-box/history",
        component: PresentBoxHistory,
        layout: "/admin",
        permission: PERMISSIONS.PRESENT_BOX_MANAGEMENT,
      },
    ],
  },
  {
    name: "Notice Management",
    path: "/notice",
    icon: <NoticeIcon color="inherit" />,
    authIcon: <NoticeIcon color="inherit" />,
    collapse: true,
    items: [
      {
        name: "Notice",
        path: "/notice/management",
        component: Notice,
        layout: "/admin",
        permission: PERMISSIONS.NOTICE_MANAGEMENT,
      },
    ],
  },
  {
    name: "Ads Management",
    path: "/ads",
    icon: <AdsIcon color="inherit" />,
    authIcon: <AdsIcon color="inherit" />,
    collapse: true,
    items: [
      {
        name: "Banner Setting",
        path: "/banner-ads/management",
        component: Ads,
        layout: "/admin",
        permission: PERMISSIONS.BANNER_MANAGEMENT,
      },
    ],
  },
  // {
  //   name: "Statistics",
  //   path: "/statistics",
  //   icon: <StatisticsIcon color="inherit" />,
  //   authIcon: <StatisticsIcon color="inherit" />,
  //   component: StatisticsSetting,
  //   layout: "/admin",
  //   collapse: false,
  // },
  // {
  //   name: "Maintenance",
  //   path: "/maintenance",
  //   icon: <MaintenanceIcon color="inherit" />,
  //   authIcon: <MaintenanceIcon color="inherit" />,
  //   component: Maintenance,
  //   layout: "/admin",
  //   collapse: false,
  // },
];

export default dashRoutes;
