import {
  Flex,
  useToast,
  useDisclosure,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import ConditionNotice from "./condition";
import CreateAndUpdateNotice from "./createAndUpdate"
import DetailNotice from "./components/detail"
import Paginate from "components/Paginate";
import { useCallback, useEffect, useState } from "react";
import { getNotices, categoriesNotice, masterCountries } from "api/notice";
import { FIRST_PAGE, LIMIT_PAGE } from "constants/constants";
import ActionNotice from "./action";
import ItemNotice from "./itemNotice";
import { usePermission } from "hooks/usePermission";
import { PERMISSIONS } from "constants/permissions";

export default function Notice() {
  const { isHasPermissionEdit } = usePermission(PERMISSIONS.NOTICE_MANAGEMENT);
  const [notices, setNotices] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categoriesCondition, setCategoriesCondition] = useState([]);
  const [dataDetail, setDataDetail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [checkedIds, setCheckedIds] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [countries, setCountries] = useState([]);
  const [params, setParams] = useState({
    page: FIRST_PAGE,
    limit: LIMIT_PAGE,
  });
  const toast = useToast();

  const {
    isOpen: isOpenCreateAndUpdate,
    onOpen: onOpenCreateAndUpdate,
    onClose: onCloseCreateAndUpdate,
  } = useDisclosure();

  const {
    isOpen: isOpenDetail,
    onOpen: onOpenDetail,
    onClose: onCloseDetail,
  } = useDisclosure();

  const getListCategories = async () => {
    try {
      const { data } = await categoriesNotice();
      if (data?.success) {
        const res = data?.data;
        const categoriesList = res.map(item => ({
          id: item.id,
          type: item.value,
          name: item.label
      }));
        const listCategoriesNotice = res.map(({ id, ...rest }) => rest);
        setCategories(categoriesList);
        setCategoriesCondition(listCategoriesNotice)
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const getMasterCountries = useCallback(
    async () => {
      try {
        const res = await masterCountries()
        if (res?.data?.success) {
          const data = res?.data.data;
          setCountries(data);
        }
      } catch (error) {
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      }
    },
  );

  const getListNotices = useCallback(
    async (newParams = {}) => {
      try {
        setIsLoading(true);
        const { data } = await getNotices({
          ...params,
          ...newParams,
        });
        if (data?.success) {
          const res = data?.data;
          setNotices(res?.records);
          setTotalPage(res?.total_page);
        }
      } catch (error) {
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      } finally {
        setIsLoading(false);
      }
    },
    [params]
  );

  useEffect(() => {
    getListCategories();
    getListNotices();
    getMasterCountries()
  }, [params]);

  const handleOpenCreateOrUpdate = (notice = null) => {
    setDataDetail(notice);
    if(notice?.is_published) return;
    onOpenCreateAndUpdate();
  };

  const handleSetDataDetail = (data) => {
    setDataDetail(data);
    onOpenDetail();
  };

  const handleCloseCreateOrUpdate = () => {
    onCloseCreateAndUpdate();
    setDataDetail(null);
  };

  const handleClose = () => {
    onCloseDetail();
    setDataDetail(null);
  };

  const handleCreateOrUpdateSuccess = useCallback(() => {
    handleCloseCreateOrUpdate();
    setParams({
      ...params,
      page: 1,
    });
  }, [params]);

  const handleChangePage = (page) => {
    setParams({
      ...params,
      page,
    });
  };

  const onChangeLimit = (limit) => {
    setParams({
      ...params,
      page: 1,
      limit,
    });
  };

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <Card px="24px">
        <CardHeader mb="32px">
          <ConditionNotice
            isLoading={isLoading}
            setParams={setParams}
            params={params}
            categories={categoriesCondition}
          >
          </ConditionNotice>
        </CardHeader>
        <CardHeader mb="12px">
          <Flex
            w="100%"
            gap={4}
            direction={{
              md: "row",
            }}
            justifyContent="flex-end"
            alignItems="center"
          >
            <Flex
              w="100%"
              justifyContent="flex-end"
              alignItems="flex-start"
            >
              {isHasPermissionEdit && (
                <ActionNotice
                  handleOpenCreateOrUpdate={() => handleOpenCreateOrUpdate()}
                  checkedIds={checkedIds}
                  setCheckedIds={setCheckedIds}
                  setParams={setParams}
                  params={params}
                />
              )}
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody>
          <Flex wrap="wrap" width="100%" >
            {notices.map((notice) => (
              <ItemNotice
                key={notice.id}
                handleOpenCreateOrUpdate={() =>
                  handleOpenCreateOrUpdate(notice)
                }
                handleSetDataDetail={() => {
                  handleSetDataDetail(notice);
                }}
                notice={notice}
                checkedIds={checkedIds}
                setCheckedIds={setCheckedIds}
                categories={categories}
                isHasPermissionEdit={isHasPermissionEdit}
              >
              </ItemNotice>
            ))}
         </Flex>
        </CardBody>
        <Paginate
          page={params?.page}
          pageCount={totalPage}
          limit={params.limit}
          onChangeLimit={onChangeLimit}
          handleChangePage={handleChangePage}
        />
      </Card>
      {isOpenCreateAndUpdate && (
        <CreateAndUpdateNotice
          isOpen={isOpenCreateAndUpdate}
          onClose={handleCloseCreateOrUpdate}
          dataDetail={dataDetail}
          handleCreateOrUpdateSuccess={handleCreateOrUpdateSuccess}
          handleViewClose={handleCloseCreateOrUpdate}
          categories={categories}
          countries={countries}
        />
      )}
      {isOpenDetail && (
        <DetailNotice
          isOpen={isOpenDetail}
          onClose={handleClose}
          dataDetail={dataDetail}
          categories={categories}
          countries={countries}
        />
      )}
    </Flex>
  );
}
