import { Box, Flex, Grid, Icon, Image, Text, useDisclosure, useToast, Switch } from '@chakra-ui/react';
import ModalBase from 'components/Modal/ModalBase'
import React, { useMemo, useState } from 'react'
import DetailCameraModal from './modalDetail';
import { STATUS } from 'constants/constants';
import { IconCamera } from "components/Icons/IconCamera";
import { IconXCamera } from "components/Icons/IconXCamera";
import { updateGameMode } from 'api/machine.api';
import { Icon2Claws } from "components/Icons/Icon2Claws";
import { Icon3Claws } from "components/Icons/Icon3Claws";
import { NAIcon } from "components/Icons/NAIcon";
import SwitchType from 'components/Switch';
import { MACHINE_CONTACT_CATEGORIES } from 'views/Contact/constants';
import { usePermission } from 'hooks/usePermission';
import { PERMISSIONS } from 'constants/permissions';

export default function ModalMachineDetail({
  isOpen,
  onClose,
  dataDetail,
  getListMachine,
  handleSwitchStatus,
  handleSwitchDirection
}) {
  const textNA = "N/A";
  const [cameraType, setCameraType] = useState("");
  const { isHasPermissionEdit } = usePermission(
    PERMISSIONS.MACHINE_MAP
  );

  const { 
    isOpen: isOpenCamera, 
    onOpen: onOpenCamera, 
    onClose: onCloseCamera,
  } = useDisclosure();
  const toast = useToast();

  const handleOpenDetail = (type) => {
    setCameraType(type);
    onOpenCamera();
  };

  const isStatusActive = useMemo(() => {
    return dataDetail?.status === STATUS.CONNECTED;
  }, [dataDetail?.status]);

  const isCameraFOK = useMemo(() => {
    return dataDetail?.status_cam_front === STATUS.OK;
  }, [dataDetail?.status_cam_front]);

  const isCameraSOK = useMemo(() => {
    return dataDetail?.status_cam_back === STATUS.OK;
  }, [dataDetail?.status_cam_back]);
  
  const is3Claws = useMemo(() => {
    return dataDetail?.machine?.machine_type === STATUS.THREE_CLAWS;
  }, [dataDetail?.machine?.machine_type]);

  const isDirection = useMemo(() => {
    return dataDetail?.direction === STATUS.RIGHT;
  }, [dataDetail?.direction]);

  const updateMode = async (gameModeId) => {
    try {
      const { data } = await updateGameMode({
        machine_id: dataDetail?.machine?.id,
        game_mode_id: gameModeId
      });
      if (data?.success) {
        getListMachine()
        toast({
          title: "Update success.",
          description: "Update success.",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const IconClaw = useMemo(() => {
    if (dataDetail) {
      return is3Claws ? Icon3Claws : Icon2Claws;
    }
    return NAIcon;
  }, [dataDetail]);

  const isStateActive = useMemo(() => {
    return dataDetail.machine?.state === STATUS.UNDER_MAINTENANCE;
  }, [dataDetail.machine?.state]);
  
  return (
    <ModalBase
      maxWContent="600px"
      isOpen={isOpen}
      onClose={onClose}
      showBtn={false}
      titleHeader={`MACHINE - ${dataDetail?.mac?.slice(-4)}`}
    >
      <Flex flexDirection="column" gap="12px">
        <Flex flexDirection="column" gap="8px">
          <Text fontSize="20px" fontWeight="700" textDecoration="underline">BASIC INFO</Text>
          <Flex alignItems="center" justifyContent="space-between">
            <Flex alignItems="center" gap="4px">
              <Image
                src={dataDetail?.icon_url || "https://i.ibb.co/CmKmKfp/ENISHI.png"}
                w="160px"
                h="160px"
                alt={dataDetail?.id}
              />
              <Flex flexDirection="column" gap="4px">
                <Box>
                  <Text fontSize="16px" fontWeight="600">Mac Address</Text>
                  <Text fontSize="16px" fontWeight="400">{dataDetail?.mac}</Text>
                </Box>
                <Box>
                  <Text fontSize="16px" fontWeight="600">Maintenance</Text>
                  <SwitchType
                    color="#e04995"
                    colorThumb="#fff"
                    size="lg"
                    colorScheme="teal"
                    position="relative"
                    isChecked={isStateActive}
                    onChange={() => handleSwitchStatus(dataDetail?.machine)}
                    isDisabled={!isHasPermissionEdit}
                  >
                    <Box
                      as="span"
                      position="absolute"
                      left="25%"
                      top="50%"
                      transform="translate(-50%, -50%)"
                      color="white"
                      fontWeight="bold"
                      fontSize="12px"
                      pointerEvents="none" // Prevents pointer events from interfering with the switch
                    >
                      On
                    </Box>
                    {!isStateActive && (
                      <Box
                        as="span"
                        position="absolute"
                        left="64%"
                        top="50%"
                        transform="translate(-50%, -50%)"
                        fontWeight="bold"
                        color="#4a4a4a"
                        fontSize="12px"
                        pointerEvents="none" // Prevents pointer events from interfering with the switch
                      >
                        Off
                      </Box>
                    )}
                  </SwitchType>
                </Box>
                <Box>
                  <Text fontSize="16px" fontWeight="600">Gameplay Type</Text>
                  <Text fontSize="16px" fontWeight="400">{dataDetail?.machine?.gameplay_type?.name}</Text>
                </Box>
              </Flex>
            </Flex>
            <Flex alignItems="flex-end" gap="8px">
              <Flex flexDirection="column" gap="4px">
                <Box>
                  <Text fontSize="16px" fontWeight="600">Booth No.</Text>
                  <Text fontSize="16px" fontWeight="400">{dataDetail?.machine?.group_label}-{dataDetail?.machine?.group_number}</Text>
                </Box>
                <Box>
                  <Text fontSize="16px" fontWeight="600">Booth Direction</Text>
                  <SwitchType
                    color="#e04995"
                    colorThumb="#fff"
                    size="lg"
                    colorScheme="teal"
                    position="relative"
                    isChecked={isDirection ? isDirection : false}
                    onChange={() => handleSwitchDirection(dataDetail)}
                    isDisabled={!isHasPermissionEdit}
                  >
                    <Box
                      as="span"
                      position="absolute"
                      left="24%"
                      top="50%"
                      transform="translate(-50%, -50%)"
                      color="white"
                      fontWeight="700"
                      fontSize="10px"
                      pointerEvents="none" // Prevents pointer events from interfering with the switch
                    >
                      R
                    </Box>
                    {!isDirection && (
                      <Box
                        as="span"
                        position="absolute"
                        left="65%"
                        top="50%"
                        transform="translate(-50%, -50%)"
                        color="#4a4a4a"
                        fontWeight="700"
                        fontSize="10px"
                        pointerEvents="none" // Prevents pointer events from interfering with the switch
                      >
                        L
                      </Box>
                    )}
                  </SwitchType>
                </Box>
                <Box>
                  <Text fontSize="16px" fontWeight="600">Booth Type</Text>
                  <Text fontSize="16px" fontWeight="400">{dataDetail ? <>{is3Claws ? "3-claw" : "2-claw"}</> : textNA}</Text>
                </Box>
              </Flex>
              <Icon fontSize="55px" as={IconClaw} />
            </Flex>
          </Flex>
        </Flex>
        <Flex flexDirection="column" gap="8px">
          <Text fontSize="20px" fontWeight="700" textDecoration="underline">CAMERA</Text>
          <Flex alignItems="center" justifyContent="space-around">
            <Flex alignItems="center" gap="8px">
              <Text fontWeight={700}>Front</Text>
              <Flex
                alignItems="center"
                position="relative"
                cursor={isStatusActive ? "pointer" : "not-allowed"}
                opacity={isStatusActive ? 1 : "0.3"}
                onClick={() => {
                  if (isStatusActive) {
                    handleOpenDetail(STATUS.FRONT);
                  }
                }}
              >
                {!isCameraFOK && (
                  <Icon
                    fontSize="16px"
                    position="absolute"
                    top="4px"
                    left="4px"
                    as={IconXCamera}
                  />
                )}
                <Icon fontSize="60px" as={IconCamera} />
                <Text
                  color="#2D377B"
                  fontWeight="700"
                  fontSize="20px"
                  position="absolute"
                  top="8px"
                  left="16px"
                >
                  F
                </Text>
              </Flex>
            </Flex>
            <Flex alignItems="center" gap="8px">
              <Text fontWeight={700}>Side</Text>
              <Flex
                alignItems="center"
                position="relative"
                cursor={isStatusActive ? "pointer" : "not-allowed"}
                opacity={isStatusActive ? 1 : "0.3"}
                onClick={() => {
                  if (isStatusActive) {
                    handleOpenDetail(STATUS.BACK);
                  }
                }}
              >
                {!isCameraSOK && (
                  <Icon
                    fontSize="16px"
                    position="absolute"
                    top="4px"
                    left="4px"
                    as={IconXCamera}
                  />
                )}
                <Icon fontSize="60px" as={IconCamera} />
                <Text
                  color="#2D377B"
                  fontWeight="700"
                  fontSize="20px"
                  position="absolute"
                  top="8px"
                  left="16px"
                >
                  S
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Flex flexDirection="column" gap="8px">
          <Text fontSize="20px" fontWeight="700" textDecoration="underline">ERRORS</Text>
          <Flex flexDirection="column" gap="6px">
            {dataDetail?.machine_contact?.length > 0 ? (
              dataDetail?.machine_contact?.map((contact, index) => (
                <Text
                  color="red" 
                  key={index}
                >{MACHINE_CONTACT_CATEGORIES[contact?.machine_contact_categories_id]}</Text>
              ))
            ) : (
              <Text>None</Text>
            )}
          </Flex>
        </Flex>
        <Flex flexDirection="column" gap="8px">
          <Text fontSize="20px" fontWeight="700" textDecoration="underline">PLAY DETAILS</Text>
          <Flex flexDirection="column" gap="8px">
            <Flex flexDirection="column" gap="0">
              <Text fontSize="18px" fontWeight="600">Mode Type</Text>
              <Text fontWeight="500" fontSize="18px">- {dataDetail?.machine?.mode_type}</Text>
              {/* <Box 
                w="50%"
              >
                <Select
                  w="50%"
                  defaultValue={gamesModeOptions?.filter((mode) => mode?.value === dataDetail?.machine?.game_mode_id)}
                  fontSize="sm"
                  onChange={(e) => {
                    updateMode(e.value);
                  }}
                  color="#000000"
                  options={!is3Claws ? gamesModeOptions?.filter(mode => mode?.machine_type === "ALL" || mode?.machine_type === "TWO_CLAWS") : gamesModeOptions?.filter(mode => mode?.machine_type === "ALL" || mode?.machine_type === "THREE_CLAWS")}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </Box> */}
            </Flex>
          </Flex>
          <Flex gap="6px">
            <Image
              src={dataDetail?.icon_url || "https://i.ibb.co/CmKmKfp/ENISHI.png"}
              w="150px"
              h="150px"
              alt={dataDetail?.id}
            />
            <Grid templateColumns="repeat(2, 1fr)" gap={1}>
              <Flex flexDirection="column" gap="4px">
                <Text fontWeight="600" fontSize="20px">Prize</Text>
                <Text fontWeight="500" fontSize="16px">N/A</Text>
              </Flex>
              <Flex flexDirection="column" gap="4px">
                <Text fontWeight="600" fontSize="20px">Reservations</Text>
                <Text fontWeight="500" fontSize="16px">{dataDetail?.waiting_players ? dataDetail?.waiting_players : 'N/A'}</Text>
              </Flex>
              <Flex flexDirection="column" gap="4px">
                <Text fontWeight="600" fontSize="20px">Current Player</Text>
                <Text fontWeight="500" fontSize="16px">N/A</Text>
              </Flex>
              <Flex flexDirection="column" gap="4px">
                <Text fontWeight="600" fontSize="20px">Player Streak</Text>
                <Text fontWeight="500" fontSize="16px">{dataDetail?.machine?.player_streak}</Text>
              </Flex>
              <Flex flexDirection="column" gap="4px">
                <Text fontWeight="600" fontSize="20px">Machine Consecutive plays</Text>
                <Text fontWeight="500" fontSize="16px">{dataDetail?.machine?.machine_plays}</Text>
              </Flex>
            </Grid>
          </Flex>
        </Flex>
      </Flex>
      {isOpenCamera && (
        <DetailCameraModal
          dataDetail={dataDetail}
          cameraType={cameraType}
          isOpen={isOpenCamera}
          onClose={onCloseCamera}
        />
      )}
    </ModalBase>
  )
}
