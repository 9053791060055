import {
  Flex,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import SiteMaintenance from "./SiteMaintenance";
import SiteEmail from "./SiteEmail";
import GameFunction from "./GameFunction";
import Inquiries from "./Inquiries";
import PaymentSystem from "./PaymentSystem";
import { usePermission } from "hooks/usePermission";
import { PERMISSIONS } from "constants/permissions";

export default function SettingSystem() {
  const { isHasPermissionEdit } = usePermission(PERMISSIONS.SETTING_SYSTEM);
  const textColor = useColorModeValue("#000000", "white");

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <Card px="0px">
        <CardHeader px="22px" mb="20px" w="100%">
          <Flex
            w="100%"
            gap={4}
            direction={{
              base: "column",
              md: "row",
            }}
            justifyContent={{
              base: "flex-start",
              md: "space-between",
            }}
            alignItems="flex-start"
          >
            <Text
              color={textColor}
              fontSize="lg"
              fontWeight="bold"
              whiteSpace="nowrap"
            >
              System
            </Text>
          </Flex>
        </CardHeader>
        <CardBody overflowX="auto" flexDirection="column" gap="40px">
          <SiteMaintenance isHasPermissionEdit={isHasPermissionEdit}/>
          <GameFunction isHasPermissionEdit={isHasPermissionEdit}/>
          <PaymentSystem isHasPermissionEdit={isHasPermissionEdit}/>
          <SiteEmail isHasPermissionEdit={isHasPermissionEdit}/>
          <Inquiries isHasPermissionEdit={isHasPermissionEdit}/>
        </CardBody>
      </Card>
    </Flex>
  );
}
