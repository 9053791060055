import { Flex, Icon, Td, Tr } from "@chakra-ui/react";
import { EditIcon } from "components/Icons/EditIcon";
import { CheckIcon } from "components/Icons/CheckIcon";
import { useState } from "react";
import FormInput from "components/form/FormInput";
import schemaCumulativeSetting from "./schema";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { convertDateLocalLLLL } from "utils/time";

export default function CumulativeSettingRow ({
  cumulative,
  onSave,
  isHasPermissionEdit
}) {
  const [isEdit, setIsEdit] = useState(false);
  const form = useForm({
    resolver: yupResolver(schemaCumulativeSetting()),
    defaultValues: {
      orb: cumulative ? cumulative?.orb : "",
      day: cumulative ? cumulative?.day : "",
    }
  });
  const {
    setValue,
    handleSubmit,
  } = form;
  const onSubmit = form.handleSubmit((data) => {
    if (onSave) {
      onSave(data);
    }
    setIsEdit(false);
  });

  return (
    <>
      { isEdit ? (
        <FormProvider {...form}>
          <Tr>
            <Td width="33%">
                <Flex gap="10px">
                  <Icon
                    fontSize="30px"
                    borderRadius="8px"
                    cursor="pointer"
                    bg="#07CF84"
                    as={CheckIcon}
                    onClick={onSubmit}
                  />
                </Flex>
              </Td>
              <Td
                width="33%"
                fontSize="sm"
                fontWeight={400}
                whiteSpace="nowrap"
              >
                {cumulative?.day}
              </Td>
              <Td
                width="33%"
                fontSize="sm"
                fontWeight={400}
                whiteSpace="nowrap"
              >
                <FormInput
                  label=""
                  name="orb"
                  type="number"
                  placeholder="Enter number orb"
                  onChange={(e) => {
                    setValue("orb", e.target.value);
                  }}
                />
              </Td>
              <Td
                width="12.5%"
                fontSize="sm"
                fontWeight={400}
                whiteSpace="nowrap"
              >
                {convertDateLocalLLLL(cumulative?.update_time * 1000)}
              </Td>
          </Tr>
        </FormProvider>
      ) : (
        <Tr>
          <Td width="33%">
            {isHasPermissionEdit && (
              <Flex gap="10px" >
                <Icon
                  fontSize="30px"
                  borderRadius="8px"
                  p="7px"
                  cursor="pointer"
                  bg="#1480FF"
                  as={EditIcon}
                  onClick={() => setIsEdit(true)}
                />
              </Flex>
            )}
          </Td>
          <Td
            width="33%"
            fontSize="sm"
            fontWeight={400}
            whiteSpace="nowrap"
          >
            {cumulative?.day}
          </Td>
          <Td
            width="33%"
            fontSize="sm"
            fontWeight={400}
            whiteSpace="nowrap"
          >
            {cumulative?.orb}
          </Td>
          <Td
            width="12.5%"
            fontSize="sm"
            fontWeight={400}
            whiteSpace="nowrap"
          >
            {convertDateLocalLLLL(cumulative?.update_time * 1000)}
          </Td>
        </Tr>
      )}
    </>
  );
}
