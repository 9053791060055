import {
  Box,
  Flex,
  Grid,
  Text,
  useToast,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import { useState } from "react";
import CardHeader from "components/Card/CardHeader";
import { FormProvider, useForm } from "react-hook-form";
import ButtonType from "components/Button";
import FormInput from "components/form/FormInput";
import { updateDailyRewardPool } from "api/dailyRewardPool";
import { NAME_REWARD_POOL } from "./constant";

const FormCommon = (params) => {
  const { title, reward, type, getListDailyReward, isHasPermissionEdit } = params
  
  const [isEdit, setIsEdit] = useState(false);
  const toast = useToast();

  const form = useForm({
    defaultValues: {
      rr_bcoin1_paid: reward?.rr_bcoin1_paid ? reward?.rr_bcoin1_paid : '',
      rr_bcoin1_free: reward?.rr_bcoin1_free ? reward?.rr_bcoin1_free : '',
      rr_bcoin2_unlocked: reward?.rr_bcoin2_unlocked ? reward?.rr_bcoin2_unlocked : '',
    }
  });

  const {
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = form;

  const onSubmit = async (data, e) => {
    e.preventDefault()
    try {
      const dataSubmit = {
        ...reward,
        "rr_bcoin1_paid": data?.rr_bcoin1_paid,
        "rr_bcoin1_free": data?.rr_bcoin1_free,
        "rr_bcoin2_unlocked": data?.rr_bcoin2_unlocked,
      }
      const res = await updateDailyRewardPool(reward?.id, dataSubmit);
      if (res?.data?.success) {
        toast({
          title: "Updated daily reward pool successfully!.",
          description: "Updated daily reward pool successfully!.",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
        setIsEdit(false)
        await getListDailyReward()
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const renderForm = () => {
    return (
      <FormProvider {...form}>
        <form id={type} style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
          <Flex direction="column" gap="12px" w="100%" padding="8px 0">
            <Grid templateColumns='repeat(3, 1fr)' gap='16px'>
              <FormInput
                wrapperProps={{
                  w: "100%",
                }}
                label="BCoin1 (paid) return rate (%)"
                name="rr_bcoin1_paid"
                placeholder="Enter value"
                type="string"
              />
              <FormInput
                wrapperProps={{
                  w: "100%",
                }}
                label="BCoin1 (free) return rate (%)"
                name="rr_bcoin1_free"
                placeholder="Enter value"
                type="string"
              />
              <FormInput
                wrapperProps={{
                  w: "100%",
                }}
                label="BCoin2 return rate (%)"
                name="rr_bcoin2_unlocked"
                placeholder="Enter value"
                type="string"
              />
            </Grid>
          </Flex>
        </form>
      </FormProvider>
    );
  };

  return (
    <Flex flexDirection="column" w="100%">
      <Card px="22px" maxW="100%">
        <CardHeader mb="20px" w="100%" display="flex" alignItems="center" justifyContent="space-between">
          <Text
            fontSize="xl"
            fontWeight="bold"
            whiteSpace="nowrap"
          >
            {NAME_REWARD_POOL[title]}
          </Text>
          {isHasPermissionEdit && (
            <Flex>
              {!isEdit ? (
                <ButtonType
                  text="EDIT"
                  w="100px"
                  fontSize="12px"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsEdit(!isEdit);
                  }}
                />
              ) : (
                <ButtonType
                  text="SAVE"
                  w="100px"
                  fontSize="12px"
                  type="submit"
                  form={type}
                  isLoading={isSubmitting}
                />
              )}
            </Flex>
          )}
        </CardHeader>
        <CardBody 
          overflowX="auto"
          opacity={isEdit ? 1 : 0.5}
          pointerEvents={isEdit ? 'auto' : 'none'}
          overflow="visible"
        >
          {renderForm()}
        </CardBody>
      </Card>
    </Flex>
  );
}

export default FormCommon
