import { Flex } from "@chakra-ui/react";
import FormInput from "components/form/FormInput";
import FormSelect from "components/form/FormSelect";
import { FormProvider, useForm } from "react-hook-form";
import { STATUS_USER_OPTIONS } from "./constant";
import ButtonType from "components/Button";

export default function ConditionUser({ isLoading, params, setParams }) {
  const form = useForm();
  const { 
    setValue, 
    handleSubmit,
    formState: { isSubmitting },
  } = form;

  const onSubmit = (data) => {
    if (!isLoading) {
      const newParams = {
        ...params,
        ...data,
        page: 1,
      };
      setParams(newParams);
    }
  };

  return (
    <Flex
      gap={4}
      flexWrap="wrap"
      justifyContent="space-between"
      w="100%"
      alignItems="flex-end"
    >
      <FormProvider {...form}>
        <form
          style={{
            maxWidth: "100%",
          }}
          id="user-search"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Flex w="100%" flexWrap="wrap" alignItems="flex-end" gap={4}>
            <FormInput
              w="349px"
              maxW="100%"
              name="keyword"
              label="Search"
              placeholder={"Enter keywords"}
            />
            <FormSelect
              name="status"
              label="Filter by Status"
              defaultValue={""}
              w="240px"
              options={[{ label: "All", value: "" }, ...STATUS_USER_OPTIONS]}
              onChange={(e) => {
                if (!isLoading) {
                  setValue("status", e.target.value);
                }
              }}
            />
            <ButtonType
              btnType="primary"
              type="submit"
              w="120px"
              h="46px"
              fontSize="16px"
              borderRadius="5px"
              form="user-search"
              isLoading={isSubmitting}
            >
              Search
            </ButtonType>
          </Flex>
        </form>
      </FormProvider>
    </Flex>
  );
}
