import { Td, Text, Tr, useColorModeValue, Flex, Icon } from "@chakra-ui/react";
import { DeleteIcon } from "components/Icons/DeleteIcon";
import FormTextarea from "components/form/FormTextAria";
import styled from "styled-components";
const TextError = styled.div`
color: #E53E3E;
font-size: 14px;
`
export default function UserKOLRow({
  item,
  handleDelete,
  index,
  setUserKOLs,
  userKOLs,
  error,
  setError
}) {
  const textColor = useColorModeValue("#000000", "white");

  function calculateHWFWLength(value) {
    if (!value) return 0;
    return [...value.trim()].reduce((sum, char) => {
      const code = char.charCodeAt(0);
      const isFullWidth = code >= 0xFF00 && code <= 0xFFEF;
      return sum + (isFullWidth ? 2 : 1);
    }, 0);
  }

  const handleInputNote = (value) => {
    const length = calculateHWFWLength(value?.replace(/<[^>]+>/g, "").trim());
    if (length > 500) {
      setError("Note must be less than 500 characters.");
      return;
    } else {
      setError("");
      const updateUserKOL = [...userKOLs];
      updateUserKOL[index].note = value;
      setUserKOLs(updateUserKOL);
    }
  };
  return (
    <Tr>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.user_id}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.email}
        </Text>
      </Td>
      <Td minW="30%">
        <FormTextarea
          minH='90px'
          borderRadius='8px'
          name="note"
          value={item?.note}
          onChange={(e) =>
            handleInputNote(e?.target?.value)
          }
        />
         {error && <TextError>{error}</TextError>}
      </Td>
      <Td>
        <Flex gap={6} align="center">
          <Icon
            fontSize="30px"
            borderRadius="8px"
            cursor="pointer"
            as={DeleteIcon}
            onClick={() => handleDelete(item)}
          />
        </Flex>
      </Td>
    </Tr>
  );
}
