import { Flex } from "@chakra-ui/react";
import FormInput from "components/form/FormInput";
import FormSelect from "components/form/FormSelect";
import { useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";

export default function ConditionDigital({
  isLoading,
  setParams,
  params,
  listLicensors = [],
}) {
  const form = useForm();

  const { setValue, handleSubmit } = form;

  const onSubmit = (data) => {
    if (!isLoading) {
      const newParams = {
        ...params,
        ...data,
        keyword: data?.keyword?.trim() || "",
        page: 1,
      };
      setParams(newParams);
    }
  };

  const optionLicensor = useMemo(() => {
    if (!listLicensors?.length) return [];

    return listLicensors
      .map((licentor) => ({
        label: licentor.name,
        value: licentor.name,
      }))
      .filter(
        (item, index, self) =>
          index === self.findIndex((t) => t.label === item.label)
      );
  }, [listLicensors]);

  return (
    <Flex
      gap={4}
      flexWrap="wrap"
      justifyContent="flex-start"
      alignItems="flex-end"
    >
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Flex flexWrap="wrap" alignItems="flex-end" gap={4}>
            <FormInput
              w="349px"
              maxW="100%"
              name="keyword"
              label="Search"
              placeholder={"Search by name"}
            />
            <FormSelect
              w="220px"
              name="licensor"
              label="Filter by Licensor"
              defaultValue={listLicensors[0]?.value}
              options={[{ value: "", label: "All" }, ...optionLicensor]}
              onChange={(e) => {
                if (!isLoading) {
                  setValue("licensor", e.target.value);
                  handleSubmit(onSubmit)();
                }
              }}
            />
          </Flex>
        </form>
      </FormProvider>
    </Flex>
  );
}
