import { Flex, Text } from "@chakra-ui/react";
import FormSelect from "components/form/FormSelect";
import { MACHINE_TYPE_OPTIONS, STATUS } from "constants/constants";
import { FormProvider, useForm } from "react-hook-form";
import { STATE, MODE_TYPE_SKILL } from "../MachineSettings/constants";
import styled from "styled-components";

const WrapperStatus = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  flex-wrap: wrap;
  .status-item {
    display: flex;
    align-items: center;
    gap: 12px;
    span {
      width: 13px;
      height: 13px;
      min-width: 13px;
      border-radius: 50%;
      border: 1px solid #000;
    }
    &__free {
      background: #D5D5D5;
      border-color: #6C6C6C;
    }
    &__playing {
      background: #98F1B3;
      border-color: #3DBB64;
    }
    &__maintenance {
      background: #F7BA8C;
      border-color: #782400;
    }
    &__preparing {
      background: #EFFFA1;
      border-color: #527800;
    }
    &__error {
      background: #FDA5A5;
      border-color: #991B1B;
    }
    &__confirm {
      background: #C2B3FF;
      border-color: #300097;
    }
  }
`

export default function ConditionMachineMap({ isLoading, setParams, params, listGroupLabel, listGamePlayType }) {
  const form = useForm();

  const { setValue, handleSubmit } = form;

  const onSubmit = (data) => {
    if (!isLoading) {
      const newParams = {
        ...params,
        ...data,
        page: 1,
      };
      setParams(newParams);
    }
  };

  return (
    <Flex
      gap="24px"
      flexWrap="wrap"
      direction="column"
      justifyContent="flex-start"
      w="100%"
      alignItems="flex-start"
    >
      <FormProvider {...form}>
        <form
          style={{
            maxWidth: "100%",
            width: "100%",
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            gap: "8px",
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Flex w="100%" alignItems="flex-end" gap={4} flexWrap={"wrap"}>
            <FormSelect
              maxW='100%'
              wrapperProps={{
                w: "220px",
              }}
              name="state"
              label="Filter by Machine State"
              defaultValue={""}
              options={[{ label: "All", value: "" }, ...STATE]}
              onChange={(e) => {
                if (!isLoading) {
                  setValue("state", e.target.value);
                  handleSubmit(onSubmit)();
                }
              }}
            />
            <FormSelect
              maxW='100%'
              wrapperProps={{
                w: "220px",
              }}
              name="machine_type"
              label="Filter by Booth Type"
              defaultValue=""
              options={[{ label: "All", value: "" }, ...MACHINE_TYPE_OPTIONS]}
              onChange={(e) => {
                if (!isLoading) {
                  setValue("machine_type", e.target.value);
                  handleSubmit(onSubmit)();
                }
              }}
            />
            <FormSelect
              maxW='100%'
              wrapperProps={{
                w: "220px",
              }}
              name="gameplay_type_id"
              label="Filter by Gameplay Type"
              defaultValue={""}
              options={[{ label: "All", value: "" }, ...listGamePlayType]}
              onChange={(e) => {
                if (!isLoading) {
                  setValue("gameplay_type_id", e.target.value);
                  handleSubmit(onSubmit)();
                }
              }}
            />
            <FormSelect
              maxW='100%'
              wrapperProps={{
                w: "220px",
              }}
              name="group_label"
              label="Filter by Group"
              defaultValue={""}
              options={[{ label: "All", value: "" }, ...listGroupLabel]}
              onChange={(e) => {
                if (!isLoading) {
                  setValue("group_label", e.target.value);
                  handleSubmit(onSubmit)();
                }
              }}
            />
            <FormSelect
              maxW='100%'
              wrapperProps={{
                w: "220px",
              }}
              name="mode_type"
              label="Filter by Mode Type"
              defaultValue={""}
              options={[{ label: "All", value: "" }, ...MODE_TYPE_SKILL]}
              onChange={(e) => {
                if (!isLoading) {
                  setValue("mode_type", e.target.value);
                  handleSubmit(onSubmit)();
                }
              }}
            />
            <Text
              cursor="pointer"
              color="#1480FF"
              fontSize="14px"
              fontWeight="500"
              alignSelf="flex-end"
              onClick={(e) => {
                e.preventDefault()
                setValue("state", "");
                setValue("machine_type", "");
                setValue("mode_type", "");
                setValue("group_label", "");
                setValue("gameplay_type_id", "");
                handleSubmit(onSubmit)();
              }}
            >
              Reset
            </Text>
          </Flex>
        </form>
      </FormProvider>
      <WrapperStatus>
        <div className="status-item">
          <span className="status-item__free"></span>
          <Text fontSize={14} fontWeight='bold'>Free Machine</Text>
        </div>
        <div className="status-item">
          <span className="status-item__playing"></span>
          <Text fontSize={14} fontWeight='bold'>Playing Machine</Text>
        </div>
        <div className="status-item">
          <span className="status-item__maintenance"></span>
          <Text fontSize={14} fontWeight='bold'>Maintenance</Text>
        </div>
        <div className="status-item">
          <span className="status-item__preparing"></span>
          <Text fontSize={14} fontWeight='bold'>Preparing Prize</Text>
        </div>
        <div className="status-item">
          <span className="status-item__error"></span>
          <Text fontSize={14} fontWeight='bold'>Error</Text>
        </div>
        <div className="status-item">
          <span className="status-item__confirm"></span>
          <Text fontSize={14} fontWeight='bold'>Confirm Prize</Text>
        </div>
      </WrapperStatus>
    </Flex>
  );
}
