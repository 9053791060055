import { Flex, Grid, useToast, Text} from '@chakra-ui/react';
import FormDatePicker from 'components/form/FormDatePicker';
import FormLabelComponent from "components/form/FormLabel";
import FormInput from 'components/form/FormInput';
import React from 'react'

import { useState, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import ButtonType from "components/Button";
import { yupResolver } from "@hookform/resolvers/yup";
import schemaReleaseBonusSetting from "./schema"
import useWithToast from "hooks/useWithToast";
import {
  getReleaseBonusSetting,
  createReleaseBonusSetting
} from "api/loginBonus.api";
export default function ReleaseBonus({ isHasPermissionEdit }) {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [releaseBonusSetting, setReleaseBonusSetting] = useState();
  const { showToastSuccess, showToastFail } = useWithToast();

  const dateFormat = "dd MMM. YYYY HH:mm:ss";
  const form = useForm({
    resolver: yupResolver(schemaReleaseBonusSetting()),
    defaultValues: {
      premium_pass: null,
      limit_per_day: null,
      limit_peak_hour: null,
      receive_start_date: null,
      receive_end_date: null,
    },
  });

  useEffect(() => {
    if (releaseBonusSetting) {
      form.reset({
        premium_pass: releaseBonusSetting?.premium_pass,
        limit_per_day: releaseBonusSetting?.limit_per_day,
        limit_peak_hour: releaseBonusSetting?.limit_peak_hour,
        receive_start_date: releaseBonusSetting?.receive_start_date ? new Date(releaseBonusSetting?.receive_start_date*1000)?.toISOString() : null,
        receive_end_date: releaseBonusSetting?.receive_end_date ? new Date(releaseBonusSetting?.receive_end_date*1000)?.toISOString() : null,
      });
    }
  }, [releaseBonusSetting]);

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
  } = form;

  const getBonusSetting = async () => {
    try {
      setIsLoading(true);
      const { data } = await getReleaseBonusSetting();
      if (data?.success) {
        const res = data?.data;
        setReleaseBonusSetting(res);
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getBonusSetting();
  }, []);

  const onSave = async (data) => {
    try {
      const res = await createReleaseBonusSetting(data);
        if (res?.data?.success) {
          showToastSuccess({
            title: "Cumulative login bonus setting created.",
          });
        } else {
          showToastFail({
            title: "Create fail!",
          });
        }
      } catch (error) {
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      }
  }


  return (
     <FormProvider {...form}>
      <form id="present-box-form" onSubmit={handleSubmit(onSave)}>
        <Flex flexDirection="column" gap="12px">
          <Grid templateColumns="repeat(2, 1fr)" gap={6} alignItems="end">
            <FormInput
              width="100%"
              type="number"
              name="premium_pass"
              label="Reward"
              isRequired
              readOnly={!isHasPermissionEdit}
            />
            <Text fontWeight={500} mb={errors?.premium_pass ? "32px" : "8px"}>Premium Pass Ticket</Text>
          </Grid>
          <FormInput
            width="100%"
            type="hidden"
            name="limit_per_day"
            label=""
            isRequired
            readOnly={!isHasPermissionEdit}
          />
          <FormInput
            width="100%"
            type="hidden"
            name="limit_peak_hour"
            label=""
            isRequired
            readOnly={!isHasPermissionEdit}
          />
          <Grid templateColumns="repeat(2, 1fr)" gap={6}>
            <Flex direction="column">
              <FormLabelComponent
                m="0px"
                title="Receiving start date"
                isRequired
              />
              <FormDatePicker
                name="receive_start_date"
                showTimeSelect
                dateFormat={dateFormat}
                h="40px"
                readOnly={!isHasPermissionEdit}
              />
            </Flex>
            <Flex direction="column">
              <FormLabelComponent
                m="0px"
                title="Receiving end date"
                isRequired
              />
              <FormDatePicker
                name="receive_end_date"
                showTimeSelect
                dateFormat={dateFormat}
                h="40px"
                readOnly={!isHasPermissionEdit}
              />
            </Flex>
          </Grid>
          {isHasPermissionEdit && (
            <ButtonType
              mt="5px"
              fontSize="14px"
              fontWeight="700"
              borderRadius="30px"
              h="50px"
              btnType="primary-new"
              sizeIcon="16px"
              type="submit"
            >
              SAVE
            </ButtonType>
          )}
        </Flex>
      </form>
    </FormProvider>
  )
}
