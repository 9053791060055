import * as yup from "yup";

const schemaApDistribution = () =>
  yup.object().shape({
    marker_a_sold: yup
      .number()
      .min(0, "Number of value must be greater or equal to 0")
      .nullable(true)
      .max(9999999, "Number of value must be at most 7 characters")
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : 0;
        }
        return 0;
      })
      .integer("Must be an integer"),
    marker_a_slope: yup
      .number()
      .min(0, "Number of value must be greater or equal to 0")
      .nullable(true)
      .max(9999999, "Number of value must be at most 7 characters")
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : 0;
        }
        return 0;
      }),
    marker_b_sold: yup
      .number()
      .min(0, "Number of value must be greater or equal to 0")
      .nullable(true)
      .max(9999999, "Number of value must be at most 7 characters")
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : 0;
        }
        return 0;
      })
      .integer("Must be an integer"),
    marker_b_slope: yup
      .number()
      .min(0, "Number of value must be greater or equal to 0")
      .nullable(true)
      .max(9999999, "Number of value must be at most 7 characters")
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : 0;
        }
        return 0;
      }),
    marker_c_sold: yup
      .number()
      .min(0, "Number of value must be greater or equal to 0")
      .nullable(true)
      .max(9999999, "Number of value must be at most 7 characters")
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : 0;
        }
        return 0;
      })
      .integer("Must be an integer"),
    marker_c_slope: yup
      .number()
      .min(0, "Number of value must be greater or equal to 0")
      .nullable(true)
      .max(9999999, "Number of value must be at most 7 characters")
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : 0;
        }
        return 0;
      }),
    marker_d_sold: yup
      .number()
      .min(0, "Number of value must be greater or equal to 0")
      .nullable(true)
      .max(9999999, "Number of value must be at most 7 characters")
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : 0;
        }
        return 0;
      })
      .integer("Must be an integer"),
    marker_d_slope: yup
      .number()
      .min(0, "Number of value must be greater or equal to 0")
      .nullable(true)
      .max(9999999, "Number of value must be at most 7 characters")
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : 0;
        }
        return 0;
      }),

  });

export default schemaApDistribution;
