import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
  useColorModeValue,
} from "@chakra-ui/react";
import { listHunterNFTs, listGauntLetNFTs, listBountyBallNFTs } from "api/presentBox.api";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import Paginate from "components/Paginate";
import TableCustom from "components/Tables/CustomTable";
import NftsRow from "./row";
import { useCallback, useEffect, useState } from "react";
import { NFT_MANAGEMENT_TYPE } from "views/NFTsManagement/constants";
import ButtonType from "components/Button";
import NFTDetailModal from "views/NFTsManagement/components/modal/NFTDetailModal";
import { useNFTList } from "views/NFTsManagement/hooks/useNFTList";
import ConditionNFT from "./condition"
import { transformNFT } from "views/PresentBoxManagement/constant"
import { TOKEN_TYPE } from "constants/constants"

export default function ListNft(props) {
  const {
    handleOpen,
    handleClose,
    params,
    setParams,
    nftMngType,
    isLoading,
    setIsLoading,
    setValue,
    getValues,
    setCheckedIds,
    checkedIds,
    userId,
    giftsSelected,
    dataDetail,
  } = props

  const labelsBountyBall = [
    "",
    "IMAGE",
    "NAME",
    "RARITY",
    "TRAIT",
    "GIMMICK",
  ];

  const labels = [
    "",
    "IMAGE",
    "NAME",
    "RARITY",
    "TRAIT",
  ];
  const textColor = useColorModeValue("#000000", "white");
  const [users, setNfts] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const toast = useToast();

  const {
    nftDetail,
    getNFTDetail,
  } = useNFTList(nftMngType);

  const {
    isOpen: isOpenDetailNft,
    onOpen: onOpenDetailNft,
    onClose: onCloseDetailNft,
  } = useDisclosure();

  const transformData = (data) => {
    const groupedData = {};

    data.forEach(item => {
        if (!groupedData[item.user_id]) {
            groupedData[item.user_id] = [];
        }
        groupedData[item.user_id].push(item.product_id);
    });

    return {
        status_mint: "MINTED",
        purpose: dataDetail?.id ? "UPDATE" : "CREATE",
        excluded_product_item_filter: {
            excluded_user_id: userId,
            selected_items: Object.entries(groupedData).map(([idUser, itemIds]) => ({
              user_id: idUser,
              product_ids: itemIds
            }))
        }
    };
  };

  const formattedData = transformData(giftsSelected.filter((gift) => gift.type == 'NFT'));
  const getUsers = useCallback(
    async () => {
      try {
        setIsLoading(true);
        let data
        if (nftMngType === NFT_MANAGEMENT_TYPE.HUNTER) {
          data = await listHunterNFTs({...formattedData, ...params});
        } else if (nftMngType === NFT_MANAGEMENT_TYPE.GAUNTLET) {
          data = await listGauntLetNFTs({...formattedData, ...params});
        } else {
          data = await listBountyBallNFTs({...formattedData, ...params});
        }
        if (data?.data?.success) {
          const res = data?.data?.data;
          setNfts(res?.records);
          setTotalPage(res?.total_page);
        }
      } catch (error) {
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      } finally {
        setIsLoading(false);
      }
    },
    [params]
  );

  // detail user modal
  const handleOpenDetailUserModal = async (item) => {
    await getNFTDetail(item);
    onOpenDetailNft(true);
  };

  const handleChangePage = (page) => {
    setParams({
      ...params,
      page,
    });
  };

  const onChangeChecked = (isChecked) => {
    if (isChecked) {
      setCheckedIds({[userId]: users.map(transformNFT)});
    } else {
      setCheckedIds([]);
    }
  }

  const onChangeLimit = (limit) => {
    setParams({
      ...params,
      page: 1,
      limit,
    });
  };

  const handleSelect = () => {
    handleClose()
    const prevNftItems = getValues("nft_items")?.[userId];
    setValue("nft_items", {
      ...prevNftItems,
      [userId]: {
        ...(prevNftItems?.[userId] || {}), // Retain old data if available
        ...checkedIds?.[userId]
      }
    })
  }

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  return (
    <Modal isOpen={handleOpen} onClose={handleClose} closeOnOverlayClick={false} size="xxl">
      <ModalOverlay />
      <ModalContent maxW="910px" >
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >
          Select Item(s)
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px" height="calc(100% - 150px)" overflowY="auto">
          <Card px="0px">
            <CardBody>
              <Flex flexDirection="column">
                <Card p="0px">
                  <CardHeader mb="32px">
                    <ConditionNFT
                      isLoading={isLoading}
                      setParams={setParams}
                      params={params}
                    />
                  </CardHeader>
                  <CardBody overflowX="auto">
                    <TableCustom
                      labels={users[0]?.token_type === TOKEN_TYPE.BOUNTY_BALL_NFT ? labelsBountyBall : labels}
                      isLoading={isLoading}
                      isCheck
                      isChecked={users?.length > 0 && checkedIds?.[userId]?.length === users?.length}
                      onChangeChecked={onChangeChecked}
                      dataRow={users}
                    >
                      <>
                        {users?.map((item) => {
                          return (
                            <NftsRow
                              key={item?.id}
                              setCheckedIds={setCheckedIds}
                              checkedIds={checkedIds}
                              handleOpenDetailUserModal={handleOpenDetailUserModal}
                              item={item}
                              nftMngType={nftMngType}
                              userId={userId}
                            />
                          );
                        })}
                      </>
                    </TableCustom>
                  </CardBody>
                  <Paginate
                    page={params?.page}
                    pageCount={totalPage}
                    limit={params.limit}
                    onChangeLimit={onChangeLimit}
                    handleChangePage={handleChangePage}
                  />
                </Card>
              </Flex>
            </CardBody>
          </Card>
        </ModalBody>
        <ModalFooter
          borderTop="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          gap="20px"
        >
          <ButtonType
            mt={4}
            m="auto"
            w="160px"
            h="46px"
            form="select-nft-form"
            disabled={!checkedIds?.[userId]?.length}
            borderRadius="5px"
            onClick={handleSelect}
          >
            SELECT
          </ButtonType>
        </ModalFooter>
      </ModalContent>
      {isOpenDetailNft && (
        <NFTDetailModal
          nftMngType={nftMngType}
          item={nftDetail}
          isOpen={isOpenDetailNft}
          onClose={onCloseDetailNft}
        />
      )}
    </Modal>
  );
}
