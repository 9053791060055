import {
  Flex,
  Grid,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { getUserInfo } from "api/auth.api";
import { getRoles } from "api/role.api";
import { getRulesGroup } from "api/rule.api";
import ButtonType from "components/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import FormCheckbox from "components/form/FormCheckbox";
import TableCustom from "components/Tables/CustomTable";
import { StorageKeys } from "constants/enum";
import { PERMISSIONS } from "constants/permissions";
import { usePermission } from "hooks/usePermission";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { setLocalStorage } from "utils/storage";
import { convertStringSnake } from "utils/string";
import RoleManagementRow from "./row";
import ModalUpdateRole from "./UpdateRole/ModalUpdateRole";
import { updateUserinfo } from "stores/user/userReducer";
import { useDispatch } from "react-redux";

export default function RoleSystemPage() {
  const dispatch = useDispatch();
  const { userinfo, isHasPermissionEdit } = usePermission(PERMISSIONS.ADMIN_ACCOUNT_ROLE);
  const labels = ["ACTION", "#", "ROLE"];
  const [roles, setRoles] = useState([]);
  const [rules, setRules] = useState([]);
  const [dataDetail, setDataDetail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const {
    isOpen: isOpenCreateAndEdit,
    onOpen: onOpenCreateAndEdit,
    onClose: onCloseCreateAndEdit,
  } = useDisclosure();

  const handleOpenEditModal = (item) => {
    setDataDetail(item);
    onOpenCreateAndEdit(true);
  };

  const handleOpenCreateModal = (item) => {
    setDataDetail(null);
    onOpenCreateAndEdit(true);
  };

  const defaultValues = useMemo(() => {
    if (rules?.length > 0 && roles?.length > 0) {
      let list = [];
      roles?.map((role) => {
        const dataFromRule = rules?.map((rule) => {
          return {
            [`${role?.id}-${rule?.id}`]: role?.rules?.some(
              (itemRuleSome) => itemRuleSome?.id === rule?.id
            ),
          };
        });
        list = list.concat(dataFromRule);
        return dataFromRule;
      });
      if (list?.length > 0) {
        const result = list.reduce((acc, obj) => {
          return { ...acc, ...obj };
        }, {});

        return result;
      }
    }
  }, [rules, roles]);

  const form = useForm({
    defaultValues,
  });

  const {
    getValues,
    reset,
    formState: { isDirty },
  } = form;

  useEffect(() => {
    if (defaultValues) {
      reset(defaultValues);
    }
  }, [defaultValues]);

  const textColor = useColorModeValue("#000000", "white");

  const getRulesAndRoles = async () => {
    try {
      setIsLoading(true);
      const [dataRule, dataRole] = await Promise.all([
        getRulesGroup(),
        getRoles(),
      ]);
      if (dataRule?.data && dataRule?.data?.data?.length > 0) {
        setRules(dataRule.data?.data);
      }
      if (dataRole?.data && dataRole?.data?.data?.records?.length > 0) {
        setRoles(dataRole.data?.data?.records);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const getInfoUser = async () => {
    const res = await getUserInfo();
    let user = { ...res?.data?.data };
    user.create_time = Number.parseFloat(user?.create_time || 0) || null;
    user.update_time = Number.parseFloat(user?.update_time || 0) || null;
    // If change role data
    if (user?.role && JSON.stringify(user?.role) !== JSON.stringify(userinfo?.role)) {
      dispatch(updateUserinfo(user));
      setLocalStorage(StorageKeys.USERINFO, JSON.stringify(user));
    }
  };

  useEffect(() => {
    getRulesAndRoles();
    getInfoUser();
  }, []);

  const ItemRule = ({ item, lenRoles }) => {
    return (
      <>
        <Text
          key={item?.id}
          color={textColor}
          fontWeight="600"
          fontSize="14px"
          minW="150px"
        >
          {convertStringSnake(item?.name)}
        </Text>
        {lenRoles > 0 &&
          Array.from(Array(lenRoles).keys())?.map((_, index) => {
            return (
              <FormCheckbox
                key={index}
                name={`${roles[index]?.id}-${item?.id}`}
              />
            );
          })}
      </>
    );
  };

  const renderListRules = useCallback(() => {
    const lenRoles = roles?.length;
    return (
      <>
        {rules?.map((item) => {
          return <ItemRule key={item?.id} item={item} lenRoles={lenRoles} />;
        })}
      </>
    );
  }, [roles, rules]);

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <Card px="24px">
        <CardHeader>
          <Flex
            w="100%"
            gap={4}
            direction={{
              base: "column",
              md: "row",
            }}
            justifyContent={{
              base: "flex-start",
              md: "space-between",
            }}
            alignItems="flex-start"
          >
            <Text color={textColor} fontSize="lg" fontWeight="bold">
              Setting Permissions
            </Text>
            {isHasPermissionEdit && (
              <ButtonType
                text="Create Role"
                fontSize="16px"
                fontWeight="700"
                onClick={handleOpenCreateModal}
              />
            )}
          </Flex>
        </CardHeader>
        <CardBody overflowX="auto">
          <TableCustom
            labels={labels}
            isLoading={isLoading}
            isCheck={false}
            dataRow={roles}
          >
            <>
              {roles?.map((item, index) => {
                return (
                  <RoleManagementRow
                    key={item?.id}
                    handleOpenEditModal={handleOpenEditModal}
                    item={item}
                    index={index + 1}
                    isHasPermissionEdit={isHasPermissionEdit}
                  />
                );
              })}
            </>
          </TableCustom>
        </CardBody>
      </Card>
      {isOpenCreateAndEdit && (
        <ModalUpdateRole
          isOpen={isOpenCreateAndEdit}
          onClose={onCloseCreateAndEdit}
          rules={rules}
          dataDetail={dataDetail}
          getRulesAndRoles={getRulesAndRoles}
        />
      )}
    </Flex>
  );
}
