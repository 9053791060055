import styled from 'styled-components';
import ICON_RANK_SILVER from 'assets/img/card/silver.png'
import ICON_DISABLED_SILVER from 'assets/img/card/silver-disabled.svg'
import ICON_RANK_GOLD from 'assets/img/card/gold.png'
import ICON_DISABLED_GOLD from 'assets/img/card/gold-disabled.png'
import ICON_RANK_PLATINUM from 'assets/img/card/platinum.png'
import ICON_DISABLED_PLATINUM from 'assets/img/card/platinum-disabled.png'
import ICON_RANK_DIAMOND from 'assets/img/card/diamond.png'
import ICON_DISABLED_DIAMOND from 'assets/img/card/diamond-disabled.png'
import ICON_RANK_IRON from 'assets/img/card/iron.png'
import ICON_DISABLED_IRON from 'assets/img/card/iron-disabled.svg'

export const ComponentLevel = styled.div`
  position: relative;
  width: auto;
  display: flex;
  align-items: center;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .item-number {
    position: absolute;
    right: -1px;
    bottom: -2px;
    font-weight: 700;
    font-size: 8px;
    line-height: 13px;
    color: #FFFFFF;
    text-shadow: 2px 0 #000, -2px 0 #000, 0 2px #000, 0 -2px #000, 1px 1px #000, -1px -1px #000, 1px -1px #000, -1px 1px #000;
    &.lg {
      font-size: 12px;
      right: 3px;
    }
  }
`;

export const  ItemIcon = ({ size, text, type, number }) => {

  const icons = {
    silver: { enabled: ICON_RANK_SILVER, disabled: ICON_DISABLED_SILVER },
    gold: { enabled: ICON_RANK_GOLD, disabled: ICON_DISABLED_GOLD },
    platinum: { enabled: ICON_RANK_PLATINUM, disabled: ICON_DISABLED_PLATINUM },
    diamond: { enabled: ICON_RANK_DIAMOND, disabled: ICON_DISABLED_DIAMOND },
    iron: { enabled: ICON_RANK_IRON, disabled: ICON_DISABLED_IRON },
  };
  
  const levelCard = (type, number) => {
    const iconSet = icons[type];
    if (!iconSet) return;
    return number >= 1 ? iconSet.enabled : iconSet.disabled;
  };

  return (
    <ComponentLevel>
      <img className={`item-icon`} style={{ width: size ? `${size}px` : "100%" }} src={levelCard(type, number)} alt="icon"/>
      <span className={`item-number ${text}`}>{number}</span>
    </ComponentLevel>
  );
};