export const DEFAULT_KEY = "************************************************";

export const PRIVATE_KEY = {
  AW_PAYER_HOT_WALLET_PRIVATE_KEY: "AW_PAYER_HOT_WALLET_PRIVATE_KEY",
  AW_NFT_HOLDER_PRIVATE_KEY: "AW_NFT_HOLDER_PRIVATE_KEY",
  AW_ENCRYPT_KEY: "AW_ENCRYPT_KEY",
  AW_ENCRYPT_IV: "AW_ENCRYPT_IV",
  SUBMIT: "SUBMIT",
};

// Key need encrypt
export const ENCRYPT_KEY = [
  PRIVATE_KEY.AW_PAYER_HOT_WALLET_PRIVATE_KEY,
  PRIVATE_KEY.AW_NFT_HOLDER_PRIVATE_KEY,
];
