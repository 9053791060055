import { useState, useCallback } from "react";
import { Select as ChakraSelect, chakraComponents } from "chakra-react-select";
import {
  Box,
  Flex,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import ModalBase from "components/Modal/ModalBase";
import debounce from "lodash/debounce";
import ButtonType from "components/Button";
import { getUsersWallet } from "api/userManagement";
import TableCustom from "components/Tables/CustomTable";
import RowFigure from "./row";
import { transferBackground, transferDigitalFigure, transferRoomBg, transferRoomItems } from "api/digitaFigure.api";
import { ERROR_TRANSFER_DIGITAL } from "views/DigitalFigure/constants";

export default function ModalTransferFigure({
  items,
  isOpen,
  onClose,
  checkedIds,
  setCheckedIds,
  handleCreateOrUpdateSuccess,
  type,
  title,
  columnTitle,
  selectTitle,
}) {
  const toast = useToast();
  const [userOptions, setUserOptions] = useState([]);
  const [receiver, setReceiver] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isTransferring, setIsTransferring] = useState(false);
  const [listFigureCheck, setListFigureCheck] = useState(checkedIds)

  const labels = [
    columnTitle,
    "Count",
    "",
  ];

  const fetchUsers = async (inputValue) => {
    setIsLoading(true);
    try {
      const params = {
        page: 1,
        limit: 100,
        status: "ACTIVE",
        keyword: inputValue,
      };
      const { data } = await getUsersWallet(params);
      if (data?.data?.records?.length > 0) {
        const options = data?.data?.records?.map((user) => {
          return {
            value: user?.id,
            label: user?.email || user?.name,
          };
        });
        setUserOptions(options);
      } else {
        toast({
          description: "No users found",
          status: "warning",
          position: "top",
        });
        setUserOptions([]);
      }
    } catch (error) {
      setUserOptions([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangePoint = (id, field, value) => {
    setCheckedIds((prev) =>
      prev.map((item) =>
        item.id === id ? { ...item, [field]: value } : item
      )
    );
  };

  // Debounce the API call to avoid excessive requests
  const debouncedFetchUsers = useCallback(
    debounce((inputValue) => fetchUsers(inputValue), 300),
    []
  );

  const handleInputChange = (inputValue) => {
    if (inputValue.length > 0) {
      debouncedFetchUsers(inputValue);
    } else {
      setUserOptions([]);
    }
  };

  const handleTransfer = async () => {
    setIsTransferring(true);
    const users = receiver?.map((user) => user?.value)
    try {
      let data
      if (type === "viewBg") {
        const listIds = checkedIds?.map((check) => ({
          background_id: check?.id,
          count: check?.count
        }))
        const params = {
          user_ids: users,
          backgrounds: listIds,
        };
        data = await transferBackground(params);
      } else if (type === "roomBg") {
        const listIds = checkedIds?.map((check) => ({
          room_bg_id: check?.id,
          count: check?.count
        }))
        const params = {
          user_ids: users,
          room_bgs: listIds,
        };
        data = await transferRoomBg(params);
      } else if (type === "roomItem") {
        const listIds = checkedIds?.map((check) => ({
          room_item_id: check?.id,
          count: check?.count
        }))
        const params = {
          user_ids: users,
          room_items: listIds,
        };
        data = await transferRoomItems(params);
      } else {
        const listIds = checkedIds?.map((check) => ({
          digital_figure_id: check?.id,
          count: check?.count
        }))
        const params = {
          user_ids: users,
          digital_figures: listIds,
        };
        data = await transferDigitalFigure(params);
      }
      // Successful transfer
      if (data?.data?.success) {
        handleCreateOrUpdateSuccess();
        onClose();
        toast({
          title: "Transfer successful.",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
        setCheckedIds([])
      } else {
        toast({
          description: ERROR_TRANSFER_DIGITAL[data?.data?.message] || ERROR_TRANSFER_DIGITAL[data?.data?.messages[0]],
          status: "error",
          position: "bottom-right",
        });
      }
    } finally {
      setIsTransferring(false);
    }
  };

  return (
    <>
      <ModalBase
        maxWContent={{
          base: "60%",
          sm: "80%",
          md: "60%",
        }}
        isOpen={isOpen}
        onClose={onClose}
        showBtn={false}
        titleHeader={title}
        isShowFooter={false}
      >
        <VStack spacing={4} py={4} align="stretch">
          <Box>
            <Text fontWeight="bold" mb={2}>
              Receiver
            </Text>
            <ChakraSelect
              options={userOptions}
              placeholder="Select a user"
              value={receiver}
              isMulti
              onChange={(selectedOption) => setReceiver(selectedOption)}
              onInputChange={handleInputChange}
              isLoading={isLoading}
              noOptionsMessage={() => null}
              chakraStyles={{
                placeholder: (provided) => ({
                  ...provided,
                  color: "gray.400",
                }),
                control: (provided) => ({
                  ...provided,
                  cursor: "text",
                }),
              }}
              components={{
                Option: ({ children, ...props }) => (
                  <chakraComponents.Option {...props}>
                    <Flex alignItems="center">
                      <Box mr={2}>{children}</Box>
                    </Flex>
                  </chakraComponents.Option>
                ),
                DropdownIndicator: () => null,
              }}
              isClearable
            />
          </Box>

          <Box>
            <Text fontWeight="bold" mb={2}>
              {selectTitle}
            </Text>
            <Box overflowX="auto" px="60px" py="20px">
              <TableCustom
                isCheck={false}
                labels={labels}
                dataRow={checkedIds}
              >
                <>
                  {checkedIds?.map((item, index) => {
                    return (
                      <RowFigure
                        key={item?.id}
                        item={item}
                        index={index}
                        handleChangePoint={handleChangePoint}
                        checkedIds={checkedIds}
                        setCheckedIds={setCheckedIds}
                        setListFigureCheck={setListFigureCheck}
                        listFigureCheck={listFigureCheck}
                      />
                    );
                  })}
                </>
              </TableCustom>
            </Box>
          </Box>

          <Flex justifyContent="center">
            <ButtonType
              w="100px"
              type="button"
              onClick={handleTransfer}
              isDisabled={!receiver || !checkedIds?.length}
              isLoading={isTransferring}
              text="SEND"
            />
          </Flex>
        </VStack>
      </ModalBase>
    </>
  );
}
