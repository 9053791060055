import { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import ICON_BOTTOM from 'assets/img/card/icon-bottom.png'
import ICON_POL from 'assets/img/card/matic.png'
import ICON_DM2 from 'assets/img/card/dm2c_icon.png'
import styled from "styled-components";
import CommonBg from 'assets/img/card/common-bg.svg'
import UncommonBg from 'assets/img/card/uncommon-bg.svg'
import RareBg from 'assets/img/card/rare-bg.svg'
import LegendaryBg from 'assets/img/card/legendary-bg.svg'
import EpicBg from 'assets/img/card/epic-bg.svg'
import { RARITY } from "constants/constants";
import { ItemIcon } from "components/ItemNftNew/ItemIcon";
import Thumbnail from "components/common/thumbnail";
import { Image, Text } from "@chakra-ui/react";
import { NFT_MANAGEMENT_TYPE } from "views/NFTsManagement/constants";
// import { ItemIcon } from "./ItemIcon";

const StyledDiv = styled.div`
  position: relative;
  background-color: lavender;
  display: flex;
  min-width: 200px;
  height: 200px;
  border-radius: 12px;

  .group-image {
    position: relative;
    flex: 1;
    .image {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    &__rank {
      width: 92%;
      position: absolute;
      bottom: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 2px 8px;
      position: absolute;
      background: rgba(0, 0, 0, 0.2);
      border-radius: 8px;
      gap: 8px;
      left: 10px;
      &-item {
        position: relative;
        width: 22px;
        display: flex;
        align-items: center;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
        .item-number {
          position: absolute;
          right: 2px;
          bottom: -2px;
          font-weight: 700;
          font-size: 9px;
          line-height: 13px;
          color: #FFFFFF;
          text-shadow: 2px 0 #000, -2px 0 #000, 0 2px #000, 0 -2px #000, 1px 1px #000, -1px -1px #000, 1px -1px #000, -1px 1px #000;
        }
      }
    }
    .name-nft {
      position: absolute;
      top: 6px;
      text-align: center;
      color: #4164ff;
      font-size: 16px;
      font-weight: 600;
      text-transform: uppercase;
      width: 100%;
      text-shadow: 2px 0 #fff, -2px 0 #fff, 0 2px #fff, 0 -2px #fff, 1px 1px #fff, -1px -1px #fff, 1px -1px #fff, -1px 1px #fff;
    }
  }
`;

const StyledIconRarity = styled.div`
  width: auto;
  /* height: 31px; */
  border-radius: 4px;
  padding: 5px 12px;
  margin: auto;

  background-color: ${props => props.bgRarity};
  border: 2px solid #000000;
  box-shadow: 1px 3px 0px 2px #000000;
  transform: skew(-12deg);
  position: absolute;
  top: -10px;
  right: -8px;

  .rarity-content {
    display: flex;
    align-items: center;
    gap: 4px;

    transform: skew(14deg);
    .icon-rarity {
      width: 22.36px;
      height: 22.36px;
    }
  }
`;

export default function ItemNft({
  item,
  tabIndex,
  handleOpenDetailUserModal,
  nftMngType,
}) {

  const IconCard = useMemo(() => {
    const rarity = item?.rarity;
    let iconCard = {
      iconBg: CommonBg,
      bgRarity: '#545f7b',
    };
    if (rarity === RARITY.RARITY_UNCOMMON) {
      iconCard = {
        iconBg: UncommonBg,
        bgRarity: '#008a17',
      };
    } else if (rarity === RARITY.RARITY_RARE) {
      iconCard = {
        iconBg: RareBg,
        bgRarity: '#005ddd',
      };
    } else if (rarity === RARITY.RARITY_EPIC) {
      iconCard = {
        iconBg: EpicBg,
        bgRarity: '#7612ff',
      };
    }
    if (rarity === RARITY.RARITY_LEGENDARY) {
      iconCard = {
        iconBg: LegendaryBg,
        bgRarity: '#ffd32f',
      };
    }

    return iconCard;
  }, [item?.rarity]);
  
  const getIconCurrency = useCallback(curr => {
    if (curr === 0) return ICON_BOTTOM;
    if (curr === 1) return ICON_DM2;
    return ICON_POL;
  }, [tabIndex]);

  return (
    <StyledDiv bg={IconCard.iconBg} className={`product-card-new`} onClick={() => handleOpenDetailUserModal(item)}>
      <div className="group-image">
        <Image className="image" src={item.image_url} alt="bg" />
        <Text className="name-nft" >
          {item?.name}
        </Text>
        {nftMngType === NFT_MANAGEMENT_TYPE.HUNTER && (
          <div className="group-image__rank">
            <ItemIcon type="silver" size={24} number={item?.treasure_hunter_init_value}/>
            <ItemIcon type="gold" size={24} number={item?.prize_hunter_init_value}/>
            <ItemIcon type="platinum" size={24} number={item?.arena_hunter_init_value}/>
            <ItemIcon type="diamond" size={24} number={item?.merchant_init_value}/>
            <ItemIcon type="iron" size={24} number={item?.mechanic_init_value}/>
          </div>
        )}
      </div>
    </StyledDiv>
  );
}
