import {
  Box,
  Flex,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import ButtonType from "components/Button";
import FormInput from "components/form/FormInput";
import useWithToast from "hooks/useWithToast";
import { useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import ratesSchema from "./schema";
import FormSelect from "components/form/FormSelect";
import { uploadImageToServer } from "utils/uploadImage";
import { NUMBER } from "constants/enum";
import FormUpload from "components/form/FormUpload";
import FormLabelComponent from "components/form/FormLabel";
import { updateTreasureBox } from "api/treasureBox.api";

export default function CreateAndUpdateTreasure({
  isOpen,
  onClose,
  dataDetail,
  handleCreateOrUpdateSuccess,
  listPlayFee,
}) {

  const textColor = useColorModeValue("#000000", "white");
  const toast = useToast();
  const { showToastSuccess } = useWithToast();
  const [nameImage, setNameImage] = useState("");

  const idEdit = useMemo(() => {
    return dataDetail?.id;
  }, [dataDetail]);

  const form = useForm({
    resolver: yupResolver(ratesSchema()),
    defaultValues: {
      play_fee_id: dataDetail?.play_fee_id || listPlayFee[0]?.value,
      allocation_rate: dataDetail?.allocation_rate || "",
      treasure_box_rank: dataDetail?.treasure_box_rank || 0,
      opening_cost: dataDetail?.opening_cost || 0,
      none_up_rank_chance: dataDetail?.treasure_box_up_rank_param?.none_up_rank_chance || 0,
      up1_rank_chance: dataDetail?.treasure_box_up_rank_param?.up1_rank_chance || 0,
      up2_rank_chance: dataDetail?.treasure_box_up_rank_param?.up2_rank_chance || 0,
      up3_rank_chance: dataDetail?.treasure_box_up_rank_param?.up3_rank_chance || 0,
      up4_rank_chance: dataDetail?.treasure_box_up_rank_param?.up4_rank_chance || 0,
      up5_rank_chance: dataDetail?.treasure_box_up_rank_param?.up5_rank_chance || 0,
      sphere_appearance_rate_pve: dataDetail?.treasure_box_bonuses[0]?.sphere_appearance_rate || 0,
      sphere_appearance_rate_pvp: dataDetail?.treasure_box_bonuses[1]?.sphere_appearance_rate || 0,
      sphere_appearance_rate_coop: dataDetail?.treasure_box_bonuses[2]?.sphere_appearance_rate || 0,
      min_number_of_sphere_pve: dataDetail?.treasure_box_bonuses[0]?.min_number_of_sphere || 0,
      max_number_of_sphere_pve: dataDetail?.treasure_box_bonuses[0]?.max_number_of_sphere || 0,
      min_number_of_sphere_pvp: dataDetail?.treasure_box_bonuses[1]?.min_number_of_sphere || 0,
      max_number_of_sphere_pvp: dataDetail?.treasure_box_bonuses[1]?.max_number_of_sphere || 0,
      min_number_of_sphere_coop: dataDetail?.treasure_box_bonuses[2]?.min_number_of_sphere || 0,
      max_number_of_sphere_coop: dataDetail?.treasure_box_bonuses[2]?.max_number_of_sphere || 0,
      orb_appearance_rate_pve: dataDetail?.treasure_box_bonuses[0]?.orb_appearance_rate || 0,
      orb_appearance_rate_pvp: dataDetail?.treasure_box_bonuses[1]?.orb_appearance_rate || 0,
      orb_appearance_rate_coop: dataDetail?.treasure_box_bonuses[2]?.orb_appearance_rate || 0,
      min_number_of_orb_pve: dataDetail?.treasure_box_bonuses[0]?.min_number_of_orb || 0,
      max_number_of_orb_pve: dataDetail?.treasure_box_bonuses[0]?.max_number_of_orb || 0,
      min_number_of_orb_pvp: dataDetail?.treasure_box_bonuses[1]?.min_number_of_orb || 0,
      max_number_of_orb_pvp: dataDetail?.treasure_box_bonuses[1]?.max_number_of_orb || 0,
      min_number_of_orb_coop: dataDetail?.treasure_box_bonuses[2]?.min_number_of_orb || 0,
      max_number_of_orb_coop: dataDetail?.treasure_box_bonuses[2]?.max_number_of_orb || 0,
      image_closed: dataDetail?.image_closed || '',
      name: dataDetail?.name || '',
    },
  });

  const {
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = form;
  
  
  const onSubmit = async (data) => {
    const dataSubmit = {
      name: data?.name,
      play_fee_id: data?.play_fee_id,
      image_closed: data?.image_closed,
      treasure_box_rank: data?.treasure_box_rank,
      allocation_rate: data?.allocation_rate,
      opening_cost: data?.opening_cost,
      treasure_box_bonuses: [
        {
          id: dataDetail?.treasure_box_bonuses[0]?.id,
          mode_type: "PVE_FREE_PLAY",
          sphere_appearance_rate: data?.sphere_appearance_rate_pve,
          min_number_of_sphere: data?.min_number_of_sphere_pve,
          max_number_of_sphere: data?.max_number_of_sphere_pve,
          orb_appearance_rate: data?.orb_appearance_rate_pve,
          min_number_of_orb: data?.min_number_of_orb_pve,
          max_number_of_orb: data?.max_number_of_orb_pve,
        },
        {
          id: dataDetail?.treasure_box_bonuses[1]?.id,
          mode_type: "PVP",
          sphere_appearance_rate: data?.sphere_appearance_rate_pvp,
          min_number_of_sphere: data?.min_number_of_sphere_pvp,
          max_number_of_sphere: data?.max_number_of_sphere_pvp,
          orb_appearance_rate: data?.orb_appearance_rate_pvp,
          min_number_of_orb: data?.min_number_of_orb_pvp,
          max_number_of_orb: data?.max_number_of_orb_pvp,
        },
        {
          id: dataDetail?.treasure_box_bonuses[2]?.id,
          mode_type: "CO_OP",
          sphere_appearance_rate: data?.sphere_appearance_rate_coop,
          min_number_of_sphere: data?.min_number_of_sphere_coop,
          max_number_of_sphere: data?.max_number_of_sphere_coop,
          orb_appearance_rate: data?.orb_appearance_rate_coop,
          min_number_of_orb: data?.min_number_of_orb_coop,
          max_number_of_orb: data?.max_number_of_orb_coop,
        }
      ],
      treasure_box_up_rank_param: {
        none_up_rank_chance: data?.none_up_rank_chance,
        up1_rank_chance: data?.up1_rank_chance,
        up2_rank_chance: data?.up2_rank_chance,
        up3_rank_chance: data?.up3_rank_chance,
        up4_rank_chance: data?.up4_rank_chance,
        up5_rank_chance: data?.up5_rank_chance
      }
    }
    try {
      if (idEdit) {
        const res = await updateTreasureBox(dataDetail?.id, dataSubmit)
        if (res?.status === 200) {
          showToastSuccess({
            title: "Update exchange rate settings successfully!",
          });
          handleCreateOrUpdateSuccess()
        } else {
          toast({
            description: res?.data?.message || res?.data?.messages[0],
            status: 'error',
            position: 'bottom-right',
          })
        }
      } 
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const handleImageChange = async (event) => {
    const image = event[0];
    await uploadImageToServer(image, toast, setValue, "image_closed", setNameImage);
  };

    const handleDragImage = async (file) => {
    if (file.length > NUMBER.ONE) {
      toast({
        description: "The number of uploaded images is greater than one.",
        status: "error",
        position: "bottom-right",
      });
      return false;
    }
    const image = file && file[0];
    await uploadImageToServer(image, toast, setValue, "image_closed", setNameImage);
  };

  const deleteImage = () => {
    setValue('image_closed', null)
  }

  const renderForm = () => {
    return (
      <FormProvider {...form}>
        <form id="treasure-form" onSubmit={handleSubmit(onSubmit)}>
          <Grid templateColumns="repeat(1, 1fr)" gap={4}>
            <Flex gap={4}>
              <FormSelect
                wrapperProps={{
                  w: "100%"
                }}
                name="play_fee_id"
                label="Play fee"
                defaultValue={listPlayFee[0]?.value}
                options={listPlayFee}
                onChange={(e) => setValue("play_fee_id", e.target.value)}
                isRequired
                disabled
              />
              <FormInput
                wrapperProps={{
                  w: "100%",
                }}
                name="allocation_rate"
                type="number"
                min={0}
                label="Allocation Ratio"
                placeholder="Enter a value"
                isRequired
                disabled
              />
            </Flex>
            <Flex gap={4}>
              <FormInput
                wrapperProps={{
                  w: "100%",
                }}
                name="name"
                type="string"
                min={0}
                label="Name"
                disabled
              />
              <FormInput
                wrapperProps={{
                  w: "100%",
                }}
                name="treasure_box_rank"
                type="number"
                min={0}
                label="Rank"
                placeholder=""
                isRequired
                disabled
              />
            </Flex>
            <Flex gap={4}>
              <FormInput
                wrapperProps={{
                  w: "100%",
                }}
                name="opening_cost"
                type="number"
                min={0}
                label="Opening cost (Sphere)"
                placeholder="Enter a value"
                isRequired
              />
              <Box width="100%"></Box>
            </Flex>
            <FormUpload
              label="Select image file to upload"
              title="Treasure Box Image"
              name="image_closed"
              type='IMAGE'
              accept={"image/*"}
              textButton={nameImage}
              isRequired
              handleOnChange={(e) =>
                handleImageChange(e)
              }
              handleDragImage={(val) =>
                handleDragImage(val)
              }
              deleteImage={() => deleteImage()}
            />
            <Flex direction="column" gap="12px" w="100%" padding="8px 0">
              <FormLabelComponent
                m="0px"
                title="Uprank weight"
              />
              <Grid templateColumns='repeat(3, 1fr)' gap='16px'>
                <FormInput
                  wrapperProps={{
                    w: "100%",
                  }}
                  name="none_up_rank_chance"
                  type="number"
                  min={0}
                  label="Non up"
                  placeholder="Enter a value"
                  isRequired
                />
                <FormInput
                  wrapperProps={{
                    w: "100%",
                  }}
                  name="up1_rank_chance"
                  type="number"
                  min={0}
                  label="Up 1 rank"
                  placeholder="Enter a value"
                  isRequired
                />
                <FormInput
                  wrapperProps={{
                    w: "100%",
                  }}
                  name="up2_rank_chance"
                  type="number"
                  min={0}
                  label="Up 2 rank"
                  placeholder="Enter a value"
                  isRequired
                />
                <FormInput
                  wrapperProps={{
                    w: "100%",
                  }}
                  name="up3_rank_chance"
                  type="number"
                  min={0}
                  label="Up 3 rank"
                  placeholder="Enter a value"
                  isRequired
                />
                <FormInput
                  wrapperProps={{
                    w: "100%",
                  }}
                  name="up4_rank_chance"
                  type="number"
                  min={0}
                  label="Up 4 rank"
                  placeholder="Enter a value"
                  isRequired
                />
                <FormInput
                  wrapperProps={{
                    w: "100%",
                  }}
                  name="up5_rank_chance"
                  type="number"
                  min={0}
                  label="Up 5 rank"
                  placeholder="Enter a value"
                  isRequired
                />
              </Grid>
            </Flex>
            <Flex direction="column" gap="12px" w="100%" padding="8px 0">
              <FormLabelComponent
                m="0px"
                title="Sphere chance"
              />
              <Flex gap={4}>
                <FormInput
                  wrapperProps={{
                    w: "100%",
                  }}
                  name="sphere_appearance_rate_pve"
                  type="number"
                  min={0}
                  max={100}
                  label="PvE - Probability*"
                  placeholder="Enter a value"
                />
                <Flex w="100%" alignItems='flex-end' gap={2}>
                  <FormInput
                    wrapperProps={{
                      w: "100%",
                    }}
                    name="min_number_of_sphere_pve"
                    type="number"
                    min={0}
                    label="PvE - Quantity Range"
                    placeholder=""
                  />
                  <Text>-</Text>
                  <FormInput
                    wrapperProps={{
                      w: "100%",
                    }}
                    name="max_number_of_sphere_pve"
                    type="number"
                    min={0}
                    label=""
                    placeholder=""
                  />
                </Flex>
              </Flex>
              <Flex gap={4}>
                <FormInput
                  wrapperProps={{
                    w: "100%",
                  }}
                  name="sphere_appearance_rate_pvp"
                  type="number"
                  min={0}
                  max={100}
                  label="PvP - Probability"
                  placeholder="Enter a value"
                />
                <Flex w="100%" alignItems='flex-end' gap={2}>
                  <FormInput
                    wrapperProps={{
                      w: "100%",
                    }}
                    name="min_number_of_sphere_pvp"
                    type="number"
                    min={0}
                    label="PvP - Quantity Range"
                    placeholder=""
                  />
                  <Text>-</Text>
                  <FormInput
                    wrapperProps={{
                      w: "100%",
                    }}
                    name="max_number_of_sphere_pvp"
                    type="number"
                    min={0}
                    label=""
                    placeholder=""
                  />
                </Flex>
              </Flex>
              <Flex gap={4}>
                <FormInput
                  wrapperProps={{
                    w: "100%",
                  }}
                  name="sphere_appearance_rate_coop"
                  type="number"
                  min={0}
                  max={100}
                  label="Coop - Probability"
                  placeholder="Enter a value"
                />
                <Flex w="100%" alignItems='flex-end' gap={2}>
                  <FormInput
                    wrapperProps={{
                      w: "100%",
                    }}
                    name="min_number_of_sphere_coop"
                    type="number"
                    min={0}
                    label="Coop - Quantity Range"
                    placeholder=""
                  />
                  <Text>-</Text>
                  <FormInput
                    wrapperProps={{
                      w: "100%",
                    }}
                    name="max_number_of_sphere_coop"
                    type="number"
                    min={0}
                    label=""
                    placeholder=""
                  />
                </Flex>
              </Flex>
            </Flex>
            <Flex direction="column" gap="12px" w="100%" padding="8px 0">
              <FormLabelComponent
                m="0px"
                title="Orb chance"
              />
              <Flex gap={4}>
                <FormInput
                  wrapperProps={{
                    w: "100%",
                  }}
                  name="orb_appearance_rate_pve"
                  type="number"
                  min={0}
                  max={100}
                  label="PvE - Probability*"
                  placeholder="Enter a value"
                />
                <Flex w="100%" alignItems='flex-end' gap={2}>
                  <FormInput
                    wrapperProps={{
                      w: "100%",
                    }}
                    name="min_number_of_orb_pve"
                    type="number"
                    min={0}
                    label="PvE - Quantity Range"
                    placeholder=""
                  />
                  <Text>-</Text>
                  <FormInput
                    wrapperProps={{
                      w: "100%",
                    }}
                    name="max_number_of_orb_pve"
                    type="number"
                    min={0}
                    label=""
                    placeholder=""
                  />
                </Flex>
              </Flex>
              <Flex gap={4}>
                <FormInput
                  wrapperProps={{
                    w: "100%",
                  }}
                  name="orb_appearance_rate_pvp"
                  type="number"
                  min={0}
                  max={100}
                  label="PvP - Probability"
                  placeholder="Enter a value"
                />
                <Flex w="100%" alignItems='flex-end' gap={2}>
                  <FormInput
                    wrapperProps={{
                      w: "100%",
                    }}
                    name="min_number_of_orb_pvp"
                    type="number"
                    min={0}
                    label="PvP - Quantity Range"
                    placeholder=""
                  />
                  <Text>-</Text>
                  <FormInput
                    wrapperProps={{
                      w: "100%",
                    }}
                    name="max_number_of_orb_pvp"
                    type="number"
                    min={0}
                    label=""
                    placeholder=""
                  />
                </Flex>
              </Flex>
              <Flex gap={4}>
                <FormInput
                  wrapperProps={{
                    w: "100%",
                  }}
                  name="orb_appearance_rate_coop"
                  type="number"
                  min={0}
                  max={100}
                  label="Coop - Probability"
                  placeholder="Enter a value"
                />
                <Flex w="100%" alignItems='flex-end' gap={2}>
                  <FormInput
                    wrapperProps={{
                      w: "100%",
                    }}
                    name="min_number_of_orb_coop"
                    type="number"
                    min={0}
                    label="Coop - Quantity Range"
                    placeholder=""
                  />
                  <Text>-</Text>
                  <FormInput
                    wrapperProps={{
                      w: "100%",
                    }}
                    name="max_number_of_orb_coop"
                    type="number"
                    min={0}
                    label=""
                    placeholder=""
                  />
                </Flex>
              </Flex>
            </Flex>
          </Grid>
        </form>
      </FormProvider>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxW="700px" overflowY="auto">
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >
          {idEdit ? "Edit Treasure Box Setup" : "New Treasure Box Setup"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px">{renderForm()}</ModalBody>
        <ModalFooter
          borderTop="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          gap="20px"
        >
          <ButtonType
            mt={4}
            btnType="primary-new-outline"
            m="auto"
            w="100%"
            h="46px"
            borderRadius="5px"
            onClick={onClose}
          >
            Cancel
          </ButtonType>
          <ButtonType
            mt={4}
            type="submit"
            m="auto"
            w="100%"
            h="46px"
            borderRadius="5px"
            form="treasure-form"
            isLoading={isSubmitting}
          >
            SAVE
          </ButtonType>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
