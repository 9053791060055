import {
  Flex,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import Paginate from "components/Paginate";
import TableCustom from "components/Tables/CustomTable";
import { useCallback, useEffect, useState } from "react";
import ExchangeRow from "./row";
import { usePermission } from "hooks/usePermission";
import { PERMISSIONS } from "constants/permissions";
import UpdateCoinConsumption from "./updateCoinConsumption/updateCoinConsumption";
import { getCoinConsumption } from "api/coinConsumption.api";

export default function CoinConsumption(props) {
  const { isHasPermissionEdit } = usePermission(
    PERMISSIONS.GAME_PARAMS_EXCHANGE_RATE
  );
  const labels = ["ACTION", "NO.", "CASE", "COIN TYPE", "TRANSACTION TYPE", "PRIORITY"];
  const [isLoading, setIsLoading] = useState(false);
  const [listCoins, setListCoins] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
  });
  const [dataDetail, setDataDetail] = useState(null);
  const textColor = useColorModeValue("#000000", "white");
  const toast = useToast();

  const {
    isOpen: isOpenCreateAndEdit,
    onOpen: onOpenCreateAndEdit,
    onClose: onCloseCreateAndEdit,
  } = useDisclosure();

  const getCoin = useCallback(
    async (newParams = {}) => {
      try {
        setIsLoading(true);
        const { data } = await getCoinConsumption({
          ...params,
          ...newParams,
        });
        if (data?.success) {
          const res = data?.data;
          setListCoins(res?.records);
          setTotalPage(res?.total_page);
        }
      } catch (error) {
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      } finally {
        setIsLoading(false);
      }
    },
    [params]
  );

  const handleOpenEditModal = (item) => {
    setDataDetail(item);
    onOpenCreateAndEdit(true);
  };

  const handleCloseEditModal = () => {
    setDataDetail(null);
    onCloseCreateAndEdit();
  };

  const handleCreateOrUpdateSuccess = useCallback(() => {
    handleCloseEditModal();
    setParams({
      ...params,
      page: 1,
    });
  }, [params]);

  const handleChangePage = (page) => {
    setParams({
      ...params,
      page,
    });
  };

  const onChangeLimit = (limit) => {
    setParams({
      ...params,
      page: 1,
      limit,
    });
  };

  useEffect(() => {
    getCoin();
  }, [getCoin]);

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <Card px="0px">
        <CardHeader px="22px" mb="12px">
          <Flex
            w="100%"
            gap={4}
            direction={{
              base: "column",
              md: "row",
            }}
            justifyContent={{
              base: "flex-start",
              md: "space-between",
            }}
            alignItems="flex-start"
          >
            <Text color={textColor} fontSize="lg" fontWeight="bold">
              Coin Consumption Priority
            </Text>
          </Flex>
        </CardHeader>

        <CardBody overflowX="auto">
          <TableCustom
            labels={labels}
            isLoading={isLoading}
            isCheck={false}
            dataRow={listCoins}
          >
            <>
              {listCoins?.map((item, index) => {
                return (
                  <ExchangeRow
                    key={item?.id}
                    handleOpenEditModal={handleOpenEditModal}
                    getCoin={getCoin}
                    item={item}
                    index={(params?.page - 1) * params?.limit + index + 1}
                    isHasPermissionEdit={isHasPermissionEdit}
                  />
                );
              })}
            </>
          </TableCustom>
        </CardBody>
        <Paginate
          page={params?.page}
          pageCount={totalPage}
          limit={params.limit}
          onChangeLimit={onChangeLimit}
          handleChangePage={handleChangePage}
        />
      </Card>
      {isOpenCreateAndEdit && (
        <UpdateCoinConsumption
          isOpen={isOpenCreateAndEdit}
          onClose={onCloseCreateAndEdit}
          handleCreateOrUpdateSuccess={handleCreateOrUpdateSuccess}
          dataDetail={dataDetail}
        />
      )}
    </Flex>
  );
}
