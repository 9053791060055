import {
  Box,
  Flex,
  Image,
  Stack,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
const RowDrpChangeHistory = ({ item }) => {
  const textColor = useColorModeValue("#000000", "white");

  return (
    <Tr>
      <Td>
        <Stack direction="row" spacing="16px">
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            Treasure Box
          </Text>
        </Stack>
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            Gauntlet Legendary
          </Text>
        </Stack>
      </Td>
      <Td>
        <Flex flexDirection={"column"} gap={"10px"}>
          <Box
            w={"fit-content"}
            padding={"5px 15px"}
            backgroundColor={"#eff6ff"}
            color={"#4b9ffa"}
            border={"1px solid #4b9ffa"}
            rounded={"50px"}
          >
            B-Coin 1 (paid): 50%
          </Box>
          <Box
            w={"fit-content"}
            padding={"5px 15px"}
            backgroundColor={"#ffe1e8"}
            color={"#f82f20"}
            border={"1px solid #f82f20"}
            rounded={"50px"}
          >
            B-Coin 1 (free): 10%
          </Box>
          <Box
            w={"fit-content"}
            padding={"5px 15px"}
            backgroundColor={"#f0fdf4"}
            color={"#32ae60"}
            border={"1px solid #32ae60"}
            rounded={"50px"}
          >
            B-Coin 2: 50%
          </Box>
        </Flex>
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            25/12/2024 10:00
          </Text>
        </Stack>
      </Td>
    </Tr>
  );
};

export default RowDrpChangeHistory;
