import {
  Box,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import NFTsCardHeader from "views/NFTsManagement/components/panel/NFTsCardHeader";
import LifeGauge from "./LifeGauge";
import EnergyGauge from "./EnergyGauge";
import { getDataGauge } from "api/gauge.api";
import { useEffect, useState } from "react";
import { usePermission } from "hooks/usePermission";
import { PERMISSIONS } from "constants/permissions";

const TAB_NAMES = ["Life Gauge", "Energy Gauge"];

export default function GaugeManagement() {
  const { isHasPermissionEdit } = usePermission(PERMISSIONS.GAME_PARAMS_LIFE_GAUGE_AND_ENERGY_GAUGE);
  const [dataGauge, setDataGauge] = useState()
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  
  const tabBg = useColorModeValue("gray.50", "gray.800");
  const activeTabBg = useColorModeValue("white", "gray.700");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const activeTabColor = useColorModeValue("blue.600", "blue.300");

  const getListGauge = async () => {
    try {
      setIsLoading(true);
      const { data } = await getDataGauge();
      if (data?.success) {
        const res = data?.data;
        setDataGauge(res?.records);
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getListGauge();
  }, []);

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <Card px="24px">
        <Flex alignItems="center" justifyContent="space-between">
          <NFTsCardHeader title="Life Gauge & Energy Gauge" />
        </Flex>
        <Tabs
          isLazy
          variant="enclosed"
        >
          <TabList borderBottom={`1px solid ${borderColor}`}>
            {TAB_NAMES.map((name) => (
              <Tab
                key={name}
                fontWeight="semibold"
                width="100%"
                _selected={{
                  color: activeTabColor,
                  bg: activeTabBg,
                  borderBottom: "3px solid",
                  borderBottomColor: activeTabColor,
                  marginBottom: "-1px",
                }}
                _hover={{
                  color: activeTabColor,
                }}
                bg={tabBg}
                borderTopRadius="md"
                mr={1}
                py={3}
                px={5}
              >
                {name}
              </Tab>
            ))}
          </TabList>
          <Box mt={4}>
            <TabPanels>
              <TabPanel padding="24px 0">
                <LifeGauge dataGauge={dataGauge} isLoading={isLoading} getListGauge={getListGauge} isHasPermissionEdit={isHasPermissionEdit}/>
              </TabPanel>
              <TabPanel padding="24px 0">
                <EnergyGauge dataGauge={dataGauge} isLoading={isLoading} getListGauge={getListGauge} isHasPermissionEdit={isHasPermissionEdit}/>
              </TabPanel>
            </TabPanels>
          </Box>
        </Tabs>
      </Card>
    </Flex>
  );
}
