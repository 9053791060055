import {
  Flex,
  Icon,
  Stack,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import CheckBoxType from "components/Checkbox";
import { useMemo } from "react";
import { EditIcon } from "components/Icons/EditIcon";

const MachineRow = ({
  machine,
  checkedGroups,
  setCheckedGroups,
  handleOpenCreateOrUpdate,
  isHasPermissionEdit,
}) => {
  const textColor = useColorModeValue("#000000", "white");
  const isMachineChecked = useMemo(() => {
    return checkedGroups?.some(
      (item) => item?.machine_label === machine?.machine_label
    );
  }, [machine?.machine_label, checkedGroups]);

  const handleCheckboxChange = () => {
    if (isMachineChecked) {
      setCheckedGroups(
        checkedGroups.filter(
          (item) => item?.machine_label !== machine?.machine_label
        )
      );
    } else {
      setCheckedGroups([...checkedGroups, machine]);
    }
  };

  return (
    <Tr>
      <Td width="10px">
        <CheckBoxType
          isChecked={isMachineChecked}
          onChange={() => handleCheckboxChange()}
          colorScheme="teal"
        />
      </Td>
      <Td>
        <Flex gap={6} align="center">
          {isHasPermissionEdit && (
            <Icon
              onClick={handleOpenCreateOrUpdate}
              fontSize="30px"
              borderRadius="8px"
              p="7px"
              cursor="pointer"
              bg="#1480FF"
              as={EditIcon}
            />
          )}
        </Flex>
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {machine?.machine_label}
          </Text>
        </Stack>
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {machine?.machine_size}
          </Text>
        </Stack>
      </Td>
    </Tr>
  );
};

export default MachineRow;
