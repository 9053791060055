import { Box, Flex, Icon, Text, Image, Spinner, Select, useToast } from "@chakra-ui/react";
import { Icon2Claws } from "components/Icons/Icon2Claws";
import { Icon3Claws } from "components/Icons/Icon3Claws";
import { NAIcon } from "components/Icons/NAIcon";
import { STATUS } from "constants/constants";
import { useCallback, useMemo } from "react";
import btnReset from 'assets/svg/btn_reset.svg'
import {CSS} from '@dnd-kit/utilities';
import { useDraggable, useDroppable } from '@dnd-kit/core';
import { ERROR_MACHINE_SETTINGS } from "views/Machine/MachineSettings/constants";
import { updateMachineMapGroup } from "api/machine.api";

export default function MachineMapItem({
  item,
  machine,
  handleOpenDetail,
  toggle,
  id,
  idMachine,
  isLoadingRestart,
  listGroupLabel,
  getListMachine,
}) {
  const toast = useToast();
  
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: item,
  });

  const { isOver, setNodeRef: setNodeRefDrop } = useDroppable({
    id: idMachine ? idMachine : id,
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    cursor: 'move',
  };

  const styleOver = {
    opacity: isOver ? 1 : 0.5,
  };

  const IconStatus = useCallback(() => {
    const isDisconnected = item?.status === STATUS.DISCONNECTED;
    const bg = isDisconnected ? "#FEE2E2" : "#DCFCE7";
    const color = isDisconnected ? "#991B1B" : "#166534";
    const textStatus = isDisconnected ? "Disconnected" : "Connected";
    return (
      <Flex bg={bg} alignItems="center" gap="6px" px="12px" py="6px" borderRadius="16px">
        <Text 
          as="span" 
          w="12px"
          h="12px"
          bg={color}
          borderRadius="100%"
        ></Text>
        <Text color={color}>{textStatus}</Text>
      </Flex>
    );
  }, [item?.status]);

  const isStatusActive = useMemo(() => {
    return (
      item?.status === STATUS.CONNECTED &&
      [STATUS.FREE, STATUS.UNDER_MAINTENANCE, STATUS.PLAYING, STATUS.ERROR].includes(machine?.state)
    );
  }, [item?.status, machine?.state]);

  const bgStyles = {
    [STATUS.FREE]: {
      bg: "#D5D5D5",
      color: "#000",
    },
    [STATUS.PLAYING]: {
      bg: "#98F1B3",
      color: "#000",
    },
    [STATUS.PRIZE_ACQUIRED]: {
      bg: "#EFFFA1",
      color: "#000",
    },
    [STATUS.PRIZE_CONFIRMATION_REQUEST]: {
      bg: "#C2B3FF",
      color: "#000",
    },
    [STATUS.UNDER_MAINTENANCE]: {
      bg: "#F7BA8C",
      color: "#000",
    },
    [STATUS.ERROR]: {
      bg: "#FDA5A5",
      color: "#000",
    },
  };

  const renderBgItem = useMemo(() => {
    return bgStyles[machine?.state];
  }, [machine?.state]);

  const is3Claws = useMemo(() => machine?.machine_type === STATUS.THREE_CLAWS, [machine?.machine_type]);
  const IconClaw = useMemo(() => machine ? (is3Claws ? Icon3Claws : Icon2Claws) : NAIcon, [machine, is3Claws]);

  const handleOpen = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (isStatusActive) {
      return handleOpenDetail();
    }
    const actionMap = {
      [STATUS.PRIZE_ACQUIRED]: () => toggle("confirmNext", item),
      [STATUS.PRIZE_CONFIRMATION_REQUEST]: () => toggle("confirmPrize", item),
    };
    const action = actionMap[machine?.state];
    if (action) action();
  };

  const handleChangeGroup = async (e, value) => {
    e.preventDefault();
    e.stopPropagation();
    const params = {
      machine_id: item?.machine?.id,
      group_label: value,
      group_number: Number(item?.machine?.group_number),
    };
    try {
      const res = await updateMachineMapGroup(params);
      if (res?.data?.success) {
        toast({
          title: "Machine updated.",
          description: "Machine updated.",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
        getListMachine();
      } else {
        toast({
          description:
            ERROR_MACHINE_SETTINGS[res?.data?.message] ||
            ERROR_MACHINE_SETTINGS[res?.data?.messages[0]],
          status: "error",
          position: "bottom-right",
        });
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  return (
    item ? (
      <Box
        w="auto"
        h="100%"
        borderRadius="10px"
        boxShadow="0px 4px 4px 0px #00000040"
        p="16px"
        bg={renderBgItem.bg}
        cursor={isStatusActive ? "pointer" : ""}
        onClick={(e) => handleOpen(e)}
        ref={setNodeRef}
        style={style}
        {...listeners} 
        {...attributes}
      >
        <Box
          ref={setNodeRefDrop}
        >
          <Flex direction="column" gap={2}>
            <Flex alignItems="flex-start" justifyContent="center" gap="12px">
              <Icon fontSize="80px" as={IconClaw} />
              <Flex flexDirection="column" gap="4px">
                <Text fontSize="24px" fontWeight="700">
                  {item?.mac?.slice(-4)} - {item?.machine?.group_label}{item?.machine?.group_number}
                </Text>
                <Flex alignItems="center" gap="6px">
                  <Text fontSize='14px' fontWeight='bold'>Group</Text>
                  {/* <Select
                    options={listGroupLabel}
                    onChange={(e) => console.log(e)}
                  /> */}
                  <Select
                    w="100px"
                    height="36px"
                    background="#fff"
                    defaultValue={item?.machine?.group_label}
                    onChange={(e) => handleChangeGroup(e, e.target.value)}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {listGroupLabel?.map((item) => (
                      <option key={item.value} value={item.value}>{item.label}</option>
                    ))}
                  </Select>
                </Flex>
                <IconStatus />
              </Flex>
              {item?.device_id && (
                isLoadingRestart ? (
                  <Spinner />
                ) : (
                  <Box
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      toggle("confirmRestart", item)
                    }}
                    cursor="pointer"
                  >
                    <Image
                      src={btnReset}
                      w="30px"
                      h="30px"
                      alt="restart"
                    />
                  </Box>
                )
              )}
            </Flex>
            <Flex flexDir="column" gap='16px' w="100%">
              <Flex alignItems="center" justifyContent="space-between" gap={4} width="100%">
                <Text fontSize="16px" fontWeight="500">Waiting Players</Text>
                <Text fontSize="16px" fontWeight="700">{machine?.waiting_players || 0}</Text>
              </Flex>
              <Flex alignItems="center" justifyContent="space-between" gap={4} width="100%">
                <Text fontSize="16px" fontWeight="500">Machine plays</Text>
                <Text fontSize="16px" fontWeight="700">{machine?.machine_plays || 0}</Text>
              </Flex>
              <Flex alignItems="center" justifyContent="space-between" gap={4} width="100%">
                <Text fontSize="16px" fontWeight="500">Player streak</Text>
                <Text fontSize="16px" fontWeight="700">{machine?.player_streak || 0}</Text>
              </Flex>
            </Flex>
          </Flex>
        </Box>
      </Box>
    ) : (
      <Box
        w="auto"
        h="auto"
        minWidth="310px"
        minHeight="260px"
        borderRadius="10px"
        border="2px solid #6C6C6C"
        p="16px"
        ref={setNodeRefDrop}
        style={styleOver}
        display="flex"
        alignItems="center"
        justifyContent="center">
        <Text
          fontSize="60px"
          fontWeight="700"
          textAlign="center"
          color="#D5D5D5"
        >
          {id}
        </Text>
      </Box>
    )
  );
}
