import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import schemaMachine from "./schema";
import ButtonType from "components/Button";
import FormTextAria from "components/form/FormTextAria";
import { updateUserKOL } from "api/userKOL.api";

export default function ModalEditNote({
  isOpen,
  onClose,
  dataDetail,
  handleCreateOrUpdateSuccess,
}) {
  const textColor = useColorModeValue("#000000", "white");
  const toast = useToast();
  const form = useForm({
    resolver: yupResolver(schemaMachine()),
    defaultValues: {
      description: dataDetail ? dataDetail?.note : '',
    }
  });
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = form;

  const onSubmit = async (data, e) => {
    e.preventDefault();
    const dataSubmit = {
      note: data?.description,
      user_id: dataDetail ? dataDetail?.user_id : "",
      id: dataDetail ? dataDetail?.id : "",
      user_name: dataDetail ? dataDetail?.user_name : "",
      email: dataDetail ? dataDetail?.email : "",
    };
    try {
      const res = await updateUserKOL(dataDetail.id, dataSubmit);
      if (res?.data?.success) {
        toast({
          title: "Note updated successfully!.",
          description: "Note updated successfully!.",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
        handleCreateOrUpdateSuccess();
      } else {
        toast({
          description: res?.data?.message || res?.data?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const renderForm = () => {
    return (
      <FormProvider {...form}>
        <form id="machine-form" onSubmit={handleSubmit(onSubmit)}>
          <FormTextAria
            label="Note"
            placeholder="Enter note"
            name="description"
            h="120px"
          />
        </form>
      </FormProvider>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxW="700px" overflowY="auto">
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >
          EDIT NOTE
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px">{renderForm()}</ModalBody>
        <ModalFooter
          borderTop="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          gap="20px"
        >
          <ButtonType
            mt={4}
            btnType="primary-new-outline"
            m="auto"
            w="100%"
            h="46px"
            borderRadius="5px"
            form="machine-form"
            onClick={onClose}
          >
            Cancel
          </ButtonType>
          <ButtonType
            mt={4}
            type="submit"
            m="auto"
            w="100%"
            h="46px"
            borderRadius="5px"
            form="machine-form"
            isLoading={isSubmitting}
          >
            SAVE
          </ButtonType>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
