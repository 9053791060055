import * as yup from 'yup'

const schemaMail = () =>
  yup.object().shape({
    name: yup
      .string()
      .trim()
      .required("Required")
      .max(255, "Maximum limit of 255 characters."),
    name_jp: yup
      .string()
      .trim()
      .required("Required")
      .max(255, "Maximum limit of 255 characters."),
    subject: yup
      .string()
      .trim()
      .required("Required")
      .max(255, "Maximum limit of 255 characters."),
    subject_jp: yup
      .string()
      .trim()
      .required("Required")
      .max(255, "Maximum limit of 255 characters."),
  })

const schemaRegistration = () =>
  yup.object().shape({
    url: yup
      .string()
      .trim()
      .required("Required"),
    host: yup
      .string()
      .trim()
      .required("Required"),
    port: yup
      .string()
      .trim()
      .required("Required"),
    sender: yup
      .string()
      .trim()
      .email("Not the format email")
      .required("Email Address is a required field")
      .matches(/^[a-zA-Z0-9.(@\-_)]*$/, "Sorry, only letters (a-z), numbers (0-9), and periods (!@#$%^&*.) are allowed")
      .max(255),
    username: yup
      .string()
      .trim()
      .required("Required")
      .max(255, "Maximum limit of 255 characters."),
    password: yup.string().required("Password is a required field"),
  })

const schemaEmailTemplate = () =>
  yup.object().shape({
    title: yup
      .string()
      .trim()
      .required("Required")
      .max(250, "Maximum limit of 250 characters."),
  })

export { schemaMail, schemaRegistration, schemaEmailTemplate };
