import * as yup from "yup";

const ratesSchema = () =>
  yup.object().shape({
    allocation_rate: yup
      .number()
      .min(0, "Number of value must be greater or equal to 0")
      .required('Required')
      .max(9999999, "Number of value must be at most 7 characters")
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : 0;
        }
        return 0;
      }),
    treasure_box_rank: yup
      .number()
      .min(0, "Number of rank must be greater or equal to 0")
      .required('Required')
      .max(9999999, "Number of rank must be at most 7 characters")
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : 0;
        }
        return 0;
      })
      .integer("Must be an integer"),
    opening_cost: yup
      .number()
      .min(0, "Number of value must be greater or equal to 0")
      .required('Required')
      .max(9999999, "Number of value must be at most 7 characters")
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : 0;
        }
        return 0;
      })
      .integer("Must be an integer"),
    none_up_rank_chance: yup
      .number()
      .min(0, "Number of value must be greater or equal to 0")
      .required('Required')
      .max(9999999, "Number of value must be at most 7 characters")
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : 0;
        }
        return 0;
      })
      .integer("Must be an integer"),
    up1_rank_chance: yup
      .number()
      .min(0, "Number of value must be greater or equal to 0")
      .required('Required')
      .max(9999999, "Number of value must be at most 7 characters")
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : 0;
        }
        return 0;
      })
      .integer("Must be an integer"),
    up2_rank_chance: yup
      .number()
      .min(0, "Number of value must be greater or equal to 0")
      .required('Required')
      .max(9999999, "Number of value must be at most 7 characters")
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : 0;
        }
        return 0;
      })
      .integer("Must be an integer"),
    up3_rank_chance: yup
      .number()
      .min(0, "Number of value must be greater or equal to 0")
      .required('Required')
      .max(9999999, "Number of value must be at most 7 characters")
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : 0;
        }
        return 0;
      })
      .integer("Must be an integer"),
    up4_rank_chance: yup
      .number()
      .min(0, "Number of value must be greater or equal to 0")
      .required('Required')
      .max(9999999, "Number of value must be at most 7 characters")
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : 0;
        }
        return 0;
      })
      .integer("Must be an integer"),
    up5_rank_chance: yup
      .number()
      .min(0, "Number of value must be greater or equal to 0")
      .required('Required')
      .max(9999999, "Number of value must be at most 7 characters")
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : 0;
        }
        return 0;
      })
      .integer("Must be an integer"),
    sphere_appearance_rate_pve: yup
      .number()
      .min(0, "Number of value must be greater or equal to 0")
      .required('Required')
      .max(100, "Number of value must be at most  100")
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : 0;
        }
        return 0;
      })
      .integer("Must be an integer"),
    sphere_appearance_rate_pvp: yup
      .number()
      .min(0, "Number of value must be greater or equal to 0")
      .required('Required')
      .max(100, "Number of value must be at most 100")
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : 0;
        }
        return 0;
      })
      .integer("Must be an integer"),
    sphere_appearance_rate_coop: yup
      .number()
      .min(0, "Number of value must be greater or equal to 0")
      .required('Required')
      .max(100, "Number of value must be at most 100")
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : 0;
        }
        return 0;
      })
      .integer("Must be an integer"),
    orb_appearance_rate_pve: yup
      .number()
      .min(0, "Number of value must be greater or equal to 0")
      .required('Required')
      .max(100, "Number of value must be at most  100")
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : 0;
        }
        return 0;
      })
      .integer("Must be an integer"),
    orb_appearance_rate_pvp: yup
      .number()
      .min(0, "Number of value must be greater or equal to 0")
      .required('Required')
      .max(100, "Number of value must be at most 100")
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : 0;
        }
        return 0;
      })
      .integer("Must be an integer"),
    orb_appearance_rate_coop: yup
      .number()
      .min(0, "Number of value must be greater or equal to 0")
      .required('Required')
      .max(100, "Number of value must be at most 100")
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : 0;
        }
        return 0;
      })
      .integer("Must be an integer"),
    min_number_of_sphere_pve: yup
      .number()
      .min(0, "Number of value must be greater or equal to 0")
      .required('Required')
      .max(9999999, "Number of value must be at most 7 characters")
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : 0;
        }
        return 0;
      })
      .integer("Must be an integer"),
    max_number_of_sphere_pve: yup
      .number()
      .min(0, "Number of value must be greater or equal to 0")
      .required('Required')
      .max(9999999, "Number of value must be at most 7 characters")
      .test(
        "is-greater-min_number_of_sphere_pve",
        "Max number must be greater than min number",
        function (value) {
          const { min_number_of_sphere_pve } = this.parent;
          return (
            value >= min_number_of_sphere_pve
          );
        }
      )
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : 0;
        }
        return 0;
      })
      .integer("Must be an integer"),
    min_number_of_sphere_pvp: yup
      .number()
      .min(0, "Number of value must be greater or equal to 0")
      .required('Required')
      .max(9999999, "Number of value must be at most 7 characters")
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : 0;
        }
        return 0;
      })
      .integer("Must be an integer"),
    max_number_of_sphere_pvp: yup
      .number()
      .min(0, "Number of value must be greater or equal to 0")
      .required('Required')
      .max(9999999, "Number of value must be at most 7 characters")
      .test(
        "is-greater-min_number_of_sphere_pvp",
        "Max number must be greater than min number",
        function (value) {
          const { min_number_of_sphere_pvp } = this.parent;
          return (
            value >= min_number_of_sphere_pvp
          );
        }
      )
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : 0;
        }
        return 0;
      })
      .integer("Must be an integer"),
    min_number_of_sphere_coop: yup
      .number()
      .min(0, "Number of value must be greater or equal to 0")
      .required('Required')
      .max(9999999, "Number of value must be at most 7 characters")
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : 0;
        }
        return 0;
      })
      .integer("Must be an integer"),
    max_number_of_sphere_coop: yup
      .number()
      .min(0, "Number of value must be greater or equal to 0")
      .required('Required')
      .max(9999999, "Number of value must be at most 7 characters")
      .test(
        "is-greater-min_number_of_sphere_coop",
        "Max number must be greater than min number",
        function (value) {
          const { min_number_of_sphere_coop } = this.parent;
          return (
            value >= min_number_of_sphere_coop
          );
        }
      )
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : 0;
        }
        return 0;
      })
      .integer("Must be an integer"),
    min_number_of_orb_pve: yup
      .number()
      .min(0, "Number of value must be greater or equal to 0")
      .required('Required')
      .max(9999999, "Number of value must be at most 7 characters")
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : 0;
        }
        return 0;
      })
      .integer("Must be an integer"),
    max_number_of_orb_pve: yup
      .number()
      .min(0, "Number of value must be greater or equal to 0")
      .required('Required')
      .max(9999999, "Number of value must be at most 7 characters")
      .test(
        "is-greater-min_number_of_orb_pve",
        "Max number must be greater than min number",
        function (value) {
          const { min_number_of_orb_pve } = this.parent;
          return (
            value >= min_number_of_orb_pve
          );
        }
      )
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : 0;
        }
        return 0;
      })
      .integer("Must be an integer"),
    min_number_of_orb_pvp: yup
      .number()
      .min(0, "Number of value must be greater or equal to 0")
      .required('Required')
      .max(9999999, "Number of value must be at most 7 characters")
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : 0;
        }
        return 0;
      })
      .integer("Must be an integer"),
    max_number_of_orb_pvp: yup
      .number()
      .min(0, "Number of value must be greater or equal to 0")
      .required('Required')
      .max(9999999, "Number of value must be at most 7 characters")
      .test(
        "is-greater-min_number_of_orb_pvp",
        "Max number must be greater than min number",
        function (value) {
          const { min_number_of_orb_pvp } = this.parent;
          return (
            value >= min_number_of_orb_pvp
          );
        }
      )
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : 0;
        }
        return 0;
      })
      .integer("Must be an integer"),
    min_number_of_orb_coop: yup
      .number()
      .min(0, "Number of value must be greater or equal to 0")
      .required('Required')
      .max(9999999, "Number of value must be at most 7 characters")
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : 0;
        }
        return 0;
      })
      .integer("Must be an integer"),
    max_number_of_orb_coop: yup
      .number()
      .min(0, "Number of value must be greater or equal to 0")
      .required('Required')
      .max(9999999, "Number of value must be at most 7 characters")
      .test(
        "is-greater-min_number_of_orb_coop",
        "Max number must be greater than min number",
        function (value) {
          const { min_number_of_orb_coop } = this.parent;
          return (
            value >= min_number_of_orb_coop
          );
        }
      )
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : 0;
        }
        return 0;
      })
      .integer("Must be an integer"),
  });

export default ratesSchema;
