import {
  Flex,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import Paginate from "components/Paginate";
import TableCustom from "components/Tables/CustomTable";
import { useCallback, useEffect, useState } from "react";
import TreasureBoxRow from "./row";
// import ActionExchangeSettings from "./action";
import FormCommonSetting from "./FormCommonSetting";
import { getTreasureBox } from "api/treasureBox.api";
import { useDispatch } from "react-redux";
import { getPlayFeeApi } from "stores/playFee/action";
import { useSelector } from "react-redux";
import { playFeeSelector } from "stores/playFee/selector";
import CreateAndUpdateTreasure from "./createAndUpdate/createAndUpdateTreasure";
import { usePermission } from "hooks/usePermission";
import { PERMISSIONS } from "constants/permissions";

const TreasureBoxManagement = (props) => {
  const labels = [
    "Action",
    "Rank", 
    "Name", 
    "Image",
    "Play fee",
    "Allocation Ratio", 
    "Opening cost (SPHERE)", 
    "Non Uprank Weight", 
    "Up 1 Rank Weight", 
    "Up 2 Rank Weight", 
    "Up 3 Rank Weight", 
    "Up 4 Rank Weight", 
    "Up 5 Rank Weight", 
    "PVE Sphere Chance",
    "PVE Sphere Range",
    "PVE ORB Chance",
    "PVE ORB Range",
    "PVP Sphere Chance",
    "PVP Sphere Range",
    "PVP ORB Chance",
    "PVP ORB Range",
    "COOP Sphere Chance",
    "COOP Sphere Range",
    "COOP ORB Chance",
    "COOP ORB Range",
  ];
  const { isHasPermissionEdit } = usePermission(PERMISSIONS.GAME_PARAMS_TREASURE_BOX);
  const [isLoading, setIsLoading] = useState(false);
  const [listTreasureBox, setListTreasureBox] = useState([]);
  const [listPlayFee, setListPlayFee] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
  });
  const [dataDetail, setDataDetail] = useState(null);
  const textColor = useColorModeValue("#000000", "white");
  const toast = useToast();
  const dispatch = useDispatch();
  const { listPlayFees } = useSelector(playFeeSelector);

  const {
    isOpen: isOpenCreateAndEdit,
    onOpen: onOpenCreateAndEdit,
    onClose: onCloseCreateAndEdit,
  } = useDisclosure();

  const getListTreasureBox = useCallback(
    async (newParams = {}) => {
      try {
        setIsLoading(true);
        const { data } = await getTreasureBox({
          ...params,
          ...newParams,
        });
        if (data?.success) {
          const res = data?.data;
          setListTreasureBox(res?.records);
          setTotalPage(res?.total_page);
        }
      } catch (error) {
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      } finally {
        setIsLoading(false);
      }
    },
    [params]
  );

  const handleOpenEditModal = (item) => {
    setDataDetail(item);
    onOpenCreateAndEdit(true);
  };

  const handleCloseEditModal = () => {
    setDataDetail(null);
    onCloseCreateAndEdit();
  };

  const handleCreateOrUpdateSuccess = useCallback(() => {
    handleCloseEditModal();
    setParams({
      ...params,
      page: 1,
    });
  }, [params]);

  const handleChangePage = (page) => {
    setParams({
      ...params,
      page,
    });
  };

  const onChangeLimit = (limit) => {
    setParams({
      ...params,
      page: 1,
      limit,
    });
  };

  const getListPlayFee = () => {
    dispatch(getPlayFeeApi({
      status: "ACTIVE",
    }));
  };

  useEffect(() => {
    getListPlayFee();
  }, []);

  useEffect(() => {
    getListTreasureBox();
  }, [getListTreasureBox]);

  useEffect(() => {
    if (listPlayFees?.records?.length > 0) {
      const listFeeFilter = listPlayFees?.records?.map((record) => ({
        ...record,
        label: record?.name,
        value: record?.id,
      }))
      setListPlayFee(listFeeFilter);
    }
  }, [listPlayFees]);

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <FormCommonSetting isHasPermissionEdit={isHasPermissionEdit}/>
      <Card px="0px">
        <CardHeader px="22px" mb="12px">
          <Flex
            w="100%"
            gap={4}
            direction={{
              base: "column",
              md: "row",
            }}
            justifyContent={{
              base: "flex-start",
              md: "space-between",
            }}
            alignItems="flex-start"
          >
            <Text color={textColor} fontSize="lg" fontWeight="bold">
              Treasure Box
            </Text>
            {/* <ActionExchangeSettings
              handleOpenCreate={() => {
                setDataDetail(null);
                onOpenCreateAndEdit();
              }}
              setParams={setParams}
              params={params}
            /> */}
          </Flex>
        </CardHeader>

        <CardBody overflowX="auto">
          <TableCustom
            labels={labels}
            isLoading={isLoading}
            isCheck={false}
            dataRow={listTreasureBox}
            isWrap={true}
          >
            <>
              {listTreasureBox?.map((item, index) => {
                return (
                  <TreasureBoxRow
                    key={item?.id}
                    handleOpenEditModal={handleOpenEditModal}
                    getListTreasureBox={getListTreasureBox}
                    item={item}
                    listPlayFee={listPlayFee}
                    isHasPermissionEdit={isHasPermissionEdit}
                  />
                );
              })}
            </>
          </TableCustom>
        </CardBody>
        <Paginate
          page={params?.page}
          pageCount={totalPage}
          limit={params.limit}
          onChangeLimit={onChangeLimit}
          handleChangePage={handleChangePage}
        />
      </Card>
      {isOpenCreateAndEdit && (
        <CreateAndUpdateTreasure
          isOpen={isOpenCreateAndEdit}
          onClose={onCloseCreateAndEdit}
          handleCreateOrUpdateSuccess={handleCreateOrUpdateSuccess}
          dataDetail={dataDetail}
          listPlayFee={listPlayFee}
        />
      )}
    </Flex>
  );
}

export default TreasureBoxManagement
