import { createSlice } from '@reduxjs/toolkit'
import { PRIZE_NAMESPACE } from 'stores/constant'
import {
  listPrizesApi,
  addPrizeApi,
  getPrizeByIdApi,
  editPrizeByIdApi,
  deletePrizeAllApi,
  listPrizeTypeApi,
  listPrizeModeTypeApi,
  listFeePrizeApi,
  listPrizeRarity,
  listPrizeTraits,
  listPrizesGameModeApi,
} from './action'
import { RARITY, TRAIT } from 'views/PrizeSettings/constant'

const name = PRIZE_NAMESPACE
const initialState = createInitialState()
const reducers = createReducers()
const extraReducers = createExtraReducers()
const prizeSlice = createSlice({ name, initialState, reducers, extraReducers })

export const prizeSliceReducer = prizeSlice.reducer

function createInitialState() {
  return {
    loadingPrize: false,
    statusDelete: false,
    errorPrize: '',
    listPrizes: [],
    listPrizesGameMode: [],
    listPrizeType: [],
    listPrizeModeType: [],
    listPrizeRarity: [],
    listPrizeTraits: [],
    listPlayFee: [],
    prizeById: null,
  }
}

function createReducers() {
  return {}
}

// Helper to transform common numeric fields
function transformRecord(item) {
  return {
    ...item,
    create_time: Number.parseFloat(item?.create_time || 0) || null,
    create_by: Number.parseFloat(item?.create_by || 0) || null,
    update_time: Number.parseFloat(item?.update_time || 0) || null,
    update_by: Number.parseFloat(item?.update_by || 0) || null,
  }
}

// Helper to add pending/fulfilled/rejected cases
function addApiCases(builder, thunk, { pending, fulfilled, rejected }) {
  builder
    .addCase(thunk.pending, pending)
    .addCase(thunk.fulfilled, fulfilled)
    .addCase(thunk.rejected, rejected)
}

function createExtraReducers() {
  return (builder) => {
    // listPrizes
    addApiCases(builder, listPrizesApi, {
      pending: (state) => {
        state.loadingPrize = true
      },
      fulfilled: (state, action) => {
        state.loadingPrize = false
        state.errorPrize = ''
        if (action.payload) {
          const records = action.payload.data.records.map(transformRecord)
          state.listPrizes = {
            records,
            total: action.payload.data.total,
            page: action.payload.data.page,
            limit: action.payload.data.limit,
            total_page: action.payload.data.total_page,
          }
        }
      },
      rejected: (state, action) => {
        state.loadingPrize = false
        state.errorPrize = action.payload
      },
    })

    // listPrizesGameMode
    addApiCases(builder, listPrizesGameModeApi, {
      pending: (state) => {
        state.loadingPrize = true
      },
      fulfilled: (state, action) => {
        state.loadingPrize = false
        state.errorPrize = ''
        if (action.payload) {
          const records = action.payload.data.records.map(transformRecord)
          state.listPrizesGameMode = {
            records,
            total: action.payload.data.total,
            page: action.payload.data.page,
            limit: action.payload.data.limit,
            total_page: action.payload.data.total_page,
          }
        }
      },
      rejected: (state, action) => {
        state.loadingPrize = false
        state.errorPrize = action.payload
      },
    })

    // addPrize
    addApiCases(builder, addPrizeApi, {
      pending: (state) => {
        state.loadingPrize = true
      },
      fulfilled: (state) => {
        state.loadingPrize = false
        state.errorPrize = ''
      },
      rejected: (state, action) => {
        state.loadingPrize = false
        state.errorPrize = action.payload
      },
    })

    // getPrizeById
    addApiCases(builder, getPrizeByIdApi, {
      pending: (state) => {
        state.loadingPrize = true
        state.prizeById = null
      },
      fulfilled: (state, action) => {
        state.loadingPrize = false
        state.errorPrize = ''
        if (action.payload) {
          state.prizeById = action.payload.data
        }
      },
      rejected: (state, action) => {
        state.loadingPrize = false
        state.errorPrize = action.payload
      },
    })

    // editPrizeById
    addApiCases(builder, editPrizeByIdApi, {
      pending: (state) => {
        state.loadingPrize = true
      },
      fulfilled: (state) => {
        state.loadingPrize = false
        state.errorPrize = ''
      },
      rejected: (state, action) => {
        state.loadingPrize = false
        state.errorPrize = action.payload
      },
    })

    // deletePrizeById
    addApiCases(builder, deletePrizeAllApi, {
      pending: (state) => {
        state.statusDelete = true
      },
      fulfilled: (state) => {
        state.statusDelete = false
        state.errorPrize = ''
      },
      rejected: (state, action) => {
        state.statusDelete = false
        state.errorPrize = action.payload
      },
    })

    // getListPrizeType
    addApiCases(builder, listPrizeTypeApi, {
      pending: (state) => {
        state.listPrizeType = []
      },
      fulfilled: (state, action) => {
        state.errorPrize = ''
        if (action.payload) {
          state.listPrizeType = action.payload.data
        }
      },
      rejected: (state, action) => {
        state.errorPrize = action.payload
      },
    })

    // getListPrizeModeType
    addApiCases(builder, listPrizeModeTypeApi, {
      pending: (state) => {
        state.listPrizeModeType = []
      },
      fulfilled: (state, action) => {
        state.errorPrize = ''
        if (action.payload) {
          state.listPrizeModeType = action.payload.data
        }
      },
      rejected: (state, action) => {
        state.errorPrize = action.payload
      },
    })

    // getListPrizeRarity
    addApiCases(builder, listPrizeRarity, {
      pending: (state) => {
        state.listPrizeRarity = []
      },
      fulfilled: (state, action) => {
        state.errorPrize = ''
        if (action.payload) {
          state.listPrizeRarity = action.payload.data.map((item) => ({
            label: RARITY[item],
            value: item,
          }))
        }
      },
      rejected: (state, action) => {
        state.errorPrize = action.payload
      },
    })

    // getListPrizeTraits
    addApiCases(builder, listPrizeTraits, {
      pending: (state) => {
        state.listPrizeTraits = []
      },
      fulfilled: (state, action) => {
        state.errorPrize = ''
        if (action.payload) {
          state.listPrizeTraits = action.payload.data.map((item) => ({
            label: TRAIT[item],
            value: item,
          }))
        }
      },
      rejected: (state, action) => {
        state.errorPrize = action.payload
      },
    })

    // getListPlayFees
    addApiCases(builder, listFeePrizeApi, {
      pending: (state) => {
        state.listPlayFee = []
      },
      fulfilled: (state, action) => {
        state.errorPrize = ''
        if (action.payload) {
          state.listPlayFee = action.payload.data
        }
      },
      rejected: (state, action) => {
        state.errorPrize = action.payload
      },
    })
  }
}