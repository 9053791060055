import React, { useCallback, useEffect, useState } from 'react'
import { Flex, Text, useToast, useColorModeValue, Image } from '@chakra-ui/react';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import TableCustom from 'components/Tables/CustomTable';
import Paginate from 'components/Paginate';
import MachineRow from './row';
import ConditionBanner from './condition';
import { getCategoryPrize} from 'api/gameMode.api';
import BackIcon from "assets/svg/back-icon.svg";

export default function DrpChangeHistory({ toggleModal }) {
  const [checkedGroups, setCheckedGroups] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [machineGroup, setMachineGroup] = useState([]);

  const toast = useToast();
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
  });
  const textColor = useColorModeValue("#000000", "white");

  const onChangeLimit = (limit) => {
    setParams({
      ...params,
      page: 1,
      limit,
    });
  };

  const handleChangePage = (page) => {
    setParams({
      ...params,
      page,
    });
  };

  const labels = [
    "Type",
    "Prize Name",
    "Return Rate",
    "Modified On",
  ];

  const getListMachineGroup = useCallback(
    async (newParams = {}) => {
      try {
        setIsLoading(true);
        setCheckedGroups([]);
        const { data } = await getCategoryPrize({
          ...params,
          ...newParams,
        });
        if (data?.success) {
          const res = data?.data;
          setMachineGroup(res?.records);
          setTotalPage(res?.total_page);
        }
      } catch (error) {
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      } finally {
        setIsLoading(false);
      }
    },
    [params]
  );


  useEffect(() => {
    getListMachineGroup()
  }, [getListMachineGroup])

  return (
    <Card p="0px">
      <CardHeader mb="12px" display="flex" flexDirection="column" gap="12px">
        <Flex
          w="100%"
          gap={4}
          alignItems="center"
        >
          <Image
            src={BackIcon}
            w="25px"
            h="25px"
            alt="back"
            cursor="pointer"
            onClick={() => toggleModal("changeHistory")}
          />
          <Text
            color={textColor}
            fontSize="lg"
            fontWeight="bold"
            whiteSpace="nowrap"
          >
            DRP Change History
          </Text>
        </Flex>
        <ConditionBanner
          isLoading={isLoading}
          setParams={setParams}
          params={params}
        />
      </CardHeader>
      <CardBody overflowX="auto">
        <TableCustom
          labels={labels}
          isLoading={isLoading}
          isCheck={false}
          dataRow={machineGroup}
        >
          <>
            {machineGroup?.map((item, index) => {
              return (
                <MachineRow
                  key={index}
                  setCheckedGroups={setCheckedGroups}
                  checkedGroups={checkedGroups}
                  item={item}
                />
              );
            })}
          </>
        </TableCustom>
      </CardBody>
      <Paginate
        page={params?.page}
        pageCount={totalPage}
        limit={params.limit}
        onChangeLimit={onChangeLimit}
        handleChangePage={handleChangePage}
      />
    </Card>
  )
}
