/* eslint-disable no-useless-catch */
import { createAsyncThunk } from '@reduxjs/toolkit'
import { createStandaloneToast } from '@chakra-ui/react'
import {
  ADD_USERS_FETCH,
  CHANGE_PASSWORD_FETCH,
  DELETE_USER_BY_ID_FETCH,
  EDIT_USER_BY_ID_FETCH,
  GET_USER_BY_ID_FETCH,
  LIST_USERS_FETCH,
  LOGIN_FETCH,
  USERINFO_FETCH,
  USER_NAMESPACE,
} from '../constant'
import {
  deleteUser,
  getUserById,
  getUserInfo,
  getUsers,
  login,
  postChangePassword,
  postUser,
  putUser,
} from 'api/auth.api'

const toast = createStandaloneToast()

export const loginReducerApi = createAsyncThunk(
  `${USER_NAMESPACE}/${LOGIN_FETCH}`,
  async (params, {rejectWithValue }) => {
    try {
      const res = await login(params)
      if (res?.status !== 200) {
        toast({
          description: res?.data?.message || res?.data?.messages[0],
          status: 'error',
          position: 'bottom-right',
        })
      }
      return res?.data
    } catch (error) {
      if (error?.message || error?.messages[0]) {
        toast({
          description: error?.message || error?.messages[0],
          status: 'error',
          position: 'bottom-right',
        })
      }
      return rejectWithValue(error);
    }
  },
)

export const userinfoReducerApi = createAsyncThunk(
  `${USER_NAMESPACE}/${USERINFO_FETCH}`,
  async () => {
    try {
      const res = await getUserInfo()
      console.log('res userinfoReducerApi', res)
      if (res?.data?.code === 200) {
        return res?.data?.data
      } else {
        console.log("res ==========> ", res);
        if (res?.data?.message || res?.data?.messages[0]) {
          toast({
            description: res?.data?.message || res?.data?.messages[0],
            status: 'error',
            position: 'bottom-right',
          })
        }
      }
    } catch (error) {
      throw error
    }
  },
)

export const userChangePasswordApi = createAsyncThunk(
  `${USER_NAMESPACE}/${CHANGE_PASSWORD_FETCH}`,
  async (params) => {
    try {
      const res = await postChangePassword(params)
      return res?.data
    } catch (error) {
      throw error
    }
  },
)

export const listUsersApi = createAsyncThunk(
  `${USER_NAMESPACE}/${LIST_USERS_FETCH}`,
  async () => {
    try {
      const res = await getUsers()
      return res?.data
    } catch (error) {
      throw error
    }
  },
)

export const addUserApi = createAsyncThunk(
  `${USER_NAMESPACE}/${ADD_USERS_FETCH}`,
  async (params) => {
    try {
      const res = await postUser(params)
      return res?.data
    } catch (error) {
      throw error
    }
  },
)

export const getUserByIdApi = createAsyncThunk(
  `${USER_NAMESPACE}/${GET_USER_BY_ID_FETCH}`,
  async (params) => {
    try {
      const res = await getUserById(params)
      return res?.data
    } catch (error) {
      throw error
    }
  },
)

export const editUserByIdApi = createAsyncThunk(
  `${USER_NAMESPACE}/${EDIT_USER_BY_ID_FETCH}`,
  async (params) => {
    try {
      const res = await putUser(params)
      return res?.data
    } catch (error) {
      throw error
    }
  },
)

export const deleteUserByIdApi = createAsyncThunk(
  `${USER_NAMESPACE}/${DELETE_USER_BY_ID_FETCH}`,
  async (params) => {
    try {
      const res = await deleteUser(params)
      return res?.data
    } catch (error) {
      throw error
    }
  },
)
