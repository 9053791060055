import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import ButtonType from "components/Button";
import EditorLink from 'components/EditorAddLink/EditorLink'
import { useEffect, useState } from "react";

export default function ModalMessageMaintenance({
  isOpen,
  onClose,
  isLoadingSubmit,
  type,
  dataMaintenance,
  setError,
  clearErrors,
  setValue,
  getValues,
}) {
  const [descriptions, setDescriptions] = useState(null);

  useEffect(() => {
    setDescriptions(getValues('message') || dataMaintenance?.message)
  }, []);
  
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW="700px" >
        <ModalHeader
          w="100%"
          textAlign="center"
          margin="auto"
          fontSize="24px"
          fontWeight="700"
        >
          Message Maintenance
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px" textAlign="center" fontSize="16px" fontWeight="500">
          <EditorLink
            name="message"
            placeholder="Enter message"
            setValue={setValue}
            descriptions={descriptions}
            setError={setError}
            clearErrors={clearErrors}
            lable="Message Maintenance"
          />
        </ModalBody>

        <ModalFooter w="100%" textAlign="center" margin="auto">
          <Flex
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            gap="16px"
            w="100%"
          >
            <ButtonType
              onClick={onClose}
              mr={3}
              text="Cancel"
              btnType="primary-new-outline"
              w="100%"
            />
            <ButtonType
              isLoading={isLoadingSubmit}
              mr={3}
              text="Save"
              w="100%"
              type="submit"
              form={type}
            />
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
