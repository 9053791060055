import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { createRoles, updateRoles } from "api/role.api";
import ButtonType from "components/Button";
import FormInput from "components/form/FormInput";
import useWithToast from "hooks/useWithToast";
import { useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import CheckboxCommon from "./CheckboxCommon";
import schemaRole from "./schema";

const DEFAULT_ROLE = [
  "SUPPER_ADMIN",
  "OWNER",
  "ADMIN",
  "OPERATOR",
  "USER",
  "MARKET_PLACE_ADMIN",
];

export default function ModalUpdateRole({
  isOpen,
  onClose,
  rules,
  dataDetail,
  getRulesAndRoles,
}) {
  const textColor = useColorModeValue("#000000", "white");
  const { showToastSuccess } = useWithToast();
  const toast = useToast();

  const defaultValues = useMemo(() => {
    let list = [];

    dataDetail?.rule_groups?.map((data) => {
      const dataFromRule = data?.rules?.map((rule) => {
        return {
          [`${rule?.id}`]: true,
        };
      });
      list = list.concat(dataFromRule);
      return dataFromRule;
    });
    if (list?.length > 0) {
      const result = list.reduce((acc, obj) => {
        return { ...acc, ...obj };
      }, {});

      return result;
    }
  }, []);

  const form = useForm({
    defaultValues: {
      name: dataDetail?.name,
      ...defaultValues,
    },
    resolver: yupResolver(schemaRole()),
  });

  const {
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { isSubmitting },
  } = form;

  const onSubmit = async (data) => {
    let listRule = [];
    for (const [key, value] of Object.entries(data)) {
      const ruleId = key;
      if (value && ruleId !== "name") {
        listRule.push({
          id: ruleId,
        });
      }
    }
    try {
      const dataSubmit = dataDetail
        ? {
            id: dataDetail?.id,
            name: data?.name,
            order_by: dataDetail?.order_by,
            rules: listRule,
          }
        : {
            name: data?.name,
            rule_ids: listRule?.map((rule) => rule?.id),
          };
      const res = dataDetail
        ? await updateRoles(dataSubmit)
        : await createRoles(dataSubmit);
      if (res) {
        getRulesAndRoles();
        showToastSuccess({
          title: "Role saved successfully!",
        });
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    } finally {
      onClose();
    }
  };

  const renderForm = () => {
    return (
      <FormProvider {...form}>
        <form id="machine-form" onSubmit={handleSubmit(onSubmit)}>
          <FormInput
            maxW="100%"
            name="name"
            label={"Role Name"}
            isDisabled={DEFAULT_ROLE.includes(dataDetail?.name)}
            isRequired
          />
          <Text color={textColor} fontSize="1rem" fontWeight="500" my={4}>
            Setting Permissions
          </Text>
          <SimpleGrid columns={2} spacing={10} gap={8}>
            {rules?.map((listRule, index) => (
              <CheckboxCommon
                key={index}
                watch={watch}
                setValue={setValue}
                control={control}
                listRule={listRule}
              />
            ))}
          </SimpleGrid>
        </form>
      </FormProvider>
    );
  };

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxW="900px">
        <ModalHeader
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >
          {dataDetail ? "Edit Permission" : "Create Role"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          p="24px 36px"
          minW="500px"
          maxH="600px"
          overflowY="auto"
          overflowX="hidden"
        >
          {renderForm()}
        </ModalBody>
        <ModalFooter w="100%" textAlign="center" margin="auto">
          <ButtonType
            mt={4}
            btnType="primary"
            type="submit"
            m="auto"
            w="120px"
            h="46px"
            fontSize="16px"
            borderRadius="5px"
            form="machine-form"
            isLoading={isSubmitting}
          >
            SAVE
          </ButtonType>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
