/* eslint-disable react/prop-types */
import { SearchIcon } from "@chakra-ui/icons";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { EyesIcon } from "components/Icons/EyesIcon";
import { EyesOffIcon } from "components/Icons/EyesOffIcon";
import { useEffect, useMemo, useState } from "react";
import { useController, useFormContext } from "react-hook-form";

const FormInput = ({
  label,
  name,
  rules,
  defaultValue = "",
  wrapperProps,
  isRequired,
  renderLeft,
  handleShowPass,
  isShowPassword = true,
  showPassword = false,
  renderRight,
  renderIconSearch,
  onSearch,
  type = "text",
  ...rest
}) => {
  const [show, setShow] = useState(showPassword);
  const { control } = useFormContext();
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ name, control, rules, defaultValue });

  const newType = useMemo(() => {
    if (type === "password") {
      return show ? "text" : "password";
    }
    return type;
  }, [type, show]);

  useEffect(() => {
    if (showPassword !== show) {
      setShow(showPassword);
    }
  }, [showPassword])
  

  return (
    <FormControl
      maxW="100%"
      w="auto"
      isInvalid={error?.message}
      {...wrapperProps}
    >
      {label && (
        <FormLabel whiteSpace="nowrap" color="#000000">
          {label} {isRequired && <span style={{ color: "red" }}>*</span>}
        </FormLabel>
      )}
      <InputGroup>
        {renderLeft && renderLeft()}
        <Input
          color="#000000"
          onChange={onChange}
          value={value}
          type={newType}
          {...rest}
        />
        {renderRight && (
          <InputRightElement width="4.5rem">
            <Icon
              as={show ? EyesIcon : EyesOffIcon}
              cursor="pointer"
              fontSize="24px"
              w="24px"
              h="24px"
              onClick={() => {
                isShowPassword ? setShow((prev) => !prev) : handleShowPass();
              }}
            />
          </InputRightElement>
        )}
        {renderIconSearch && (
          <InputRightElement width="4.5rem">
            <Icon
              as={SearchIcon}
              cursor="pointer"
              w="24px"
              h="24px"
              onClick={onSearch}
            />
          </InputRightElement>
        )}
      </InputGroup>
      {error?.message && <FormErrorMessage>{error?.message}</FormErrorMessage>}
    </FormControl>
  );
};

export default FormInput;
