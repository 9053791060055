import http from "./http";
import END_POINT from "./constants";
import { convertParams, omitParams } from "utils/object";

export const getDigitalFigure = (p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.DIGITAL_FIGURE}?${params}`);
};

export const getDetailDigitalFigure = (id) => {
  return http.get(`${END_POINT.DIGITAL_FIGURE}/${id}`);
};

export const createlDigitalFigure = (data) => {
  return http.post(`${END_POINT.DIGITAL_FIGURE}`, data);
};

export const updateDigitalFigure = (id, params) => {
  return http.put(`${END_POINT.DIGITAL_FIGURE}/${id}`, params);
};

export const deleteDigitalFigure = (data) => {
  return http.delete(`${END_POINT.DIGITAL_FIGURE}`, data);
};

export const updateDigitalFigureAllocation = (params) => {
  return http.put(`${END_POINT.DIGITAL_FIGURE_ALLOCATION}`, params);
};

export const transferDigitalFigure = (params) => {
  return http.post(`${END_POINT.DIGITAL_FIGURE_TRANSFER}`, params);
};

export const importCSVSerial = (form, p) => {
  const params = convertParams(omitParams(p));
  return http.post(`${END_POINT.DIGITAL_FIGURE}/import?${params}`, form, {
    headers: {
      "Content-type": "multipart/form-data",
    },
  });
};

// BACKGROUND
export const getBackground = (p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.BACKGROUND}?${params}`);
};

export const getDetailBackground = (id) => {
  return http.get(`${END_POINT.BACKGROUND}/${id}`);
};

export const createlBackground = (data) => {
  return http.post(`${END_POINT.BACKGROUND}`, data);
};

export const updateBackground = (id, params) => {
  return http.put(`${END_POINT.BACKGROUND}/${id}`, params);
};

export const deleteBackground = (data) => {
  return http.delete(`${END_POINT.BACKGROUND}`, data);
};

export const updateBackgroundAllocation = (params) => {
  return http.put(`${END_POINT.BACKGROUND_ALLOCATION}`, params);
};

export const transferBackground = (params) => {
  return http.post(`${END_POINT.BACKGROUND_TRANSFER}`, params);
};

// ROOM ITEMS
export const getRoomItems = (p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.ROOM_ITEMS}?${params}`);
};

export const getDetailRoomItems = (id) => {
  return http.get(`${END_POINT.ROOM_ITEMS}/${id}`);
};

export const createRoomItems = (data) => {
  return http.post(`${END_POINT.ROOM_ITEMS}`, data);
};

export const updateRoomItems = (id, params) => {
  return http.put(`${END_POINT.ROOM_ITEMS}/${id}`, params);
};

export const deleteRoomItems = (data) => {
  return http.delete(`${END_POINT.ROOM_ITEMS}`, data);
};

export const updateRoomItemsAllocation = (params) => {
  return http.put(`${END_POINT.ROOM_ITEM_ALLOCATION}`, params);
};

export const transferRoomItems = (params) => {
  return http.post(`${END_POINT.ROOM_ITEM_TRANSFER}`, params);
};

// ROOM BG
export const getRoomBg = (p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.ROOM_BG}?${params}`);
};

export const getDetailRoomBg = (id) => {
  return http.get(`${END_POINT.ROOM_BG}/${id}`);
};

export const createRoomBg = (data) => {
  return http.post(`${END_POINT.ROOM_BG}`, data);
};

export const updateRoomBg = (id, params) => {
  return http.put(`${END_POINT.ROOM_BG}/${id}`, params);
};

export const deleteRoomBg = (data) => {
  return http.delete(`${END_POINT.ROOM_BG}`, data);
};

export const updateRoomBgAllocation = (params) => {
  return http.put(`${END_POINT.ROOM_BG_ALLOCATION}`, params);
};

export const transferRoomBg = (params) => {
  return http.post(`${END_POINT.ROOM_BG_TRANSFER}`, params);
};

// old
export const uploadLinkFigure = (params) => {
  return http.post(`${END_POINT.DIGITAL_FIGURE}/upload-link`, params);
};

export const uploadLinkFigureDone = (params) => {
  return http.post(`${END_POINT.DIGITAL_FIGURE}/upload-done`, params);
};

// ALLOCATION
export const getListTypeAllocation = () => {
  return http.get(`${END_POINT.DIGITAL_FIGURE_ALLOCATION_TYPE}`);
};

export const getListBgTypeAllocation = () => {
  return http.get(`${END_POINT.ALLOCATION_TYPE}`);
};

export const getListRoomBgTypeAllocation = () => {
  return http.get(`${END_POINT.ROOM_BG_ALLOCATION_TYPE}`);
};

export const getListRoomItemsTypeAllocation = () => {
  return http.get(`${END_POINT.ROOM_ITEMS_ALLOCATION_TYPE}`);
};

// PARTNER
export const getListPartner = () => {
  return http.get(`${END_POINT.PARTNER}`);
};

// TYPE
export const getListTypeRoomItems = () => {
  return http.get(`${END_POINT.ROOM_TYPE}`);
};
