import { Icon, Td, Text, Tr, useColorModeValue } from "@chakra-ui/react";
import CheckBoxType from "components/Checkbox";
import { EditIcon } from "components/Icons/EditIcon";
import { useMemo } from "react";

const LicensorsRow = ({
  index,
  data,
  isHasPermissionEdit,
  checkedIds,
  setCheckedIds,
  toggle,
  countries = [],
}) => {
  const textColor = useColorModeValue("#000000", "white");
  const isChecked = useMemo(() => {
    return checkedIds.some((itemCheck) => itemCheck?.id === data?.id);
  }, [data?.id, checkedIds]);
  const isDefaultData =
    data?.name === "Bounty Hunter" && data?.project === "Bounty Hunter";

  const handleCheckboxChange = () => {
    if (isChecked) {
      setCheckedIds(
        checkedIds.filter((itemCheck) => itemCheck?.id !== data?.id)
      );
    } else {
      setCheckedIds([...checkedIds, data]);
    }
  };

  return (
    <Tr>
      <Td width="10px">
        {!isDefaultData && (
          <CheckBoxType
            isChecked={isChecked}
            onChange={handleCheckboxChange}
            colorScheme="teal"
          />
        )}
      </Td>
      <Td>
        {isHasPermissionEdit && (
          <Icon
            fontSize="30px"
            borderRadius="8px"
            p="7px"
            cursor="pointer"
            bg="#1480FF"
            as={EditIcon}
            onClick={() => toggle("modalCreateAndEdit", data)}
          />
        )}
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {index}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {data?.name}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {data?.project}
        </Text>
      </Td>
      <Td>
        <Text
          p="4px 10px"
          bg="#fff171"
          borderRadius="20px"
          minW={"91px"}
          w={"fit-content"}
          textAlign={"center"}
        >
          {data?.country_targets?.length}/{countries?.length}
        </Text>
      </Td>
    </Tr>
  );
};

export default LicensorsRow;
