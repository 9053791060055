import {
  Flex,
  useToast,
  useDisclosure,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import ConditionPresentBoxHistory from "./condition";
import DetailPresentBoxHistory from "./detail"
import PresentBoxHistoryRow from "./row"
import TableCustom from "components/Tables/CustomTable";
import Paginate from "components/Paginate";
import { useCallback, useEffect, useState } from "react";
import { FIRST_PAGE, LIMIT_PAGE } from "constants/constants";
import { getListPresentBoxHistory } from "api/presentBox.api";

export default function PresentBoxManagement() {
  const [userPresentBoxs, setUserPresentBoxs] = useState([]);
  const [dataDetail, setDataDetail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [params, setParams] = useState({
    page: FIRST_PAGE,
    limit: LIMIT_PAGE,
  });
  const toast = useToast();

  const {
    isOpen: isOpenDetail,
    onOpen: onOpenDetail,
    onClose: onCloseDetail,
  } = useDisclosure();

  const getUserPresentBox = useCallback(
    async () => {
      try {
        setIsLoading(true);
        const { data } = await getListPresentBoxHistory(params);
        if (data?.success) {
          const res = data?.data
          setUserPresentBoxs(res?.records);
          setTotalPage(res?.total_page);
        }
      } catch (error) {
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      } finally {
        setIsLoading(false);
      }
    },
    [params]
  );

  useEffect(() => {
    getUserPresentBox();
  }, [params]);

  const handleSetDataDetail = (data) => {
    setDataDetail(data);
    onOpenDetail();
  };

  const handleClose = () => {
    onCloseDetail();
    setDataDetail(null);
  };

  const handleChangePage = (page) => {
    setParams({
      ...params,
      page,
    });
  };

  const onChangeLimit = (limit) => {
    setParams({
      ...params,
      page: 1,
      limit,
    });
  };

  const labels = [
    "",
    "MGMT.NAME",
    "NAME",
    "STATUS",
    "USER",
    "DISTRIBUTION DATE",
  ];

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <Card px="24px">
        <CardHeader mb="32px">
          <ConditionPresentBoxHistory
            isLoading={isLoading}
            setParams={setParams}
            params={params}
          />
        </CardHeader>
        <CardBody overflowX="auto">
          <TableCustom
            labels={labels}
            isLoading={isLoading}
            isCheck={false}
            dataRow={userPresentBoxs}
          >
            <>
              {userPresentBoxs?.map((item) => {
                return (
                  <PresentBoxHistoryRow
                    key={item?.id}
                    userPresentBox={item}
                    handleSetDataDetail={() => {
                      handleSetDataDetail(item);
                    }}
                  />
                );
              })}
            </>
          </TableCustom>
        </CardBody>
        <Paginate
          page={params?.page}
          pageCount={totalPage}
          limit={params.limit}
          onChangeLimit={onChangeLimit}
          handleChangePage={handleChangePage}
        />
      </Card>
      {isOpenDetail && (
        <DetailPresentBoxHistory
          isOpen={isOpenDetail}
          onClose={handleClose}
          dataDetail={dataDetail}
        />
      )}
    </Flex>
  );
}
