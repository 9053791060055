import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { userinfoSelector } from "stores/user/selector";

export const usePermission = (permission) => {
  const { userinfo } = useSelector(userinfoSelector);

  const ruleGroups = userinfo?.role?.rule_groups || [];
  const rules = useMemo(
    () =>
      ruleGroups?.find((rule) => rule?.rule_group === permission)?.rules || [],
    [permission, ruleGroups]
  );

  // Check permission all
  const isHasPermission = rules && rules.length > 0;

  // Check permission view
  const isHasPermissionView = useMemo(() => {
    return isHasPermission && rules?.some((item) => item.name === "VIEW");
  }, [isHasPermission, rules]);

  // Check permission edit
  const isHasPermissionEdit = useMemo(() => {
    return isHasPermission && rules?.some((item) => item.name === "EDIT");
  }, [isHasPermission, rules]);

  const handleCheckPermission = useCallback(
    (permission) => {
      const rules =
        ruleGroups?.find((rule) => rule?.rule_group === permission)?.rules;
      return rules && rules?.length > 0;
    },
    [ruleGroups]
  );

  return {
    userinfo,
    rules,
    isHasPermission,
    isHasPermissionView,
    isHasPermissionEdit,
    handleCheckPermission,
  };
};
