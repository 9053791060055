import { TRANS_LABEL } from "views/DigitalFigure/constants";
import * as yup from "yup";

const schemaLicensor = (transType = "en") =>
  yup.object().shape({
    name: yup
      .string()
      .trim()
      .required(TRANS_LABEL.en.required)
      .max(255, TRANS_LABEL.en.maxLenght),
    name_jp: yup
      .string()
      .trim()
      .required(TRANS_LABEL.jp.required)
      .max(255, TRANS_LABEL.jp.maxLenght),
    project: yup
      .string()
      .trim()
      .required(TRANS_LABEL.en.required)
      .max(255, TRANS_LABEL.en.maxLenght),
    project_jp: yup
      .string()
      .trim()
      .required(TRANS_LABEL.jp.required)
      .max(255, TRANS_LABEL.jp.maxLenght),
    country_targets: yup.array().min(1, TRANS_LABEL[transType].required),
  });

export default schemaLicensor;
