import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';
import React from 'react';

const StyledDiv = styled.div`
  display: block;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  &.rounded {
    border-radius: 50%;
  }

  &.contain {
    background-size: contain;
    background-repeat: no-repeat;
  }

  &.avatar-image, &.avatar, &.border-thumbnail {
    background-size: cover;
  }
`;

const Thumbnail = ({ url, size, style, placeholderUrl, rounded, contain, className, children, ...props }) => (
  <StyledDiv
    style={{ backgroundImage: `url('${url || placeholderUrl || ''}')`, ...style }}
    {...props}
    className={classNames(className, { rounded, contain })}
    size={size || 40}
    alt=""
  >
    {children}
  </StyledDiv>
);
Thumbnail.propTypes = {
  url: PropTypes.string,
  placeholderUrl: PropTypes.string,
  size: PropTypes.number,
  rounded: PropTypes.bool,
  contain: PropTypes.bool,
  style: PropTypes.any,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default Thumbnail;
