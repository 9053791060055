import {
  Box,
  Flex,
  FormLabel,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  Tabs,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import FormInput from "components/form/FormInput";
import { createRef, useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import ButtonType from "components/Button";
import MceEditor from "components/mail-editor";
import { schemaMail } from "./schema";
import { createSystemMailTemplate } from "api/system";

const TRANS_LABEL = {
	en: {
		name: "Name",
		enterName: "Enter Name",
    subject: "Subject",
		enterSubject: "Enter Subject",
		description: "Content",
	},
	jp: {
		name: "名前",
		enterName: "名前を入力",
    subject: "主題",
		enterSubject: "件名を入力",
		description: "コンテンツ",
	},
};

export default function CreateEmailTemplate({
  isOpen,
  onClose,
  getSystemMaintenanceMail,
}) {
  const [tabIndex, setTabIndex] = useState(0);
  const [trans, setTrans] = useState("en");
  const textColor = useColorModeValue("#000000", "white");
  const toast = useToast();

  const editorRefs = useRef([]);

  const form = useForm({
    resolver: yupResolver(schemaMail()),
    defaultValues: {
      name: '',
      name_jp: '',
    }
  });
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = form;

  const handleTabsChange = (index) => {
    setTabIndex(index);
    if (index === 1) {
      setTrans("jp");
    } else {
      setTrans("en");
    }
  };

  const transLabel = (label) => {
    return TRANS_LABEL[trans][label];
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();
    const dataSubmit = [
      {
        language: "EN",
        name: data?.name,
        subject: data?.subject,
        content: editorRefs.current['0'].getContent(),
      },
      {
        language: "JP",
        name: data?.name_jp,
        subject: data?.subject_jp,
        content: editorRefs.current['1'].getContent(),
      }
    ]
    try {
      const res = await createSystemMailTemplate(dataSubmit);
      if (res?.data?.success) {
        toast({
          title: "Create template successfully!",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
        getSystemMaintenanceMail();
        onClose()
      } else {
        toast({
          description: res?.data?.message || res?.data?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const renderForm = () => {
    return (
      <FormProvider {...form}>
        <form id="machine-form" onSubmit={handleSubmit(onSubmit)}>
          <Tabs
            mb={4}
            index={tabIndex}
            variant="unstyled"
            onChange={handleTabsChange}
          >
            <TabList gap={2}>
              <Tab
                fontSize="16px"
                fontWeight="700"
                border="1px solid #EEEFF2"
                boxShadow="none !important"
                borderRadius="5px"
                color={textColor}
                _selected={{
                  color: "white",
                  backgroundImage: "linear-gradient(310deg,#2152ff,#21d4fd)",
                }}
              >
                English
              </Tab>
              <Tab
                fontSize="16px"
                fontWeight="700"
                border="1px solid #EEEFF2"
                boxShadow="none !important"
                borderRadius="5px"
                color={textColor}
                _selected={{
                  color: "white",
                  backgroundImage: "linear-gradient(310deg,#2152ff,#21d4fd)",
                }}
              >
                Japanese
              </Tab>
            </TabList>
          </Tabs>
          <Flex direction="column" w="100%" gap={4}>
            <Grid emplateColumns="repeat(1, 1fr)" gap={4}>
              <Flex direction="column" gap={4}>
                {trans === "en" ? (
                  <Box display="flex" flexDirection="column" gap="8px">
                    <FormInput
                      maxW="100%"
                      name="name"
                      label={transLabel("name")}
                      placeholder={transLabel("enterName")}
                      isRequired
                    />
                    <FormInput
                      maxW="100%"
                      name="subject"
                      label={transLabel("subject")}
                      placeholder={transLabel("enterSubject")}
                      isRequired
                    />
                  </Box>
                ) : (
                  <Flex  flexDirection="column" gap="8px">
                    <FormInput
                      maxW="100%"
                      name="name_jp"
                      label={transLabel("name")}
                      placeholder={transLabel("enterName")}
                      isRequired
                    />
                    <FormInput
                      maxW="100%"
                      name="subject_jp"
                      label={transLabel("subject")}
                      placeholder={transLabel("enterSubject")}
                      isRequired
                    />
                  </Flex>
                )}
                  <Box display={trans === "en" ? "flex" : "none"} flexDirection='column' gap="8px">
                    <FormLabel whiteSpace="nowrap" color="#000000" mb="0" fontWeight="600">
                      {transLabel("description")}
                    </FormLabel>
                    <MceEditor
                      mailContent=''
                      height={300}
                      index={0}
                      ref={editorRefs}
                    />
                  </Box>
                  <Box display={trans === "jp" ? "flex" : "none"} flexDirection='column' gap="8px">
                    <FormLabel whiteSpace="nowrap" color="#000000" mb="0" fontWeight="600">
                      {transLabel("description")}
                    </FormLabel>
                    <MceEditor
                      mailContent=''
                      height={300}
                      index={1}
                      ref={editorRefs}
                    />
                  </Box>
              </Flex>
            </Grid>
          </Flex>
        </form>
      </FormProvider>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxW="700px" >
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >
          New Email Template
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px">{renderForm()}</ModalBody>
        <ModalFooter
          borderTop="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          gap="20px"
        >
          <ButtonType
            mt={4}
            btnType="primary-new-outline"
            m="auto"
            w="100%"
            h="46px"
            borderRadius="5px"
            form="machine-form"
            onClick={onClose}
          >
            Cancel
          </ButtonType>
          <ButtonType
            mt={4}
            type="submit"
            m="auto"
            w="100%"
            h="46px"
            borderRadius="5px"
            form="machine-form"
            isLoading={isSubmitting}
          >
            ADD
          </ButtonType>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
