
export const STATUS_PRESENTBOX = [
  { value: "PENDING", label: "Pending" },
  { value: "ON_GOING", label: "OnGoing" },
  { value: "COMPLETED", label: "Completed" },
  { value: "FAIL", label: "Failed" },
];

export const RECIPIENTS = [
  { value: "INDIVIDUALS", label: "Individual" },
  { value: "MULTIPLE", label: "Multiple" },
  { value: "ALL", label: "All" },
];

export const DISTRIBUTE_TYPE = [
  { value: "STANDARD", label: "Standard" },
  { value: "CONDITION", label: "Conditional" },
];

export const DISTRIBUTE_CONDITION = [
  { value: "EVENT_INVITATION_CARD", label: "Send Invitation Card" }
];

export const TYPE_CURRENCY = [
  { value: "point_currency", label: "Point & Currency" },
  { value: "invitation_card", label: "Invitation Card" },
  { value: "NFT", label: "NFT" },
];

export const TYPE_NFT = [
  { value: "HUNTER", label: "Hunter" },
  { value: "GAUNTLET", label: "Gauntlet" },
  { value: "BOUNTY_BALL", label: "Bounty Ball" }
];

export const USER_RECIPIENTS = [
  { value: "SAME", label: "Same items" },
  { value: "CUSTOM", label: "Custom items" },
];

export const transformNFT = (nft) => {
  const nftItem = {
      nft_item_type: nft.token_type,
      nft_detail_bounty_ball: {},
      nft_detail_gauntlet: {},
      nft_detail_hunter: {}
  };

  if (nft.token_type === "HUNTER_NFT") {
      nftItem.nft_detail_hunter = itemNft(nft);
  } else if (nft.token_type === "GAUNTLET_NFT") {
      nftItem.nft_detail_gauntlet = itemNft(nft);
  } else if (nft.token_type === "BOUNTY_BALL_NFT") {
      nftItem.nft_detail_bounty_ball = itemNft(nft);
  }

  return nftItem;
}

function itemNft(nft) {
  return {
    id: nft.id,
    name: nft.name,
    token_type: nft.token_type,
    chain_id: nft.chain_id,
    product_id: nft.product_id,
    contract_address: nft.contract_address,
    rarity: nft.rarity,
    trait: nft.trait,
    image_url: nft.image_url
  }
}
