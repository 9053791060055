import * as yup from "yup";

const schemaLifeGauge = () =>
  yup.object().shape({
    max_life_gauge: yup
      .number()
      .min(0, "Number of rate must be greater or equal to 0")
      .nullable(true)
      .max(99999999, "Number of rate must be max 99999999")
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : 0;
        }
        return 0;
      })
      .integer("Must be an integer"),
    bcoin_lg_recovery_value: yup
      .number()
      .min(0, "Number of restoration cost (b-coin) be greater or equal to 0")
      .nullable(true)
      .max(99999999, "Number of restoration cost (b-coin) be max 99999999")
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : 0;
        }
        return 0;
      })
      .integer("Must be an integer"),
    sphere_lg_recovery_value: yup
      .number()
      .min(0, "Number of restoration cost (SPHERE) be greater or equal to 0")
      .nullable(true)
      .max(99999999, "Number of restoration cost (SPHERE) be max 99999999")
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : 0;
        }
        return 0;
      })
      .integer("Must be an integer"),
    max_lg_restoration_limit: yup
      .number()
      .min(0, "Number of MAX. RESTORATION be greater or equal to 0")
      .nullable(true)
      .max(99999999, "Number of MAX. RESTORATION be max 99999999")
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : 0;
        }
        return 0;
      })
      .integer("Must be an integer"),
  });

export default schemaLifeGauge;
