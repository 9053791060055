import {
  Box,
  Flex,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  Tabs,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { createLicensor, updateLicensor } from "api/licensors.api";
import ButtonType from "components/Button";
import FormCheckbox from "components/form/FormCheckbox";
import FormInput from "components/form/FormInput";
import FormLabelComponent from "components/form/FormLabel";
import { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import styled from "styled-components";
import { convertStringSnake } from "utils/string";
import {
  ERROR_DIGITAL_FIGURE,
  TRANS_LABEL,
} from "views/DigitalFigure/constants";
import ModalSelectCountries from "views/Notice/createAndUpdate/ModalSelectCountries";
import schemaLicensor from "./schema";

const TextError = styled.div`
  padding: 0;
  color: #e53e3e;
  font-size: 16px;
`;

export default function CreateAndUpdateLicensor({
  isOpen,
  onClose,
  dataDetail,
  handleCreateOrUpdateSuccess,
  countries = [],
}) {
  const isDefaultData =
    dataDetail?.name === "Bounty Hunter" &&
    dataDetail?.project === "Bounty Hunter";
  const [tabIndex, setTabIndex] = useState(0);
  const [trans, setTrans] = useState("en");
  const [checkedIds, setCheckedIds] = useState([]);
  const textColor = useColorModeValue("#000000", "white");
  const toast = useToast();
  const form = useForm({
    resolver: yupResolver(schemaLicensor(trans, isDefaultData)),
  });
  const {
    handleSubmit,
    reset,
    setValue,
    watch,
    clearErrors,
    formState: { isSubmitting, errors },
  } = form;

  const {
    isOpen: isOpenSelectCountry,
    onOpen: onOpenSelectCountry,
    onClose: onCloseSelectCountry,
  } = useDisclosure();

  const idEdit = useMemo(() => {
    return dataDetail?.id;
  }, [dataDetail]);

  const handleTabsChange = (index) => {
    setTabIndex(index);
    clearErrors();
    if (index === 1) {
      setTrans("jp");
    } else {
      setTrans("en");
    }
  };

  const handleCheckboxChangeAll = (isChecked) => {
    const newCheckedIds = isChecked
      ? countries.map((country) => country.country_code)
      : [];
    setCheckedIds(newCheckedIds);
    setValue("country_targets", newCheckedIds);
    clearErrors("country_targets");
  };

  const selectedCountries = useMemo(() => {
    const countryItems = watch("country_targets")
      ? watch("country_targets")
      : [];
    return countryItems?.length > 0 ? countryItems : checkedIds;
  }, [watch("country_targets")]);

  const transLabel = (label) => {
    return TRANS_LABEL[trans][label];
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();
    const dataSubmit = {
      name: data?.name,
      project: data?.project,
      country_targets: data?.country_targets,
      translations: [
        {
          lang_key: "EN",
          name: data?.name,
          project: data?.project,
        },
        {
          lang_key: "JP",
          name: data?.name_jp,
          project: data?.project_jp,
        },
      ],
    };
    try {
      if (idEdit) {
        const res = await updateLicensor(dataDetail?.id, dataSubmit);
        if (res?.data?.success) {
          toast({
            title: "Licensor Edited Successfully.",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
          handleCreateOrUpdateSuccess();
          onClose();
        } else {
          toast({
            description: convertStringSnake(
              ERROR_DIGITAL_FIGURE[res?.data?.message] ||
                ERROR_DIGITAL_FIGURE[res?.data?.messages[0]] ||
                res?.data?.messages[0]
            ),
            status: "error",
            position: "bottom-right",
          });
        }
      } else {
        const res = await createLicensor(dataSubmit);
        if (res?.data?.success) {
          toast({
            title: "Licensor Created Successfully.",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
          handleCreateOrUpdateSuccess();
          onClose();
        } else {
          toast({
            description: convertStringSnake(
              ERROR_DIGITAL_FIGURE[res?.data?.message] ||
                ERROR_DIGITAL_FIGURE[res?.data?.messages[0]] ||
                res?.data?.messages[0]
            ),
            status: "error",
            position: "bottom-right",
          });
        }
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  useEffect(() => {
    if (dataDetail?.id) {
      const itemJP = dataDetail?.translations?.find(
        (item) => item?.lang_key === "JP"
      );
      reset({
        name: dataDetail?.name,
        name_jp: itemJP?.name,
        project: dataDetail?.project,
        project_jp: itemJP?.project,
        country_targets: dataDetail?.country_targets || [],
      });
      setCheckedIds(dataDetail?.country_targets || []);
    } else {
      reset({
        country_targets: [],
      });
    }
  }, [reset, dataDetail]);

  const renderForm = () => {
    return (
      <FormProvider {...form}>
        <form id="machine-form" onSubmit={handleSubmit(onSubmit)}>
          <Tabs
            mb={4}
            index={tabIndex}
            variant="unstyled"
            onChange={handleTabsChange}
          >
            <TabList gap={2}>
              <Tab
                fontSize="16px"
                fontWeight="700"
                border="1px solid #EEEFF2"
                boxShadow="none !important"
                borderRadius="5px"
                color={textColor}
                _selected={{
                  color: "white",
                  backgroundImage: "linear-gradient(310deg,#2152ff,#21d4fd)",
                }}
              >
                English
              </Tab>
              <Tab
                fontSize="16px"
                fontWeight="700"
                border="1px solid #EEEFF2"
                boxShadow="none !important"
                borderRadius="5px"
                color={textColor}
                _selected={{
                  color: "white",
                  backgroundImage: "linear-gradient(310deg,#2152ff,#21d4fd)",
                }}
              >
                Japanese
              </Tab>
            </TabList>
          </Tabs>
          <Flex direction="column" w="100%" gap={4}>
            <Grid emplateColumns="repeat(1, 1fr)" gap={4}>
              <Flex flexDirection="column" gap={4}>
                <FormInput
                  key={`name_${trans}`}
                  maxW="100%"
                  name={trans === "en" ? "name" : "name_jp"}
                  label={transLabel("licensor")}
                  placeholder={transLabel("enterLicensor")}
                  isDisabled={isDefaultData}
                  isRequired
                />
                <FormInput
                  key={`project_${trans}`}
                  maxW="100%"
                  name={trans === "en" ? "project" : "project_jp"}
                  label={transLabel("project")}
                  placeholder={transLabel("enterProject")}
                  isDisabled={isDefaultData}
                  isRequired
                />
                <Flex alignItems="center" gap="8px" mt={"8px"}>
                  <FormLabelComponent
                    m="0px"
                    title={transLabel("targetCountries")}
                    isRequired
                  />
                  <Text p="3px 12px" bg="#b4d7ff" borderRadius="20px">
                    {checkedIds?.length}/{countries?.length}{" "}
                    {transLabel("selected")}
                  </Text>
                </Flex>
                <Grid templateColumns="repeat(2, 1fr)" gap="16px">
                  <FormCheckbox
                    name={`all_country`}
                    label={transLabel("all")}
                    wrapperProps={{
                      display: "flex",
                      alignItems: "center",
                      margin: "0",
                    }}
                    isChecked={
                      selectedCountries?.length === countries.length &&
                      countries.length > 0
                    }
                    onChange={(e) => handleCheckboxChangeAll(e.target.checked)}
                  />
                </Grid>
                <FormInput
                  type="text"
                  placeholder={transLabel("allCountries")}
                  onClick={onOpenSelectCountry}
                  autoComplete="off"
                  isReadOnly
                  name="country_targets"
                  value=""
                  renderIconSearch
                  cursor="pointer"
                />
              </Flex>
            </Grid>
          </Flex>
        </form>
      </FormProvider>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxW="700px" overflowY="auto">
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >
          {idEdit ? "Edit Licensor" : "New Licensor"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px">{renderForm()}</ModalBody>
        {errors?.name_jp?.message && trans === "en" && (
          <Box px="24px">
            <TextError>
              Please check the form again in the Japanese section.
            </TextError>
          </Box>
        )}
        <ModalFooter
          borderTop="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          gap="20px"
        >
          <ButtonType
            mt={4}
            btnType="primary-new-outline"
            m="auto"
            w="100%"
            h="46px"
            borderRadius="5px"
            form="machine-form"
            onClick={onClose}
          >
            {transLabel("cancel")}
          </ButtonType>
          <ButtonType
            mt={4}
            type="submit"
            m="auto"
            w="100%"
            h="46px"
            borderRadius="5px"
            form="machine-form"
            isLoading={isSubmitting}
          >
            {transLabel("save")}
          </ButtonType>
        </ModalFooter>
      </ModalContent>
      {isOpenSelectCountry && (
        <ModalSelectCountries
          isOpen={isOpenSelectCountry}
          onClose={onCloseSelectCountry}
          setCheckedIds={setCheckedIds}
          checkedIds={checkedIds}
          setValue={setValue}
          clearErrors={clearErrors}
          watch={watch}
          countries={countries}
          fieldName={"country_targets"}
        />
      )}
    </Modal>
  );
}
