import { STATUS } from "constants/constants";

export const MACHINE_TYPE_OPTIONS = [
  { label: "2 Claws", value: "TWO_CLAWS" },
  { label: "3 Claws", value: "THREE_CLAWS" },
];

export const STATE_OPTIONS = [
  { label: "Maintenance", value: "NO_ACTIVE" },
  { label: "Available", value: "ACTIVE" },
];

export const STATE = [
  { label: "Free", value: "FREE" },
  { label: "Playing", value: "PLAYING" },
  { label: "Prize Acquired", value: "PRIZE_ACQUIRED" },
  { label: "Prize Confirmation Request", value: "PRIZE_CONFIRMATION_REQUEST" },
  { label: "Under Maintenance", value: "UNDER_MAINTENANCE" },
  { label: "Error", value: "ERROR" },
];

export const STATE_SHOW = {
  [STATUS.FREE]: "Free",
  [STATUS.PLAYING]: "Playing",
  [STATUS.PRIZE_ACQUIRED]: "Prize Acquired",
  [STATUS.PRIZE_CONFIRMATION_REQUEST]: "Prize Confirmation Request",
  [STATUS.UNDER_MAINTENANCE]: "Under Maintenance",
  [STATUS.ERROR]: "Error",
  [STATUS.CONNECTED]: "Connected",
  [STATUS.DISCONNECTED]: "Disconnected",
};

export const DEDICATED_TO = {
  [STATUS.FREE]: "Free play",
  [STATUS.PRIZE]: "Get Prize",
  [STATUS.EVENT]: "Event",
};

export const DIRECTION = {
  [STATUS.LEFT]: "Left",
  [STATUS.RIGHT]: "Right",
};

export const DIRECTION_RADIO = [
  { label: "Left", value: "LEFT" },
  { label: "Right", value: "RIGHT" },
];

export const DEDICATED_TO_OPTIONS = [
  { label: "Free play", value: STATUS.FREE },
  { label: "Get Prize", value: STATUS.PRIZE },
  { label: "Event", value: STATUS.EVENT },
];

export const MACHINE_GROUPS = [
  { label: "A", value: "A" },
  { label: "B", value: "B" },
  { label: "C", value: "C" },
  { label: "D", value: "D" },
  { label: "E", value: "E" },
];

export const PRIZE_TYPE = [
  { value: "POINT_EVENT", label: "Point Event" },
  { value: "TREASURE_BOX", label: "Treasure Box" },
  { value: "IN_HOUSE_NFT", label: "In House NFT" },
  { value: "COLLAB_NFT", label: "Collab NFT" },
  { value: "LISTING_NFT", label: "Listing NFT" },
  { value: "DIGITAL_PRIZE", label: "Digital Prize" },
]

export const MODE_TYPE = [
  { label: "PVE Single Play", value: "PVE_SINGLE_PLAY" },
  // { label: "PVE Free Play", value: "PVE_FREE_PLAY" },
  // { label: "PVP", value: "PVP" },
]

export const MODE_TYPE_SKILL = [
  { label: "PVE Single Play", value: "PVE_SINGLE_PLAY" },
  { label: "PVE Free Play", value: "PVE_FREE_PLAY" },
  { label: "PVP", value: "PVP" },
  { label: "Co_op", value: "CO_OP" },
]

export const ERROR_MACHINE_SETTINGS = {
  "INVALID_MACHINE_STATE": "Invalid machine state",
  "CRANE_MACHINE_IS_IN_USED": "Crane machine is in used",
  "GAME_PLAY_TYPE_IS_NOT_ACTIVE": "Game play type is not active",
  "INVALID_GROUP_NUMBER": "Invalid group number",
  "INVALID_GROUP_NUMBER_OR_GROUP_LIST": "Invalid group number or group list",
  "INVALID_GROUP_NUMBER_OR_GROUP_LABEL": "Invalid group number or group label",
  "MACHINE_IS_RUNNING": "Machine is running",
  "GAME_MODE_NOT_FOUND": "Game mode not found",
  "MACHINE_OR_CRANE_IS_NOT_ACTIVE": "Machine or crane is not active",
  "NOT_EQUAL_BOOTH_TYPE": "Not equal booth type",
  "DEVICE_IS_USING": "Device is using",
  "CRANE_MACHINE_IS_UNDER_TRACKING": "Crane machine is under tracking",
  "DEVICE_IS_EXIST": "Device is exist",
};

export const MODE_TYPE_SHOW = {
  "PVE_SINGLE_PLAY": "PVE Single Play",
  "TRAINING": "Training",
  "PVE_FREE_PLAY": "PVE Free Play",
  "BASIC_TREASURE": "Basic Treasure",
  "BASIC_PRIZE": "Basic Prize",
  "PVP": "PVP",
  "TOURNAMENT": "Tournament",
  "BATTLE_ROYALE": "Battle Royale",
  "CO_OP": "Co_op",
};

export const ERROR_MACHINE_GROUPS = {
  "MACHINE_LABEL_NOT_EXIST": "Machine Label Not Exist",
  "INVALID_GROUP_LABEL": "Invalid Group Label",
  "INVALID_GROUP_NUMBER": "Invalid Group Number",
  "MACHINE_GROUP_IS_CURRENTLY_IN_USED": "Machine Group Is Currently In Used",
  "MACHINE_ASSIGNED_TO_GROUP_NUMBER": "Machine Assigned To Group Number",
  "MACHINE_LABEL_EXIST": "Machine Label Exist",
};
