import { Flex } from "@chakra-ui/react";
import FormSelect from "components/form/FormSelect";
import { useMemo } from "react";
import { TRANS_LABEL } from "views/DigitalFigure/constants";

export default function SelectGroupLicensor({
  trans,
  listLicensors = [],
  watch,
  setValue,
}) {
  const isJPForm = trans !== "en";
  const licensorValue = watch("licensor");

  const transLabel = (label) => {
    return TRANS_LABEL[trans][label];
  };

  const optionLicensor = useMemo(() => {
    if (!listLicensors?.length) return [];

    return listLicensors.reduce((options, { name }) => {
      if (!options.some((item) => item.label === name)) {
        options.push({ label: name, value: name });
      }
      return options;
    }, []);
  }, [listLicensors]);

  const optionProject = useMemo(() => {
    if (!listLicensors?.length) return [];

    return listLicensors.reduce((options, { id, name, project }) => {
      if (name === licensorValue) {
        options.push({ label: project, value: id });
      }
      return options;
    }, []);
  }, [listLicensors, licensorValue]);

  return (
    <Flex gap="16px" w="100%">
      <FormSelect
        key={`licensor_${trans}`}
        wrapperProps={{
          w: "100%",
        }}
        name={"licensor"}
        label={transLabel("licensor")}
        options={optionLicensor}
        placeholder={transLabel("selectLicensor")}
        onChange={(e) => {
          setValue("licensor", e.target.value);
          setValue("project", "");
        }}
        isRequired
        isDisabled={isJPForm}
      />
      <FormSelect
        key={`project_${trans}`}
        wrapperProps={{
          w: "100%",
        }}
        name={"project"}
        label={transLabel("project")}
        options={optionProject}
        placeholder={transLabel("selectProject")}
        isRequired
        isDisabled={!licensorValue || isJPForm}
      />
    </Flex>
  );
}
