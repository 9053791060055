import { Stack, Td, Text, Tr, useColorModeValue } from "@chakra-ui/react";

const PlayerLevelRow = ({ item }) => {
  const textColor = useColorModeValue("#000000", "white");
  return (
    <Tr>
      <Td>
        <Stack direction="row" spacing="16px">
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {item?.current_level?.split("_")?.[1]}
          </Text>
        </Stack>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
            {item?.xp_required}
        </Text>
      </Td>
    </Tr>
  );
};

export default PlayerLevelRow;
