import { useState } from "react";
import {
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  Icon,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import { DeleteIcon } from "components/Icons/DeleteIcon";

export default function RowFigure({
  item,
  index,
  handleChangePoint,
  setCheckedIds,
  checkedIds,
  setListFigureCheck,
  listFigureCheck,
}) {
  const [dataFactor, setDataFactor] = useState({
    count: item?.count,
  })

  const handleChange = (newValue, type) => {
    if (newValue === '') {
      setDataFactor(prev => ({ ...prev, [type]: '0' }));
    } else if (/^\d*\.?\d+$/.test(newValue) && Number(newValue) <= Number(listFigureCheck[index]?.count)) {
      setDataFactor(prev => ({ ...prev, [type]: newValue }));
    }
  };

  const handleDelete = (item) => {
    const dataFilter = checkedIds?.filter((check) => check?.id !== item?.id)
    setCheckedIds(dataFilter)
    setListFigureCheck(dataFilter)
  }
  
  const textColor = useColorModeValue("#000000", "white");
  return (
    <Tr>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.name}
        </Text>
      </Td>
      <Td minW="180px">
        <Editable 
          display="flex" 
          alignItems="center" 
          width="auto" 
          defaultValue={item?.count || "0"}
          value={dataFactor?.count}
          onChange={(e) => handleChange(e, 'count')}
          submitOnBlur={true}
          onSubmit={(e) => handleChangePoint(item.id, 'count', parseFloat(e || 0))}
        >
          <EditablePreview padding={2} border="1px solid #e6e6e6" minW="100px"/>
          <EditableInput type="number" width="100px" padding={2} border="1px solid transparent"/>
        </Editable>
      </Td>
      <Td>
        <Flex gap={6} align="center">
          <Icon
            onClick={() => handleDelete(item)}
            fontSize="30px"
            borderRadius="8px"
            cursor="pointer"
            as={DeleteIcon}
          />
        </Flex>
      </Td>
    </Tr>
  );
}
