import {
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import ButtonType from "components/Button";
import FormSelect from "components/form/FormSelect";
import { FormProvider, useForm } from "react-hook-form";
import { STATUS_CONTACT } from "../constants";
import { updateContactStatus } from "api/contact.api";

export default function ContactUpdateStatus({
  isOpen,
  onClose,
  handleCreateOrUpdateSuccess,
  checkedIds,
}) {
  const toast = useToast();
  const textColor = useColorModeValue("#000000", "white");
  const form = useForm({
    defaultValues: {
      status: STATUS_CONTACT[0].value,
    }
  });

  const {
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = form;

  const onSubmit = async (data, e) => {
    e.preventDefault();
    const listContact = checkedIds?.map((item) => item?.id)
    const dataSubmit = {
      status: data?.status,
      ids: listContact,
    };
    try {
      const res = await updateContactStatus(dataSubmit);
      if (res?.data?.success) {
        toast({
          title: "Contact status updated successfully!.",
          description: "Contact status updated successfully!.",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
        handleCreateOrUpdateSuccess();
        onClose();
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };


  const renderForm = () => {
    return (
      <FormProvider {...form}>
        <form id="contact-status-form" onSubmit={handleSubmit(onSubmit)}>
          <Grid templateColumns="repeat(1, 1fr)" gap={4}>
            <FormSelect
              name="status"
              label="Status Contact"
              defaultValue={STATUS_CONTACT[0].value}
              options={STATUS_CONTACT}
              onChange={(e) => setValue('status', e.target.value)}
            />
          </Grid>
        </form>
      </FormProvider>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxW="1000px" overflowY="auto">
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >
          UPDATE CONTACT STATUS
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px">{renderForm()}</ModalBody>
        <ModalFooter
          borderTop="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
        >
          <ButtonType
            mt={4}
            type="submit"
            m="auto"
            w="120px"
            h="46px"
            borderRadius="5px"
            form="contact-status-form"
            isLoading={isSubmitting}
          >
            SAVE
          </ButtonType>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
