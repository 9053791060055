/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  Flex,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  getPrizeByIdApi,
  listPrizeTypeApi,
  listPrizeModeTypeApi,
  listPrizeRarity,
  listPrizeTraits,
} from "stores/prize/action";

import ConditionPrize from "./condition";
import Paginate from "components/Paginate";
import PrizeRow from "components/Tables/prize/PrizeRow";
import { ACTION_PAGE, FIRST_PAGE, LIMIT_PAGE } from "constants/constants";
import PrizeDetail from "./components/detail";
import ActionPrizeSettings from "./action";
import TableCustom from "components/Tables/CustomTable";
import { getPrize } from "api/prize.api";
import { convertParams } from "utils/object";
import PrizeCreateAndUpdate from "./createAndUpdate";
import ModalConfirm from "components/Modal/ModalConfirm";
import { usePermission } from "hooks/usePermission";
import { PERMISSIONS } from "constants/permissions";

export default function PrizeSettings() {
  const { isHasPermissionEdit } = usePermission(
    PERMISSIONS.GAME_CONTROL_PRIZE
  );
  const { t } = useTranslation("prize");
  const dispatch = useDispatch();
  const textColor = useColorModeValue("#000000", "white");
  const [checkedIds, setCheckedIds] = useState([]);
  const [dataDetail, setDataDetail] = useState(null);
  const actionPageRef = useRef(ACTION_PAGE.VIEW);
  const [totalPage, setTotalPage] = useState(0);
  const [params, setParams] = useState({
    page: FIRST_PAGE,
    limit: LIMIT_PAGE,
  });

  const [dataTable, setDataTable] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const {
    isOpen: isOpenDetail,
    onOpen: onOpenDetail,
    onClose: onCloseDetail,
  } = useDisclosure();

  const {
    isOpen: isOpenNotice,
    onOpen: onOpenNotice,
    onClose: onCloseNotice,
  } = useDisclosure();

  const getListPrizeType = () => {
    dispatch(listPrizeTypeApi());
    dispatch(listPrizeModeTypeApi());
    dispatch(listPrizeRarity());
    dispatch(listPrizeTraits());
  };

  useEffect(() => {
    getListPrizeType();
  }, []);

  const handleClose = () => {
    onClose();
    setDataDetail(null);
  };

  const handleDetailClose = () => {
    onCloseDetail();
  };

  const getListPrize = useCallback(
    async (newParams = {}) => {
      try {
        setIsLoading(true);
        setCheckedIds([]);
        const paramsPrize = convertParams({
          ...params,
          ...newParams,
        })
        const { data } = await getPrize(paramsPrize);
        if (data?.success) {
          const res = data?.data;
          setDataTable(res?.records);
          setTotalPage(res?.total_page);
        }
      } catch (error) {
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      } finally {
        setIsLoading(false);
      }
    },
    [params]
  );

  useEffect(() => {
    getListPrize();
  }, [getListPrize]);

  const handleOpenCreateOrUpdate = (handle = ACTION_PAGE.VIEW, data = null) => {
    actionPageRef.current = handle;
    setDataDetail(data);
    if (handle !== "CREATE") {
      dispatch(getPrizeByIdApi(data?.id));
    }
    onOpen();
  };

  const handleCreateOrUpdateSuccess = useCallback(() => {
    handleClose();
    setDataDetail(null);
    setParams({
      ...params,
      // page: FIRST_PAGE,
    });
  }, [params]);

  const labels = [
    "Action",
    t("label_name"),
    t("label_image"),
    t("label_type"),
    "GameMode",
    "Stock",
    "Allocation",
    "Status",
    "Play Fee",
    "Sale Status",
    "Sale start time",
    "Sale end time",
  ];

  const handleChangePage = (page) => {
    setParams({
      ...params,
      page,
    });
  };

  const handleSetDataDetail = (id = "") => {
    dispatch(getPrizeByIdApi(id));
    onOpenDetail();
  };

  const onChangeLimit = (limit) => {
    setParams({
      ...params,
      page: 1,
      limit,
    });
  };

  const onChangeChecked = (isChecked) => {
    if (isChecked) {
      setCheckedIds(dataTable);
    } else {
      setCheckedIds([]);
    }
  };

  return (
    <>
      <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
        <Card px="0px">
          <CardHeader px="24px" mb="12px">
            <Flex
              w="100%"
              gap={4}
              direction={{
                base: "column",
                md: "row",
              }}
              justifyContent={{
                base: "flex-start",
                md: "space-between",
              }}
              alignItems="flex-start"
            >
              <Text color={textColor} fontSize="lg" fontWeight="bold">
                {t("prize_title")}
              </Text>
              {isHasPermissionEdit && (
                <ActionPrizeSettings
                  handleOpenCreateOrUpdate={() =>
                    handleOpenCreateOrUpdate(ACTION_PAGE.CREATE)
                  }
                  checkedIds={checkedIds}
                  setCheckedIds={setCheckedIds}
                  setParams={setParams}
                  params={params}
                  onOpenNotice={onOpenNotice}
                />
              )}
            </Flex>
          </CardHeader>
          <CardHeader px="24px" mb="32px">
            <ConditionPrize
              isLoading={isLoading}
              setParams={setParams}
              params={params}
            />
          </CardHeader>
          <CardBody overflowX="auto">
            <TableCustom
              labels={labels}
              isLoading={isLoading}
              isCheck
              isChecked={dataTable?.length > 0 && checkedIds.length === dataTable?.length}
              onChangeChecked={onChangeChecked}
              dataRow={dataTable}
            >
              <>
                {dataTable?.map((prize, index) => {
                  return (
                    <PrizeRow
                      key={index}
                      setCheckedIds={setCheckedIds}
                      checkedIds={checkedIds}
                      handleSetDataDetail={handleSetDataDetail}
                      handleOpenCreateOrUpdate={() => {
                        handleOpenCreateOrUpdate(ACTION_PAGE.EDIT, prize);
                      }}
                      listPrize={prize}
                    />
                  );
                })}
              </>
            </TableCustom>
          </CardBody>
          {(totalPage && (
            <Paginate
              page={params?.page}
              pageCount={totalPage}
              limit={params.limit}
              onChangeLimit={onChangeLimit}
              handleChangePage={handleChangePage}
            />
          )) ||
            ""}
        </Card>

        {isOpenDetail && (
          <PrizeDetail isOpen={isOpenDetail} onClose={handleDetailClose} />
        )}

        {isOpenNotice && (
          <ModalConfirm
            title="CANNOT DELETE"
            description="One of selected prize(s) are in use."
            textOk="OK"
            onClose={onCloseNotice}
            isOpen={isOpenNotice}
            onSubmit={onCloseNotice}
            isShowCancel={false}
          />
        )}

        {isOpen && (
          <PrizeCreateAndUpdate
            isOpen={isOpen}
            onClose={handleClose}
            dataDetail={dataDetail}
            handle={actionPageRef.current}
            handleCreateOrUpdateSuccess={handleCreateOrUpdateSuccess}
            handleViewClose={handleClose}
          />
        )}
      </Flex>
    </>
  );
}
