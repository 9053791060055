import {
  Flex,
  FormLabel,
  Icon,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import { useEffect, useRef, useState } from "react";
import CardHeader from "components/Card/CardHeader";
import { FormProvider, useForm } from "react-hook-form";
import ButtonType from "components/Button";
import { getSystemMailTemplate, testSendMail, updateSystemMailTemplate } from "api/system";
import FormInput from "components/form/FormInput";
import FormSelect from "components/form/FormSelect";
import MceEditor from "components/mail-editor";
import { MdOutlineAdd } from "react-icons/md";
import CreateEmailTemplate from "./CreateEmailTemplate";
import { emailRegex } from "utils/regex";
import { schemaEmailTemplate } from "./schema";
import { yupResolver } from "@hookform/resolvers/yup";

export default function EmailTemplate(params) {
  const { title, isHasPermissionEdit } = params
  const [isEdit, setIsEdit] = useState(false);
  const [activeTemp, setActiveTemp] = useState(0)
  const [dataTemplate, setDataTemplate] = useState([]);
  const toast = useToast();
  const editorRefs = useRef([]);
  const textColor = useColorModeValue("#000000", "white");

  const { isOpen, onOpen, onClose } = useDisclosure();

  const form = useForm({
    resolver: yupResolver(schemaEmailTemplate()),
    defaultValues: {}
  });

  const {
    handleSubmit,
    reset,
    watch,
    setValue,
    setError,
    formState: { isSubmitting, errors },
  } = form;

  const onSubmit = async (data, e) => {
    try {
      const param = {
        "name": dataTemplate[activeTemp]?.name,
        "subject": data?.title,
        "content": editorRefs.current[activeTemp].getContent(),
        "language": dataTemplate[activeTemp]?.language,
      }
      const res = await updateSystemMailTemplate(dataTemplate[activeTemp]?.id, param);
      if (res?.data?.success) {
        toast({
          title: "Content updated successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
        setIsEdit(false)
        const dataList = dataTemplate?.filter((data) => {
          if (data?.id === res?.data?.data?.id) {
            return {
              ...res?.data?.data,
            }
          }
          return data
        })
        setDataTemplate(dataList)
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const getSystemMaintenanceMail = async () => {
    try {
      const { data } = await getSystemMailTemplate();
      if (data?.success) {
        const listTemplates = data?.data?.map((item, index) => ({
          ...item,
          value: index,
          label: `${item?.name} (${item?.language})`,
        }))
        reset({
          title: listTemplates[0]?.subject,
          content: listTemplates[0]?.content,
          template: 0,
        });
        setDataTemplate(listTemplates)
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    } 
  };

  useEffect(() => {
    getSystemMaintenanceMail()
  }, [])

  
  const testSendEmail = async (mail) => {
    try {
      const params = {
        "to": [mail],
        "id": dataTemplate[activeTemp]?.id
      }
      if (!emailRegex.test(mail)) {
        setError("mail", { message: "Invalid email format" })
      } else {
        const res = await testSendMail(params);
        if (res?.data?.success) {
          toast({
            title: "Send mail successfully!",
            description: "Send mail successfully!",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
          setActiveTemp(0)
          getSystemMaintenanceMail()
        } else {
          toast({
            description: res?.data?.message || res?.data?.messages[0],
            status: "error",
            position: "bottom-right",
          });
        }
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const renderForm = () => {
    return (
      <FormProvider {...form}>
        <form id="email-template" style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
          <Flex alignItems="flex-start" flexDirection='column' gap="16px" w="100%" mt="10px">
            {dataTemplate?.length > 0 && dataTemplate?.map((temp, index) => (
              <Flex key={index} display={index === activeTemp ? "flex" : "none"} flexDirection='column' gap='12px' w="100%">
                <FormSelect
                  name="template"
                  label="Email Template"
                  options={dataTemplate}
                  lenSplitLabel={60}
                  onChange={(e) => {
                    reset({
                      title: dataTemplate[e?.target?.value]?.subject,
                      content: dataTemplate[e?.target?.value]?.content,
                      template: e?.target?.value,
                    });
                    setActiveTemp(Number(e?.target?.value))
                    setValue('mail', '')
                  }}
                />
                <FormInput
                  wrapperProps={{
                    w: "100%",
                  }}
                  maxW="100%"
                  type="text"
                  name="title"
                  label='Title'
                  onChange={(e) => {
                    setValue('title', e?.target?.value)
                  }}
                />
                <Flex flexDirection='column' gap={1}>
                  <FormLabel fontWeight="500" fontSize="16px" color={textColor}>
                    Content
                  </FormLabel>
                  <MceEditor
                    mailContent={watch('content')}
                    height={400}
                    ref={editorRefs}
                    index={index}
                  />
                </Flex>
              </Flex>
            ))}
            <Flex alignItems={errors?.mail ? "center" : "flex-end"} gap="12px">
              <FormInput
                wrapperProps={{
                  w: "100%",
                }}
                maxW="100%"
                name="mail"
                label='Test email'
                placeholder='Enter an email'
              />
              <ButtonType
                text="SEND"
                w="100px"
                fontSize="12px"
                onClick={(e) => {
                  e.preventDefault()
                  testSendEmail(watch("mail"))
                }}
                disabled={!watch("mail")}
              />
            </Flex>
          </Flex>
        </form>
      </FormProvider>
    );
  };

  return (
    <Flex flexDirection="column" w="100%">
      <Card px="22px" maxW="80%">
        <CardHeader mb="20px" w="100%" display="flex" alignItems="center" justifyContent="space-between">
          <Flex alignItems="center" gap="12px">
            <Text
              fontSize="xl"
              fontWeight="bold"
              whiteSpace="nowrap"
            >
              {title}
            </Text>
            <Icon
              fontSize="30px"
              borderRadius="8px"
              border="2px solid #ccc"
              cursor="pointer"
              p="7px"
              bg="#fff"
              as={MdOutlineAdd}
              onClick={onOpen}
            />
          </Flex>
          {isHasPermissionEdit && (
            <Flex>
              {!isEdit ? (
                <ButtonType
                  text="EDIT"
                  w="100px"
                  fontSize="12px"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsEdit(!isEdit);
                  }}
                />
              ) : (
                <ButtonType
                  text="SAVE"
                  w="100px"
                  fontSize="12px"
                  type="submit"
                  form="email-template"
                  isLoading={isSubmitting}
                />
              )}
            </Flex>
          )}
        </CardHeader>
        <CardBody 
          overflowX="auto"
          opacity={isEdit ? 1 : 0.5}
          pointerEvents={isEdit ? 'auto' : 'none'}
          overflow="visible"
        >
          {renderForm()}
        </CardBody>
      </Card>
      {isOpen && (
        <CreateEmailTemplate
          isOpen={isOpen}
          onClose={onClose}
          getSystemMaintenanceMail={getSystemMaintenanceMail}
          // handleCreateOrUpdateSuccess={handleCreateOrUpdateSuccess}
        />
      )}
    </Flex>
  );
}
