import {
  Flex,
  useToast
} from "@chakra-ui/react";
import {
  getStartDashLoginBonusSetting,
  createStartDashLoginBonusSetting
} from "api/loginBonus.api";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import TableCustom from "components/Tables/CustomTable";
import Paginate from "components/Paginate";
import StartDashSettingRow from "./row";
import useWithToast from "hooks/useWithToast";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function ConsecutiveBonus({ isHasPermissionEdit }) {
  const { t } = useTranslation("error");
  const [isLoading, setIsLoading] = useState(false);
  const [startDashSettings, setStartDashSettings] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const { showToastSuccess, showToastFail } = useWithToast();
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
  });
  const toast = useToast();

  const labels = [
    "ACTION",
    "DAY",
    "CO-OP FREE TICKET",
    "LAST UPDATED",
  ];

  const getStartDashSettings = useCallback(
    async (newParams = {}) => {
      try {
        setIsLoading(true);
        const { data } = await getStartDashLoginBonusSetting({
          ...params,
          ...newParams,
        });
        if (data?.success) {
          const res = data?.data;
          setStartDashSettings(res?.records);
          setTotalPage(res?.total_page);
        }
      } catch (error) {
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      } finally {
        setIsLoading(false);
      }
    },
    [params]
  );

  useEffect(() => {
    getStartDashSettings();
  }, [params]);

  const handleSaveStartDashSetting = async (data) => {
    try {
      const res = await createStartDashLoginBonusSetting(data);
        if (res?.data?.success) {
          showToastSuccess({
            title: "Start dash login bonus setting created.",
          });
          await getStartDashSettings();
        } else {
          showToastFail({
            title: t(`${res?.data?.messages[0]}`) || "Create fail!",
          });
        }
      } catch (error) {
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      }
  }

  const handleChangePage = (page) => {
    setParams({
      ...params,
      page,
    });
  };

  const onChangeLimit = (limit) => {
    setParams({
      ...params,
      page: 1,
      limit,
    });
  };

  return (
    <Flex flexDirection="column">
      <Card p="0px">
        <CardBody overflowX="auto">
          <TableCustom
            labels={labels}
            isLoading={isLoading}
            isCheck={false}
            dataRow={startDashSettings}
          >
            <>
              {startDashSettings?.map((item) => {
                return (
                  <StartDashSettingRow
                    key={item?.id}
                    startDash={item}
                    onSave={handleSaveStartDashSetting}
                    isHasPermissionEdit={isHasPermissionEdit}
                  />
                );
              })}
            </>
          </TableCustom>
        </CardBody>
        <Paginate
          page={params?.page}
          pageCount={totalPage}
          limit={params.limit}
          onChangeLimit={onChangeLimit}
          handleChangePage={handleChangePage}
        />
      </Card>
    </Flex>
  );
}
