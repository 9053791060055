import { pickBy, reduce } from "lodash";

export const parseFilter = (params) => {
  if (params?.filter) {
    const { filter, ...restParams } = params;
    return { ...restParams, ...filter };
  }
  return params;
};

export const json2Form = (jsonObj) =>
  reduce(
    jsonObj,
    (result, item, key) => {
      result.append(key, item);
      return result;
    },
    new FormData()
  );

export const convertBodyToFormData = (body) => {
  const formData = new FormData();
  Object.keys(body).forEach(
    (prop) => body[prop] && formData.append(prop, body[prop])
  );
  return formData;
};

export const convertParams = (obj) => {
  let str = "";
  for (let key in obj) {
    if (obj[key]) {
      if (str != "") {
        str += "&";
      }
      str += key + "=" + encodeURIComponent(obj[key]);
    }
  }
  return str;
};

export const omitParams = (obj) => {
  return pickBy(obj, (v) => v !== null && v !== undefined && v);
};
