import { Flex } from "@chakra-ui/react";
import FormInput from "components/form/FormInput";
import { FormProvider, useForm } from "react-hook-form";

export default function ConditionDigital({ isLoading, setParams, params }) {
  const form = useForm();

  const { handleSubmit } = form;

  const onSubmit = (data) => {
    if (!isLoading) {
      const newParams = {
        ...params,
        keyword: data?.keyword?.trim(),
        page: 1,
      };
      setParams(newParams);
    }
  };

  return (
    <Flex
      gap={4}
      flexWrap="wrap"
      justifyContent="flex-start"
      alignItems="flex-end"
    >
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Flex flexWrap="wrap" alignItems="flex-end" gap={4}>
            <FormInput
              w="349px"
              maxW="100%"
              name="keyword"
              label="Search"
              placeholder={"Search by name"}
            />
          </Flex>
        </form>
      </FormProvider>
    </Flex>
  );
}
