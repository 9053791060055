import { useCallback, useEffect, useState } from "react";
import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { getUserSimpleResource } from "api/event.api";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import Paginate from "components/Paginate";
import TableCustom from "components/Tables/CustomTable";
import ItemsSendRow from "./row";
import ConditionLeaderBoard from "./action";
import ButtonType from "components/Button";
import ConfirmSend from "./ConfirmSend";

export default function ModalSendItems({
  isOpen,
  onClose,
  isHasPermissionEdit,
}) {
  const labels = [
    "User",
    "Email",
    "Ticket",
    "B-coin",
    "Sphere",
  ];
  const [checkedIds, setCheckedIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
  });
  const toast = useToast();
  const textColor = useColorModeValue("#000000", "white");
  const {
    isOpen: isOpenSend,
    onOpen: onOpenSend,
    onClose: onCloseSend,
  } = useDisclosure();

  const onChangeLimit = (limit) => {
    setParams({
      ...params,
      page: 1,
      limit,
    });
  };
  
  const handleChangePage = (page) => {
    setParams({
      ...params,
      page,
    });
  };

  const getEvent = useCallback(
    async (newParams = {}) => {
      try {
        setIsLoading(true);
        setCheckedIds([]);
        const { data } = await getUserSimpleResource({
          ...params,
          ...newParams,
        });
        if (data?.success) {
          const res = data?.data;
          setItems(res?.records);
          setTotalPage(res?.total_page);
        }
      } catch (error) {
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      } finally {
        setIsLoading(false);
      }
    },
    [params]
  );

  useEffect(() => {
    getEvent();
  }, [getEvent]);

  const onChangeChecked = (isChecked) => {
    if (isChecked) {
      setCheckedIds(items);
    } else {
      setCheckedIds([]);
    }
  };

  const handleCreateOrUpdateSuccess = useCallback(() => {
    setParams({
      ...params,
      page: 1,
    });
  }, [params]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xxl">
      <ModalOverlay />
      <ModalContent maxW="900px" overflowY="auto">
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >
          SEND ITEMS
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px">
          <Flex flexDirection="column">
            <Card px="0px">
              <CardHeader display="flex" flexDirection="column" gap="12px" mb="24px">
                <ConditionLeaderBoard
                  isLoading={isLoading}
                  setParams={setParams}
                  params={params}
                />
              </CardHeader>
              <CardBody overflowX="auto">
                <TableCustom
                  labels={labels}
                  isLoading={isLoading}
                  dataRow={items}
                  isCheck
                  isChecked={
                    items?.length > 0 && checkedIds.length === items?.length
                  }
                  onChangeChecked={onChangeChecked}
                >
                  <>
                    {items?.map((item) => {
                      return (
                        <ItemsSendRow
                          key={item?.id}
                          item={item}
                          setCheckedIds={setCheckedIds}
                          checkedIds={checkedIds}
                        />
                      );
                    })}
                  </>
                </TableCustom>
              </CardBody>
              <Paginate
                page={params?.page}
                pageCount={totalPage}
                limit={params.limit}
                onChangeLimit={onChangeLimit}
                handleChangePage={handleChangePage}
              />
            </Card>
          </Flex>
        </ModalBody>
        <ModalFooter
          borderTop="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          gap="20px"
        >
          <ButtonType
            mt={4}
            m="auto"
            w="160px"
            h="46px"
            borderRadius="5px"
            disabled={!checkedIds?.length || !isHasPermissionEdit}
            onClick={onOpenSend}
          >
            SEND
          </ButtonType>
        </ModalFooter>
      </ModalContent>
      {isOpenSend && (
        <ConfirmSend 
          isOpen={isOpenSend}
          onClose={onCloseSend}
          checkedIds={checkedIds}
          setCheckedIds={setCheckedIds}
          handleCreateOrUpdateSuccess={handleCreateOrUpdateSuccess}
        />
      )}
    </Modal>
  );
}
  