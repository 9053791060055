import React, { useCallback, useEffect, useState } from 'react'
import ActionMachineGroup from './actionBanner';
import { CloseButton, Flex, Text, useToast, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import TableCustom from 'components/Tables/CustomTable';
import Paginate from 'components/Paginate';
import CreateAndUpdateGroup from "./createAndUpdate";
import {
  deleteMachineGroup,
} from "api/machine.api";
import MachineRow from './row';
import ConditionBanner from './condition';
import { deleteCategoryPrize, getCategoryPrize, updateStatusCategoryPrize } from 'api/gameMode.api';
import ModalConfirm from "components/Modal/ModalConfirm";
import ModalDetail from './modalDetail';
import { STATUS } from 'constants/constants';

export default function BannerSetup({ toggleModal, prizeTypes, isHasPermissionEdit }) {
  const [checkedGroups, setCheckedGroups] = useState([]);
  const [dataDetail, setDataDetail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [machineGroup, setMachineGroup] = useState([]);

  const toast = useToast();
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
  });
  const textColor = useColorModeValue("#000000", "white");

  const onChangeLimit = (limit) => {
    setParams({
      ...params,
      page: 1,
      limit,
    });
  };

  const handleChangePage = (page) => {
    setParams({
      ...params,
      page,
    });
  };

  const labels = [
    "Action",
    "Name",
    "Banner Image",
    "Prize(s)",
    "Status",
  ];

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { 
    isOpen: isOpenDelete, 
    onOpen: onOpenDelete, 
    onClose: onCloseDelete, 
  } = useDisclosure();

  const { 
    isOpen: isOpenDetail, 
    onOpen: onOpenDetail, 
    onClose: onCloseDetail, 
  } = useDisclosure();

  const handleOpenDetail = (data = null) => {
    setDataDetail(data);
    onOpenDetail();
  };

  const handleOpenCreateOrUpdate = (data = null) => {
    setDataDetail(data);
    onOpen();
  };

  const onChangeChecked = (isChecked) => {
    if (isChecked) {
      setCheckedGroups(machineGroup);
    } else {
      setCheckedGroups([]);
    }
  };

  const getListMachineGroup = useCallback(
    async (newParams = {}) => {
      try {
        setIsLoading(true);
        setCheckedGroups([]);
        const { data } = await getCategoryPrize({
          ...params,
          ...newParams,
        });
        if (data?.success) {
          const res = data?.data;
          setMachineGroup(res?.records);
          setTotalPage(res?.total_page);
        }
      } catch (error) {
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      } finally {
        setIsLoading(false);
      }
    },
    [params]
  );

  const handleCreateOrUpdateSuccess = useCallback(() => {
    onClose();
    setDataDetail(null);
    setParams({
      ...params,
      page: 1,
    });
  }, [params]);

  useEffect(() => {
    getListMachineGroup()
  }, [getListMachineGroup])

  const deleteItem = async (id) => {
    return deleteMachineGroup(id);
  };

  const handleDelete = async () => {
    try {
      const dataRemove = checkedGroups?.map((banner) => banner?.id)
      const res = await deleteCategoryPrize(dataRemove);
      if (res?.data?.success) {
        toast({
          title: "Delete Banner Successfully!",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
        handleCreateOrUpdateSuccess();
        setCheckedGroups([])
        onCloseDelete()
        } else {
        toast({
          description: res?.data?.message || res?.data?.messages[0] || res?.data?.messages[0],
          status: "error",
          position: "bottom-right",
        });
        onCloseDelete()
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

    const handleSwitchStatus = async (item) => {
      try {
        setIsLoading(true);
        const res = await updateStatusCategoryPrize(item?.id, {
          "status": item?.status === STATUS.ACTIVE ? STATUS.NO_ACTIVE : STATUS.ACTIVE,
        });
        if (res?.data?.success) {
          getListMachineGroup();
          toast({
            title: "Update success.",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
        } else {
          setIsLoading(false);
          toast({
            description: res?.data?.message || res?.data?.messages[0],
            status: "error",
            position: "bottom-right",
          });
        }
      } catch (error) {
        setIsLoading(false);
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      }
    };

  return (
    <Card p="0px">
      <CardHeader mb="12px" display="flex" flexDirection="column" gap="12px">
        <Flex
          w="100%"
          gap={4}
          alignItems="center"
        >
          <CloseButton size='lg' onClick={() => toggleModal("bannerSetup")}/>
          <Text
            color={textColor}
            fontSize="lg"
            fontWeight="bold"
            whiteSpace="nowrap"
          >
            Banner Setup
          </Text>
        </Flex>
        <Flex w="100%">
          {isHasPermissionEdit && (
            <ActionMachineGroup
              handleOpenCreateOrUpdate={handleOpenCreateOrUpdate}
              checkedGroups={checkedGroups}
              setCheckedGroups={setCheckedGroups}
              toggleModal={toggleModal}
              onOpenDelete={onOpenDelete}
            />
          )}
        </Flex>
        <ConditionBanner
          isLoading={isLoading}
          setParams={setParams}
          params={params}
        />
      </CardHeader>
      <CardBody overflowX="auto">
        <TableCustom
          labels={labels}
          isLoading={isLoading}
          isCheck
          isChecked={
            machineGroup?.length > 0 && checkedGroups?.length === machineGroup?.length
          }
          onChangeChecked={onChangeChecked}
          dataRow={machineGroup}
        >
          <>
            {machineGroup?.map((item, index) => {
              return (
                <MachineRow
                  key={index}
                  setCheckedGroups={setCheckedGroups}
                  checkedGroups={checkedGroups}
                  handleOpenCreateOrUpdate={() => {
                    handleOpenCreateOrUpdate(item);
                  }}
                  handleOpenDetail={() => {
                    handleOpenDetail(item);
                  }}
                  handleSwitchStatus={() => {
                    handleSwitchStatus(item);
                  }}
                  machine={item}
                  isHasPermissionEdit={isHasPermissionEdit}
                />
              );
            })}
          </>
        </TableCustom>
      </CardBody>
      <Paginate
        page={params?.page}
        pageCount={totalPage}
        limit={params.limit}
        onChangeLimit={onChangeLimit}
        handleChangePage={handleChangePage}
      />
      {isOpen && (
        <CreateAndUpdateGroup
          isOpen={isOpen}
          onClose={onClose}
          dataDetail={dataDetail}
          handleCreateOrUpdateSuccess={handleCreateOrUpdateSuccess}
          prizeTypes={prizeTypes}
        />
      )}
      {isOpenDetail && (
        <ModalDetail 
          isOpen={isOpenDetail}
          onClose={onCloseDetail}
          dataDetail={dataDetail}
          isHasPermissionEdit={isHasPermissionEdit}
        />
      )}
      <ModalConfirm
        isOpen={isOpenDelete}
        onClose={onCloseDelete}
        onSubmit={handleDelete}
      />
    </Card>
  )
}
