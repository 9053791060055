import {
  Box,
  Center,
  Icon,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import CheckBoxType from "components/Checkbox";
import { NoDataTableIcon } from "components/Icons/NoDataTableIcon";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";

export default function TableCustom({
  labels = [],
  dataRow = [],
  isLoading = false,
  isCheck = true,
  isChecked = false,
  onChangeChecked,
  children,
  showCheck = true,
  textNoData = "NO DATA",
  textAlign = "left",
  isWrap = false,
  isSticky = false
}) {
  return (
    <Box overflowX="auto" position="relative" w="100%">
      <Table variant="simple">
        <Thead 
          boxShadow="0px 20px 27px 0px #0000000D" 
          background={isSticky ? "#fff" : 'transparent'} 
          zIndex={isSticky ? 1 : 0}  
          position={isSticky ? "sticky" : "static"}  
          top={0}  
        >
          <Tr>
            {(isCheck && showCheck) ? (
              <Th width="10px">
                <CheckBoxType
                  colorScheme="teal"
                  isChecked={isChecked}
                  onChange={(e) => {
                    const isChecked = e.target.checked;
                    onChangeChecked && onChangeChecked(isChecked);
                  }}
                ></CheckBoxType>
              </Th>
            ) : (!showCheck && <Th></Th>)}
            {labels.map((label, index) => {
              const isLast = index === labels.length - 1
              return (
                <Th
                  whiteSpace={isWrap ? "wrap" : "nowrap"}
                  color="#000000"
                  fontWeight="700"
                  fontSize="sm"
                  textTransform="uppercase"
                  textAlign={ isLast ? textAlign : "left"}
                  key={label}
                >
                  {label}
                </Th>
              );
            })}
          </Tr>
        </Thead>
        <Tbody bg="white">
          {dataRow.length > 0 ? (
            children
          ) : (
            <Tr>
              <Td colSpan={labels.length + 1}>
                {isLoading ? (
                  <Text textAlign="center" color="#000000" padding="80px 0px">
                    <Box position="absolute" top={0} left={0} w="100%" h="100%">
                      <LoadingSpinner />
                    </Box>
                  </Text>
                ) : (
                  <Center w="100%" color="#000000">
                    <Icon as={NoDataTableIcon} w="250px" h="200px" mt="20px" />
                  </Center>
                )}
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
      {dataRow.length > 0 && isLoading ? (
        <Box position="absolute" top={0} left={0} w="100%" h="100%">
          <LoadingSpinner />
        </Box>
      ) : null}
    </Box>
  );
}
