import {
  Box,
  Flex,
  Text,
  useToast,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import Maintenance from "./Maintenance";
import { getSystemMaintenance } from "api/system";
import { useEffect, useState } from "react";
import LoginAccess from "./LoginAccess";

export default function SiteMaintenance({ isHasPermissionEdit }) {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [dataMaintenance, setDataMaintenance] = useState()

  const getListMaintenance = async () => {
    try {
      setIsLoading(true);
      const { data } = await getSystemMaintenance();
      if (data?.success) {
        setDataMaintenance(data?.data);
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getListMaintenance()
  }, [])

  return (
    <Flex flexDirection="column" w="100%">
      <Card p="0px">
        <CardHeader px="22px" w="100%">
          <Text
            fontSize="xl"
            fontWeight="bold"
            whiteSpace="nowrap"
          >
            Sites Maintenance
          </Text>
        </CardHeader>
        <CardBody overflowX="auto" display="flex" flexDirection="column" gap="8px">
          {dataMaintenance && (
            <>
              <Maintenance 
                title="Game" 
                dataMaintenance={dataMaintenance} 
                itemMaintenance={dataMaintenance?.site_maintenance?.game_maintenance}
                type="game_maintenance"
                getListMaintenance={getListMaintenance}
                isHasPermissionEdit={isHasPermissionEdit}
              />
              <Maintenance 
                title="Registration" 
                dataMaintenance={dataMaintenance} 
                itemMaintenance={dataMaintenance?.site_maintenance?.registration_maintenance}
                type="registration_maintenance"
                getListMaintenance={getListMaintenance}
                isHasPermissionEdit={isHasPermissionEdit}
              />
              <Maintenance 
                title="Marketplace" 
                dataMaintenance={dataMaintenance} 
                itemMaintenance={dataMaintenance?.site_maintenance?.marketplace_maintenance}
                type="marketplace_maintenance"
                getListMaintenance={getListMaintenance}
                isHasPermissionEdit={isHasPermissionEdit}
              />
            </>
          )}
          <LoginAccess
            title="Login Access" 
            dataMaintenance={dataMaintenance} 
            itemLogin={dataMaintenance?.login_access}
            getListMaintenance={getListMaintenance}
            isHasPermissionEdit={isHasPermissionEdit}
          />
        </CardBody>
      </Card>
    </Flex>
  );
}
