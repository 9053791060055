export const LIMIT_PAGE = 10;
export const FIRST_PAGE = 1;
export const MAX_FILE_SIZE = 5;
export const LIMIT_FULL = 999999;

export const STATUS = {
  ACTIVE: "ACTIVE",
  NO_ACTIVE: "NO_ACTIVE",
  BLOCKED: "BLOCKED",
  AVAILABLE: "Available",
  MAINTENANCE: "Maintenance",
  EVENT: "EVENT",
  PRIZE: "PRIZE",
  FREE: "FREE",
  FAIL: "FAIL",
  MISSING: "MISSING",
  CLOSE: "CLOSE",
  OK: "OK",
  CONNECTED: "CONNECTED",
  DISCONNECTED: "DISCONNECTED",
  DISCONNECT: "DISCONNECT",
  LEFT: "LEFT",
  RIGHT: "RIGHT",
  FRONT: "FRONT",
  BACK: "BACK",
  SIDE: "SIDE",
  TWO_CLAWS: "TWO_CLAWS",
  THREE_CLAWS: "THREE_CLAWS",
  PLAYING: "PLAYING",
  PRIZE_ACQUIRED: "PRIZE_ACQUIRED",
  PRIZE_CONFIRMATION_REQUEST: "PRIZE_CONFIRMATION_REQUEST",
  UNDER_MAINTENANCE: "UNDER_MAINTENANCE",
  ERROR: "ERROR",
  IN_EVENT: "IN_EVENT",
  CLOSED: "CLOSED",
  OPEN: "OPEN",
  IN_PRE_REGISTER: "IN_PRE_REGISTER",
  IN_RESULT: "IN_RESULT",
};

export const STATUS_RATE = {
  BCOIN_TO_BTH: "BCOIN_TO_BTH",
  BCOIN_TO_SPHERE: "BCOIN_TO_SPHERE",
  BCOIN_TO_USD: "BCOIN_TO_USD",
  BCOIN_TO_YEN: "BCOIN_TO_YEN",
};

export const TOKEN_TYPE = {
  HUNTER_NFT: "HUNTER_NFT",
  GAUNTLET_NFT: "GAUNTLET_NFT",
  NON_NFT: "NON_NFT",
  GENESIS_NFT: "GENESIS_NFT",
  BOUNTY_BALL_NFT: "BOUNTY_BALL_NFT",
  COLLAB_NFT: "COLLAB_NFT",
  LOCK_BNB_NFT: "LOCK_BNB_NFT",
  LOCK_IMMUTABLE_PASSPORT_NFT: "LOCK_IMMUTABLE_PASSPORT_NFT",
  LOCK_DMM_NFT: "LOCK_DMM_NFT",
};

export const TOKEN_TYPE_LABEL = {
  [TOKEN_TYPE.HUNTER_NFT]: "Hunter NFT",
  [TOKEN_TYPE.GAUNTLET_NFT]: "Gauntlet NFT",
  [TOKEN_TYPE.NON_NFT]: "Non NFT",
  [TOKEN_TYPE.GENESIS_NFT]: "Genesis NFT",
  [TOKEN_TYPE.BOUNTY_BALL_NFT]: "Bounty Ball NFT",
  [TOKEN_TYPE.COLLAB_NFT]: "Collab NFT",
  [TOKEN_TYPE.LOCK_BNB_NFT]: "Lock BNB NFT",
  [TOKEN_TYPE.LOCK_IMMUTABLE_PASSPORT_NFT]: "Lock Immutable Passport NFT",
  [TOKEN_TYPE.LOCK_DMM_NFT]: "Lock DMM NFT",
};

export const MACHINE_TYPE_OPTIONS = [
  { label: "2 Claws", value: STATUS.TWO_CLAWS },
  { label: "3 Claws", value: STATUS.THREE_CLAWS },
];

export const DEDICATED_TO = {
  true: "Free play",
  false: "Get Prize",
};

export const DEDICATED_TO_OPTIONS = [
  { label: "Free play", value: STATUS.FREE },
  { label: "Get Prize", value: STATUS.PRIZE },
  { label: "Event", value: STATUS.EVENT },
];

export const STATE_OPTIONS = [
  { label: "Maintenance", value: "NO_ACTIVE" },
  { label: "Available", value: "ACTIVE" },
];

export const CABINET_TYPE_OPTIONS = [
  { label: "Cabinet 1", value: "1772835644541865986" },
];

export const GENRE_TAGS_OPTIONS = [
  { label: "Genre Tags 1", value: "Genre_Tags_1" },
];

export const PAYEE_OPTIONS = [
  { label: "RSharing 1", value: "1772835644713832449" },
];

export const SALE_OPTIONS = [
  { label: "None", value: "none" },
  { label: "Yes", value: "yes" },
];

export const BOUNTY_BALL_OPTIONS = [
  { label: "Bounty Ball NFT", value: "bounty_ball_nft" },
  { label: "Others", value: "other" },
];

export const PRIZE_TYPE_OPTIONS = [
  // { label: "Digital Prize", value: "digital_prize" },
  { label: "TREASURE_BOX", value: "1765994158289481733" },
];

export const ACTION_PAGE = {
  VIEW: "VIEW",
  CREATE: "CREATE",
  EDIT: "EDIT",
};

export const UMIT_OPTIONS = [
  { label: "C Coin 1", value: "C_COIN_1" },
  { label: "C Coin 2", value: "C_COIN_2" },
  { label: "B Coin", value: "B_COIN" },
  { label: "BTH TOKEN", value: "BTH_TOKEN" },
  { label: "TICKET NFT", value: "TICKET_NFT" },
  { label: "ORB", value: "ORB" },
];

export const TYPE_PRIZE = {
  TREASURE_BOX: "Treasure Box",
  PRIZE_GAUNTLET: "NFT Gauntlet",
  PRIZE_BOUNTY_BALL: "NFT Bounty Ball",
  DIGITAL_PRIZE: "Digital Prize",
  IN_HOUSE_NFT: "In-house NFT",
  COLLAB_NFT: "Collab NFT",
  LISTING_NFT: "Listing NFT",
  POINT_EVENT: "Point Event",
  INVITATION_CARD: "Invitation Card",
  DIGITAL_FIGURE: "Digital Figure",
  ROOM_ITEM: "Room item",
  VIEWER_BACKGROUND_3D_IMAGE: "Viewer Background 3d Image",
  ROOM_BACKGROUND: "Room Background",
};

export const GAME_MODE_OPTIONS = [
  { label: "Training Mode", value: "TRAINING" },
  { label: "Free play", value: "FREE_PLAY" },
  { label: "Basic Play", value: "BASIC" },
  { label: "Battle Arena", value: "PVP" },
  { label: "League Tournament", value: "TOURNAMENT" },
  { label: "League Battle Royale", value: "BATTLE_ROYALE" },
  // { label: "Group vs Group", value: "Group vs Group" },
];

export const ROLE_OPTIONS = {
  SUPPER_ADMIN: "Super Admin",
  ADMIN: "Admin",
  MARKET_PLACE_ADMIN: "MKP Admin",
  USER: "User",
  OPERATOR: "Operator",
};

export const OPTIONS_SKILL_RANK = [
  {
    label: "Default",
    value: "DEFAULT",
  },
  {
    label: "Iron",
    value: "IRON",
  },
  {
    label: "Bronze",
    value: "BRONZE",
  },
  {
    label: "Silver",
    value: "SILVER",
  },
  {
    label: "Gold",
    value: "GOLD",
  },
  {
    label: "Platium",
    value: "PLATINUM",
  },
  {
    label: "Emerald",
    value: "EMERALD",
  },
  {
    label: "Diamond",
    value: "DIAMOND",
  },
  {
    label: "Master",
    value: "MASTER",
  },
  {
    label: "Grandmaster",
    value: "GRANDMASTER",
  },
  {
    label: "Challenger",
    value: "CHALLENGER",
  },
];

export const CHAIN_ID_AVAILABLE = {
  POLYGON_MAINNET: "137",
  POLYGON_TESTNET: "80002",

  IMX_MAINNET: "13371",
  IMX_TESTNET: "13473",

  OASYS_MAINNET: "248",
  OASYS_TESTNET: "9372",

  DM2_MAINNET: "2400",
  DM2_TESTNET: "12401",

  BNB_MAINNET: "56",
  BNB_TESTNET: "97",
};

export const RPC_URL = {
  [CHAIN_ID_AVAILABLE.POLYGON_MAINNET]: "https://polygon-rpc.com",
  [CHAIN_ID_AVAILABLE.POLYGON_TESTNET]: "https://rpc-amoy.polygon.technology",

  [CHAIN_ID_AVAILABLE.IMX_MAINNET]: "https://rpc.immutable.com",
  [CHAIN_ID_AVAILABLE.IMX_TESTNET]: "https://rpc.testnet.immutable.com",

  [CHAIN_ID_AVAILABLE.OASYS_MAINNET]: "https://rpc.mainnet.oasys.games",
  [CHAIN_ID_AVAILABLE.OASYS_TESTNET]: "https://rpc.testnet.oasys.games",

  [CHAIN_ID_AVAILABLE.DM2_MAINNET]: "https://rpc.tcgverse.xyz",
  [CHAIN_ID_AVAILABLE.DM2_TESTNET]: "https://rpc.testnet.tcgverse.xyz",

  [CHAIN_ID_AVAILABLE.BNB_MAINNET]: "https://bsc-dataseed1.binance.org",
  [CHAIN_ID_AVAILABLE.BNB_TESTNET]:
    "https://data-seed-prebsc-1-s1.binance.org:8545",
};

export const CHAIN_NATIVE_TOKEN = {
  [CHAIN_ID_AVAILABLE.POLYGON_MAINNET]: "POL",
  [CHAIN_ID_AVAILABLE.POLYGON_TESTNET]: "POL",

  [CHAIN_ID_AVAILABLE.IMX_MAINNET]: "IMX",
  [CHAIN_ID_AVAILABLE.IMX_TESTNET]: "IMX",

  [CHAIN_ID_AVAILABLE.BNB_MAINNET]: "BNB",
  [CHAIN_ID_AVAILABLE.BNB_TESTNET]: "BNB",

  [CHAIN_ID_AVAILABLE.OASYS_MAINNET]: "OAS",
  [CHAIN_ID_AVAILABLE.OASYS_TESTNET]: "OAS",

  [CHAIN_ID_AVAILABLE.DM2_MAINNET]: "OAS",
  [CHAIN_ID_AVAILABLE.DM2_TESTNET]: "OAS",
};

export const CHAIN_TYPE = {
  TESTNET: "TESTNET",
  MAINNET: "MAINNET",
};

export const NFT_CHAIN = [
  {
    label: "Immutable zkEVM",
    value: "IMX",
    chain_id: CHAIN_ID_AVAILABLE.IMX_MAINNET,
    type: CHAIN_TYPE.MAINNET,
  },
  {
    label: "Immutable zkEVM Testnet",
    value: "T_IMX",
    chain_id: CHAIN_ID_AVAILABLE.IMX_TESTNET,
    type: CHAIN_TYPE.TESTNET,
  },
  {
    label: "BNB Smart Chain",
    value: "BNB",
    chain_id: CHAIN_ID_AVAILABLE.BNB_MAINNET,
    type: CHAIN_TYPE.MAINNET,
  },
  {
    label: "BNB Smart Chain Testnet",
    value: "T_BNB",
    chain_id: CHAIN_ID_AVAILABLE.BNB_TESTNET,
    type: CHAIN_TYPE.TESTNET,
  },
];

export const GENESIS_NFT_CHAIN = [
  {
    label: "Immutable zkEVM",
    value: "IMX",
    chain_id: CHAIN_ID_AVAILABLE.IMX_MAINNET,
    type: CHAIN_TYPE.MAINNET,
  },
  {
    label: "Immutable zkEVM Testnet",
    value: "T_IMX",
    chain_id: CHAIN_ID_AVAILABLE.IMX_TESTNET,
    type: CHAIN_TYPE.TESTNET,
  },
  {
    label: "Oasys Verse",
    value: "DM2_VERSE",
    chain_id: CHAIN_ID_AVAILABLE.DM2_MAINNET,
    type: CHAIN_TYPE.MAINNET,
  },
  {
    label: "Oasys Verse Testnet",
    value: "T_DM2_VERSE",
    chain_id: CHAIN_ID_AVAILABLE.DM2_TESTNET,
    type: CHAIN_TYPE.TESTNET,
  },
  {
    label: "Polygon",
    value: "POLYGON",
    chain_id: CHAIN_ID_AVAILABLE.POLYGON_MAINNET,
    type: CHAIN_TYPE.MAINNET,
  },
  {
    label: "Polygon Testnet",
    value: "T_POLYGON",
    chain_id: CHAIN_ID_AVAILABLE.POLYGON_TESTNET,
    type: CHAIN_TYPE.TESTNET,
  },
];

export const ONLY_POLYGON_CHAINS = [
  {
    label: "Polygon",
    value: "POLYGON",
    chain_id: CHAIN_ID_AVAILABLE.POLYGON_MAINNET,
    type: CHAIN_TYPE.MAINNET,
  },
  {
    label: "Polygon Testnet",
    value: "T_POLYGON",
    chain_id: CHAIN_ID_AVAILABLE.POLYGON_TESTNET,
    type: CHAIN_TYPE.TESTNET,
  },
];

export const ALL_NFT_CHAINS = [
  ...new Set([...NFT_CHAIN, ...GENESIS_NFT_CHAIN].map(JSON.stringify)),
].map(JSON.parse);

export const CODE_MAINTENANCE = [
  { label: "UPDATE", value: "UPDATE" },
  { label: "HOT_FIX", value: "HOT_FIX" },
];

export const EVENT_WEBSOCKET = {
  NUMBER_USER_ONLINE: "NUMBER_USER_ONLINE",
};

export const RARITY = {
  RARITY_COMMON: "COMMON",
  RARITY_UNCOMMON: "UNCOMMON",
  RARITY_EPIC: "EPIC",
  RARITY_RARE: "RARE",
  RARITY_LEGENDARY: "LEGENDARY",
};

export const TRAIT = {
  ARENA_HUNTER: "ARENA_HUNTER",
  MECHANIC: "MECHANIC",
  MERCHANT: "MERCHANT",
  PRIZE_HUNTER: "PRIZE_HUNTER",
  TREASURE_HUNTER: "TREASURE_HUNTER",
};

export const ALLOCATION = {
  GACHA: "GACHA",
  MKP: "MKP",
  PRIZE: "PRIZE",
  PRESALE: "PRESALE",
  NONE: "NONE",
};

export const ALLOCATION_LABEL = {
  [ALLOCATION.GACHA]: "In-Gacha",
  [ALLOCATION.MKP]: "In-MKP",
  [ALLOCATION.PRIZE]: "In-Prize",
  [ALLOCATION.PRESALE]: "In-Presale",
};

export const OPTIONS_RARITY = [
  {
    label: "Common",
    value: RARITY.RARITY_COMMON,
  },
  {
    label: "Uncommon",
    value: RARITY.RARITY_UNCOMMON,
  },
  {
    label: "Epic",
    value: RARITY.RARITY_EPIC,
  },
  {
    label: "Rare",
    value: RARITY.RARITY_RARE,
  },
  {
    label: "Legendary",
    value: RARITY.RARITY_LEGENDARY,
  },
];

export const ALLOCATION_HUNTER_NFT_OPTIONS = [
  { value: ALLOCATION.GACHA, label: "Gacha" },
  // { value: ALLOCATION.MKP, label: "MKP" },
  { value: ALLOCATION.NONE, label: "None" },
];

export const ALLOCATION_GAUNTLET_BB_OPTIONS = [
  { value: ALLOCATION.GACHA, label: "Gacha" },
  // { value: ALLOCATION.MKP, label: "MKP" },
  { value: ALLOCATION.PRIZE, label: "Prize" },
  { value: ALLOCATION.NONE, label: "None" },
];

export const ALLOCATION_GENESIS_NFT_OPTIONS = [
  { value: ALLOCATION.PRESALE, label: "Pre-sale" },
];

export const TRAIT_NFT = {
  ARENA_HUNTER_TRAIT: "Arena Hunter",
  MECHANIC_TRAIT: "Mechanic",
  MERCHANT_TRAIT: "Merchant",
  PRIZE_HUNTER_TRAIT: "Prize Hunter",
  TREASURE_HUNTER_TRAIT: "Treasure Hunter",
};

export const TRAIT_NFT_OPTIONS = [
  { label: TRAIT_NFT.ARENA_HUNTER_TRAIT, value: TRAIT.ARENA_HUNTER },
  { label: TRAIT_NFT.MECHANIC_TRAIT, value: TRAIT.MECHANIC },
  { label: TRAIT_NFT.MERCHANT_TRAIT, value: TRAIT.MERCHANT },
  { label: TRAIT_NFT.PRIZE_HUNTER_TRAIT, value: TRAIT.PRIZE_HUNTER },
  { label: TRAIT_NFT.TREASURE_HUNTER_TRAIT, value: TRAIT.TREASURE_HUNTER },
];

export const OPERATING_SYSTEMS = [
  { value: "IOS", label: "iOS" },
  { value: "ANDROID", label: "Android" },
];

export const ETHERSCAN_URL = {
  [CHAIN_ID_AVAILABLE.POLYGON_MAINNET]: "https://polygonscan.com",
  [CHAIN_ID_AVAILABLE.POLYGON_TESTNET]: "https://amoy.polygonscan.com",

  [CHAIN_ID_AVAILABLE.IMX_MAINNET]: "https://explorer.immutable.com",
  [CHAIN_ID_AVAILABLE.IMX_TESTNET]: "https://explorer.testnet.immutable.com",

  [CHAIN_ID_AVAILABLE.OASYS_MAINNET]: "https://explorer.oasys.games",
  [CHAIN_ID_AVAILABLE.OASYS_TESTNET]: "https://explorer.testnet.oasys.games",

  [CHAIN_ID_AVAILABLE.DM2_MAINNET]: "https://explorer.testnet.oasys.games",
  [CHAIN_ID_AVAILABLE.DM2_TESTNET]: "https://explorer.testnet.dm2verse.dmm.com",

  [CHAIN_ID_AVAILABLE.BNB_MAINNET]: "https://bscscan.com",
  [CHAIN_ID_AVAILABLE.BNB_TESTNET]: "https://testnet.bscscan.com",
};

export const SYSTEM_COINS = [
  { value: "ORB", label: "Orb" },
  { value: "BCOIN", label: "BCoin" },
  { value: "SPHERE", label: "Sphere" },
];
export const TASK_STATUS_OWNER = [
  { value: true, label: "Done" },
  { value: false, label: "Not Done" },
]

export const BG_ITEM_NUM_OF_NFT = {
  green_nft_quantity: "#99e177",
  pink_nft_quantity: "#ff99b2",
  purple_nft_quantity: "#c099ff",
}

export const SYSTEM_COINS_SHOW = {
  ORB: "Orb" ,
  BCOIN: "BCoin",
  SPHERE: "Sphere",
}

export const RARITY_LABEL = {
  COMMON: "Common",
  UNCOMMON: "Uncommon",
  EPIC: "Epic",
  RARE: "Rare",
  LEGENDARY: "Legendary",
  OFF_CHAIN: "Off Chain",
};

export const STATUS_PRESENTBOX = {
  PENDING: "Pending" ,
  ON_GOING: "OnGoing",
  COMPLETED: "Completed",
  FAIL: "Failed",
}

export const DISTRIBUTE_CONDITION = {
  SEND_INVITATION_CARD: "Send Invitation Card" ,
  TOP_RANK_OF_EVENT: "Top Rank Of Event",
}

export const STATUS_USER_PRESENTBOX = {
  OPENED: "Opened" ,
  NOT_OPENED: "Not opened",
  EXPIRED: "Expired",
}

export const STATUS_PRIZE = {
  IN_USED: "In Used",
  AVAILABLE: "Available",
}

export const LABEL_EVENT = {
  SKILL_RANK_EVENT: "Skill Rank",
  STANDARD_EVENT: "Standard",
}
