import { Td, Tr, Image, Flex, Badge, Text, useColorModeValue, Icon } from "@chakra-ui/react";
import { NFT_MANAGEMENT_TYPE } from "views/NFTsManagement/constants";
import { TRAIT_NFT, TRAIT_NFT_OPTIONS } from "constants/constants";
import { DeleteIcon } from "components/Icons/DeleteIcon";
export default function NFTRow ({
  item,
  nftMngType,
  handleDeleteNFTItem
}) {
  const textColor = useColorModeValue("#000000", "white");
   const getTrait = () => {
      if (nftMngType === NFT_MANAGEMENT_TYPE.HUNTER) {
        const traitConfig = {
          ARENA_HUNTER: {
            text: TRAIT_NFT.ARENA_HUNTER_TRAIT,
            color: "#FFF599",
          },
          MECHANIC: { text: TRAIT_NFT.MECHANIC_TRAIT, color: "#C4C1BF" },
          MERCHANT: { text: TRAIT_NFT.MERCHANT_TRAIT, color: "#FF9999" },
          PRIZE_HUNTER: {
            text: TRAIT_NFT.PRIZE_HUNTER_TRAIT,
            color: "#99E0FF",
          },
          TREASURE_HUNTER: {
            text: TRAIT_NFT.TREASURE_HUNTER_TRAIT,
            color: "#99E177",
          },
        };
        const activeTraits = Object.entries(traitConfig)
          .filter(([key]) => item.trait == key)
          .map(([_, config]) => config);

        return (
          <Flex gap={2} flexDirection="column" width="fit-content">
            {activeTraits.map(({ text, color }) => (
              <Badge
                key={text}
                borderRadius="10px"
                padding="4px 8px"
                fontWeight="normal"
                bgColor={color}
                textAlign="center"
              >
                {text}
              </Badge>
            ))}
          </Flex>
        );
      }

      return (
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {TRAIT_NFT_OPTIONS.find((trait) => trait.value === item?.trait)
            ?.label ?? "-"}
        </Text>
      );
    };

  return (
    <>
      <Tr>
        <Td>
          <Flex gap={6}>
            <Icon
              onClick={() => handleDeleteNFTItem(item)}
              fontSize="30px"
              borderRadius="8px"
              cursor="pointer"
              as={DeleteIcon}
            />
          </Flex>
        </Td>
        <Td>
          {item?.name}
        </Td>
        <Td minWidth="150px">
          <Image w="50px" lazyLoad={true} src={item?.image_url} alt="NFT" />
        </Td>
        <Td>
          {item?.rarity}
        </Td>
        <Td >
         {getTrait()}
        </Td>
        <Td>
          1
        </Td>
      </Tr>
    </>
  );
}
