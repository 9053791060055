import {
  Flex,
} from "@chakra-ui/react";
import PlayerLevelXp from "./PlayerLevelXp";
import PlayerLevelAp from "./PlayerLevelAp";
import { usePermission } from "hooks/usePermission";
import { PERMISSIONS } from "constants/permissions";

export default function PlayerLevel() {
  const { isHasPermissionEdit } = usePermission(PERMISSIONS.GAME_CONTROL_EVENT);

  return (
    <Flex borderRadius="8px" flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <PlayerLevelXp isHasPermissionEdit={isHasPermissionEdit}/>
      <PlayerLevelAp isHasPermissionEdit={isHasPermissionEdit}/>
    </Flex>
  );
}
