import {
  Box,
  Flex,
  FormLabel,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import ButtonType from "components/Button";
import FormInput from "components/form/FormInput";
import FormRadio from "components/form/FormRadio";
import useWithToast from "hooks/useWithToast";
import { useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import ratesSchema from "./schema";
import { useTranslation } from "react-i18next";
import FormSelect from "components/form/FormSelect";
import { ERROR_EXCHANGE_RATE, rateExchange } from "../constant";
import CheckBoxType from "components/Checkbox";
import FormTextAria from "components/form/FormTextAria";
import { createExchangeRate, updateExchangeRate } from "api/rate.api";

export default function CreateAndUpdateExchange({
  isOpen,
  onClose,
  dataDetail,
  handleCreateOrUpdateSuccess,
  listExchangeRate,
}) {
  const { t } = useTranslation("error");
  const textColor = useColorModeValue("#000000", "white");
  const toast = useToast();
  const { showToastSuccess, showToastFail } = useWithToast();

  const idEdit = useMemo(() => {
    return dataDetail?.id;
  }, [dataDetail]);

  const form = useForm({
    resolver: yupResolver(ratesSchema()),
    defaultValues: {
      first_currency: dataDetail ? dataDetail?.first_currency : listExchangeRate[0]?.value,
      first_currency_type: dataDetail ? dataDetail?.first_currency_type : listExchangeRate[0]?.type,
      second_currency: dataDetail ? dataDetail?.second_currency : listExchangeRate[1]?.value,
      second_currency_type: dataDetail ? dataDetail?.second_currency_type : listExchangeRate[1]?.type,
      numerator: dataDetail ? dataDetail?.numerator : null,
      denominator: dataDetail ? dataDetail?.denominator : null,
      description: dataDetail ? dataDetail?.description : "",
      exchange_fee: dataDetail ? dataDetail?.exchange_fee : "",
      way_exchange: dataDetail ? dataDetail?.way_exchange || false : false,
      assist: rateExchange[0]?.value,
      float_rate: null,
    },
  });

  const {
    handleSubmit,
    watch,
    setValue,
    clearErrors,
    formState: { isSubmitting },
  } = form;
  
  const getFraction = (decimal) => {
    let denominator = 1;
    while ((decimal * denominator) % 1 !== 0) {
      denominator++;
    }
    return { numerator: decimal * denominator, denominator };
  };

  const onSubmit = async (data) => {
    const frac = getFraction(data?.float_rate);
    const dataSubmit = {
      "first_currency": data?.first_currency,
      "first_currency_type": data?.first_currency_type,
      "second_currency": data?.second_currency,
      "second_currency_type": data?.second_currency_type,
      "numerator": data?.assist === "FRACTION" ? data?.numerator : frac.numerator,
      "denominator": data?.assist === "FRACTION" ? data?.denominator : frac.denominator,
      "description": data.description,
      "exchange_type": "SYSTEM_EXCHANGE",
      "is_two_way_exchange": data.way_exchange,
      "currency_subtype": "CURRENCY_1",
      "exchange_fee": data.exchange_fee
    }
    try {
      if (idEdit) {
        const res = await updateExchangeRate(dataDetail?.id, dataSubmit)
        if (res?.status === 200) {
          showToastSuccess({
            title: "Update exchange rate settings successfully!",
          });
          handleCreateOrUpdateSuccess()
        } else {
          toast({
            description: ERROR_EXCHANGE_RATE[res?.data?.message] || ERROR_EXCHANGE_RATE[res?.data?.messages[0]],
            status: 'error',
            position: 'bottom-right',
          })
        }
      } else {
        const res = await createExchangeRate(dataSubmit)
        if (res?.status === 200) {
          showToastSuccess({
            title: "Create exchange rate settings successfully!",
          });
          handleCreateOrUpdateSuccess()
        } else {
          toast({
            description: ERROR_EXCHANGE_RATE[res?.data?.message] || ERROR_EXCHANGE_RATE[res?.data?.messages[0]],
            status: 'error',
            position: 'bottom-right',
          })
        }
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const renderForm = () => {
    return (
      <FormProvider {...form}>
        <form id="exchange-form" onSubmit={handleSubmit(onSubmit)}>
          <Grid templateColumns="repeat(1, 1fr)" gap={4}>
            <Flex gap={4}>
              <FormSelect
                wrapperProps={{
                  w: "100%"
                }}
                name="first_currency"
                label="From"
                defaultValue={listExchangeRate[0]?.value}
                options={listExchangeRate?.filter(rate => rate.value !== watch('second_currency'))}
                onChange={(e) => {
                  const exchangeChoose = listExchangeRate?.filter((rate) => rate?.value === e.target.value)
                  setValue("first_currency", e.target.value);
                  setValue("first_currency_type", exchangeChoose[0]?.type);
                }}
              />
              <FormSelect
                wrapperProps={{
                  w: "100%"
                }}
                name="second_currency"
                label="To"
                defaultValue={listExchangeRate[0]?.value}
                options={listExchangeRate?.filter(rate => rate.value !== watch('first_currency'))}
                onChange={(e) => {
                  const exchangeChoose = listExchangeRate?.filter((rate) => rate?.value === e.target.value)
                  setValue("second_currency", e.target.value);
                  setValue("second_currency_type", exchangeChoose[0]?.type);
                }}
              />
            </Flex>
            <Flex w="100%" flexDirection='column' gap="12px">
              <Flex w="100%" alignItems="center" gap={4}>
                <FormRadio 
                  label="Rate"
                  name="assist" 
                  options={rateExchange}
                  value={watch('assist')}
                  onChange={(e) => {
                    setValue("assist", e)
                    setValue("float_rate", null)
                    setValue("numerator", null)
                    setValue("denominator", null)
                    clearErrors("float_rate")
                    clearErrors("numerator")
                    clearErrors("denominator")
                  }}
                  isRequired
                  wrapperProps={{
                    w: "100%",
                  }}
                />
              </Flex>
              <Flex w="100%" gap={16}>
                <Flex w="100%" alignItems='center' gap={2}>
                  <FormInput
                    wrapperProps={{
                      w: "100%",
                    }}
                    name="numerator"
                    type="number"
                    min={0}
                    label=""
                    placeholder=""
                    disabled={watch("assist") !== "FRACTION"}
                  />
                  /
                  <FormInput
                    wrapperProps={{
                      w: "100%",
                    }}
                    name="denominator"
                    type="number"
                    min={1}
                    label=""
                    placeholder=""
                    disabled={watch("assist") !== "FRACTION"}
                  />
                </Flex>
                <FormInput
                  wrapperProps={{
                    w: "100%",
                  }}
                  name="float_rate"
                  type="number"
                  min={0}
                  step="any"
                  label=""
                  placeholder=""
                  disabled={watch("assist") !== "FLOAT"}
                />
              </Flex>
              <FormInput
                wrapperProps={{
                  w: "100%",
                }}
                min={0}
                max={99999999}
                name="exchange_fee"
                type="number"
                label="Fee (%)"
                placeholder="Enter fee"
              />
              <FormTextAria
                label="Description"
                placeholder="Enter description"
                name="description"
                defaultValue=''
                h="100px"
              />
              {!idEdit && (
                <Flex
                  alignItems="center"
                  gap="6px"
                >
                  <CheckBoxType
                    onChange={(e) => {
                      const isChecked = e.target.checked;
                      setValue("way_exchange", isChecked)
                    }}
                  />
                  <Text
                    color={textColor}
                    fontSize="14"
                    fontWeight="400"
                    whiteSpace="nowrap"
                  >
                    Two-way exchange
                  </Text>
                </Flex>
              )}
            </Flex>
          </Grid>
        </form>
      </FormProvider>
    );
  };

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxW="685px" overflowY="auto">
        <ModalHeader
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >
          {idEdit ? "Edit Exchange" : "New Exchange"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px" minW="500px">
          {renderForm()}
        </ModalBody>
        <ModalFooter w="100%" textAlign="center" margin="auto" gap="16px">
          <ButtonType
            mt={4}
            btnType="primary-new-outline"
            type="submit"
            m="auto"
            w="100%"
            h="46px"
            fontSize="16px"
            borderRadius="5px"
            onClick={() => {
              onClose();
            }}
          >
            CANCEL
          </ButtonType>
          <ButtonType
            mt={4}
            btnType="primary"
            type="submit"
            m="auto"
            w="100%"
            h="46px"
            fontSize="16px"
            borderRadius="5px"
            form="exchange-form"
            isLoading={isSubmitting}
          >
            SAVE
          </ButtonType>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
