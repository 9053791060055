import { Td, Tr, Image} from "@chakra-ui/react";
import CheckBoxType from "components/Checkbox"
import { useMemo } from "react";

export default function CardRow ({
  item,
  checkedIds,
  setCheckedIds
}) {

  const isChecked = useMemo(() => {
    return checkedIds.some((itemCheck) => itemCheck?.id === item?.id);
  }, [item?.id, checkedIds]);

  const handleCheckboxChange = () => {
    if (isChecked) {
      setCheckedIds(
        checkedIds.filter((itemCheck) => itemCheck?.id !== item?.id)
      );
    } else {
      setCheckedIds([...checkedIds, item]);
    }
  };

  return (
    <>
      <Tr>
        <Td width="10px">
          <CheckBoxType
            isChecked={isChecked}
            onChange={() => handleCheckboxChange()}
            colorScheme="teal"
          />
        </Td>
        <Td>
          {item?.card_name}
        </Td>
        <Td minWidth="150px">
        <Image
          src={item?.url_image}
          w="28px"
          h="28px"
          alt={item?.id}
        />
        </Td>
      </Tr>
    </>
  );
}
