import { Td, Text, Tr, useColorModeValue } from "@chakra-ui/react";
import { TYPE_PRIZE } from "constants/constants";

const RowRoomSetup = ({ item, index }) => {
  const textColor = useColorModeValue("#000000", "white");

  return (
    <Tr>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.pos}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.size}%
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {TYPE_PRIZE[item?.type]}
        </Text>
      </Td>
    </Tr>
  );
};

export default RowRoomSetup;
