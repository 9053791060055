import {
    Flex,
    useDisclosure,
    useToast,
  } from "@chakra-ui/react";
  import { getListUserKOL, updateUserKOL } from "api/userKOL.api";
  import Card from "components/Card/Card";
  import CardBody from "components/Card/CardBody";
  import CardHeader from "components/Card/CardHeader";
  import Paginate from "components/Paginate";
  import TableCustom from "components/Tables/CustomTable";
  import UserKOLRow from "./row";
  import FormCreateKOL from "./create";
  import { useCallback, useEffect, useState } from "react";
  import ConditionKOL from "./condition";
  import ActionUserKOL from "./action";
import { usePermission } from "hooks/usePermission";
import { PERMISSIONS } from "constants/permissions";
import ModalEditNote from "./ModalEditNote";

  export default function UserKOLManagement() {
    const { isHasPermissionEdit } = usePermission(
      PERMISSIONS.USER_MANAGEMENT_VERIFICATION_BADGE
    );
    const labels = [
      "ACTION",
      "ID",
      "USERNAME",
      "EMAIL",
      "NOTE",
    ];
    const [isLoading, setIsLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [checkedIds, setCheckedIds] = useState([]);
    const [userKOLs, setUserKOLs] = useState([]);
    const [totalPage, setTotalPage] = useState(0);
    const [dataDetail, setDataDetail] = useState(null);
    const [params, setParams] = useState({
      page: 1,
      limit: 10,
    });
    const {
      isOpen: isOpenCreate,
      onOpen: onOpenCreate,
      onClose: onCloseCreate,
    } = useDisclosure();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const handleOpenEdit = (data = null) => {
      setDataDetail(data);
      onOpen();
    };

    const handleClose = () => {
      onClose();
      setDataDetail(null);
    };

    const toast = useToast();

    const getUserKOLs = useCallback(
      async (newParams = {}) => {
        try {
          setIsLoading(true);
          setCheckedIds([]);
          const { data } = await getListUserKOL({
            ...params,
            ...newParams,
          });
          if (data?.success) {
            const res = data?.data;
            setUsers(res?.records);
            setTotalPage(res?.total_page);
          }
        } catch (error) {
          toast({
            description: error?.message || error?.messages[0],
            status: "error",
            position: "bottom-right",
          });
        } finally {
          setIsLoading(false);
        }
      },
      [params]
    );

    const handleChangePage = (page) => {
      setParams({
        ...params,
        page,
      });
    };

    const onChangeChecked = (isChecked) => {
      if (isChecked) {
        setCheckedIds(users.map(user => user.id));
      } else {
        setCheckedIds([]);
      }
    };

    const onChangeLimit = (limit) => {
      setParams({
        ...params,
        page: 1,
        limit,
      });
    };

    useEffect(() => {
      getUserKOLs();
    }, [getUserKOLs]);

    const handleOpenCreate = () => {
      onOpenCreate();
    };

    const handleUpdate = async (data) => {
      try {
        const res = await updateUserKOL(data.id, data);
        if (res?.data?.success) {
          toast({
            title: "User KOL update success",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
          await getUserKOLs();
        } else {
          toast({
            description: res?.data?.messages[0] || "Update fail!",
            status: "error",
            position: "bottom-right",
          });
        }
      } catch (error) {
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      }
    }

    const handleCloseCreate = () => {
      onCloseCreate();
      setUserKOLs([])
    };

    const handleCreateSuccess = useCallback(() => {
      handleCloseCreate();
      handleClose()
      setParams({
        ...params,
        page: 1,
      });
    }, [params]);

    return (
      <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
        <Card px="24px">
        <CardHeader mb="32px">
          <ConditionKOL
            isLoading={isLoading}
            setParams={setParams}
            params={params}
          />
        </CardHeader>
        <CardHeader mb="12px">
          <Flex
            w="100%"
            gap={4}
            direction={{
              md: "row",
            }}
            justifyContent="flex-end"
            alignItems="center"
          >
            <Flex
              w="100%"
              justifyContent="flex-end"
              alignItems="flex-start"
            >
              {isHasPermissionEdit && (
                <ActionUserKOL
                  handleOpenCreate={() => handleOpenCreate()}
                  checkedIds={checkedIds}
                  setCheckedIds={setCheckedIds}
                  setParams={setParams}
                  params={params}
                />
              )}
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody overflowX="auto">
          <TableCustom
            labels={labels}
            isLoading={isLoading}
            isCheck
            isChecked={users?.length > 0 && checkedIds.length === users?.length}
            onChangeChecked={onChangeChecked}
            dataRow={users}
          >
            <>
              {users?.map((item) => {
                return (
                  <UserKOLRow
                    key={item?.id}
                    handleUpdate={handleUpdate}
                    checkedIds={checkedIds}
                    setCheckedIds={setCheckedIds}
                    item={item}
                    isHasPermissionEdit={isHasPermissionEdit}
                    handleOpenEdit={handleOpenEdit}
                  />
                );
              })}
            </>
            </TableCustom>
        </CardBody>
        <Paginate
            page={params?.page}
            pageCount={totalPage}
            limit={params.limit}
            onChangeLimit={onChangeLimit}
            handleChangePage={handleChangePage}
        />
        </Card>
        {isOpenCreate && (
          <FormCreateKOL
            isOpen={isOpenCreate}
            onClose={handleCloseCreate}
            handleCreateSuccess={handleCreateSuccess}
            userKOLs={userKOLs}
            setUserKOLs={setUserKOLs}
          />
        )}
        {isOpen && (
          <ModalEditNote
            isOpen={isOpen}
            onClose={handleClose}
            dataDetail={dataDetail}
            handleCreateOrUpdateSuccess={handleCreateSuccess}
          />
        )}
      </Flex>
    );
  }
