import http from "./http";
import END_POINT from "./constants";
import { convertParams, omitParams } from "utils/object";

export const getXpRewardGameMode = (p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.XP_REWARD_GAME_MODE}?${params}`);
};

export const updateXpRewardGameMode = (idEdit, data, option = {}) => {
  return http.put(`${END_POINT.XP_REWARD_GAME_MODE}/${idEdit}`, data, option);
};

export const getXpRewardHunter = (p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.XP_REWARD_HUNTER}?${params}`);
};

export const updateXpRewardHunter = (idEdit, data, option = {}) => {
  return http.put(`${END_POINT.XP_REWARD_HUNTER}/${idEdit}`, data, option);
};
