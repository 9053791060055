import {
  Flex,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import Paginate from "components/Paginate";
import TableCustom from "components/Tables/CustomTable";
import { useCallback, useEffect, useState } from "react";
import AdminManagementRow from "./row";
import ActionAdminManagement from "./action";
import AdminManagementCreateAndUpdate from "./createAndUpdate/createAndUpdateRule";
import { getAdminsApi } from "api/admin.api";
import { getUserInfo } from "api/auth.api";
import { setLocalStorage } from "utils/storage";
import { StorageKeys } from "constants/enum";
import { usePermission } from "hooks/usePermission";
import { PERMISSIONS } from "constants/permissions";
import { useDispatch } from "react-redux";
import { updateUserinfo } from "stores/user/userReducer";

export default function AdminManagement(props) {
  const dispatch = useDispatch();
  const { userinfo, isHasPermissionEdit } = usePermission(
    PERMISSIONS.ADMIN_ACCOUNT_LIST
  );
  const labels = [ "ACTION", "#", "USERNAME", "EMAIL", "ROLE"];
  const [isLoading, setIsLoading] = useState(false);
  const [admins, setAdmins] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
  });
  const [dataDetail, setDataDetail] = useState(null);
  const textColor = useColorModeValue("#000000", "white");
  const toast = useToast();

  const {
    isOpen: isOpenCreateAndEdit,
    onOpen: onOpenCreateAndEdit,
    onClose: onCloseCreateAndEdit,
  } = useDisclosure();

  const getAdmins = useCallback(
    async (newParams = {}) => {
      try {
        setIsLoading(true);
        const { data } = await getAdminsApi({
          ...params,
          ...newParams,
        });
        if (data?.success) {
          const res = data?.data;
          setAdmins(res?.records);
          setTotalPage(res?.total_page);
        }
      } catch (error) {
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      } finally {
        setIsLoading(false);
      }
    },
    [params]
  );
  // create and update modal

  const handleOpenEditModal = (item) => {
    setDataDetail(item);
    onOpenCreateAndEdit(true);
  };

  const handleCloseEditModal = () => {
    setDataDetail(null);
    onCloseCreateAndEdit();
  };

  const handleCreateOrUpdateSuccess = useCallback(() => {
    getInfoUser();
    handleCloseEditModal();
    setParams({
      ...params,
      page: 1,
    });
  }, [params]);

  const handleChangePage = (page) => {
    setParams({
      ...params,
      page,
    });
  };

  const onChangeLimit = (limit) => {
    setParams({
      ...params,
      page: 1,
      limit,
    });
  };

  const getInfoUser = async () => {
    const res = await getUserInfo();
    let user = { ...res?.data?.data };
    user.create_time = Number.parseFloat(user?.create_time || 0) || null;
    user.update_time = Number.parseFloat(user?.update_time || 0) || null;
    // If change role data
    if (user?.role && JSON.stringify(user?.role) !== JSON.stringify(userinfo?.role)) {
      dispatch(updateUserinfo(user));
      setLocalStorage(StorageKeys.USERINFO, JSON.stringify(user));
    }
  };

  useEffect(() => {
    getAdmins();
    getInfoUser();
  }, [getAdmins]);

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <Card px="0px">
        <CardHeader px="22px" mb="12px">
          <Flex
            w="100%"
            gap={4}
            direction={{
              base: "column",
              md: "row",
            }}
            justifyContent={{
              base: "flex-start",
              md: "space-between",
            }}
            alignItems="flex-start"
          >
            <Text color={textColor} fontSize="lg" fontWeight="bold">
              Admin Management
            </Text>
            {isHasPermissionEdit && (
              <ActionAdminManagement
                handleOpenCreate={() => {
                  setDataDetail(null);
                  onOpenCreateAndEdit();
                }}
                setParams={setParams}
                params={params}
              />
            )}
          </Flex>
        </CardHeader>

        <CardBody overflowX="auto">
          <TableCustom
            labels={labels}
            isLoading={isLoading}
            isCheck={false}
            dataRow={admins}
          >
            <>
              {admins?.map((item, index) => {
                return (
                  <AdminManagementRow
                    key={item?.id}
                    handleOpenEditModal={handleOpenEditModal}
                    getAdmins={getAdmins}
                    item={item}
                    index={(params?.page - 1) * params?.limit + index + 1}
                    isHasPermissionEdit={isHasPermissionEdit}
                  />
                );
              })}
            </>
          </TableCustom>
        </CardBody>
        <Paginate
          page={params?.page}
          pageCount={totalPage}
          limit={params.limit}
          onChangeLimit={onChangeLimit}
          handleChangePage={handleChangePage}
        />
      </Card>
      {isOpenCreateAndEdit && (
        <AdminManagementCreateAndUpdate
          isOpen={isOpenCreateAndEdit}
          onClose={onCloseCreateAndEdit}
          handleCreateOrUpdateSuccess={handleCreateOrUpdateSuccess}
          dataDetail={dataDetail}
          isHasPermissionEdit={isHasPermissionEdit}
        />
      )}
    </Flex>
  );
}
