import { Flex, Icon, Td, Text, Tr } from "@chakra-ui/react";
import { EditIcon } from "components/Icons/EditIcon";
import { CheckIcon } from "components/Icons/CheckIcon";
import { useState } from "react";
import FormInput from "components/form/FormInput";
import schemaCumulativeSetting from "./schema";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { RARITY } from "views/PrizeSettings/constant";

export default function LifeGaugeRow ({
  cumulative,
  onSave,
  isHasPermissionEdit,
}) {
  const [isEdit, setIsEdit] = useState(false);
  const form = useForm({
    resolver: yupResolver(schemaCumulativeSetting()),
    defaultValues: {
      max_life_gauge: cumulative ? cumulative?.max_life_gauge : "",
      bcoin_lg_recovery_value: cumulative ? cumulative?.bcoin_lg_recovery_value : "",
      sphere_lg_recovery_value: cumulative ? cumulative?.sphere_lg_recovery_value : "",
      max_lg_restoration_limit: cumulative ? cumulative?.max_lg_restoration_limit : "",
    }
  });
  const {
    setValue,
    handleSubmit,
  } = form;
  const onSubmit = form.handleSubmit((data) => {
    if (onSave) {
      onSave(cumulative?.id, data);
    }
    setIsEdit(false);
  });

  const exceptThisSymbols = ["e", "E", "+", "-", "."];

  return (
    <>
      {isEdit ? (
        <FormProvider {...form}>
          <Tr>
            <Td width="10%">
              <Flex gap="10px" justifyContent="center">
                <Icon
                  fontSize="30px"
                  borderRadius="8px"
                  cursor="pointer"
                  bg="#07CF84"
                  as={CheckIcon}
                  onClick={onSubmit}
                />
              </Flex>
            </Td>
            <Td
              width="20%"
              fontSize="sm"
              fontWeight={400}
              whiteSpace="nowrap"
            >
              {RARITY[cumulative?.id]}
            </Td>
            <Td>
              <FormInput
                label=""
                name="max_life_gauge"
                type="number"
                placeholder="Enter number rate"
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                  setValue("max_life_gauge", e.target.value.replace(/[^0-9]/g, ""));
                }}
              />
            </Td>
            <Td>
              <FormInput
                label=""
                name="bcoin_lg_recovery_value"
                type="number"
                placeholder="Enter number rate"
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                  setValue("bcoin_lg_recovery_value", e.target.value.replace(/[^0-9]/g, ""));
                }}
              />
            </Td>
            
            <Td>
              <FormInput
                label=""
                name="sphere_lg_recovery_value"
                type="number"
                placeholder="Enter number rate"
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                  setValue("sphere_lg_recovery_value", e.target.value.replace(/[^0-9]/g, ""));
                }}
              />
            </Td>
            <Td>
              <FormInput
                label=""
                name="max_lg_restoration_limit"
                type="number"
                placeholder="Enter number rate"
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                  setValue("max_lg_restoration_limit", e.target.value.replace(/[^0-9]/g, ""));
                }}
              />
            </Td>
          </Tr>
        </FormProvider>
      ) : (
        <Tr>
          <Td width="10%">
            <Flex gap="10px" justifyContent="center">
            {isHasPermissionEdit && (
              <Icon
                fontSize="30px"
                borderRadius="8px"
                p="7px"
                cursor="pointer"
                bg="#1480FF"
                as={EditIcon}
                onClick={() => setIsEdit(true)}
              />
            )}
            </Flex>
          </Td>
          <Td
            width="20%"
            fontSize="sm"
            fontWeight={400}
            whiteSpace="nowrap"
          >
            {RARITY[cumulative?.id]}
          </Td>
          <Td
            width="35%"
            fontSize="sm"
            fontWeight={400}
            whiteSpace="nowrap"
          >
            <Text>
              {cumulative?.max_life_gauge ? `${cumulative?.max_life_gauge}` : "NULL"}
            </Text>
          </Td>
          <Td
            width="35%"
            fontSize="sm"
            fontWeight={400}
            whiteSpace="nowrap"
          >
            <Text>
              {cumulative?.bcoin_lg_recovery_value ? `${cumulative?.bcoin_lg_recovery_value}` : "NULL"}
            </Text>
          </Td>
          
          <Td
            width="35%"
            fontSize="sm"
            fontWeight={400}
            whiteSpace="nowrap"
          >
            <Text>
              {cumulative?.sphere_lg_recovery_value ? `${cumulative?.sphere_lg_recovery_value}` : "NULL"}
            </Text>
          </Td>
          <Td
            width="35%"
            fontSize="sm"
            fontWeight={400}
            whiteSpace="nowrap"
          >
            <Text>
              {cumulative?.max_lg_restoration_limit ? `${cumulative?.max_lg_restoration_limit}` : "NULL"}
            </Text>
          </Td>
        </Tr>
      )}
    </>
  );
}
