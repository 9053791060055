import React, { useMemo } from "react";
import { Flex, VStack, Text, useColorModeValue, Grid, Box, Image } from "@chakra-ui/react";
import ModalBase from "components/Modal/ModalBase";
import ButtonType from "components/Button";

export default function DetailRoomItem({ item, isOpen, onClose }) {
  const textColor = useColorModeValue("gray.700", "white");

  const countCountries = useMemo(() => {
    if (item?.country_targets?.length) {
      return item?.country_targets?.length;
    }
    const countries = JSON.parse(item?.licensor?.country_targets);
    return countries?.length;
  }, [item]);

  return (
    <ModalBase
      isOpen={isOpen}
      onClose={onClose}
      showBtn={false}
      titleHeader={item?.name}
      isShowFooter={false}
    >
      <VStack spacing={6} py={4} align="stretch">
        <VStack spacing={4} align="stretch">
        <Grid templateColumns="1fr 1fr" gap={4}>
          <Box
            position="relative"
            backgroundPosition="center"
            backgroundRepeat="no-repeat"
            backgroundSize="contain"
            borderRadius="md"
            overflow="hidden"
          >
            <Image
              src={item?.image_url}
              alt={item?.nft_information?.name}
              objectFit="contain"
              w="60%"
              h="100%"
              position="absolute"
              top="50%"
              left="50%"
              transform="translate(-50%, -50%)"
            />
          </Box>
          <VStack align="start">
            <Text
              fontWeight="bold"
              color={"blue.500"}
            >
              {item?.name}
            </Text>
            <Text fontWeight="bold" color={textColor}>
              Licensor:{" "}
              <Text
                as="span"
                color={"blue.500"}
              >
                {item?.licensor?.name || "--"}
              </Text>
            </Text>
            <Text fontWeight="bold" color={textColor}>
              Project:{" "}
              <Text
                as="span"
                color={"blue.500"}
              >
                {item?.licensor?.project || "--"}
              </Text>
            </Text>
            <Text fontWeight="bold" color={textColor}>
              Count:{" "}
              <Text
                as="span"
                color={"red.500"}
              >
                {item?.count}
              </Text>
            </Text>
            <Text fontWeight="bold" color={textColor}>
              Available in:{" "}
              <Text
                as="span"
                color={"red.500"}
              >
                {countCountries || 0} {" "}
              </Text>
              <Text
                as="span"
                color={"black"}
              >
                countries
              </Text>
            </Text>
            <Text fontWeight="bold" color={textColor}>
              Allocation:{" "}
              <Text
                as="span"
                color={"green.500"}
              >
                {item?.allocation}
              </Text>
            </Text>
          </VStack>
        </Grid>
      </VStack>
        <Flex justifyContent="center">
          <ButtonType
            w="100px"
            mt={6}
            type="button"
            text="OK"
            colorScheme="blue"
            onClick={onClose}
          />
        </Flex>
      </VStack>
    </ModalBase>
  );
}
