import {
  Flex,
  Icon,
  Td,
  Text,
  Tooltip,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import { EditIcon } from "components/Icons/EditIcon";
import { ViewIcon } from "components/Icons/ViewIcon";
import { MACHINE_CONTACT_CATEGORIES, STATUS } from "views/Contact/constants";
import { convertDateLocalLLLL } from "utils/time";
import { useMemo } from "react";
import CheckBoxType from "components/Checkbox";

const ContactRow = ({
  contact,
  handleSetDataDetail,
  handleOpenCreateOrUpdate,
  isHasPermissionEdit,
  checkedIds,
  setCheckedIds,
}) => {
  const textColor = useColorModeValue("#000000", "white");
  
  const isChecked = useMemo(() => {
    return checkedIds.some((itemCheck) => itemCheck?.id === contact?.id);
  }, [contact?.id, checkedIds]);

  const handleCheckboxChange = () => {
    if (isChecked) {
      setCheckedIds(
        checkedIds.filter((itemCheck) => itemCheck?.id !== contact?.id)
      );
    } else {
      setCheckedIds([...checkedIds, contact]);
    }
  };

  return (
    <Tr>
      <Td width="10px">
        <CheckBoxType
          isChecked={isChecked}
          onChange={handleCheckboxChange}
          colorScheme="teal"
        />
      </Td>
      <Td>
        <Flex gap={6} align="center">
          {/* <Icon
            onClick={() => handleSetDataDetail()}
            fontSize="16px"
            cursor="pointer"
            color={textColor}
            as={ViewIcon}
          /> */}
          <Icon
            onClick={() => handleSetDataDetail()}
            fontSize="30px"
            borderRadius="8px"
            cursor="pointer"
            as={ViewIcon}
          />
          {isHasPermissionEdit && (
            <Icon
              onClick={() => handleOpenCreateOrUpdate()}
              fontSize="30px"
              borderRadius="8px"
              p="7px"
              cursor="pointer"
              bg="#1480FF"
              as={EditIcon}
            />
          )}
          {/* <Icon
            onClick={() => handleOpenCreateOrUpdate()}
            fontSize="16px"
            cursor="pointer"
            color={textColor}
            as={MdEdit}
          /> */}
        </Flex>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {contact?.id}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {contact?.mac}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {MACHINE_CONTACT_CATEGORIES[contact?.machine_contact_categories_id]}
        </Text>
      </Td>
      <Td>
        <Tooltip label={contact?.message}>
          <Text color={textColor} fontSize="sm" fontWeight={400} width="200px" isTruncated>
            {contact?.message}
          </Text>
        </Tooltip>
      </Td>
      <Td>
        <Tooltip label={contact?.note}>
          <Text color={textColor} fontSize="sm" fontWeight={400} width="200px" isTruncated>
            {contact?.note}
          </Text>
        </Tooltip>
      </Td>
      <Td>
        <Text
          color={textColor}
          fontSize="sm"
          fontWeight={400}
          whiteSpace="nowrap"
        >
          {convertDateLocalLLLL(contact?.create_time * 1000)}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {STATUS[contact?.status]}
        </Text>
      </Td>
    </Tr>
  );
};

export default ContactRow;
