import * as yup from "yup";

const schemaXpRewardGameMode = () =>
  yup.object().shape({
    base: yup
      .number()
      .min(0, "Number of base must be greater or equal to 0")
      .nullable(true)
      .max(9999999, "Number of base must be at most 7 characters")
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : 0;
        }
        return 0;
      })
      .integer("Must be an integer"),
    win_bonus: yup
      .number()
      .min(0, "Number of win bonus must be greater or equal to 0")
      .nullable(true)
      .max(9999999, "Number of win bonus must be at most 7 characters")
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : 0;
        }
        return 0;
      })
      .integer("Must be an integer"),
  });

export default schemaXpRewardGameMode;
