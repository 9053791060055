import { Box, Flex, Grid, Text, useColorModeValue, useToast } from '@chakra-ui/react';
import { getTreasureBoxConfig, updateTreasureBoxConfig } from 'api/treasureBox.api';
import ButtonType from 'components/Button';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import FormInput from 'components/form/FormInput';
import useWithToast from 'hooks/useWithToast';
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form';

export default function FormCommonSetting({ isHasPermissionEdit }) {
  const [isEdit, setIsEdit] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const textColor = useColorModeValue("#000000", "white");
  const { showToastSuccess } = useWithToast();

  const form = useForm({
    defaultValues: {},
  });

  const {
    handleSubmit,
    setValue,
    reset,
    formState: { isSubmitting },
  } = form;

  const getDataTreasureBoxConfig = async(id) => {
    try {
      setIsLoading(true);
      const { data } = await getTreasureBoxConfig();
      if (data?.success) {
        reset({
          lock_duration: data?.data?.lock_duration,
          lock_duration_before_linear_distribution: data?.data?.lock_duration_before_linear_distribution,
          lock_percentage: data?.data?.lock_percentage,
        });
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = async (data) => {
    setIsEdit(false)
    const dataSubmit = {
      "lock_percentage": data.lock_percentage,
      "lock_duration":  data.lock_duration,
      "lock_duration_before_linear_distribution":  data.lock_duration_before_linear_distribution,
    }
    try {
      const res = await updateTreasureBoxConfig(dataSubmit)
      if (res?.status === 200) {
        showToastSuccess({
          title: "Update common settings successfully!",
        });
        setIsEdit(false)
      } else {
        toast({
          description: res?.data?.message || res?.data?.messages[0],
          status: 'error',
          position: 'bottom-right',
        })
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  useEffect(() => {
    getDataTreasureBoxConfig()
  }, [])

  const renderForm = () => {
    return (
      <FormProvider {...form}>
        <form id="common-setting" onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
          <Grid templateColumns="repeat(1, 1fr)" gap={4}>
            <Flex alignItems="center" gap="20px" w="100%">
              <FormInput
                wrapperProps={{
                  w: "100%"
                }}
                type="number"
                label="Lock period (day(s))"
                placeholder="Enter a number"
                name="lock_duration"
                disabled={!isEdit}
              />
              <FormInput
                wrapperProps={{
                  w: "100%"
                }}
                type="number"
                label="Distribution completed in (day(s))"
                placeholder="Enter a number"
                name="lock_duration_before_linear_distribution"
                disabled={!isEdit}
              />
            </Flex>
            <Flex alignItems="center" gap="20px" w="100%">
              <FormInput
                wrapperProps={{
                  w: "100%"
                }}
                type="number"
                label="Lock percentage (%)"
                placeholder="Enter a number"
                name="lock_percentage"
                disabled={!isEdit}
              />
              <Box width="100%"></Box>
            </Flex>
          </Grid>
        </form>
      </FormProvider>
    );
  };


  return (
    <Card px="0px">
      <CardHeader px="22px" mb="12px">
        <Flex
          w="100%"
          gap={4}
          justifyContent="space-between"
          alignItems="center"
        >
          <Text color={textColor} fontSize="lg" fontWeight="bold">
            Common Settings
          </Text>
          {isHasPermissionEdit && (
            <>
              {isEdit ? (
                <ButtonType
                  type="submit"
                  text="Save"
                  fontSize="16px"
                  fontWeight="500"
                  isLoading={isSubmitting}
                  form="common-setting"
                />
              ) : (
                <ButtonType
                  text="Edit"
                  fontSize="16px"
                  fontWeight="500"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsEdit(true);
                  }}
                />
              )}
            </>
          )}
        </Flex>
      </CardHeader>
      <CardBody p="24px" pt="0">{renderForm()}</CardBody>
    </Card>
  )
}
