import React, { useEffect, useState } from "react";
import {
  Flex,
  Text,
  Box,
  Image,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import { capitalize, startCase } from "lodash";
import { NFT_MANAGEMENT_TYPE } from "views/NFTsManagement/constants";
import ICON_POL from "assets/img/card/matic.png";
import ICON_DM2 from "assets/img/card/dm2c_icon.png";
import ICON_IMMUTABLE from "assets/img/card/passport_icon.svg";
import ICON_BNB from "assets/img/card/bnb_icon.svg";
import ICON_PINK_NFT from "assets/svg/pink-nfts-icon.svg";
import ICON_GREEN_NFT from "assets/svg/green-nft-icon.svg";
import ICON_PURPLE_NFT from "assets/svg/purple-nfts-icon.svg";
import { useSelector } from "react-redux";
import { nftsSelector } from "stores/nfts/selector";
import { countNumberArray } from "utils/number";
import NFTsOwnerSummaryPanel from "./NFTsOwnerSummaryPanel";
import { formatTimestampToMonthYear } from "utils/time";

const INIT_SUMMARY_DATA = {
  summary: {
    total: 0,
    admin: 0,
    user: 0,
  },
  chainData: [
    { name: "Immutable", icon: ICON_IMMUTABLE, count: 0 },
    { name: "Oasys", icon: ICON_DM2, count: 0 },
    // { name: "Polygon", icon: ICON_POL, count: 0 },
    { name: "BNB", icon: ICON_BNB, count: 0 },
  ],
};

const INIT_SUMMARY_GENESIS_DATA = {
  summary: {
    total_nfts: 0,
    total_nfts_sold: 0,
  },
  chainData: [
    { name: "Immutable", icon: ICON_IMMUTABLE, count: 0, subCount: 0 },
    { name: "Oasys", icon: ICON_DM2, count: 0, subCount: 0 },
    { name: "Polygon", icon: ICON_POL, count: 0, subCount: 0 },
    { name: "BNB", icon: ICON_BNB, count: 0, subCount: 0 },
  ],
};

const INIT_SUMMARY_OWNER_DATA = {
  summary: {
    current_month: 0,
    active_machine: 0,
    revenue: "Processing...",
  },
  chainData: [
    { name: "NFTs", count: 0, sold: 0 },
    { icon: ICON_PINK_NFT, count: 0, sold: 0 },
    { icon: ICON_GREEN_NFT, count: 0, sold: 0 },
    { icon: ICON_PURPLE_NFT, count: 0, sold: 0 },
  ],
};

const SUMMARY_BY_NFT_TYPE = {
  [NFT_MANAGEMENT_TYPE.GENESIS]: INIT_SUMMARY_GENESIS_DATA,
  [NFT_MANAGEMENT_TYPE.OWNER]: INIT_SUMMARY_OWNER_DATA,
};

const NFTsSummaryPanel = ({ nftMngType }) => {
  const { summaryData } = useSelector(nftsSelector);
  const textColor = useColorModeValue("#000000", "white");
  const bgColor = useColorModeValue("white", "gray.700");
  const itemBgColor = useColorModeValue("gray.50", "gray.600");
  const shadowColor = useColorModeValue(
    "rgba(0, 0, 0, 0.1)",
    "rgba(255, 255, 255, 0.1)"
  );

  const [summaryByNftType, setSummaryByNftType] = useState(
    SUMMARY_BY_NFT_TYPE[nftMngType] || INIT_SUMMARY_DATA
  );

  const renderSummaryItem = (key, value) => (
    <Box
      key={key}
      p={4}
      bg={itemBgColor}
      borderRadius="md"
      boxShadow={`0 0 10px ${shadowColor}`}
      transition="all 0.3s"
      _hover={{
        transform: "translateY(-2px)",
        boxShadow: `0 0 15px ${shadowColor}`,
      }}
    >
      <Text
        fontSize="md"
        fontWeight="bold"
        color={getItemColor(key, nftMngType)}
      >
        {capitalize(startCase(key))}
      </Text>
      <Text fontSize="xl" fontWeight="bold" mt={2}>
        {value || 0}
      </Text>
    </Box>
  );

  useEffect(() => {
    if (summaryData && Object.keys(summaryData).length) {
      switch (nftMngType) {
        case NFT_MANAGEMENT_TYPE.HUNTER:
          setSummaryByNftType({
            summary: {
              total: summaryData?.total,
              admin: summaryData?.total_of_admin,
              user: summaryData?.total_of_user,
            },
            chainData: [
              {
                name: "Immutable",
                icon: ICON_IMMUTABLE,
                count: summaryData?.imx_hunter,
              },
              { name: "Oasys", icon: ICON_DM2, count: summaryData?.dm2_hunter },
              // {
              //   name: "Polygon",
              //   icon: ICON_POL,
              //   count: summaryData?.polygon_hunter,
              // },
              // { name: "BNB", icon: ICON_BNB, count: summaryData?.bnb_hunter },
            ],
          });
          break;

        case NFT_MANAGEMENT_TYPE.GAUNTLET:
          setSummaryByNftType({
            summary: {
              total: summaryData?.total,
              admin: summaryData?.total_of_admin,
              user: summaryData?.total_of_user,
            },
            chainData: [
              {
                name: "Immutable",
                icon: ICON_IMMUTABLE,
                count: summaryData?.imx_gauntlet,
              },
              { name: "Oasys", icon: ICON_DM2, count: summaryData?.dm2_gauntlet },
              // {
              //   name: "Polygon",
              //   icon: ICON_POL,
              //   count: summaryData?.polygon_gauntlet,
              // },
              // { name: "BNB", icon: ICON_BNB, count: summaryData?.bnb_gauntlet },
            ],
          });
          break;
        case NFT_MANAGEMENT_TYPE.BOUNTY_BALL:
          setSummaryByNftType({
            summary: {
              total: summaryData?.total,
              admin: summaryData?.total_of_admin,
              user: summaryData?.total_of_user,
            },
            chainData: [
              {
                name: "Immutable",
                icon: ICON_IMMUTABLE,
                count: summaryData?.imx_bounty_ball,
              },
              {
                name: "Oasys",
                icon: ICON_DM2,
                count: summaryData?.dm2_bounty_ball,
              },
              // {
              //   name: "Polygon",
              //   icon: ICON_POL,
              //   count: summaryData?.polygon_bounty_ball,
              // },
              // {
              //   name: "BNB",
              //   icon: ICON_BNB,
              //   count: summaryData?.bnb_bounty_ball,
              // },
            ],
          });
          break;
        case NFT_MANAGEMENT_TYPE.GENESIS:
          setSummaryByNftType({
            summary: {
              total_nfts: summaryData?.current_package,
              total_nfts_sold: summaryData?.total_package_sold,
            },
            chainData: [
              {
                name: "Immutable",
                icon: ICON_IMMUTABLE,
                count: summaryData?.current_imx_package,
                subCount: summaryData?.total_imx_package_sold,
              },
              {
                name: "Oasys",
                icon: ICON_DM2,
                count: summaryData?.current_dm2_package,
                subCount: summaryData?.total_dm2_package_sold,
              },
              {
                name: "Polygon",
                icon: ICON_POL,
                count: summaryData?.current_polygon_package,
                subCount: summaryData?.total_polygon_package_sold,
              },
            ],
          });
          break;
        case NFT_MANAGEMENT_TYPE.OWNER:
          setSummaryByNftType(() => {
            const totalNfts = countNumberArray(
              Object.values(summaryData?.nft_summary || {})
            );
            const totalSold = countNumberArray(
              Object.values(summaryData?.nft_sold || {})
            );
            const revenue_temp =`(${summaryData?.revenue_temp?.symbol || '$'}${summaryData?.revenue_temp?.amount || 0})`
            return {
              summary: {
                current_month: formatTimestampToMonthYear(
                  summaryData?.summary_month
                ),
                active_machine: summaryData?.machine_amount,
                revenue: summaryData?.revenue
                  ? `${summaryData?.revenue?.amount} ${summaryData?.revenue?.symbol} ${revenue_temp}`
                  : "Processing...",
              },
              chainData: [
                {
                  name: "NFTs",
                  count: totalNfts,
                  sold: totalSold,
                },
                {
                  icon: ICON_PINK_NFT,
                  count: summaryData?.nft_summary?.pink_nft_quantity,
                  sold: summaryData?.nft_sold?.pink_nft_quantity,
                },
                {
                  icon: ICON_GREEN_NFT,
                  count: summaryData?.nft_summary?.green_nft_quantity,
                  sold: summaryData?.nft_sold?.green_nft_quantity,
                },
                {
                  icon: ICON_PURPLE_NFT,
                  count: summaryData?.nft_summary?.purple_nft_quantity,
                  sold: summaryData?.nft_sold?.purple_nft_quantity,
                },
              ],
            };
          });
          break;
        default:
          break;
      }
    }
  }, [JSON.stringify(summaryData)]);

  const renderChainItem = ({ icon, count, subCount, name }) => (
    <Flex
      key={name}
      p={4}
      alignItems="center"
      bg={itemBgColor}
      borderRadius="md"
      boxShadow={`0 0 10px ${shadowColor}`}
      transition="all 0.3s"
      _hover={{
        transform: "translateY(-2px)",
        boxShadow: `0 0 15px ${shadowColor}`,
      }}
    >
      <Image src={icon} boxSize="40px" mr={4} alt={`${name} icon`} />
      <Box>
        <Text fontSize="sm" fontWeight="medium" color={textColor}>
          {name}
        </Text>
        <Text fontSize="xl" fontWeight="bold">
          {nftMngType === "GENESIS"
            ? `${count || 0} (${subCount || 0})`
            : `${count || 0}`}
        </Text>
      </Box>
    </Flex>
  );

  if (nftMngType === NFT_MANAGEMENT_TYPE.OWNER) {
    return <NFTsOwnerSummaryPanel summaryByNftType={summaryByNftType} />;
  }

  return (
    <Card
      px="24px"
      py="20px"
      bg={bgColor}
      boxShadow={`0 0 10px ${shadowColor}`}
      borderRadius="xl"
    >
      <Flex direction="column">
        <Text color={textColor} fontSize="xl" fontWeight="bold" mb={6}>
          Summary
        </Text>
        {nftMngType === NFT_MANAGEMENT_TYPE.GENESIS && (
          <SimpleGrid
            columns={{
              base: 1,
              md: 2,
            }}
            spacing={6}
            mb={8}
          >
            {Object.entries(summaryByNftType?.summary)?.map(([key, value]) =>
              renderSummaryItem(key, value)
            )}
          </SimpleGrid>
        )}
        <SimpleGrid
          columns={{
            base: 1,
            md: 3,
          }}
          spacing={6}
        >
          {nftMngType !== NFT_MANAGEMENT_TYPE.GENESIS && (
            <Flex
              key={name}
              p={4}
              alignItems="center"
              bg={itemBgColor}
              borderRadius="md"
              boxShadow={`0 0 10px ${shadowColor}`}
              transition="all 0.3s"
              _hover={{
                transform: "translateY(-2px)",
                boxShadow: `0 0 15px ${shadowColor}`,
              }}
            >
              <Box>
                <Text
                  fontSize="md"
                  fontWeight="bold"
                  color={getItemColor("total", nftMngType)}
                >
                  Total
                </Text>
                <Text fontSize="xl" fontWeight="bold" mt={2}>
                  {summaryByNftType?.summary?.admin || 0}
                </Text>
              </Box>
            </Flex>
          )}
          {summaryByNftType?.chainData?.map(renderChainItem)}
        </SimpleGrid>
      </Flex>
    </Card>
  );
};

const getItemColor = (key, nftMngType) => {
  if (nftMngType === "GENESIS") {
    return key === "total_nfts"
      ? "blue.500"
      : key === "total_nfts_sold"
      ? "red.500"
      : "inherit";
  }
  return key === "total" ? "blue.500" : key === "admin" ? "red.500" : "inherit";
};

export default NFTsSummaryPanel;
