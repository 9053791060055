import {
  Center,
  Flex,
  Icon,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { useCallback, useEffect, useState } from "react";
import ConditionGachaManagement from "./condition";
import DetailGacha from "./detail";
import ButtonType from "components/Button";
import { NoDataTableIcon } from "components/Icons/NoDataTableIcon";
import CreateAndUpdate from "./CreateAndUpdate";
import { getListGacha, getListStatusGacha } from "api/gacha.api";
import ModalDelete from "./ModalDelete";
import ModalRewardContainer from "./ModalRewardContainer";
import ModalHistoryGacha from "./ModalHistoryGacha";
import { LIST_STATUS_GACHA } from "../constants";
import { usePermission } from "hooks/usePermission";
import { PERMISSIONS } from "constants/permissions";

export default function GachaManagement() {
  const { isHasPermissionEdit } = usePermission(PERMISSIONS.GACHA_MANAGEMENT);
  const [isLoading, setIsLoading] = useState(false);
  const [listGacha, setListGacha] = useState([]);
  const [statusGacha, setStatusGacha] = useState([]) 
  const [params, setParams] = useState({
    page: 1,
    limit: 999,
  });
  const [dataDetail, setDataDetail] = useState(null);
  const toast = useToast();
  const [modal, setModal] = useState({
    createAndEdit: false,
    modalDelete: false,
    rewardContainer: false,
    modalHistory: false,
  })

	const textColor = useColorModeValue("#000000", "white");

  const toggle = (type, data) => {
    setModal(prev => ({ ...prev, [type]: !prev[type] }))
    setDataDetail(data)
  }

  const getGachas = useCallback(
    async (newParams = {}) => {
      try {
        setIsLoading(true);
        const { data } = await getListGacha({
          ...params,
          ...newParams,
        });
        if (data?.success) {
          const res = data?.data;
          setListGacha(res?.records);
        }
      } catch (error) {
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      } finally {
        setIsLoading(false);
      }
    },
    [params]
  );

  useEffect(() => {
    getGachas();
  }, [getGachas]);

  const handleCreateOrUpdateSuccess = useCallback(() => {
    setParams({
      ...params,
      page: 1,
    });
  }, [params]);


  const getListStatus = useCallback(
		async () => {
			try {
				setIsLoading(true);
				const { data } = await getListStatusGacha();
				if (data?.success) {
					const res = data?.data?.map((type) => ({
						label: LIST_STATUS_GACHA[type],
						value: type
					}));
					setStatusGacha(res);
				}
			} catch (error) {
				toast({
					description: error?.message || error?.messages[0],
					status: "error",
					position: "bottom-right",
				});
			} finally {
				setIsLoading(false);
			}
		},
		[]
	);

	useEffect(() => {
		getListStatus()
	}, [])

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <Card px="24px">
        <CardHeader mb="12px" display="flex" flexDirection="column" gap="20px">
					<Flex
						w="100%"
						gap={4}
						direction={{
							base: "column",
							md: "row",
						}}
						justifyContent={{
							base: "flex-start",
							md: "space-between",
						}}
						alignItems="flex-start"
					>
						<Text
							color={textColor}
							fontSize="lg"
							fontWeight="bold"
							whiteSpace="nowrap"
						>
							GACHA MANAGEMENT
						</Text>
					</Flex>
				</CardHeader>
        <CardHeader mb="32px">
          <Flex
            w="100%"
            gap={4}
            direction={{
              md: "row",
            }}
            justifyContent="space-between"
            alignItems="flex-end"
            flexWrap='wrap'
          >
            <ConditionGachaManagement
              isLoading={isLoading}
              setParams={setParams}
              params={params}
              statusGacha={statusGacha}
            />
            <Flex gap={4} flexWrap="wrap" alignItems="flex-end">
              {isHasPermissionEdit && (
                <Flex alignItems="flex-end" gap={6}>
                  <ButtonType
                    text="NEW"
                    w="120px"
                    fontSize="12px"
                    onClick={() => {
                      toggle("createAndEdit", null);
                    }}
                  />
                  <ButtonType
                    text="DELETE"
                    w="120px"
                    fontSize="12px"
                    btnType="primary-new-outline"
                    onClick={() => {
                      toggle("modalDelete", null);
                    }}
                  />
                </Flex>
              )}
            </Flex>
          </Flex>
        </CardHeader>
        {/* <CardHeader mb="12px">
          <Flex
            w="100%"
            gap={4}
            direction={{
              md: "row",
            }}
            justifyContent="flex-end"
            alignItems="center"
          >
            <Flex gap={4} flexWrap="wrap" alignItems="flex-end">
              <Flex alignItems="flex-end" gap={6} mb="10px">
                <ButtonType
                  text="NEW"
                  w="120px"
                  fontSize="12px"
                  onClick={() => {
                    toggle("createAndEdit", null)
                  }}
                />
                <ButtonType
                  text="DELETE"
                  w="120px"
                  fontSize="12px"
                  btnType="primary-new-outline"
                  onClick={() => {
                    toggle("modalDelete", null)
                  }}
                />
              </Flex>
            </Flex>
          </Flex>
        </CardHeader> */}
        <CardBody overflowX="auto">
          <Flex flexWrap="wrap" gap="6px" w="100%">
            {listGacha?.length > 0 ? (
              listGacha?.map((event, index) => (
                <DetailGacha 
                  item={event}
                  key={index}
                  toggle={toggle}
                  isHasPermissionEdit={isHasPermissionEdit}
                />
              ))
            ) : (
              <Flex w="100%" justifyContent="center">
                <Center w="100%" color="#000000">
                  <Icon as={NoDataTableIcon} w="250px" h="200px" mt="20px" />
                </Center>
              </Flex>
            )}
          </Flex>
        </CardBody>
      </Card>
      {modal?.createAndEdit && (
        <CreateAndUpdate
          isOpen={modal?.createAndEdit}
          onClose={() => toggle("createAndEdit", null)}
          dataDetail={dataDetail}
          handleCreateOrUpdateSuccess={handleCreateOrUpdateSuccess}
        />
      )}
      {modal.modalDelete && (
        <ModalDelete
          isOpen={modal.modalDelete}
          onClose={() => toggle("modalDelete", null)}
          handleCreateOrUpdateSuccess={handleCreateOrUpdateSuccess}
        />
      )}
      {modal.rewardContainer && (
        <ModalRewardContainer
          isOpen={modal.rewardContainer}
          onClose={() => toggle("rewardContainer", null)}
          dataDetail={dataDetail}
        />
      )}
      {modal.modalHistory && (
        <ModalHistoryGacha
          isOpen={modal.modalHistory}
          onClose={() => toggle("modalHistory", null)}
          dataDetail={dataDetail}
        />
      )}
    </Flex>
  );
}
