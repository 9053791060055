import * as yup from "yup";

const schemaMaintenance = () =>
  yup.object().shape({
    start_at: yup
      .date()
      .required(),
    end_at: yup
      .date()
      .test(
        "is-greater-start_at",
        "End time must be after start time",
        function (value) {
          const { start_at } = this.parent;
          return (
            start_at &&
            value &&
            new Date(value) > new Date(start_at)
          );
        }
      )
      .required(),
  });

export default schemaMaintenance;
