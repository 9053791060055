import { Icon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import { EditIcon } from "components/Icons/EditIcon";
import { SYSTEM_COINS_SHOW } from "constants/constants";

export default function ExchangeRow({
  item,
  handleOpenEditModal,
  index,
  isHasPermissionEdit,
}) {
  const textColor = useColorModeValue("#000000", "white");

  const setColor = (type) => {
    switch (type) {
      case "BCOIN_COIN2_LOCK":
        return (
          <Box
            w={"fit-content"}
            padding={"5px 15px"}
            backgroundColor={"#eff6ff"}
            color={"#4b9ffa"}
            border={"1px solid #4b9ffa"}
            rounded={"50px"}
          >
            BCoin2 Lock
          </Box>
        )
      case "BCOIN_COIN2_UNLOCK":
        return (
          <Box
            w={"fit-content"}
            padding={"5px 15px"}
            backgroundColor={"#fff0e1"}
            color={"#cc6719"}
            border={"1px solid #cc6719"}
            rounded={"50px"}
          >
            BCoin2 Unlock
          </Box>
        )
      case "BCOIN_COIN1_FREE":
        return (
          <Box
            w={"fit-content"}
            padding={"5px 15px"}
            backgroundColor={"#f0fdf4"}
            color={"#32ae60"}
            border={"1px solid #32ae60"}
            rounded={"50px"}
          >
            BCoin1 Free
          </Box>
        )
      case "BCOIN_COIN1_PAID":
        return (
          <Box
            w={"fit-content"}
            padding={"5px 15px"}
            backgroundColor={"#fdfcf0"}
            color={"#aea432"}
            border={"1px solid #aea432"}
            rounded={"50px"}
          >
            BCoin1 Paid
          </Box>
        )
      case "SPHERE_COIN1_FREE":
        return (
          <Box
            w={"fit-content"}
            padding={"5px 15px"}
            backgroundColor={"#e4d4ff"}
            color={"#3f06a3"}
            border={"1px solid #3f06a3"}
            rounded={"50px"}
          >
            Sphere Free
          </Box>
        )
      case "SPHERE_COIN1_PAID":
        return (
          <Box
            w={"fit-content"}
            padding={"5px 15px"}
            backgroundColor={"#d4fff8"}
            color={"#06a3a0"}
            border={"1px solid #06a3a0"}
            rounded={"50px"}
          >
            Sphere Paid
          </Box>
        )
      case "ORB_COIN1_FREE":
        return (
          <Box
            w={"fit-content"}
            padding={"5px 15px"}
            backgroundColor={"#ffe9d4"}
            color={"#a36706"}
            border={"1px solid #a36706"}
            rounded={"50px"}
          >
            Orb Free
          </Box>
        )
      case "ORB_COIN1_PAID":
        return (
          <Box
            w={"fit-content"}
            padding={"5px 15px"}
            backgroundColor={"#ffe1e8"}
            color={"#f82f20"}
            border={"1px solid #f82f20"}
            rounded={"50px"}
          >
            Orb Paid
          </Box>
        )
      default:
        break;
    }
  }

  const transactionType = (type) => {
    switch (type) {
      case "DECREASE":
        return (
          <Text color="#cc6719" fontSize="sm" fontWeight={400}>
            Consumption
          </Text>
        )
      case "INCREASE":
        return (
          <Text color="#3acc19" fontSize="sm" fontWeight={400}>
            Acquisition
          </Text>
        )
      default:
        return <></>
    }
  }

  return (
    <>
      <Tr>
        <Td>
          <Flex gap="10px">
            {isHasPermissionEdit && (
              <Icon
                onClick={() => {
                  item?.is_editable 
                  ? handleOpenEditModal(item)
                  : null;
                }}
                fontSize="30px"
                borderRadius="8px"
                p="7px"
                cursor={item?.is_editable ? "pointer" : "not-allowed"}
                bg={item?.is_editable ? "#1480FF" : "#b9d9ff"}
                as={EditIcon}
              />
            )}
          </Flex>
        </Td>
        <Td w="50px">
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {index}
          </Text>
        </Td>
        <Td>
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {item?.case_name}
          </Text>
        </Td>
        <Td>
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {SYSTEM_COINS_SHOW[item?.system_coin]}
          </Text>
        </Td>
        <Td>
          {transactionType(item?.change_type)}
        </Td>
        <Td>
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            <Flex alignItems="center" gap={2}>
              {item?.priorities?.map((coin, index) => (
                <Flex key={index} alignItems="center" gap={2}>
                  {setColor(`${item?.system_coin}_${coin}`)}
                  {item?.priorities?.length - 1 !== index && (
                    item?.change_type === "DECREASE" 
                      ? <span>{`>`}</span>
                      : <span>&</span>
                  )}
                </Flex>
              ))}
            </Flex>
          </Text>
        </Td>
      </Tr>
    </>
  );
}
