import { useCallback, useEffect, useState } from "react";
import {
Flex,
Modal,
ModalBody,
ModalCloseButton,
ModalContent,
ModalFooter,
ModalHeader,
ModalOverlay,
useColorModeValue,
useToast,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Paginate from "components/Paginate";
import TableCustom from "components/Tables/CustomTable";
import CardRow from "./cardRow";
import ButtonType from "components/Button";
import { getInvitationCard } from "api/presentBox.api";

export default function CardItem({
isOpen,
onClose,
setCheckedIds,
checkedIds,
setValue,
watch,
event
}) {
const labels = [
  "CARD NAME",
  "IMAGE",
];
const [isLoading, setIsLoading] = useState(false);
const [items, setItems] = useState([]);
const [totalPage, setTotalPage] = useState(0);
const [params, setParams] = useState({
  page: 1,
  limit: 10,
});
const toast = useToast();
const textColor = useColorModeValue("#000000", "white");

const onChangeLimit = (limit) => {
  setParams({
    ...params,
    page: 1,
    limit,
  });
};

const handleChangePage = (page) => {
  setParams({
    ...params,
    page,
  });
};

const getInvitationCards = useCallback(
  async () => {
    try {
      setIsLoading(true);
      if(event?.id) {
        const { data } = await getInvitationCard({...params, isInComingEvent: true, eventId: event.id})
        if (data?.success) {
          const res = data?.data;
          setItems(res?.records);
          setTotalPage(res?.total_page);
        }
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    } finally {
      setIsLoading(false);
    }
  },
  [params]
);

useEffect(() => {
  getInvitationCards();
}, [getInvitationCards]);

useEffect(() => {
  setCheckedIds(watch("card_items"));
}, [watch("card_items")]);

const onChangeChecked = (isChecked) => {
  if (isChecked) {
    setCheckedIds(items);
  } else {
    setCheckedIds([]);
  }
};

const handleClose = () => {
  setCheckedIds(watch("card_items") ? watch("card_items") : []);
  onClose()
}

const handleSelect = () => {
  onClose()
  setValue("card_items", checkedIds)
}

return (
  <Modal isOpen={isOpen} onClose={handleClose} closeOnOverlayClick={false} size="xxl">
    <ModalOverlay />
    <ModalContent maxW="900px" overflowY="auto">
      <ModalHeader
        borderBottom="1px solid #EEEFF2"
        w="100%"
        textAlign="center"
        margin="auto"
        color={textColor}
      >
        Select Item(s)
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody p="24px">
        <Flex flexDirection="column">
          <Card px="0px">
            <CardBody overflowX="auto">
              <TableCustom
                labels={labels}
                isLoading={isLoading}
                dataRow={items}
                isCheck
                isChecked={
                  items?.length > 0 && checkedIds.length === items?.length
                }
                onChangeChecked={onChangeChecked}
              >
                <>
                  {items?.map((item) => {
                    return (
                      <CardRow
                        key={item?.id}
                        item={item}
                        setCheckedIds={setCheckedIds}
                        checkedIds={checkedIds}
                      />
                    );
                  })}
                </>
              </TableCustom>
            </CardBody>
            <Paginate
              page={params?.page}
              pageCount={totalPage}
              limit={params.limit}
              onChangeLimit={onChangeLimit}
              handleChangePage={handleChangePage}
            />
          </Card>
        </Flex>
      </ModalBody>
      <ModalFooter
        borderTop="1px solid #EEEFF2"
        w="100%"
        textAlign="center"
        margin="auto"
        gap="20px"
      >
        <ButtonType
          mt={4}
          m="auto"
          w="160px"
          h="46px"
          borderRadius="5px"
          onClick={handleSelect}
        >
          Select
        </ButtonType>
      </ModalFooter>
    </ModalContent>
  </Modal>
);
}
