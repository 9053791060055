import { convertParams, omitParams } from "utils/object";
import END_POINT from "./constants";
import http from "./http";

export const getLicensors = (p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.LICENSORS}?${params}`);
};

export const createLicensor = (params) => {
  return http.post(`${END_POINT.LICENSORS}`, params);
};

export const updateLicensor = (id, params) => {
  return http.put(`${END_POINT.LICENSORS}/${id}`, params);
};

export const deleteLicensors = (data) => {
  return http.delete(`${END_POINT.LICENSORS}`, data);
};
