import {
  Flex,
  useToast,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import TableCustom from "components/Tables/CustomTable";
import EnergyGaugeRow from "./row";
import { useTranslation } from "react-i18next";
import { updateDataEnergyGauge } from "api/gauge.api";
import useWithToast from "hooks/useWithToast";

export default function EnergyGauge(props) {
  const { dataGauge, isLoading, getListGauge } = props
  const { t } = useTranslation("error");
  const { showToastSuccess, showToastFail } = useWithToast();
  const toast = useToast();
  const labels = [
    "Action",
    "Rarity",
    "MAX VALUE",
    "Restoration cost (sphere)",
  ];

  const handleSaveCumulative = async (id, data) => {
    try {
      const res = await updateDataEnergyGauge(id, data);
        if (res?.data?.success) {
          showToastSuccess({
            title: "Setting life gauge & energy gauge successfully!",
          });
          await getListGauge();
        } else {
          showToastFail({
            title: t(`${res?.data?.messages[0]}`) || "Create fail!",
          });
        }
      } catch (error) {
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      }
  }

  return (
    <Flex flexDirection="column" w="100%">
      <Card p="0px">
        <CardBody overflowX="auto">
          <TableCustom
            labels={labels}
            isLoading={isLoading}
            isCheck={false}
            dataRow={dataGauge}
          >
            <>
              {dataGauge?.map((item, index) => {
                return (
                  <EnergyGaugeRow
                    key={index}
                    cumulative={item}
                    onSave={handleSaveCumulative}
                    {...props}
                  />
                );
              })}
            </>
          </TableCustom>
        </CardBody>
      </Card>
    </Flex>
  );
}
