import {
	Flex,
	Text,
	useColorModeValue,
	useDisclosure,
	useToast,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import Paginate from "components/Paginate";
import TableCustom from "components/Tables/CustomTable";
import { useCallback, useEffect, useState } from "react";
import ActionNonNft from "./action";
import RowLargeReward from "./row";
import CreateAndUpdateLargeReward from "./CreateAndUpdateLargeReward";
import { getGachaItemType, getListRewardContainer } from "api/gacha.api";
import { TOKEN_TYPE_LABEL } from "constants/constants";
import { usePermission } from "hooks/usePermission";
import { PERMISSIONS } from "constants/permissions";

export default function LargeRewardContainer() {
	const { isHasPermissionEdit } = usePermission(PERMISSIONS.GACHA_MANAGEMENT);
	const [isLoading, setIsLoading] = useState(false);
	const [checkedIds, setCheckedIds] = useState([]);
	const [dataDetail, setDataDetail] = useState(null);
	const [listType, setListType] = useState([]);

	const [totalPage, setTotalPage] = useState(0);
	const [params, setParams] = useState({
		page: 1,
		limit: 10,
	});
	const toast = useToast();
	const [rewardContainer, setRewardContainer] = useState([]);

	const labels = [
		"Action",
		"Id",
		"Name",
		"Chain",
		"Small Reward Containers",
		"Weight",
	];
	const textColor = useColorModeValue("#000000", "white");

	const { isOpen, onOpen, onClose } = useDisclosure();

	const handleClose = () => {
		onClose();
		setDataDetail(null);
	};

	const handleOpenCreateOrUpdate = (data = null) => {
		setDataDetail(data);
		onOpen();
	};

	const handleCreateOrUpdateSuccess = useCallback(() => {
		handleClose();
		setDataDetail(null);
		setParams({
			...params,
			page: 1,
		});
	}, [params]);

	const getListMachine = useCallback(
		async (newParams = {}) => {
			try {
				setIsLoading(true);
				setCheckedIds([]);
				const { data } = await getListRewardContainer({
					...params,
					...newParams,
				});
				if (data?.success) {
					const res = data?.data;
					setRewardContainer(res?.records);
					setTotalPage(res?.total_page);
				}
			} catch (error) {
				toast({
					description: error?.message || error?.messages[0],
					status: "error",
					position: "bottom-right",
				});
			} finally {
				setIsLoading(false);
			}
		},
		[params]
	);

	const handleChangePage = (page) => {
		setParams({
			...params,
			page,
		});
	};

	useEffect(() => {
		getListMachine();
	}, [getListMachine]);

	const onChangeChecked = (isChecked) => {
		if (isChecked) {
			setCheckedIds(rewardContainer);
		} else {
			setCheckedIds([]);
		}
	};

	const onChangeLimit = (limit) => {
		setParams({
			...params,
			page: 1,
			limit,
		});
	};
  
	const getListTypes = useCallback(
		async () => {
			try {
				setIsLoading(true);
				const { data } = await getGachaItemType();
				if (data?.success) {
					const res = data?.data?.filter((el) => el !== "NON_NFT").map((type) => ({
						label: TOKEN_TYPE_LABEL[type],
						value: type
					}));
					setListType(res);
				}
			} catch (error) {
				toast({
					description: error?.message || error?.messages[0],
					status: "error",
					position: "bottom-right",
				});
			} finally {
				setIsLoading(false);
			}
		},
		[]
	);

	useEffect(() => {
		getListTypes()
	}, [])

	return (
		<Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
			<Card px="0px">
				<CardHeader px="24px" mb="12px" display="flex" flexDirection="column" gap="20px">
					<Flex
						w="100%"
						gap={4}
						direction={{
							base: "column",
							md: "row",
						}}
						justifyContent={{
							base: "flex-start",
							md: "space-between",
						}}
						alignItems="flex-start"
					>
						<Text
							color={textColor}
							fontSize="lg"
							fontWeight="bold"
							whiteSpace="nowrap"
						>
							LARGE REWARD CONTAINER SETUP
						</Text>
					</Flex>
				</CardHeader>
				<CardHeader px="42px" mb="32px">
					<Flex
						w="100%"
						justifyContent={{
							base: "flex-end",
						}}
					>	
						{isHasPermissionEdit && (
							<ActionNonNft
								handleOpenCreateOrUpdate={handleOpenCreateOrUpdate}
								checkedIds={checkedIds}
								setCheckedIds={setCheckedIds}
								setParams={setParams}
								params={params}
							/>
						)}
					</Flex>
				</CardHeader>
				<CardBody overflowX="auto">
					<TableCustom
						labels={labels}
						isLoading={isLoading}
						isCheck
						isChecked={
							rewardContainer?.length > 0 && checkedIds.length === rewardContainer?.length
						}
						onChangeChecked={onChangeChecked}
						dataRow={rewardContainer}
					>
						<>
							{rewardContainer?.map((item, index) => {
								return (
									<RowLargeReward
										key={item?.id}
										setCheckedIds={setCheckedIds}
										checkedIds={checkedIds}
										handleOpenCreateOrUpdate={() => {
											handleOpenCreateOrUpdate(item);
										}}
										index={(params?.page - 1) * params?.limit + index + 1}
										item={item}
										isHasPermissionEdit={isHasPermissionEdit}
									/>
								);
							})}
						</>
					</TableCustom>
				</CardBody>
				<Paginate
					page={params?.page}
					pageCount={totalPage}
					limit={params.limit}
					onChangeLimit={onChangeLimit}
					handleChangePage={handleChangePage}
				/>
			</Card>
			{isOpen && (
				<CreateAndUpdateLargeReward
					isOpen={isOpen}
					onClose={handleClose}
					dataDetail={dataDetail}
					handleCreateOrUpdateSuccess={handleCreateOrUpdateSuccess}
          listType={listType}
				/>
			)}
		</Flex>
	);
}
