import { Icon } from "@chakra-ui/icons";
import {
  Flex,
  Image,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import { EditIcon } from "components/Icons/EditIcon";
// import { DeleteIcon } from "components/Icons/DeleteIcon";

export default function TreasureBoxRow({
  item,
  handleOpenEditModal,
  listPlayFee,
  isHasPermissionEdit,
}) {
  const textColor = useColorModeValue("#000000", "white");

  return (
    <>
      <Tr>
        <Td>
          {isHasPermissionEdit && (
            <Flex gap="10px">
              <Icon
                onClick={() => {
                  handleOpenEditModal(item);
                }}
                fontSize="30px"
                borderRadius="8px"
                p="7px"
                cursor="pointer"
                bg="#1480FF"
                as={EditIcon}
              />
              {/* <Icon
                fontSize="30px"
                borderRadius="8px"
                cursor="pointer"
                as={DeleteIcon}
              /> */}
            </Flex>
          )}
        </Td>
        <Td w="50px">
          <Text color={textColor} textAlign='center' fontSize="sm" fontWeight={400}>
            {item?.treasure_box_rank}
          </Text>
        </Td>
        <Td>
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {item?.name}
          </Text>
        </Td>
        <Td>
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            <Image w="50px" lazyLoad={true} src={item?.image_closed} alt="NFT" />
          </Text>
        </Td>
        <Td>
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {listPlayFee?.filter((fee) => fee?.id === item?.play_fee_id)[0]?.name || 0}
          </Text>
        </Td>
        <Td>
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {item?.allocation_rate}
          </Text>
        </Td>
        <Td>
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {item?.opening_cost}
          </Text>
        </Td>
        <Td>
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {item?.treasure_box_up_rank_param?.none_up_rank_chance}
          </Text>
        </Td>
        <Td>
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {item?.treasure_box_up_rank_param?.up1_rank_chance}
          </Text>
        </Td>
        <Td>
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {item?.treasure_box_up_rank_param?.up2_rank_chance}
          </Text>
        </Td>
        <Td>
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {item?.treasure_box_up_rank_param?.up3_rank_chance}
          </Text>
        </Td>
        <Td>
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {item?.treasure_box_up_rank_param?.up4_rank_chance}
          </Text>
        </Td>
        <Td>
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {item?.treasure_box_up_rank_param?.up5_rank_chance}
          </Text>
        </Td>
        {item?.treasure_box_bonuses?.map((bonus, index) => (
          <>
            <Td>
              <Text color={textColor} fontSize="sm" fontWeight={400}>
                {bonus?.min_number_of_sphere} - {bonus?.max_number_of_sphere}
              </Text>
            </Td>
            <Td>
              <Text color={textColor} fontSize="sm" fontWeight={400}>
                {bonus?.sphere_appearance_rate}%
              </Text>
            </Td>
            <Td>
              <Text color={textColor} fontSize="sm" fontWeight={400}>
                {bonus?.min_number_of_orb} - {bonus?.max_number_of_orb}
              </Text>
            </Td>
            <Td>
              <Text color={textColor} fontSize="sm" fontWeight={400}>
                {bonus?.orb_appearance_rate}%
              </Text>
            </Td>
          </>
        ))}
      </Tr>
    </>
  );
}
