import END_POINT from "./constants";
import http from "./http";
import { convertParams, omitParams } from "utils/object";

export function getRateApi() {
  return http.get(`${END_POINT.EXCHANGE_RATE}`);
}

export const getListExchangeRates = (p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.EXCHANGE_RATE}?${params}`);
};

export function updateRateApi(id, data) {
  return http.put(`${END_POINT.EXCHANGE_RATE}/${id}`, data);
}

export function updateExchangeRate(id, data) {
  return http.put(`${END_POINT.EXCHANGE_RATE}/${id}`, data);
}

export function createExchangeRate(data) {
  return http.post(`${END_POINT.EXCHANGE_RATE}`, data);
}

export function getExchangeRateCurrency() {
  return http.get(`${END_POINT.EXCHANGE_RATE_CURRENCY}`);
}
