/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ChakraProvider, Portal, useDisclosure } from "@chakra-ui/react";
import "assets/css/pud-dashboard-styles.css";
// Custom components
import MainPanel from "components/Layout/MainPanel";
import PanelContainer from "components/Layout/PanelContainer";
import PanelContent from "components/Layout/PanelContent";
// Layout components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import { SidebarContext } from "context/SidebarContext";
import React, { useCallback, useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css"; // ES6
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import routes from "routes.js";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
// Custom Chakra theme
import theme from "theme/theme.js";
import { getLocalStorage } from "utils/storage";
import { StorageKeys } from "constants/enum";
import { usePermission } from "hooks/usePermission";
import { PERMISSIONS } from "constants/permissions";

export default function Dashboard(props) {
  const { ...rest } = props;
  // states and functions
  const { userinfo, handleCheckPermission } = usePermission();
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const [sidebarWidth, setSidebarWidth] = useState(275);

  const permissionViewDashboard = handleCheckPermission(PERMISSIONS.DASHBOARD);

  // ref for main panel div
  const mainPanel = React.createRef();

  // functions for changing the states from components
  const getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };

  const getActiveRoute = (routes) => {
    const defaultRoute = {
      activeRoute: "Default Brand Text",
      textRoot: "Default",
      textParent: undefined,
    };
  
    for (const route of routes) {
      // Recursively check collapsible routes.
      if (route.collapse) {
        const active = getActiveRoute(route.items);
        if (active.activeRoute !== defaultRoute.activeRoute) {
          return {
            activeRoute: active.activeRoute,
            textRoot: active?.isHiddenTextRoot ? "" : route.name,
            textParent: active.textParent,
          };
        }
      }
      // Recursively check category routes.
      else if (route.category) {
        const active = getActiveRoute(route.items);
        if (active.activeRoute !== defaultRoute.activeRoute) {
          return active;
        }
      }
      // Check for a normal route match.
      else {
        const checkPath = route.layout + (route.pathNotSlug || route.path);
        if (window.location.href.indexOf(checkPath) !== -1) {

          const isHasPermission = handleCheckPermission(route?.permission);
          if (route?.permission && !isHasPermission) {
            return {
              activeRoute: "",
              textRoot: "",
              textParent: "",
              isHiddenTextRoot: true,
            };
          }

          return {
            activeRoute: route.name,
            textRoot: route.name,
            textParent: route.nameParent,
          };
        }
      }
    }
  
    return defaultRoute;
  };

  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveNavbar = getActiveNavbar(routes[i].items);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else if (routes[i].category) {
        let categoryActiveNavbar = getActiveNavbar(routes[i].items);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].secondaryNavbar;
        }
      }
    }
    return activeNavbar;
  };

  const getRoutes = useCallback(
    (routes) => {
      return routes.map((prop, key) => {
        const isHasPermission = handleCheckPermission(prop?.permission);
        if (prop.layout === "/admin" && !prop?.permission) {
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          );
        } else if (
          prop.layout === "/admin" &&
          prop?.permission &&
          isHasPermission
        ) {
          return (
            <Route
              path={prop.layout + prop.path}
              component={() => <prop.component />}
              key={key}
            />
          );
        }
        if (prop.collapse) {
          return getRoutes(prop.items);
        }
        if (prop.category) {
          return getRoutes(prop.items);
        } else {
          return null;
        }
      });
    },
    [userinfo]
  );

  const { isOpen, onOpen, onClose } = useDisclosure();
  document.documentElement.dir = "ltr";
  document.documentElement.layout = "admin";

  let history = useHistory();

  const token = getLocalStorage(StorageKeys.TOKEN);

  useEffect(() => {
    if (!token) {
      return history.push("/auth/authentication/sign-in/cover");
    }
  }, [token]);

  return (
    <ChakraProvider theme={theme} resetCss={false} overflow="scroll">
      <SidebarContext.Provider
        value={{
          sidebarWidth,
          setSidebarWidth,
          toggleSidebar,
          setToggleSidebar,
        }}
      >
        <Sidebar
          routes={routes}
          logoText={"REMOTE CLAW"}
          display="none"
          sidebarVariant="transparent"
          {...rest}
        />
        <MainPanel
          ref={mainPanel}
          w={{
            base: "100%",
            lg: `calc(100% - ${sidebarWidth}px)`,
          }}
        >
          <Portal>
            <AdminNavbar
              onOpen={onOpen}
              logoText={"REMOTE CLAW"}
              brandText={getActiveRoute(routes)?.activeRoute}
              textRoot={getActiveRoute(routes)?.textRoot}
              textParent={getActiveRoute(routes)?.textParent}
              secondary={getActiveNavbar(routes)}
              fixed='false'
              {...rest}
            />
          </Portal>

          {getRoute() ? (
            <PanelContent>
              <PanelContainer>
                <Switch>
                  {getRoutes(routes)}
                  {permissionViewDashboard && (
                    <Redirect from="/admin" to="/admin/dashboard/default" />
                  )}
                </Switch>
              </PanelContainer>
            </PanelContent>
          ) : null}
        </MainPanel>
      </SidebarContext.Provider>
    </ChakraProvider>
  );
}
