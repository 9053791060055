import {
  Flex,
  Icon,
  Image,
  Stack,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import { RARITY_LABEL, TOKEN_TYPE_LABEL } from "constants/constants";
import { convertDateLocalLLLL } from "utils/time";
import { getChainIcon } from "views/NFTsManagement/utils/utils";
import BCoinIcon from 'assets/img/coin/bcoin.png'
import SphereIcon from 'assets/img/coin/sphere.png'
import OrbIcon from 'assets/img/coin/orb_icon.png'

const RowNftGacha = ({
  item,
}) => {
  const textColor = useColorModeValue("#000000", "white");

  const showCoinOffChain = (type) => {
    switch (type) {
      case 'BCOIN':
        return (
          <Image
            boxSize="50px"
            src={BCoinIcon}
            alt={item?.id}
          />
        )
      case 'SPHERE':
        return (
          <Image
            boxSize="50px"
            src={SphereIcon}
            alt={item?.id}
          />
        )
      case 'ORB':
        return (
          <Image
            boxSize="50px"
            src={OrbIcon}
            alt={item?.id}
          />
        )
      default:
        return (
          <Image
            boxSize="50px"
            src={BCoinIcon}
            alt={item?.id}
          />
        )
    }
  }

  return (
  <Tr>
    <Td>
      <Stack direction="row" spacing="16px">
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.id}
        </Text>
      </Stack>
    </Td>
    <Td>
      <Stack direction="row" spacing="16px">
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.gacha_name}
        </Text>
      </Stack>
    </Td>
    <Td>
      <Stack direction="row" spacing="16px">
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.user_name}
        </Text>
      </Stack>
    </Td>
    <Td>
      <Stack direction="row" spacing="16px">
        <Text color={textColor} fontSize="sm" fontWeight={400}>
            {convertDateLocalLLLL(item?.draw_date * 1000)}
          </Text>
      </Stack>
    </Td>
    <Td>
      <Stack direction="row" spacing="16px">
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {TOKEN_TYPE_LABEL[item?.related_type]}
        </Text>
      </Stack>
    </Td>
    <Td>
      <Stack direction="row" spacing="16px">
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.name}
        </Text>
      </Stack>
    </Td>
    <Td>
      <Stack direction="row" spacing="16px">
        {item?.img_url ? (
          <Image
            width="80px"
            src={item?.img_url}
            alt={item?.id}
          />
        ) : (
          showCoinOffChain(item?.non_nft_type)
        )}
      </Stack>
    </Td>
    <Td>
      <Stack direction="row" spacing="16px">
        {item?.chain_id && item?.rarity !== "OFF_CHAIN" ? (
          <Image
            boxSize="30px"
            src={getChainIcon(item?.chain_id).src}
            alt={getChainIcon(item?.chain_id).alt}
          />
        ) : (
          <></>
        )}
      </Stack>
    </Td>
    <Td>
      <Stack direction="row" spacing="16px">
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {RARITY_LABEL[item?.rarity]}
        </Text>
      </Stack>
    </Td>
    {/* <Td>
      <Flex gap={6} align="center">
        <Icon
          fontSize="30px"
          borderRadius="8px"
          cursor="pointer"
          as={ViewIcon}
        />
      </Flex>
    </Td> */}
  </Tr>
  );
};

export default RowNftGacha;
