import http from "./http";
import END_POINT from "./constants";
import { convertParams, omitParams } from "utils/object";

export const getDailyRewardPool = (p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.DAILY_REWARD_POOL}?${params}`);
};

export const updateDailyRewardPool = (id, data) => {
  return http.put(`${END_POINT.DAILY_REWARD_POOL}/${id}`, data);
};
