import {
	Flex,
	Text,
	useColorModeValue,
	useDisclosure,
	useToast,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import Paginate from "components/Paginate";
import TableCustom from "components/Tables/CustomTable";
import { useCallback, useEffect, useState } from "react";
import ActionNonNft from "./action";
import ConditionNonNft from "./condition";
import CreateAndUpdateGachaNonNft from "./createAndUpdate";
import RowNftGacha from "./row";
import { getListNonNftGacha } from "api/gacha.api";
import { usePermission } from "hooks/usePermission";
import { PERMISSIONS } from "constants/permissions";

export default function NonNftGachaManagement() {
	const { isHasPermissionEdit } = usePermission(PERMISSIONS.GACHA_MANAGEMENT);
	const [isLoading, setIsLoading] = useState(false);
	const [checkedIds, setCheckedIds] = useState([]);
	const [dataDetail, setDataDetail] = useState(null);

	const [totalPage, setTotalPage] = useState(0);
	const [params, setParams] = useState({
		page: 1,
		limit: 10,
	});
	const toast = useToast();
	const [nonNfts, setNonNfts] = useState([]);

	const labels = [
		"Action",
		"Name",
		"Type",
		"Value",
		"Count",
		"Weight",
	];
	const textColor = useColorModeValue("#000000", "white");

	const { isOpen, onOpen, onClose } = useDisclosure();

	const handleClose = () => {
		onClose();
		setDataDetail(null);
	};

	const handleOpenCreateOrUpdate = (data = null) => {
		setDataDetail(data);
		onOpen();
	};

	const handleCreateOrUpdateSuccess = useCallback(() => {
		handleClose();
		setDataDetail(null);
		setParams({
			...params,
			page: 1,
		});
	}, [params]);

	const getListNonNftsGacha = useCallback(
		async (newParams = {}) => {
			try {
				setIsLoading(true);
				setCheckedIds([]);
				const { data } = await getListNonNftGacha({
					...params,
					...newParams,
				});
				if (data?.success) {
					const res = data?.data;
					setNonNfts(res?.records);
					setTotalPage(res?.total_page);
				}
			} catch (error) {
				toast({
					description: error?.message || error?.messages[0],
					status: "error",
					position: "bottom-right",
				});
			} finally {
				setIsLoading(false);
			}
		},
		[params]
	);

	const handleChangePage = (page) => {
		setParams({
			...params,
			page,
		});
	};

	useEffect(() => {
		getListNonNftsGacha();
	}, [getListNonNftsGacha]);

	const onChangeChecked = (isChecked) => {
		if (isChecked) {
			setCheckedIds(nonNfts);
		} else {
			setCheckedIds([]);
		}
	};

	const onChangeLimit = (limit) => {
		setParams({
			...params,
			page: 1,
			limit,
		});
	};

	return (
		<Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
			<Card px="0px">
				<CardHeader px="24px" mb="12px" display="flex" flexDirection="column" gap="20px">
					<Flex
						w="100%"
						gap={4}
						direction={{
							base: "column",
							md: "row",
						}}
						justifyContent={{
							base: "flex-start",
							md: "space-between",
						}}
						alignItems="flex-start"
					>
						<Text
							color={textColor}
							fontSize="lg"
							fontWeight="bold"
							whiteSpace="nowrap"
						>
							GACHA NON-NFT ITEMS MANAGEMENT
						</Text>
					</Flex>
				</CardHeader>
				<CardHeader px="24px" mb="32px">
					<Flex
						w="100%"
						justifyContent='space-between'
						alignItems='center'
					>	
						<ConditionNonNft
							isLoading={isLoading}
							setParams={setParams}
							params={params}
						/>
						{isHasPermissionEdit && (
							<ActionNonNft
								handleOpenCreateOrUpdate={handleOpenCreateOrUpdate}
								checkedIds={checkedIds}
								setCheckedIds={setCheckedIds}
								setParams={setParams}
								params={params}
							/>
						)}
					</Flex>
				</CardHeader>
				<CardBody overflowX="auto">
					<TableCustom
						labels={labels}
						isLoading={isLoading}
						isCheck
						isChecked={
							nonNfts?.length > 0 && checkedIds.length === nonNfts?.length
						}
						onChangeChecked={onChangeChecked}
						dataRow={nonNfts}
					>
						<>
							{nonNfts?.map((item) => {
								return (
									<RowNftGacha
										key={item?.id}
										setCheckedIds={setCheckedIds}
										checkedIds={checkedIds}
										handleOpenCreateOrUpdate={() => {
											handleOpenCreateOrUpdate(item);
										}}
										machine={item}
										isHasPermissionEdit={isHasPermissionEdit}
									/>
								);
							})}
						</>
					</TableCustom>
				</CardBody>
				<Paginate
					page={params?.page}
					pageCount={totalPage}
					limit={params.limit}
					onChangeLimit={onChangeLimit}
					handleChangePage={handleChangePage}
				/>
			</Card>
			{isOpen && (
				<CreateAndUpdateGachaNonNft
					isOpen={isOpen}
					onClose={handleClose}
					dataDetail={dataDetail}
					handleCreateOrUpdateSuccess={handleCreateOrUpdateSuccess}
				/>
			)}
		</Flex>
	);
}
