import { Flex, useDisclosure, useToast } from "@chakra-ui/react";
import ButtonType from "components/Button";
import DeleteModal from "components/Modal/deleteModal";
import { deleteUserKOLs } from "api/userKOL.api";
import useWithToast from "hooks/useWithToast";
import { useTranslation } from "react-i18next";

export default function ActionUserKOL({
  handleOpenCreate,
  checkedIds,
  setCheckedIds,
  setParams,
  params,
  }) {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { showToastSuccess, showToastFail } = useWithToast();
  const { t } = useTranslation("error");

  const handleDelete = async () => {
    try {
      if (checkedIds?.length) {
        const newParams = {
          ids: checkedIds,
        };
        const res = await deleteUserKOLs(newParams);
        if (res?.data?.success) {
          toast({
            title: "Delete Success",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
          setCheckedIds([])
          setParams({
            ...params,
            page: 1,
          });
        } else {
          toast({
            description: res?.data?.messages[0] || "Delete fail",
            status: "error",
            position: "bottom-right",
          });
        }
        onClose();
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  return (
    <Flex gap={4} flexWrap="wrap" alignItems="flex-end">
      <Flex alignItems="flex-end" gap={6} mb="10px">
        <ButtonType
          text="NEW"
          fontSize="12px"
          width="100px"
          onClick={() => {
            handleOpenCreate();
          }}
        />
        <ButtonType
          text="DELETE"
          width="100px"
          fontSize="12px"
          btnType="danger"
          disabled={checkedIds?.length === 0}
          onClick={onOpen}
        />
      </Flex>
      <DeleteModal
        isOpen={isOpen}
        onClose={onClose}
        handleDelete={handleDelete}
        title="Delete User KOL"
        content="Do you want delete User KOL ?"
        btnYes="OK"
        btnNo="Cancel"
      />
    </Flex>
  );
}
