import { Box, Flex } from "@chakra-ui/react";
import ButtonType from "components/Button";
import { useMemo } from "react";

export default function ActionDigitalFigures({ checkedIds, toggle }) {
  const isHaveCheckedIds = useMemo(() => {
    return (
      checkedIds?.length > 0 &&
      checkedIds?.some((check) => check?.allocation !== "NONE")
    );
  }, [checkedIds]);

  return (
    <Box>
      <Flex alignItems="center" justifyContent="flex-end" gap={4}>
        <ButtonType
          text="NEW"
          fontSize="12px"
          width="100px"
          onClick={() => toggle("modalCreateAndEdit")}
        />
        <ButtonType
          text="DELETE"
          fontSize="12px"
          btnType="danger"
          width="100px"
          onClick={() => toggle("modalDelete")}
          disabled={!isHaveCheckedIds}
        />
      </Flex>
    </Box>
  );
}
