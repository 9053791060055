import http from "./http";
import END_POINT from "./constants";
import { convertParams, omitParams } from "utils/object";

export const getCoinConsumption = (p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.COIN_CONSUMPTION_PRIORITY}?${params}`);
};

export const updateCoinConsumption = (id, data) => {
  return http.put(`${END_POINT.COIN_CONSUMPTION_PRIORITY}/${id}`, data);
};