import * as yup from "yup";

const schemaUpdatePriority = () =>
  yup.object().shape({
    first_priority: yup
      .string()
      .required('First Priority is required'),
    second_priority: yup
      .string()
      .test('unique-second', 'Priority values must be unique', function (value) {
        const { first_priority } = this.parent;
        // allow empty values
        if (value === "") return true;
        return value !== first_priority;
      }),
    third_priority: yup
      .string()
      .test('unique-third', 'Priority values must be unique', function (value) {
        const { first_priority, second_priority } = this.parent;
        if (value === "") return true;
        return value !== first_priority && (second_priority === "" || value !== second_priority);
      }),
    fourth_priority: yup
      .string()
      .test('unique-fourth', 'Priority values must be unique', function (value) {
        const { first_priority, second_priority, third_priority } = this.parent;
        if (value === "") return true;
        return (
          value !== first_priority &&
          (second_priority === "" || value !== second_priority) &&
          (third_priority === "" || value !== third_priority)
        );
      }),
  });

export default schemaUpdatePriority;
