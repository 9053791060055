import { Flex, useToast } from "@chakra-ui/react";
import ButtonType from "components/Button";
import { downloadCSV } from "utils/downloadCSV";
import END_POINT from "api/constants";

export default function ActionWalletManagement({ onOpenImportCSV, isHasPermissionEdit }) {
  const toast = useToast();

  const downloadCsvNft = async (props) => {
    try {
      const baseUrl = `${END_POINT.AP_REWARD}/export-csv`;
      await downloadCSV(baseUrl, true);
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  return (
    <Flex gap={4} flexWrap="wrap" alignItems="flex-end">
      <Flex alignItems="center" gap={4} mb="10px">
        {isHasPermissionEdit && (
          <ButtonType
            text="Import CSV"
            w="120px"
            fontSize="16px"
            fontWeight="700"
            onClick={onOpenImportCSV}
          />
        )}
        <ButtonType
          text="Download Template"
          fontSize="16px"
          fontWeight="700"
          btnType="primary-new-outline"
          onClick={downloadCsvNft}
        />
      </Flex>
    </Flex>
  );
}
