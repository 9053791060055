import {
  Box,
  Center,
  Flex,
  Spinner,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import {
  changeStatusMachineSetting,
  getListCraneMachines,
  changeDirectionMachine,
  getMachineGroup,
  updateAllMachinesMaintain,
  updateMachineMapGroup,
} from "api/machine.api";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import Paginate from "components/Paginate";
import { STATUS } from "constants/constants";
import { useCronJob } from "hooks/useCronJob";
import { useCallback, useEffect, useMemo, useState } from "react";
import ConditionMachineMap from "./condition";
import MainMap from "./mainMap";
import { getGamePlayType } from "api/gameplayType.api";
import SwitchType from "components/Switch";
import ModalConfirm from "components/Modal/ModalConfirm";
import {
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { ERROR_MACHINE_SETTINGS } from "../MachineSettings/constants";
import { getAllStatusIot } from "api/iotDevice.api";
import { usePermission } from "hooks/usePermission";
import { PERMISSIONS } from "constants/permissions";

export default function MachineMap() {
  const [isLoading, setIsLoading] = useState(false);
  // const [isLoadingRestart, setLoadingRestart] = useState(false);
  const [listGamePlayType, setListGamePlayType] = useState([]);
  const [listGroupLabel, setListGroupLabel] = useState([]);
  const [listStatusIot, setListStatusIot] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [draggingItem, setDraggingItem] = useState(null);

  const [totalPage, setTotalPage] = useState(0);
  const [params, setParams] = useState({
    page: 1,
    limit: 50,
  });
  const [machines, setMachines] = useState([]);

  const toast = useToast();
  const textColor = useColorModeValue("#000000", "white");
  const { isHasPermissionEdit } = usePermission(PERMISSIONS.MACHINE_MAP);

  const onChangeLimit = (limit) => {
    setParams({
      ...params,
      page: 1,
      limit,
    });
  };

  const handleChangePage = (page) => {
    setParams({
      ...params,
      page,
    });
  };

  const getListMachine = useCallback(
    async (newParams = {}, isReStartJon = true) => {
      try {
        if (isReStartJon) {
          stopJob();
        }
        setIsLoading(true);
        const { data } = await getListCraneMachines({
          ...params,
          ...newParams,
        });
        if (data?.success) {
          const res = data?.data;
          const combineArrays = (arr1, arr2) => {
            return arr1.map((item, index) => {
              return arr2[index]?.machine?.group_number ? arr2[index] : null;
            });
          };
          const dataFilter = res?.map((machine) => {
            const array1 = Array.from(
              { length: machine?.machine_map_v2?.length },
              (_, index) => index + 1
            );
            const result = combineArrays(array1, machine?.machine_map_v2);

            return {
              ...machine,
              machine_filter: result,
            };
          });
          setMachines(dataFilter);
          setTotalPage(res?.total_page);
        }
      } catch (error) {
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      } finally {
        setIsLoading(false);
        if (isReStartJon) {
          startJob();
        }
      }
    },
    [params]
  );

  useEffect(() => {
    getListMachine();
  }, [getListMachine]);

  const switchStatus = async (machine, status) => {
    const switchStatus = {
      id: machine?.id,
      state: status
        ? status
        : machine?.state === STATUS.FREE
        ? STATUS.UNDER_MAINTENANCE
        : STATUS.FREE,
    };
    return changeStatusMachineSetting(switchStatus);
  };

  const handleSwitchStatus = async (machine, status) => {
    try {
      setIsLoading(true);
      const res = await switchStatus(machine, status);
      if (res?.data?.success) {
        getListMachine();
        toast({
          title: "Update success.",
          description: "Update success.",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      } else {
        setIsLoading(false);
        toast({
          description:
            ERROR_MACHINE_SETTINGS[res?.data?.message] ||
            ERROR_MACHINE_SETTINGS[res?.data?.messages[0]],
          status: "error",
          position: "bottom-right",
        });
      }
    } catch (error) {
      setIsLoading(false);
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const switchDirection = async (machine) => {
    const switchDirection = {
      id: machine?.id,
      direction:
        machine?.direction === STATUS.LEFT ? STATUS.RIGHT : STATUS.LEFT,
    };
    return changeDirectionMachine(switchDirection);
  };

  const handleSwitchDirection = async (machine) => {
    try {
      setIsLoading(true);
      const res = await switchDirection(machine);
      if (res?.data?.success) {
        getListMachine();
        toast({
          title: "Update success.",
          description: "Update success.",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const { startJob, stopJob } = useCronJob({
    task: () => {
      getListMachine({}, false);
    },
  });

  const getListMachineGroup = async () => {
    try {
      const { data } = await getMachineGroup({
        page: 1,
        limit: 999999,
      });
      if (data?.success) {
        const res = data?.data;
        const options = res?.records?.map((item) => ({
          ...item,
          label: item?.machine_label,
          value: item?.machine_label,
        }));
        setListGroupLabel(options);
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getListStatusIot = async () => {
    try {
      const { data } = await getAllStatusIot();
      if (data?.length) {
        const dataReview = data.reduce(
          (acc, curr) => ((acc[curr?.device_id] = curr?.status), acc),
          {}
        );
        setListStatusIot(dataReview);
      } else {
        setListStatusIot(null);
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    } finally {
      setIsLoading(false);
    }
  };

  // const handleRestartMachine = async (id, status) => {
  //   setLoadingRestart(true)
  //   try {
  //     const params = {
  //       device_id: id,
  //     }
  //     const { data } = await restartStatusMachine(params);
  //     if (data?.success) {
  //       getListStatusIot();
  //       toast({
  //         title: "Update iot status successfully!",
  //         description: "Update iot status successfully!",
  //         status: "success",
  //         duration: 3000,
  //         isClosable: true,
  //         position: "top",
  //       });
  //     }
  //     setLoadingRestart(false)
  //   } catch (error) {
  //     setLoadingRestart(false)
  //     toast({
  //       description: error?.message || error?.messages[0],
  //       status: "error",
  //       position: "bottom-right",
  //     });
  //   }
  // }

  const getListGamePlayType = async () => {
    try {
      const res = await getGamePlayType();
      if (res?.data?.success) {
        const listType = res?.data?.data?.records?.map((item) => ({
          label: item?.name,
          value: item?.id,
        }));
        setListGamePlayType(listType);
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const handleMaintainALl = async (status) => {
    try {
      let params;
      if (status) {
        params = {
          state: STATUS.FREE,
        };
      } else {
        params = {
          state: STATUS.UNDER_MAINTENANCE,
        };
      }
      const res = await updateAllMachinesMaintain(params);
      if (res?.data?.success) {
        toast({
          description: "Maintain successfully!",
          status: "success",
          position: "top-right",
        });
        getListMachine();
        onClose();
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const isCheckMaintain = useMemo(() => {
    const maintainList = machines?.every((state) => {
      const statusMachine = state?.machine_map?.every(
        (machine) => machine?.machine?.state === STATUS.UNDER_MAINTENANCE
      );
      return statusMachine;
    });
    return maintainList;
  }, [machines]);

  useEffect(() => {
    getListMachineGroup();
    getListGamePlayType();
    getListStatusIot();
    return () => {
      stopJob();
    };
  }, []);

  const handleUpdatePosition = async (id, label, number) => {
    const dataSubmit = {
      machine_id: id,
      group_label: label,
      group_number: Number(number),
    };
    setIsLoading(true);
    try {
      const res = await updateMachineMapGroup(dataSubmit);
      if (res?.data?.success) {
        toast({
          title: "Machine updated.",
          description: "Machine updated.",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
        getListMachine();
      } else {
        toast({
          description:
            ERROR_MACHINE_SETTINGS[res?.data?.message] ||
            ERROR_MACHINE_SETTINGS[res?.data?.messages[0]],
          status: "error",
          position: "bottom-right",
        });
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  // Cấu hình sensors
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    })
    // Bạn có thể thêm nhiều sensors ở đây nếu cần
  );

  const onDragStart = (id) => {
    setDraggingItem(id); // Set the currently dragging item
  };

  function handleDragEnd(e) {
    if (!e?.over) {
      setDraggingItem(null); // Set the currently dragging item
    } else {
      if (!e?.over?.id?.mac) {
        const split_string = e?.over?.id?.split(/(\d+)/);
        handleUpdatePosition(
          draggingItem?.id?.machine?.id,
          split_string[0],
          split_string[1]
        );
      } else {
        draggingItem?.id?.mac !== e?.over?.id?.mac &&
          handleUpdatePosition(
            draggingItem?.id?.machine?.id,
            e?.over?.id?.machine?.group_label,
            e?.over?.id?.machine?.group_number
          );
      }
    }
  }

  return (
    <Flex flexDirection="column" gap="20px" pt={{ base: "120px", md: "75px" }}>
      <Card px="0px">
        <CardHeader px="28px" mb="32px">
          <Flex
            gap={4}
            w="100%"
            alignItems="center"
            justifyContent="space-between"
          >
            <Text color={textColor} fontSize="lg" fontWeight="bold">
              Machines Map
            </Text>
            <Flex alignItems="center" gap="6px">
              <Text fontSize="20px" fontWeight="600">
                Maintenance
              </Text>
              <SwitchType
                color="#e04995"
                colorThumb="#fff"
                size="lg"
                colorScheme="teal"
                position="relative"
                isChecked={isCheckMaintain}
                onChange={() => onOpen()}
                isDisabled={!isHasPermissionEdit}
              />
            </Flex>
          </Flex>
        </CardHeader>
        <CardHeader px="28px" mb="24px">
          <ConditionMachineMap
            isLoading={isLoading}
            setParams={setParams}
            params={params}
            listGamePlayType={listGamePlayType}
            listGroupLabel={listGroupLabel}
          />
        </CardHeader>
      </Card>
      <Card px="0px">
        <CardBody
          overflowX={{ base: "auto" }}
          position="relative"
          aria-busy="true"
          userSelect="none"
        >
          <Flex
            p="10px 28px"
            w="100%"
            gap="24px"
            direction="column"
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <DndContext
              sensors={sensors}
              onDragStart={({ active }) => onDragStart(active)}
              onDragEnd={(e) => handleDragEnd(e)}
            >
              {machines?.map((item, index) => {
                return (
                  <Flex key={item?.group_label} direction="column" gap="24px">
                    <Text>{item?.group_label}</Text>
                    <MainMap
                      isLoading={isLoading}
                      machines={item?.machine_filter}
                      handleSwitchStatus={handleSwitchStatus}
                      getListMachine={getListMachine}
                      handleSwitchDirection={handleSwitchDirection}
                      groupLabel={item?.group_label}
                      id={`list-${index}`}
                      listStatusIot={listStatusIot}
                      getListStatusIot={getListStatusIot}
                      listGroupLabel={listGroupLabel}
                      />
                  </Flex>
                );
              })}
            </DndContext>
          </Flex>
          {isLoading && (
            <Box pos="absolute" inset="0" bg="bg/80">
              <Center h="full">
                <Spinner color="teal.500" />
              </Center>
            </Box>
          )}
        </CardBody>
        {machines?.length > params?.limit && (
          <Paginate
            page={params?.page}
            pageCount={totalPage}
            limit={params?.limit}
            onChangeLimit={onChangeLimit}
            handleChangePage={handleChangePage}
          />
        )}
      </Card>
      <ModalConfirm
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={() => handleMaintainALl(isCheckMaintain)}
        title="Maintenance"
        description="Are you sure to do that?"
      />
    </Flex>
  );
}
