import { Flex, Icon, Td, Tr, Text } from "@chakra-ui/react";
import { EditIcon } from "components/Icons/EditIcon";
import { ViewIcon } from "components/Icons/ViewIcon";
import { DuplicateIcon } from "components/Icons/DuplicateIcon";
import { PlayIcon } from "components/Icons/PlayIcon";
import CheckBoxType from "components/Checkbox"
import { STATUS_PRESENTBOX } from "constants/constants";
import { lowerCase, upperFirst } from "lodash";
import { DISTRIBUTE_CONDITION } from "./constant";
import { convertShortDateLocal } from "utils/time";
import LegendaryBg from 'assets/svg/machine.svg'

export default function PresentBoxManagementRow ({
  itemPresentBox,
  checkedIds,
  setCheckedIds,
  handleOpenCreateOrUpdate,
  handleSetDataDetail,
  handleTrigger,
  isHasPermissionEdit
}) {
  const statusAllowPlay = ["FAIL", "PENDING"];
  const handleCheckboxChange = () => {
    if (checkedIds.includes(itemPresentBox?.id)) {
      setCheckedIds(
        checkedIds.filter((checkedId) => checkedId !== itemPresentBox?.id)
      );
    } else {
      setCheckedIds([...checkedIds, itemPresentBox?.id]);
    }
  };

  const showBgItems = (status) => {
    switch(status) {
      case "PENDING":
        return '#ffc107';
      case "COMPLETED":
        return '#4caf50';
      case "ON_GOING":
        return '#2196f3';
      case "FAIL":
        return '#f44336';
      default:
        return '#ffffff';
    }
  };

  return (
    <>
      <Tr>
        <Td width="10px">
          <CheckBoxType
            isChecked={checkedIds.includes(itemPresentBox?.id)}
            onChange={() => handleCheckboxChange()}
            colorScheme="teal"
            disabled={itemPresentBox.status !== "PENDING"}
          />
        </Td>
        <Td width="12.5%">
          <Flex gap={6} align="center">
            <Icon
              fontSize="30px"
              borderRadius="8px"
              cursor="pointer"
              as={ViewIcon}
              onClick={() => handleSetDataDetail()}
            />
            <Icon
              fontSize="30px"
              borderRadius="8px"
              cursor="pointer"
              backgroundColor="#f983ce"
              p="7px"
              as={DuplicateIcon}
              onClick={() => handleOpenCreateOrUpdate(itemPresentBox, true)}
            />
            {isHasPermissionEdit && (
              <Icon
                onClick={() => handleOpenCreateOrUpdate(itemPresentBox, false)}
                fontSize="30px"
                borderRadius="8px"
                p="6px"
                cursor={
                  itemPresentBox.status !== "PENDING"
                    ? "not-allowed"
                    : "pointer"
                }
                bg={itemPresentBox.status !== "PENDING" ? "#b9d9ff" : "#1480FF"}
                as={EditIcon}
              />
            )}
            {isHasPermissionEdit && (
              <Icon
                fontSize="30px"
                borderRadius="8px"
                cursor={
                  !statusAllowPlay.includes(itemPresentBox.status) ||
                  itemPresentBox?.distribution_condition
                    ? "not-allowed"
                    : "pointer"
                }
                bg={
                  !statusAllowPlay.includes(itemPresentBox.status) ||
                  itemPresentBox?.distribution_condition
                    ? "#b9d9ff"
                    : "#07cf84"
                }
                as={PlayIcon}
                p="5px"
                onClick={() => handleTrigger()}
              />
            )}
          </Flex>
        </Td>
        <Td>
          {itemPresentBox?.mgmt_name}
        </Td>
        <Td minWidth="150px">
          {itemPresentBox?.name_en}
        </Td>
        <Td>
          <Text p="2px 10px" fontSize="12px" background={showBgItems(itemPresentBox?.status)} borderRadius="12px">
            {STATUS_PRESENTBOX[itemPresentBox?.status]}
          </Text>
        </Td>
        <Td>
        {upperFirst(lowerCase(itemPresentBox?.recipient_type))}
        </Td>
        <Td minWidth="300px">
          {itemPresentBox?.distribution_condition ? (
            <Flex gap={1} align="center">
              <Text p="2px 10px" fontSize="12px" background="#9fffe8" borderRadius="12px">
              {upperFirst(lowerCase(itemPresentBox?.distribution_type))}
              </Text>
              <Text p="2px 10px" fontSize="12px" background="#d5c2ff" borderRadius="12px">
                {DISTRIBUTE_CONDITION.find(item => item.value === itemPresentBox?.distribution_condition)?.label}
              </Text>
          </Flex>
          ) : (
            <Flex gap={1} align="center">
              <Text p="2px 10px" fontSize="12px"  background="#ffbe99" borderRadius="12px">
                {upperFirst(lowerCase(itemPresentBox?.distribution_type))}
              </Text>
            </Flex>
          )}
        </Td>
        <Td>
          {convertShortDateLocal(itemPresentBox?.distribution_start_date * 1000)}
        </Td>
        <Td minWidth="150px">
          {itemPresentBox.is_no_expiration ? "No Expiration" : `${itemPresentBox?.available_in_days} days`}
        </Td>
      </Tr>
    </>
  );
}
