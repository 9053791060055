import * as yup from 'yup'

function calculateHWFWLength(value) {
  if (!value) return 0;
  return [...value.trim()].reduce((sum, char) => {
    const code = char.charCodeAt(0);
    const isFullWidth = code >= 0xFF00 && code <= 0xFFEF;
    return sum + (isFullWidth ? 2 : 1);
  }, 0);
}

const schemaKOL = () =>
  yup.object().shape({
    note: yup
      .string()
      .trim()
      .test("html-stripped-length", "Note must be less than 500 characters.", (value) => {
        const length = calculateHWFWLength(value?.replace(/<[^>]+>/g, "").trim());
        return length <= 500;
      }),
  })

export default schemaKOL
