export const rateExchange = [
  {
    value: "FRACTION",
    label: "Fraction rate (Ex: 3/2)",
  },
  {
    value: "FLOAT",
    label: "Float rate (Ex: 1.50)",
  },
]

export const ERROR_EXCHANGE_RATE = {
  EXCHANGE_RATE_EXISTED: "Exchange rate existed",
  INVALID_CURRENCY: "Invalid currency",
  INVALID_EXCHANGE_RATE: "Invalid exchange rate",
  INVALID_NUMBER: "Invalid number",
  INVALID_AMOUNT: "Invalid amount",
  EXCHANGE_RATE_NOT_FOUND: "Exchange rate not found",
}
