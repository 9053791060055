import { ALLOCATION } from "constants/constants";
import styled from "styled-components";

export const FEATURES_LIST = [
  { label: "3D", value: "is_3d" },
  { label: "Room", value: "is_room" },
  { label: "AR Support", value: "is_ar" },
  { label: "Holomodels", value: "is_holo_model" },
]

export const SIZE_POSITION = [
  { label: "5%", value: "5" },
  { label: "10%", value: "10" },
  { label: "15%", value: "15" },
  { label: "20%", value: "20" },
  { label: "25%", value: "25" },
  { label: "30%", value: "30" },
  { label: "35%", value: "35" },
  { label: "40%", value: "40" },
  { label: "45%", value: "45" },
  { label: "50%", value: "50" },
  { label: "55%", value: "55" },
  { label: "60%", value: "60" },
  { label: "65%", value: "65" },
  { label: "70%", value: "70" },
  { label: "75%", value: "75" },
  { label: "80%", value: "80" },
  { label: "85%", value: "85" },
  { label: "90%", value: "90" },
  { label: "95%", value: "95" },
  { label: "100%", value: "100" },
]

export const TRANS_LABEL = {
	en: {
		name: "Name",
		enterName: "Enter a name",
		description: "Description",
		enterDesc: "Enter description",
		image: "Image",
		asset: "Asset",
    features: "Features",
    partner: "Partner",
    serialCode: "Import Serial Code",
    count: "Count",
    countries: "Target Countries",
    allCountries: "Add countries",
    enterValue: "Enter a value",
    relatedDigital: "Related Digital Figure",
    selectDigital: "Select a digital figure",
    licensor: "Licensor",
    enterLicensor: "Enter a name",
    selectLicensor: "Select a licensor",
    project: "Project",
    enterProject: "Enter a project name",
    selectProject: "Select a project",
    restriction: "Item Restriction",
    targetCountry: "Specify Target Countries",
    targetCountries: "Target Countries",
    selected: "selected",
    all: "All",
    cancel: "Cancel",
    add: "Add",
    save: "Save",
    required: "Required",
    notOneOf:
      'Value cannot be "Bounty Hunter". Please enter a different value.',
    duplicate: "Licensor and Project cannot be the same",
    maxLenght: "Maximum limit of 255 characters",
	},
	jp: {
		name: "名前",
		enterName: "名前を入力してください",
		description: "説明",
		enterDesc: "説明を入力",
		image: "画像",
		asset: "資産",
    features: "特徴",
    partner: "パートナー",
    serialCode: "シリアルコードのインポート",
    count: "数",
    countries: "対象国",
    allCountries: "国を追加",
    enterValue: "値を入力してください",
    relatedDigital: "関連デジタルフィギュア",
    selectDigital: "デジタルフィギュアを選択",
    licensor: "ライセンサー",
    enterLicensor: "名前を入力してください",
    selectLicensor: "ライセンサーを選択してください",
    project: "プロジェクト",
    enterProject: "プロジェクトを選択してください",
    selectProject: "プロジェクトを選択",
    restriction: "アイテム制限",
    targetCountry: "対象国を指定する",
    targetCountries: "対象国",
    selected: "選択済み",
    all: "すべて",
    cancel: "キャンセル",
    add: "追加",
    save: "保存",
    required: "必須です",
    notOneOf: "値は「Bounty Hunter」にできません。別の値を入力してください。",
    duplicate: "ライセンサーとプロジェクトは同じにできません。",
    maxLenght: "最大文字数は255文字までです。",
	},
};

export const listPartner = [
  {
    "id": "1",
    "create_time": 1734707933,
    "update_time": 1734707943,
    "name": "Pepsi",
    "label": "Pepsi",
    "value": "1",
  },
  {
    "id": "2",
    "create_time": 1734707933,
    "update_time": 1734707943,
    "name": "Coca",
    "label": "Coca",
    "value": "2",
  },
]

export const ALLOCATION_DF = [
  { value: ALLOCATION.PRIZE, label: "Prize" },
  { value: ALLOCATION.NONE, label: "None" },
];

export const bgFeature = (type) => {
  switch (type) {
    case "3D":
      return "#fffd77"
    case "AR":
      return "#b8ffa2"
    case "ROOM":
      return "#d096ff"
    case "HoloModel":
      return "#ffabff"
    default:
      return "#ccc"
  }
}

export const ERROR_DIGITAL_FIGURE = {
  "DIGITAL_FIGURE_HAS_BEEN_ALLOCATED_FOR_PRIZE": "Digital figure has been allocated for prize",
  "NUMBER_OF_COLUMNS_INVALID": "Number of columns invalid",
  "THIS_ITEM_CANNOT_BE_DELETED_AS_IT_IS_CURRENTLY_IN_USE_BY_ANOTHER_ITEM": "This item cannot be deleted as it is currently in use by another item",
  "LICENSOR_AND_PROJECT_ALREADY_EXISTS": "Licensor and project already exists",
}

export const ITEM_RESTRICTIONS = [
  { label: "No Restriction", value: "NO_RESTRICTION" },
  { label: "Only same-project items allowed", value: "ONLY_SAME_PROJECT" },
]

export const ERROR_TRANSFER_DIGITAL = {
  "NOT_ENOUGH_ROOM_BG_TO_TRANSFER": "Not enough room background to transfer",
}

export const TextError = styled.div`
  padding: 0;
  color: #E53E3E;
  font-size: 16px;
`