import { Box, Image, Flex, Text } from "@chakra-ui/react";
import ICON_RANK_SILVER from "assets/img/card/silver.png";
import ICON_DISABLED_SILVER from "assets/img/card/silver-disabled.svg";
import ICON_RANK_GOLD from "assets/img/card/gold.png";
import ICON_DISABLED_GOLD from "assets/img/card/gold-disabled.png";
import ICON_RANK_PLATINUM from "assets/img/card/platinum.png";
import ICON_DISABLED_PLATINUM from "assets/img/card/platinum-disabled.png";
import ICON_RANK_DIAMOND from "assets/img/card/diamond.png";
import ICON_DISABLED_DIAMOND from "assets/img/card/diamond-disabled.png";
import ICON_RANK_IRON from "assets/img/card/iron.png";
import ICON_DISABLED_IRON from "assets/img/card/iron-disabled.svg";

const TraitInfo = ({ item }) => {
  const traits = [
    {
      icon: item?.merchant_init_value > 0 ? ICON_RANK_SILVER : ICON_DISABLED_SILVER,
      count: item?.merchant_init_value,
    },
    {
      icon: item?.arena_hunter_init_value > 0 ? ICON_RANK_GOLD : ICON_DISABLED_GOLD,
      count: item?.arena_hunter_init_value,
    },
    {
      icon: item?.prize_hunter_init_value > 0
        ? ICON_RANK_PLATINUM
        : ICON_DISABLED_PLATINUM,
      count: item?.prize_hunter_init_value,
    },
    {
      icon: item?.treasure_hunter_init_value > 0
        ? ICON_RANK_DIAMOND
        : ICON_DISABLED_DIAMOND,
      count: item?.treasure_hunter_init_value,
    },
    {
      icon: item?.mechanic_init_value > 0 ? ICON_RANK_IRON : ICON_DISABLED_IRON,
      count: item?.mechanic_init_value,
    },
  ];

  return (
    <Flex gap={3} bg="rgba(0,0,0,0.4)" padding="6px 16px" borderRadius="16px">
      {traits.map((trait, index) => (
        <Box
          key={index}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Box position="relative">
            <Image w="40px" src={trait.icon} alt="icon" />
            <Text
              position="absolute"
              bottom="-1px"
              right="-6px"
              fontSize="xs"
              fontWeight="bold"
              color="white"
              px={1}
              borderRadius="sm"
              textShadow="-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000"
            >
              {trait.count}
            </Text>
          </Box>
        </Box>
      ))}
    </Flex>
  );
};

export default TraitInfo;
