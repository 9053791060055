 import { Flex } from "@chakra-ui/react";
import FormInput from "components/form/FormInput";
import FormSelect from "components/form/FormSelect";
import { FormProvider, useForm } from "react-hook-form";
import { SORT, STATUS_CONTACT } from "./constants";
import ButtonType from "components/Button";

export default function ConditionMachine({
  isLoading,
  setParams,
  params,
  checkedIds,
  onOpenUpdateStatus,
}) {
  const form = useForm();

  const { setValue, handleSubmit } = form;

  const onSubmit = (data) => {
    if (!isLoading) {
      const newParams = {
        ...params,
        ...data,
        page: 1,
      };
      setParams(newParams);
    }
  };

  return (
    <Flex
      gap={4}
      flexWrap="wrap"
      justifyContent="space-between"
      w="100%"
      alignItems="flex-end"
    >
      <FormProvider {...form}>
        <form
          style={{
            maxWidth: "100%",
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Flex w="100%" flexWrap="wrap" alignItems="flex-end" gap={4}>
            <FormInput
              w="349px"
              maxW="100%"
              name="keyword"
              label="Search"
              placeholder="Enter keywords"
            />
            <FormSelect
              name="sortColumn"
              label="Sort"
              defaultValue={SORT[0]?.value}
              w="156px"
              options={SORT}
              onChange={(e) => {
                if (!isLoading) {
                  setValue("sortColumn", e.target.value);
                  handleSubmit(onSubmit)();
                }
              }}
            />
            <FormSelect
              name="status"
              label="Status"
              defaultValue={STATUS_CONTACT[0]?.value}
              w="156px"
              options={STATUS_CONTACT}
              onChange={(e) => {
                if (!isLoading) {
                  setValue("status", e.target.value);
                  handleSubmit(onSubmit)();
                }
              }}
            />
          </Flex>
        </form>
      </FormProvider>
      <ButtonType
        btnType="primary"
        w="120px"
        h="40px"
        fontSize="16px"
        borderRadius="5px"
        disabled={checkedIds.length === 0}
        onClick={onOpenUpdateStatus}
      >
        Update status
      </ButtonType>
    </Flex>
  );
}
