import {
  Flex,
  Text,
  FormLabel,
  Grid,
  Box,
  TagLabel,
  Tag,
  Table,
  Tr,
  Thead,
  Th,
  Tbody,
  Td
} from '@chakra-ui/react';
import { Select as ChakraSelect, chakraComponents } from "chakra-react-select";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import FormDatePicker from 'components/form/FormDatePicker';
import FormInput from 'components/form/FormInput';
import FormSelect from 'components/form/FormSelect';
import React from 'react'
import ButtonType from "components/Button";
import FormCheckbox from "components/form/FormCheckbox";
import FormRadio from "components/form/FormRadio";
import TableCustom from "components/Tables/CustomTable";
import RowGift from "./rowGift"
import { useState, useEffect } from "react";
import { DISTRIBUTE_TYPE, RECIPIENTS, DISTRIBUTE_CONDITION, USER_RECIPIENTS } from "../constant";
import FormTextAria from "components/form/FormTextAria";
import styled from "styled-components";
import { truncate } from 'lodash';
const TextError = styled.div`
color: #E53E3E;
font-size: 16px;
`
export default function FormJp(props) {
  const [userOptions, setUserOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [resourceData, setResourceData] = useState({});
  const [cardData, setCardData] = useState([]);
  const [nftData, setNftData] = useState([]);
  const [gifts, setGifts] = useState([]);
    const [userReceivers, setUserReceivers] = useState([]);

  const {
    transLabel,
    disableWhenJP,
    setValue,
    dateFormat,
    watch,
    onOpenSelectItem,
    dataDetail,
    getValues,
    onOpenImportCSV,
    optionEvents,
    fileSelect,
    errors,
    receiver,
    setReceiver,
    isNoExpire,
    setIsNoExpire,
    userId,
  } = props

  const labels = [
    "NAME",
    "TYPE",
    "SUB TYPE",
    "VALUE",
    ""
  ];
  const watchedResource = watch("resource");
  const watchedDetails = watch("details");
  const watchedNfts = watch("nfts");

  useEffect(() => {
    setResourceData(watchedResource || {});
    setCardData(watchedDetails || []);
    setNftData(watchedNfts || []);
  }, [watchedResource, watchedDetails, watchedNfts]);

  useEffect(() => {
    const giftsData = [
      ...cardData.map((item) => ({
        type: "Invitation Card",
        name: item?.card_name || item?.name,
        value: item?.amount,
      })),
      ...Object.entries(resourceData).flatMap(([userId, resources]) =>
        Object.keys(resources)
          .filter((key) => resources[key] > 0)
          .map((key) => ({
            type: "Point & Currency",
            name: key,
            value: resources[key],
            user_id: userId || "Unknown",
          }))
      ),
      ...Object.entries(nftData).flatMap(([userId, nfts]) =>
        Object.keys(nfts)
          .map((key) => {
            const detail = getDetailNFT(nfts[key]);
            return {
              type: "NFT",
              name: nfts[key]?.name || detail.name,
              value: nfts[key]?.amount || 1,
              user_id: userId || "Unknown",
              id: nfts[key]?.id || detail.id,
              nft_item_type: nfts[key]?.token_type || nfts[key].nft_item_type,
              chain_id: nfts[key]?.chain_id || detail.chain_id,
              product_id: nfts[key]?.product_id || detail.product_id,
              rarity: nfts[key]?.rarity || detail.rarity,
              trait: nfts[key]?.trait || detail.trait,
              image_url: nfts[key]?.image_url || detail.image_url,
            }
          })
      ),
    ];

    let uniqueGiftsData = {}
    if(userId) {
      uniqueGiftsData = giftsData.filter((gift) => gift.user_id == userId);
    } else {
      uniqueGiftsData = giftsData;
    }

    if(giftsData.length > 0) {
      setGifts((prevGifts) =>
        userId
          ? [
              ...prevGifts.filter((gift) => gift.user_id !== userId),
              ...uniqueGiftsData
            ]
          : uniqueGiftsData
      );
    }

  }, [cardData, resourceData, nftData]);


  function getDetailNFT(nft) {
    return nft?.nft_detail_hunter?.name ? nft.nft_detail_hunter :
      nft?.nft_detail_gauntlet?.name ? nft.nft_detail_gauntlet :
      nft.nft_detail_bounty_ball;
  }

  useEffect(() => {
    if(!fileSelect) {
      setReceiver(getValues("receivers"))
    }
  }, [getValues("receivers")]);

  useEffect(() => {
    setValue("expired_date", getValues('expired_date'))
  }, []);
    useEffect(() => {
      setUserReceivers(getValues('receivers'))
    }, [getValues('receivers')]);

  const renderCustomItem = () => {
    if (
      watch("recipient_type") === "MULTIPLE" &&
      watch("multiple_item_recipient_type") === "CUSTOM" &&
      userReceivers?.length > 0
    ) {
      return userReceivers.map((user) => {
        const userGifts = gifts.filter((gift) => gift.user_id === user.value);
        return (
          <Card key={user.value} style={{ border: "1px solid #ccc" }}>
            <CardHeader px="10px" mb="12px">{user.label}</CardHeader>
            <CardBody>
              {userGifts.length === 0 ? (
                <Table variant="simple">
                  <Thead boxShadow="0px 20px 27px 0px #0000000D">
                    <Tr>
                      {labels.map((label) => (
                        <Th
                          key={label}
                          whiteSpace="nowrap"
                          color="#000000"
                          fontWeight="700"
                          fontSize="sm"
                          textTransform="uppercase"
                        >
                          {label}
                        </Th>
                      ))}
                    </Tr>
                  </Thead>
                  <Tbody bg="white">
                    <Tr>
                      <Td colSpan={labels.length + 1} textAlign="center">
                        <ButtonType
                          text="+"
                          fontSize="25px"
                          sizeIcon="8px"
                          btnType="primary-new-outline"
                          disabled={disableWhenJP}

                        />
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              ) : (
                <TableCustom labels={labels} dataRow={userGifts} isCheck={false}>
                  {userGifts.map((item, index) => (
                    <RowGift
                      key={index}
                      item={item}
                      isDetail={true}
                    />
                  ))}
                  <Tr>
                    <Td colSpan={labels.length + 1} textAlign="center">
                      <ButtonType
                        text="+"
                        fontSize="25px"
                        sizeIcon="8px"
                        btnType="primary-new-outline"
                        disabled={disableWhenJP}
                      />
                    </Td>
                  </Tr>
                </TableCustom>
              )}
            </CardBody>
          </Card>
        );
      });
    }
  }
  return (
    <Flex flexDirection="column" gap="12px">
      <FormInput
        maxW="100%"
        name="mgmt_name"
        label={transLabel("mgmt_name")}
        placeholder={transLabel("enterMgmtName")}
        isRequired
      />
      <FormInput
        maxW="100%"
        name="name_jp"
        label={transLabel("name")}
        placeholder={transLabel("enterName")}
        isRequired
      />
      <FormTextAria
        label={transLabel("description")}
        placeholder={transLabel("enterDesc")}
        name="description_jp"
        rows={7}
        h="150px"
        fontSize="md"
      />
      <Flex direction="column" gap="2px" w="100%" padding="8px 0">
        <FormLabel>{transLabel("distributeType")}</FormLabel>
        <Flex alignItems="center" gap="70px" width="100%">
          <FormRadio
            name="distribute_type"
            options={DISTRIBUTE_TYPE}
            defaultValue={DISTRIBUTE_TYPE[0].value}
            isRequired
            isDisabled={disableWhenJP}
          />
        </Flex>
      </Flex>
      {watch("distribute_type") === "CONDITION" && (
        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
          <FormSelect
            name="distribute_condition"
            label={transLabel("distributeCondition")}
            options={[...DISTRIBUTE_CONDITION]}
            onChange={(e) => setValue("distribute_condition", e.target.value)}
            isRequired
            disabled={disableWhenJP}
          />
          <FormSelect
            name="event"
            label={transLabel("event")}
            options={[{ label: "Select Event", value: "" }, ...optionEvents]}
            disabled={disableWhenJP}
          />
        </Grid>
      )}
      <FormLabel>{transLabel("distributeDate")}<span style={{ color: 'red' }}>&nbsp;*</span></FormLabel>
      <Flex w="100%" gap="8px">
        <Flex w="50%">
          <FormDatePicker
            name="distribution_start_date"
            showTimeSelect
            dateFormat={dateFormat}
            h="40px"
            disabled={disableWhenJP}
          />
        </Flex>
      </Flex>
      <Flex direction="column" gap="12px" w="100%" padding="8px 0">
        <FormLabel>{transLabel("recipient")}</FormLabel>
        <Flex alignItems="center" gap="70px" width="100%">
          <FormRadio
            name="recipient_type"
            options={RECIPIENTS}
            defaultValue={RECIPIENTS[1].value}
            isRequired
            isDisabled={disableWhenJP}
          />
        </Flex>
      </Flex>
      {watch("recipient_type") == 'INDIVIDUALS' && (
        <Flex alignItems="center" gap={6} width="100%">
          <Flex width="100%">
            <Box width="100%">
              <ChakraSelect
                options={userOptions}
                placeholder={transLabel("addUsers")}
                value={receiver}
                isLoading={isLoading}
                noOptionsMessage={() => null}
                chakraStyles={{
                  placeholder: (provided) => ({
                    ...provided,
                    color: "gray.400",
                  }),
                  control: (provided) => ({
                    ...provided,
                    cursor: "text",
                  }),
                }}
                components={{
                  Option: ({ children, ...props }) => (
                    <chakraComponents.Option {...props}>
                      <Flex alignItems="center">
                        <Box mr={2}>{children}</Box>
                      </Flex>
                    </chakraComponents.Option>
                  ),
                  DropdownIndicator: () => null,
                }}
                isClearable
                disabled={disableWhenJP}
              />
            </Box>
          </Flex>
        </Flex>
      )}
      {watch("recipient_type") == 'MULTIPLE' && (
        <Flex alignItems="center" gap={6} width="100%">
          <Flex width="50%">
            <Box width="100%">
              <ChakraSelect
                options={userOptions}
                placeholder={transLabel("addUsers")}
                value={receiver}
                isLoading={isLoading}
                noOptionsMessage={() => null}
                chakraStyles={{
                  placeholder: (provided) => ({
                    ...provided,
                    color: "gray.400",
                  }),
                  control: (provided) => ({
                    ...provided,
                    cursor: "text",
                  }),
                }}
                components={{
                  Option: ({ children, ...props }) => (
                    <chakraComponents.Option {...props}>
                      <Flex alignItems="center">
                        <Box mr={2}>{children}</Box>
                      </Flex>
                    </chakraComponents.Option>
                  ),
                  DropdownIndicator: () => null,
                }}
                isMulti
                disabled={disableWhenJP}
              />
            </Box>
          </Flex>
          <Text fontSize="sm" ml="2">Or</Text>
          <ButtonType
            text="DOWNLOAD TEMPLATE"
            fontSize="12px"
            sizeIcon="8px"
            btnType="primary-new-outline"
            disabled={disableWhenJP}
          />
          <ButtonType
            text="IMPORT CSV"
            fontSize="12px"
            sizeIcon="8px"
            onClick={onOpenImportCSV}
            disabled={disableWhenJP}
          />
        </Flex>
      )}
      {receiver?.length > 0 && (
        <Flex wrap="wrap" mb={4}>
          {receiver.map((user) => (
            <Tag key={user.value} colorScheme="blue" mr={2} mb={2}>
              <TagLabel>{user.label}</TagLabel>
            </Tag>
          ))}
        </Flex>
      )}
      {errors?.receivers?.message && <TextError>{errors?.receivers?.message}</TextError>}
      {fileSelect && (
        <Flex alignItems="cetner" flexWrap="wrap" gap="8px" mt="8px">
          <Flex
            background="#e0ffff"
            padding="4px 6px"
            alignItems="center"
            borderRadius="6px"
          >
            <Text fontSize="14px" fontWeight="400">{fileSelect?.name}</Text>
          </Flex>
        </Flex>
      )}
      {watch("recipient_type") == 'MULTIPLE' && (
      <Flex direction="row" gap="12px" w="100%" marginTop="12px">
        <FormLabel margin="0px" width="30%">{transLabel("userRecipient")}</FormLabel>
        <Grid templateColumns='repeat(1, 1fr)' gap='16px' width="70%">
          <FormRadio
            name="multiple_item_recipient_type"
            options={USER_RECIPIENTS}
            isRequired
            disabled={disableWhenJP}
          />
        </Grid>
      </Flex>
      )}
      <Flex direction="row" gap={4} w="100%" alignItems="flex-end" justifyContent="flex-start">
        <Text fontSize="16px" fontWeight="500">
          {transLabel("selectPresentBoxItem")}
        </Text>
        <ButtonType
          text="+"
          fontSize="25px"
          sizeIcon="8px"
          btnType="primary-new-outline"
          onClick={() => onOpenSelectItem()}
          disabled={disableWhenJP}
        />
      </Flex>
      {errors?.resource?.message && <TextError>{errors?.resource?.message}</TextError>}
      {watch("multiple_item_recipient_type") !== 'CUSTOM' && (
        gifts.length > 0 && (
          <TableCustom
          labels={labels}
          dataRow={gifts}
          isCheck={false}
        >
          {gifts?.map((item, index) => {
            return (
              <RowGift
                key={index}
                item={item}
                handleDelete
              />
            );
          })}
        </TableCustom>
        )
      )}
      { renderCustomItem() }
      <FormLabel mt="3" direction="row" >{transLabel("receiptExpireSetting")}<span style={{ color: 'red' }}>&nbsp;*</span></FormLabel>
      <Flex>
        <Flex direction="row" gap={4} w="100%" alignItems="center" justifyContent="space-between">
          <FormCheckbox
            name="is_no_expiration"
            label={transLabel("noExpire")}
            isChecked={isNoExpire}
            wrapperProps={{
              display: "flex",
              alignItems: "center",
              margin: "0"
            }}
            disabled={disableWhenJP}
          />
          <Flex justifyContent="flex-end" alignItems="center" gap="4px">
            <FormInput
              maxW="100%"
              type="number"
              name="expired_date"
              placeholder={transLabel("enterExpireDate")}
              disabled={isNoExpire || disableWhenJP}
            />
            <Text fontSize="12px" >
              {transLabel("day")}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
