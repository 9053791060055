/* eslint-disable react/jsx-key */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-unused-vars */
// Chakra imports
import {
  Box,
  Flex,
  Icon,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import ImageDefault from "assets/img/empty-image.png";
import CheckBoxType from "components/Checkbox";
import { TYPE_PRIZE } from "constants/constants";
import { EditIcon } from "components/Icons/EditIcon";
import { ViewIcon } from "components/Icons/ViewIcon";
import { getDayLLLL } from "utils/time";
import { MODE_TYPE_SHOW } from "views/Machine/MachineSettings/constants";
import { useMemo } from "react";
import { usePermission } from "hooks/usePermission";
import { PERMISSIONS } from "constants/permissions";

const PrizeRow = ({
  checkedIds,
  setCheckedIds,
  handleSetDataDetail,
  handleOpenCreateOrUpdate,
  listPrize,
}) => {
  const textColor = useColorModeValue("#000000", "white");
  const { isHasPermissionEdit } = usePermission(PERMISSIONS.GAME_CONTROL_PRIZE);

  const isMachineChecked = useMemo(() => {
    return checkedIds.some((item) => item?.id === listPrize?.id);
  }, [listPrize?.id, checkedIds]);

  const handleCheckboxChange = () => {
    if (isMachineChecked) {
      setCheckedIds(
        checkedIds.filter((checkedId) => checkedId?.id !== listPrize?.id)
      );
    } else {
      setCheckedIds([...checkedIds, listPrize]);
    }
  };

  return (
    <Tr key={listPrize?.id}>
      <Td width="10px">
        <CheckBoxType
          isChecked={isMachineChecked}
          onChange={() => handleCheckboxChange()}
          colorScheme="teal"
        />
      </Td>
      <Td>
        <Flex gap={6} align="center">
          <Icon
            fontSize="30px"
            borderRadius="8px"
            cursor="pointer"
            as={ViewIcon}
            onClick={() => handleSetDataDetail(listPrize?.id)}
          />
          {isHasPermissionEdit && (
            <Icon
              onClick={() => handleOpenCreateOrUpdate()}
              fontSize="30px"
              borderRadius="8px"
              p="7px"
              cursor="pointer"
              bg="#1480FF"
              as={EditIcon}
            />
          )}
        </Flex>
      </Td>
      <Td minW="180px">
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {listPrize?.name || "---"}
        </Text>
      </Td>
      <Td p="2" minW="60px" padding={"0 28px"}>
        <Box
          bgImage={listPrize?.image_url ? listPrize?.image_url : ImageDefault}
          w="50px"
          h="50px"
          backgroundSize="contain"
          backgroundRepeat='no-repeat'
        />
      </Td>
      <Td minW="150px">
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {listPrize?.prize_type
            ? TYPE_PRIZE[listPrize?.prize_type]
            : "---"}
        </Text>
      </Td>
      <Td minW="150px">
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {listPrize?.game_mode_name
            ? listPrize?.game_mode_name
            : "---"}
        </Text>
      </Td>
      <Td minW="150px">
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {listPrize?.prize_type === "TREASURE_BOX" ? "--" :listPrize?.total_items}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {MODE_TYPE_SHOW[listPrize?.mode_type] || "---"}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {listPrize?.prize_status || "---"}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {listPrize?.play_fee?.name || "---"}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {listPrize?.sale_period ? "Yes" : "No" || "---"}
        </Text>
      </Td>
      <Td minW="200px">
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {listPrize?.start_sale_period
            ? getDayLLLL(listPrize?.start_sale_period * 1000)
            : "---"}
        </Text>
      </Td>
      <Td minW="200px">
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {listPrize?.end_sale_period
            ? getDayLLLL(listPrize?.end_sale_period * 1000)
            : "---"}
        </Text>
      </Td>
    </Tr>
  );
};

export default PrizeRow;
