import {
  Flex,
  Text,
  useToast
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import TableCustom from "components/Tables/CustomTable";
import Paginate from "components/Paginate";
import XpRewardRow from "./row";
import useWithToast from "hooks/useWithToast";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CardHeader from "components/Card/CardHeader";
import { getXpRewardGameMode, updateXpRewardGameMode } from "api/xpReward.api";

export default function XpRewardGameMode(props) {
  const { t } = useTranslation("error");
  const [isLoading, setIsLoading] = useState(false);
  const [listXpReward, setListXpReward] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const { showToastSuccess, showToastFail } = useWithToast();
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
  });
  const toast = useToast();

  const labels = [
    "ACTION",
    "MODE TYPE",
    "PLAY",
    "GET PRIZE",
  ];

  const getListXpRewardGameMode = useCallback(
    async (newParams = {}) => {
      try {
        setIsLoading(true);
        const { data } = await getXpRewardGameMode({
          ...params,
          ...newParams,
        });
        if (data?.success) {
          const res = data?.data;
          setListXpReward(res?.records);
          setTotalPage(res?.total_page);
        }
      } catch (error) {
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      } finally {
        setIsLoading(false);
      }
    },
    [params]
  );

  useEffect(() => {
    getListXpRewardGameMode();
  }, [params]);

  const handleSaveXpRewardGameMode = async (id, data) => {
    try {
      const res = await updateXpRewardGameMode(id, data);
        if (res?.data?.success) {
          showToastSuccess({
            title: "Update XP reward successfully!",
          });
          await getListXpRewardGameMode();
        } else {
          showToastFail({
            title: t(`${res?.data?.messages[0]}`) || "Create fail!",
          });
        }
      } catch (error) {
        toast({
          description: error?.message || error?.messages[0],
          status: "error",
          position: "bottom-right",
        });
      }
  }

  const handleChangePage = (page) => {
    setParams({
      ...params,
      page,
    });
  };

  const onChangeLimit = (limit) => {
    setParams({
      ...params,
      page: 1,
      limit,
    });
  };

  return (
    <Flex flexDirection="column" w="100%">
      <Card p="0px">
        <CardHeader px="22px" mb="20px" w="100%">
          <Text
            fontSize="sm"
            fontWeight="bold"
            whiteSpace="nowrap"
          >
            XP rewards depend on Game Mode
          </Text>
        </CardHeader>
        <CardBody overflowX="auto">
          <TableCustom
            labels={labels}
            isLoading={isLoading}
            isCheck={false}
            dataRow={listXpReward}
          >
            <>
              {listXpReward?.map((item) => {
                return (
                  <XpRewardRow
                    key={item?.id}
                    item={item}
                    onSave={handleSaveXpRewardGameMode}
                    {...props}
                  />
                );
              })}
            </>
          </TableCustom>
        </CardBody>
        <Paginate
          page={params?.page}
          pageCount={totalPage}
          limit={params.limit}
          onChangeLimit={onChangeLimit}
          handleChangePage={handleChangePage}
        />
      </Card>
    </Flex>
  );
}
