import { Td, Text, Tr, useColorModeValue, Flex, Icon } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { CheckIcon } from "components/Icons/CheckIcon";
import { EditIcon } from "components/Icons/EditIcon";
import CheckBoxType from "components/Checkbox"
import FormTextarea from "components/form/FormTextAria";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import schemaKOL from "./schema"
export default function UserKOLRow({
  item,
  handleUpdate,
  checkedIds,
  setCheckedIds,
  isHasPermissionEdit,
  handleOpenEdit,
}) {
  const textColor = useColorModeValue("#000000", "white");
   const [isEdit, setIsEdit] = useState(false);

   const form = useForm({
    resolver: yupResolver(schemaKOL()),
    defaultValues: {
      id: item ? item?.id : "",
      user_id: item ? item?.user_id : "",
      user_name: item ? item?.user_name : "",
      email: item ? item?.email : "",
      note: item ? item?.note : "",
    }
  });
  const {
    setValue
  } = form;

  const handleCheckboxChange = () => {
    if (checkedIds.includes(item?.id)) {
      setCheckedIds(
        checkedIds.filter((checkedId) => checkedId !== item?.id)
      );
    } else {
      setCheckedIds([...checkedIds, item?.id]);
    }
  };

  const onSubmit = form.handleSubmit((data) => {
    handleUpdate(data)
    setIsEdit(false);
  });
  return (
    <FormProvider {...form}>
      <Tr>
        <Td>
          <CheckBoxType
            isChecked={checkedIds.includes(item?.id)}
            onChange={() => handleCheckboxChange()}
            colorScheme="teal"
          />
        </Td>
        <Td>
          {isHasPermissionEdit && (
            <Flex gap={6} align="center">
              {isEdit ? (
                <Icon
                  onClick={onSubmit}
                  fontSize="30px"
                  borderRadius="8px"
                  p="7px"
                  cursor="pointer"
                  bg="#07CF84"
                  as={CheckIcon}
                />
              ) : (
                <Icon
                  fontSize="30px"
                  borderRadius="8px"
                  p="7px"
                  cursor="pointer"
                  bg="#1480FF"
                  as={EditIcon}
                  onClick={() => handleOpenEdit(item)}
                />
              )}
            </Flex>
          )}
        </Td>
        <Td>
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {item?.id}
          </Text>
        </Td>
        <Td>
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {item?.user_name}
          </Text>
        </Td>
        <Td>
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {item.email}
          </Text>
        </Td>
        <Td maxWidth="300px">
          {isEdit ? (
            <FormTextarea
              minH='90px'
              borderRadius='8px'
              name="note"
              onChange={(e) => {
                setValue("note", e.target.value);
              }}
            />
          ) : (
            <Text color={textColor} fontSize="sm" fontWeight={400}>
              {item.note}
            </Text>
          )}

        </Td>
      </Tr>
    </FormProvider>
  );
}
