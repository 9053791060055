export const listCoinSubTypeBCoin = [
  {
    value: "",
    label: "None",
  },
  {
    value: "COIN2_LOCK",
    label: "BCoin2 Lock",
  },
  {
    value: "COIN2_UNLOCK",
    label: "BCoin2 Unlock",
  },
  {
    value: "COIN1_FREE",
    label: "BCoin1 Free",
  },
  {
    value: "COIN1_PAID",
    label: "BCoin1 Paid",
  },
]

export const listCoinSubTypeSphere = [
  {
    value: "",
    label: "None",
  },
  {
    value: "COIN1_FREE",
    label: "Sphere Free",
  },
  {
    value: "COIN1_PAID",
    label: "Sphere Paid",
  },
]

export const listCoinSubTypeOrb = [
  {
    value: "",
    label: "None",
  },
  {
    value: "COIN1_FREE",
    label: "Orb Free",
  },
  {
    value: "COIN1_PAID",
    label: "Orb Paid",
  },
]
