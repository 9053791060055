import {
  Box,
  Flex,
  Grid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import ButtonType from "components/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import FormInput from "components/form/FormInput";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import ValidateShowPassword from "./ValidateShowPassword";
import { getPaymentFincode, getPaymentSlash, updatePaymentFincode, updatePaymentSlash } from "api/system";
import ValidateUpdate from "./ValidateUpdate";

export default function PaymentSystem({ isHasPermissionEdit }) {
  const toast = useToast();
  const textColor = useColorModeValue("#000000", "white");
  const [isEdit, setIsEdit] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [isShowPasswordFincode, setIsShowPasswordFincode] = useState(false);
  const [isShowPasswordSlash, setIsShowPasswordSlash] = useState(false);
  const [isShowAll, setIsShowAll] = useState({
    fincode: false,
    slash: false,
  });

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { 
    isOpen: isOpenSlash, 
    onOpen: onOpenSlash, 
    onClose: onCloseSlash, 
  } = useDisclosure();

  const { 
    isOpen: isOpenUpdate, 
    onOpen: onOpenUpdate, 
    onClose: onCloseUpdate, 
  } = useDisclosure();

  const handleTabsChange = (index) => {
    setTabIndex(index);
  };

  const form = useForm();
  
  const {
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { isSubmitting },
  } = form;  
    
  const onSubmit = async (data, e) => {
    e.preventDefault();
    try {
      onOpenUpdate()
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const handleUpdate = async (data) => {
    try {
      if (tabIndex === 0) {
        const dataSubmit = {
          ...data,
          api_key: watch('api_key'),
          base_url: watch('base_url'),
          signature: watch('signature'),
          expired_time_fincode: watch('expired_time_fincode'),
        };
        const res = await updatePaymentFincode(dataSubmit);
        if (res?.data?.success) {
          toast({
            title: "Invitation updated successfully!.",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
          onCloseUpdate()
          setIsEdit(false)
        } else {
          toast({
            description: res?.data?.message || res?.data?.messages[0],
            status: "error",
            position: "bottom-right",
          });
        }
      } else {
        const dataSubmit = {
          ...data,
          auth_token: watch('auth_token'),
          hash_token: watch('hash_token'),
          api_base_url: watch('api_base_url'),
          expired_time_slash: watch('expired_time_slash'),
        }
        const res = await updatePaymentSlash(dataSubmit);
        if (res?.data?.success) {
          toast({
            title: "Invitation updated successfully!.",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
          onCloseUpdate()
          setIsEdit(false)
        } else {
          toast({
            description: res?.data?.message || res?.data?.messages[0],
            status: "error",
            position: "bottom-right",
          });
        }
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  }

  const handleShowPass = (type) => {
    if (type === 'fincode') {
      if (isShowAll?.fincode) {
        setIsShowPasswordFincode(!isShowPasswordFincode);
      } else {
        onOpen();
      }
    } else {
      if (isShowAll?.slash) {
        setIsShowPasswordSlash(!isShowPasswordSlash);
      } else {
        onOpenSlash();
      }
    }
  }

  const getFincode = async (params) => {
    try {
      const { data } = await getPaymentFincode(params);
      if (data?.success) {
        const res = data?.data;
        Object.keys(res).forEach(function(key, index) {
          setValue(key, res[key]);
        });
      }
      return data
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const getSlash = async (params) => {
    try {
      const { data } = await getPaymentSlash(params);
      if (data?.success) {
        const res = data?.data;
        Object.keys(res).forEach(function(key, index) {
          setValue(key, res[key]);
        });
      }
      return data
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  useEffect(() => {
    getFincode({ 
      get_sensitive_info: false,
    });
    getSlash({ 
      get_sensitive_info: false,
    });
  }, []);

  const renderForm = () => {
    return (
      <FormProvider {...form} >
        <form id="payment-form" onSubmit={handleSubmit(onSubmit)}>
          <Tabs
            mb={4}
            index={tabIndex}
            variant="unstyled"
            onChange={handleTabsChange}
          >
            <TabList gap={2}>
              <Tab
                fontSize="16px"
                fontWeight="700"
                boxShadow="none !important"
                color={textColor}
                width="100%"
                _selected={{
                  color: "#348cff",
                  backgroundImage: "#fff",
                  borderBottom: '1px solid #348cff'
                }}
              >
                Fincode
              </Tab>
              <Tab
                fontSize="16px"
                fontWeight="700"
                boxShadow="none !important"
                color={textColor}
                width="100%"
                _selected={{
                  color: "#348cff",
                  backgroundImage: "#fff",
                  borderBottom: '1px solid #348cff'
                }}
              >
                Slash
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel padding="24px 0">
                <Flex direction="column" w="100%" gap={4}>
                  <Grid templateColumns="repeat(1, 1fr)" gap={4}>
                    <Flex direction="column" gap={4}>
                      <FormInput
                        maxW="100%"
                        name="base_url"
                        label='API Url'
                      />
                      <FormInput
                        maxW="100%"
                        name="api_key"
                        type="password"
                        label='API Key'
                        renderRight={true}
                        isShowPassword={isShowPasswordFincode}
                        handleShowPass={() => handleShowPass('fincode')}
                      />
                      <FormInput
                        maxW="100%"
                        name="expired_time_fincode"
                        type="password"
                        label='Expired Time'
                        renderRight={true}
                        isShowPassword={isShowPasswordFincode}
                        handleShowPass={() => handleShowPass('fincode')}
                      />
                      <FormInput
                        maxW="100%"
                        name="signature"
                        type="password"
                        label='Signature'
                        renderRight={true}
                        isShowPassword={isShowPasswordFincode}
                        handleShowPass={() => handleShowPass('fincode')}
                      />
                    </Flex>
                  </Grid>
                </Flex>
              </TabPanel>
              <TabPanel padding="24px 0">
                <Flex direction="column" w="100%" gap={4}>
                  <Grid templateColumns="repeat(1, 1fr)" gap={4}>
                    <Flex direction="column" gap={4}>
                      <FormInput
                        maxW="100%"
                        name="api_base_url"
                        label='API Url'
                      />
                      <FormInput
                        maxW="100%"
                        name="auth_token"
                        type="password"
                        label='Auth Token'
                        renderRight={true}
                        isShowPassword={isShowPasswordSlash}
                        handleShowPass={() => handleShowPass('slash')}
                      />
                      <FormInput
                        maxW="100%"
                        name="expired_time_slash"
                        type="password"
                        label='Expired Time'
                        renderRight={true}
                        isShowPassword={isShowPasswordSlash}
                        handleShowPass={() => handleShowPass('slash')}
                      />
                      <FormInput
                        maxW="100%"
                        name="hash_token"
                        type="password"
                        label='Hash Token'
                        renderRight={true}
                        isShowPassword={isShowPasswordSlash}
                        handleShowPass={() => handleShowPass('slash')}
                      />
                    </Flex>
                  </Grid>
                </Flex>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </form>
      </FormProvider>
    );
  };

  return (
    <Flex flexDirection="column" w="100%">
      <Card maxW="80%" mx="0px">
        <CardHeader mb="20px" mx="22px" w="100%" display="flex" alignItems="center" justifyContent="space-between">
          <Text
            fontSize="xl"
            fontWeight="bold"
            whiteSpace="nowrap"
          >
            Payment System
          </Text>
          {isHasPermissionEdit && (
            <Flex>
              {!isEdit ? (
                <ButtonType
                  text="EDIT"
                  w="100px"
                  fontSize="12px"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsEdit(!isEdit);
                  }}
                />
              ) : (
                <ButtonType
                  text="SAVE"
                  w="100px"
                  fontSize="12px"
                  type="submit"
                  form="payment-form"
                  isLoading={isSubmitting}
                />
              )}
            </Flex>
          )}
        </CardHeader>
        <CardBody 
          overflowX="auto" 
          mx="22px" 
          display="flex" 
          flexDirection="column" 
          gap="8px"
          opacity={isEdit ? 1 : 0.5}
          pointerEvents={isEdit ? 'auto' : 'none'}
        >
          {renderForm()}
        </CardBody>
      </Card>
      {isOpen && (
        <ValidateShowPassword
          isOpen={isOpen}
          onClose={onClose}
          setIsShowPassword={setIsShowPasswordFincode}
          getData={getFincode}
          type="fincode"
          setIsShowAll={setIsShowAll}
        />
      )}
      {isOpenSlash && (
        <ValidateShowPassword
          isOpen={isOpenSlash}
          onClose={onCloseSlash}
          setIsShowPassword={setIsShowPasswordSlash}
          getData={getSlash}
          type="slash"
          setIsShowAll={setIsShowAll}
        />
      )}
      {isOpenUpdate && (
        <ValidateUpdate
          isOpen={isOpenUpdate}
          onClose={onCloseUpdate}
          handleUpdate={handleUpdate}
        />
      )}
    </Flex>
  );
}
