import {
  Flex,
  Icon,
  Image,
  Stack,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import CheckBoxType from "components/Checkbox";
import { useMemo } from "react";
import { EditIcon } from "components/Icons/EditIcon";
import { ViewIcon } from "components/Icons/ViewIcon";
import { STATUS } from "constants/constants";
import SwitchType from "components/Switch";
import { STATE_SHOW } from "views/Machine/MachineSettings/constants";
import { STATUS_MACHINE } from "views/Machine/MachineState/constants";

const Row = ({
  machine,
  checkedGroups,
  setCheckedGroups,
  handleOpenCreateOrUpdate,
  handleSwitchStatus,
  handleOpenDetail,
  isHasPermissionEdit,
}) => {
  const textColor = useColorModeValue("#000000", "white");
  const isMachineChecked = useMemo(() => {
    return checkedGroups?.some((item) => item?.id === machine?.id);
  }, [machine?.id, checkedGroups]);

  const handleCheckboxChange = () => {
    if (isMachineChecked) {
      setCheckedGroups(checkedGroups.filter((item) => item?.id !== machine?.id));
    } else {
      setCheckedGroups([...checkedGroups, machine]);
    }
  };

  return (
    <Tr>
      <Td width="10px">
        <CheckBoxType
          isChecked={isMachineChecked}
          onChange={() => handleCheckboxChange()}
          colorScheme="teal"
        />
      </Td>
      <Td>
        <Flex gap={6} align="center">
          <Icon
            fontSize="30px"
            borderRadius="8px"
            cursor="pointer"
            as={ViewIcon}
            onClick={handleOpenDetail}
          />
          {isHasPermissionEdit && (
            <Icon
              onClick={handleOpenCreateOrUpdate}
              fontSize="30px"
              borderRadius="8px"
              p="7px"
              cursor="pointer"
              bg="#1480FF"
              as={EditIcon}
            />
          )}
        </Flex>
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {machine?.name}
          </Text>
        </Stack>
      </Td>
      <Td>
        <Image w="50%" lazyLoad={true} src={machine?.image_url} alt="NFT" />
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <Flex alignItems="center" gap="4px" flexWrap="wrap">
            {machine?.prize_items?.map((gacha, index) => (
              index < 3 && <Text key={index} p="6px 8px" borderRadius="16px" color="#fff" bg="#8bd5ff">
                {gacha.name}
              </Text>
            ))}
            {machine?.prize_items?.length > 3 && <Text>({machine?.prize_items?.length - 3} more)</Text>}
          </Flex>
        </Stack>
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <Text color={textColor} fontSize="sm" fontWeight={400} >
            {STATUS_MACHINE[machine?.status]}
          </Text>
          <SwitchType
            isChecked={machine?.status === STATUS.ACTIVE}
            onChange={handleSwitchStatus}
            isDisabled={!isHasPermissionEdit}
          />
        </Stack>
      </Td>
    </Tr>
  );
};

export default Row;
