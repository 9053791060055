import http from "./http";
import END_POINT from "./constants";
import { convertParams, omitParams } from "utils/object";

export const getApDistribution = (p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.HUNTER_AR_PARAM}?${params}`);
};

export const updateApDistribution = (rarity, data, option = {}) => {
  return http.put(`${END_POINT.HUNTER_AR_PARAM}/${rarity}`, data, option);
};
