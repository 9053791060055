import {
  Grid,
  Flex
} from "@chakra-ui/react";
import FormInput from "components/form/FormInput";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Paginate from "components/Paginate";
import TableCustom from "components/Tables/CustomTable";
import NFTRow from "./nftRow"
export default function NFTItem({
  onOpenNFTItem,
  items,
  nftMngType,
  handleDeleteNFTItem
}) {
  const labels = [
    "",
    "NAME",
    "IMAGE",
    "RARITY",
    "TRAIT",
    "VALUE"
  ];

  const nftArray = Object.values(items).flatMap(item =>
    Object.values(item).filter(nft => typeof nft === "object" && Object.keys(nft).length > 0)
);

  return (
    <Grid templateColumns="repeat(1, 1fr)" gap={4}>
      <FormInput
        label="Add item(s)"
        maxW="100%"
        name="nft_items"
        value=""
        placeholder="Select Item(s)"
        onClick={() => onOpenNFTItem()}
        autoComplete="off"
      />
      <Flex flexDirection="column">
        <Card px="0px">
          <CardBody overflowX="auto">
            <TableCustom
              labels={labels}
              dataRow={nftArray}
              isCheck={false}
            >
              <>
                {nftArray?.map((item) => {
                  return (
                    <NFTRow
                      key={item?.id}
                      item={item}
                      nftMngType={nftMngType}
                      handleDeleteNFTItem={handleDeleteNFTItem}
                    />
                  );
                })}
              </>
            </TableCustom>
          </CardBody>
        </Card>
      </Flex>
    </Grid>
  );
}
