/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useState, useEffect } from 'react'
import { StorageKeys } from 'constants/enum'
import { useDispatch, useSelector } from 'react-redux'
import { userinfoReducerApi } from 'stores/user/action'
import { userinfoSelector } from 'stores/user/selector'
import { getLocalStorage } from 'utils/storage'

export const UserContext = createContext({
  isAuth: false,
  isLoading: false,
  setIsLoading: () => {},
  triggerAuth: () => {},
})

export function UserProvider(props) {
  const [isAuth, setIsAuth] = useState(false)
  // const [isLoading, setIsLoading] = useState(true)
  const dispatch = useDispatch()
  const { userinfo } = useSelector(userinfoSelector)

  const getAuthState = async () => {
    // setIsLoading(true)
    const token = getLocalStorage(StorageKeys.TOKEN)
    if (!token) {
      setIsAuth(false)
    } else {
      const users = getLocalStorage(StorageKeys.USERINFO)
      const info = JSON.parse(users || '{}')
      if (!info || !info.name) {
        const { payload } = await dispatch(userinfoReducerApi())
        if (!payload) {
          setIsAuth(false)
        }
      } else {
        setIsAuth(true)
      }
    }
  }
  
  useEffect(() => {
    getAuthState()

    // return () => clearTimeout(timeout)
  }, [userinfo])

  return (
    <>
      <UserContext.Provider
        value={{ isAuth, triggerAuth: getAuthState }}
      >
        {props.children}
      </UserContext.Provider>
    </>
  )
}
