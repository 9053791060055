import {
  Box,
  Flex,
  Text,
  useToast,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import EmailRegistration from "./EmailRegistration";
import EmailTemplate from "./EmailTemplate";

export default function SiteEmail({ isHasPermissionEdit }) {

  return (
    <Flex flexDirection="column" w="100%">
      <Card p="0px">
        <CardHeader px="22px" w="100%">
          <Text
            fontSize="xl"
            fontWeight="bold"
            whiteSpace="nowrap"
          >
            Email
          </Text>
        </CardHeader>
        <CardBody overflowX="auto" display="flex" flexDirection="column" gap="8px">
          <EmailRegistration
            title="Email Registration" 
            isHasPermissionEdit={isHasPermissionEdit}
          />
          <EmailTemplate 
            title="Email Template"
            isHasPermissionEdit={isHasPermissionEdit}
          />
        </CardBody>
      </Card>
    </Flex>
  );
}
