import {
  FormLabel,
  Text,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
} from "@chakra-ui/react";
import { getDayLLLL } from "utils/time";
import { STATUS_USER_PRESENTBOX } from "constants/constants";
import TableCustom from "components/Tables/CustomTable";
import { useState, useEffect } from "react";
import RowGift from "./rowGift"

export default function DetailPresentBoxHistory({
  isOpen,
  onClose,
  dataDetail,
}) {
  const textColor = useColorModeValue("#000000", "white");
  const [gifts, setGifts] = useState([]);
  const labels = [
    "NAME",
    "TYPE",
    "SUB TYPE",
    "VALUE",
    ""
  ];

  useEffect(() => {
    const gift = dataDetail?.gift;
    let resourceData = {};
    let nftData = [];
    let card = [];

    if (gift?.resource) {
      resourceData = gift.resource;
    }
    if (gift?.nft_items) {
      nftData = gift.nft_items;
    }
    if (gift?.event_invitation_cards) {
      card = gift.event_invitation_cards;
    }

    const giftsData = [
      ...card.map((item) => ({
        type: "Invitation Card",
        name: item?.card_name || "Unknown",
        value: item?.amount || 0,
      })),

      ...Object.entries(resourceData)
        .flatMap(([key, value]) =>
          value > 0
            ? [{ type: "Point & Currency", name: key, value: value }]
            : []
        ),
        ...nftData.map((nft) => {
        const name =
        nft.nft_detail_hunter?.name ||
        nft.nft_detail_gauntlet?.name ||
        nft.nft_detail_bounty_ball?.name ||
        "Unknown";
          return {
            type: "NFT",
            name: name,
            value: 1,
          };
        })
    ];

    setGifts(giftsData);

    }, [dataDetail]);


  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxW="1000px" overflowY="auto" maxHeight="90vh" overflow="auto">
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >
          DETAIL PRESENT BOX HISTORY
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px" height="calc(100% - 70px)" overflowY="auto">
          <Grid templateColumns="repeat(1, 1fr)" gap={4}>
            <div>
              <FormLabel fontWeight="700" fontSize="16px" color={textColor}>
                Mgmt Name
              </FormLabel>
              <Text
                ml={2}
                mt={4}
                fontSize="16px"
                fontWeight="400"
                color={textColor}
              >
                {dataDetail.mgmt_name}
              </Text>
            </div>
            <div>
              <FormLabel fontWeight="700" fontSize="16px" color={textColor}>
                Name
              </FormLabel>
              <Text
                ml={2}
                mt={4}
                fontSize="16px"
                fontWeight="400"
                color={textColor}
              >
                {dataDetail?.name_en}
              </Text>
            </div>
            <div>
              <FormLabel fontWeight="700" fontSize="16px" color={textColor}>
                Status
              </FormLabel>
              <Text
                ml={2}
                mt={4}
                fontSize="16px"
                fontWeight="400"
                color={textColor}
              >
                  {STATUS_USER_PRESENTBOX[dataDetail?.status]}
              </Text>
            </div>
            <div>
              <FormLabel fontWeight="700" fontSize="16px" color={textColor}>
                Users
              </FormLabel>
              <Text
                ml={2}
                mt={4}
                mb={2}
                fontSize="16px"
                fontWeight="400"
                color={textColor}
              >
                  {dataDetail?.user_name_display}
              </Text>
            </div>
            <div>
              <FormLabel fontWeight="700" fontSize="16px" color={textColor}>
                Gift
              </FormLabel>
              <Text
                ml={2}
                mt={4}
                mb={2}
                fontSize="16px"
                fontWeight="400"
                color={textColor}
              >
                  {gifts.length > 0 ? (
                    <TableCustom
                      labels={labels}
                      dataRow={gifts}
                      isCheck={false}
                    >
                      {gifts?.map((item, index) => {
                        return (
                          <RowGift
                            key={index}
                            item={item}
                            handleDelete
                            isDetail={true}
                          />
                        );
                      })}
                    </TableCustom>
                    ) : 'NULL'
                  }
              </Text>
            </div>
            <div>
              <FormLabel fontWeight="700" fontSize="16px" color={textColor}>
                Distribution Date
              </FormLabel>
              <Text
                ml={2}
                mt={4}
                fontSize="16px"
                fontWeight="400"
                color={textColor}
              >
                  {getDayLLLL(dataDetail?.distribution_start_date * 1000)}
              </Text>
            </div>
          </Grid>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
