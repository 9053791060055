import { Icon } from "@chakra-ui/icons";
import { Badge, Flex, Image, Td, Text, Tr, useColorModeValue } from "@chakra-ui/react";
import CheckBoxType from "components/Checkbox";
import { useMemo } from "react";
import { ViewIcon } from "components/Icons/ViewIcon";
import { capitalize } from "lodash";
import { TRAIT_NFT_OPTIONS, TOKEN_TYPE } from "constants/constants";
import { transformNFT } from "views/PresentBoxManagement/constant"

export default function NftsRow({
  item,
  checkedIds,
  setCheckedIds,
  handleOpenDetailUserModal,
  nftMngType,
  userId
}) {
  const textColor = useColorModeValue("#000000", "white");

  const isChecked = useMemo(() => {
    return (checkedIds?.[userId] ?? []).some((itemCheck) => {
      const checked = itemCheck?.nft_detail_hunter?.name ? itemCheck.nft_detail_hunter :
      itemCheck?.nft_detail_gauntlet?.name ? itemCheck.nft_detail_gauntlet :
      itemCheck.nft_detail_bounty_ball;
      return checked?.product_id === item?.product_id
    });
  }, [item?.product_id, checkedIds?.[userId]]);

  const handleCheckboxChange = () => {
    setCheckedIds((prevCheckedIds) => {
      const userCheckedItems = prevCheckedIds[userId] || [];

      if (userCheckedItems.some((checkedItem) => {
        const checked = getDetail(checkedItem)
        return checked.product_id === item.product_id
      })) {
        return {
          ...prevCheckedIds,
          [userId]: userCheckedItems.filter((checkedItem) => {
            const checked = getDetail(checkedItem)
            return checked.product_id !== item.product_id
          }),
        };
      } else {
        return {
          ...prevCheckedIds,
          [userId]: [...userCheckedItems, transformNFT(item)],
        };
      }
    });
  };

  function getDetail(nft) {
    return nft?.nft_detail_hunter?.name ? nft.nft_detail_hunter :
      nft?.nft_detail_gauntlet?.name ? nft.nft_detail_gauntlet :
      nft.nft_detail_bounty_ball;
  }

  const getTrait = () => {
    return (
      <Text color={textColor} fontSize="sm" fontWeight={400}>
        {TRAIT_NFT_OPTIONS.find((trait) => trait.value === item?.trait)
          ?.label ?? "-"}
      </Text>
    );
  };

  return (
    <Tr>
      <Td width="10px">
        <CheckBoxType
          isChecked={isChecked}
          onChange={handleCheckboxChange}
          colorScheme="teal"
        />
      </Td>
      <Td>
        <Flex gap={6}>
          <Icon
            onClick={() => handleOpenDetailUserModal(item)}
            fontSize="30px"
            borderRadius="8px"
            cursor="pointer"
            as={ViewIcon}
          />
        </Flex>
      </Td>
      <Td>
        <Image w="50px" lazyLoad={true} src={item?.image_url} alt="NFT" />
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.name}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {capitalize(item?.rarity)}
        </Text>
      </Td>
      <Td>
         {getTrait()}
      </Td>
      {item.token_type === TOKEN_TYPE.BOUNTY_BALL_NFT && (
        <Td>
        {item?.gimmick || "N/A"}
      </Td>
      )}
    </Tr>
  );
}
