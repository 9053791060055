import { Stack, Td, Text, Tr, useColorModeValue } from "@chakra-ui/react";
import SwitchType from "components/Switch";
import { trimCharacter } from "utils/string";
import { NFT_CHAIN } from "constants/constants";

const WalletManagementRow = ({ item, isHasPermissionEdit }) => {
  const textColor = useColorModeValue("#000000", "white");
  return (
    <Tr>
      <Td>
        <Stack direction="row" spacing="16px">
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {item?.id}
          </Text>
        </Stack>
      </Td>
      <Td>
        <Stack direction="row" spacing="16px">
          <Text color="#1480FF" fontSize="sm" fontWeight={400} minW="90px">
            {trimCharacter(item?.public_key, 14, 16)}
          </Text>
        </Stack>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {Number(item?.admin_balance)?.toFixed(5)}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {NFT_CHAIN?.filter((nft) => nft.chain_id === item.chain_id)[0].value}
        </Text>
      </Td>
      <Td>
        <SwitchType color="#FFCF3E" isReadOnly={!isHasPermissionEdit}/>
      </Td>
    </Tr>
  );
};

export default WalletManagementRow;
