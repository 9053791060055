import { Icon } from "@chakra-ui/icons";
import {
  Flex,
  Td,
  Text,
  Tr,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { updateBlockAdminApi, updateUnblockAdminApi } from "api/admin.api";
import { EditIcon } from "components/Icons/EditIcon";
import { LockIcon } from "components/Icons/LockIcon";
import { STATUS } from "constants/constants";
import useWithToast from "hooks/useWithToast";
import ModalConfirm from "components/Modal/ModalConfirm";

export default function AdminManagementRow({
  item,
  handleOpenEditModal,
  index,
  getAdmins,
  isHasPermissionEdit,
}) {
  const toast = useToast();
  const { showToastSuccess } = useWithToast();
  const textColor = useColorModeValue("#000000", "white");
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleLock = async () => {
    try {
      if (item?.status === STATUS.ACTIVE || item?.status === STATUS.BLOCKED) {
        let res
        if (item?.status === STATUS.ACTIVE) {
          res = await updateBlockAdminApi({
            "admin_ids": [item?.id]
          });
        } else {
          res = await updateUnblockAdminApi({
            "admin_ids": [item?.id]
          });
        }
        if (res) {
          showToastSuccess({
            title: `Successfully ${
              item?.status === STATUS.ACTIVE ? "blocked" : "unblocked"
            }`,
          });
          onClose();
          getAdmins();
        }
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  return (
    <>
      <Tr>
        <Td>
          <Flex gap="10px">
            {isHasPermissionEdit && (
              <>
                <Icon
                  onClick={() => {
                    handleOpenEditModal(item);
                  }}
                  fontSize="30px"
                  borderRadius="8px"
                  p="7px"
                  cursor="pointer"
                  bg="#1480FF"
                  as={EditIcon}
                />
                {item?.role?.name !== "SUPPER_ADMIN" && (
                  <Icon
                    fontSize="30px"
                    borderRadius="8px"
                    p="7px"
                    cursor="pointer"
                    bg={item?.status === STATUS.BLOCKED ? "#E50000" : "#07CF84"}
                    as={LockIcon}
                    onClick={() => {
                      // handleLock();
                      onOpen();
                    }}
                  />
                )}
              </>
            )}
          </Flex>
        </Td>
        <Td>
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {index}
          </Text>
        </Td>
        <Td>
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {item?.name}
          </Text>
        </Td>
        <Td>
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {item?.email}
          </Text>
        </Td>
        <Td>
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {item?.role?.name}
          </Text>
        </Td>
      </Tr>
      {isOpen && (
        <ModalConfirm
          isOpen={isOpen}
          onClose={onClose}
          onSubmit={handleLock}
          title=""
          description={"Are you sure you want to do this?"}
          textOk="OK"
        />
      )}
    </>
  );
}
