import { CHAIN_ID_AVAILABLE } from "constants/constants";

export const STATUS_MODE = {
  MODE_ARENA_BATTLE: "MODE_ARENA_BATTLE",
  MODE_PVP: "MODE_PVP",
  MODE_FREE: "MODE_FREE",
  MODE_GET_PRIZE: "MODE_GET_PRIZE",
};

export const STATUS_USER_OPTIONS = [
  { label: "Active", value: "ACTIVE" },
  { label: "Banned", value: "NO_ACTIVE" },
];

export const STATUS_USER = {
  ACTIVE: "ACTIVE",
  BANNED: "NO_ACTIVE",
};

export const STATUS_USER_SHOW = {
  ACTIVE: "Active",
  NO_ACTIVE: "Ban",
};

export const GAME_MODE_OPTIONS = [
  { label: "PvP", value: STATUS_MODE.MODE_PVP },
  { label: "Free", value: STATUS_MODE.MODE_FREE },
  { label: "Get Prize", value: STATUS_MODE.MODE_GET_PRIZE },
];

export const STATUS_HISTORY_PLAY_GAME_OPTIONS = [
  { label: "Win", value: "WIN" },
  { label: "Lose", value: "LOSE" },
];

export const NFT_TICKET = {
  bcoin_ticket1_k: "1000BC Ticket",
  bcoin_ticket100: "100BC Ticket",
  bcoin_ticket100_k: "100k BC Ticket",
  bcoin_ticket500: "500BC Ticket",
  bcoin_ticket_free: "Free Ticket",
};

export const LIST_CHAIN = [
  { label: "Immutable Chain", value: "13473" },
  { label: "Polygon", value: "80002" },
  { label: "Oasys Chain", value: "12401" },
];

export const LIST_CHAIN_TESTNET = [
  {
    label: "Immutable zkEVM Testnet",
    value: CHAIN_ID_AVAILABLE.IMX_TESTNET,
  },
  {
    label: "Oasys Verse Testnet",
    value: CHAIN_ID_AVAILABLE.DM2_TESTNET,
  },
  {
    label: "Polygon",
    value: CHAIN_ID_AVAILABLE.POLYGON_TESTNET,
  },
];

export const LIST_CHAIN_MAINNET = [
  {
    label: "Immutable zkEVM Testnet",
    value: CHAIN_ID_AVAILABLE.IMX_MAINNET,
  },
  {
    label: "Oasys Verse Testnet",
    value: CHAIN_ID_AVAILABLE.DM2_MAINNET,
  },
  {
    label: "Polygon",
    value: CHAIN_ID_AVAILABLE.POLYGON_MAINNET,
  },
];

export const LOGIN_TYPE = {
  WEB3_AUTH: "Web3Auth",
  IMMUTABLE: "immutable",
}
