import { Flex, Text, FormLabel, Grid, TagLabel, Tag } from '@chakra-ui/react';
import FormDatePicker from 'components/form/FormDatePicker';
import FormInput from 'components/form/FormInput';
import FormLabelComponent from 'components/form/FormLabel';
import FormUpload from 'components/form/FormUpload';
import React from 'react'
import CheckBoxType from "components/Checkbox";
import EditorLink from 'components/EditorAddLink/EditorLink'
import FormCheckbox from "components/form/FormCheckbox";
import { useState, useEffect, useMemo } from "react";

export default function FormEng(props) {
  const [isCheckedPublishNow, setIsCheckedPublishNow] = useState(false);
  const [checkedCategory, setCheckedCategory] = useState([]);
  const [descriptions, setDescriptions] = useState(null);
  const [selectedNameCountries, setSelectedNameCountries] = useState([]);
  const {
    transLabel,
    handleImageChange,
    handleDragImage,
    deleteImage,
    nameImage,
    setValue,
    setError,
    clearErrors,
    dateFormat,
    watch,
    onOpenCoutryItems,
    checkedIds,
    setCheckedIds,
    countries,
    categories,
    dataDetail,
    getValues,
    errors
  } = props

  useEffect(() => {
    if (dataDetail) {
      setCheckedIds(dataDetail?.restricted_countries);
      setCheckedCategory(dataDetail?.categories);
      setValue("type", dataDetail?.categories);
    }
  }, [dataDetail]);

  const handleCheckboxChange = (isChecked) => {
    setIsCheckedPublishNow(!isCheckedPublishNow)
    if (isChecked) {
      let now = new Date()
      setValue("publish_at", now);
      setValue("publish_immediately", true);
    } else {
      setValue("publish_at", null);
      setValue("publish_immediately", false);
    }
  };

  const handleCheckboxChangeAll = (isChecked) => {
    const newCheckedIds = isChecked ? countries.map(country => country.country_code) : [];
    setCheckedIds(newCheckedIds);
    setValue("restricted_countries", newCheckedIds);
    selectedCountries
    clearErrors("restricted_countries")
  }

  const selectedCountries = useMemo(() => {
    const countryItems = watch("restricted_countries") ? watch("restricted_countries") : [];
    return countryItems.length > 0 ? countryItems : checkedIds;
  }, [watch("restricted_countries")]);

  const handleCheckboxChangeCategory = (isChecked, category) => {
    const newCategory = isChecked ? [category] : [];
    setValue("type", newCategory);
    setCheckedCategory(newCategory)
  };

  const categoryChecked = () => {
    if (!categories.length || !dataDetail?.categories.length) return [];
    return categories
        .filter(category => dataDetail.categories.includes(category.id))
        .map(category => category.id);
  };

  useEffect(() => {
    setCheckedCategory(getValues("type") || categoryChecked());
  }, [categories, dataDetail]);

  useEffect(() => {
    setDescriptions(getValues('content_en') || dataDetail?.content_en)
  }, []);

  useEffect(() => {
    const filteredNames = countries
    .filter(country => selectedCountries.includes(country.country_code))
    .map(country => country.country_name);

  setSelectedNameCountries(filteredNames);
  }, [selectedCountries]);

  return (
    <Flex flexDirection="column" gap="12px">
      <Flex w="100%" gap="8px">
        <Flex direction="column" gap="8px" w="100%">
          <FormLabelComponent
            m="0px"
            title={transLabel("publicDate")}
            isRequired
          />
          <Flex direction="row" gap="10px" w="100%" mr="3" alignItems="center">
            <Flex direction="column" w="50%">
              <FormDatePicker
                name="publish_at"
                showTimeSelect
                dateFormat={dateFormat}
                placeholder={transLabel("selectDate")}
                h="40px"
                disabled={isCheckedPublishNow}
              />
            </Flex>
            <Text fontSize="14px" fontWeight="400" mt={errors.publish_at ? "-23px" : "0px"}>OR</Text>
            <CheckBoxType
               mt={errors.publish_at ? "-24px" : "0px"}
              isChecked={isCheckedPublishNow}
              onChange={(e) => handleCheckboxChange(e.target.checked)}
              colorScheme="teal"
            />
             <Text fontSize="14px" fontWeight="400" mt={errors.publish_at ? "-23px" : "0px"}>{transLabel("publicNow")}</Text>
          </Flex>
        </Flex>
      </Flex>
      <Flex gap={2} textAlign="center">
        <FormLabel whiteSpace="nowrap" color="#000000">
          {transLabel("targetContries")} <span style={{ color: 'red' }}>&nbsp;*</span>
        </FormLabel>
        <Text
          padding="4px 8px"
          borderRadius="12px"
          background="#faf871"
          fontSize="14px"
          fontWeight="400"
        >
          {selectedCountries?.length}/{countries.length}
        </Text>
      </Flex>
      <Flex gap={2}>
        <CheckBoxType
          isChecked={selectedCountries.length === countries.length && countries.length > 0}
          onChange={(e) => handleCheckboxChangeAll(e.target.checked)}
          colorScheme="teal"
        />
        <Text fontSize="14px" fontWeight="400">{transLabel("all")}</Text>
      </Flex>
      <FormInput
        maxW="100%"
        name="restricted_countries"
        value=""
        placeholder={transLabel("addContries")}
        onClick={() => onOpenCoutryItems()}
        autoComplete="off"
      />
      <Flex direction="row" wrap="wrap" gap="4px" w="100%" padding="8px 0">
      {
        selectedNameCountries?.map((name, index) => {
          if (index < 15) {
            return (
              <Tag key={index} colorScheme="teal" mr={2} mb={2}>
                <TagLabel>{name}</TagLabel>
              </Tag>
            );
          }
          if (index === 15) {
            return (
              <Tag key={index} colorScheme="teal" mr={2} mb={2}>
                <TagLabel>...</TagLabel>
              </Tag>
            );
          }
          return null;
        })
      }
      </Flex>
      <Flex direction="column" gap="12px" w="100%" padding="8px 0">
        <FormLabelComponent
          m="0px"
          title={transLabel("category")}
          isRequired
        />
        <Grid templateColumns='repeat(2, 1fr)' gap='16px'>
          {categories.map((item) => (
            <Flex
              key={item.id}
              flexDirection="row"
              alignItems="center"
              width="calc(50% - 10px)"
              >
              <FormCheckbox
                name={`${item?.type}`}
                label=""
                isChecked={checkedCategory.includes(item.id)}
                onChange={(e) => {
                const isChecked = e.target.checked;
                handleCheckboxChangeCategory(isChecked, item.id);
                }}
                wrapperProps={{
                  display: "flex",
                  alignItems: "center",
                  margin: "0"
                }}
              />
              <Text ml="2" >{ item.name} </Text>
            </Flex>
          ))}
        </Grid>
        {errors?.type?.message && <Text fontSize="sm" color="#E53E3E">{errors?.type?.message}</Text>}
      </Flex>
      <FormInput
        maxW="100%"
        name="header_en"
        label={transLabel("header")}
        placeholder={transLabel("enterHeader")}
        isRequired
      />
      <EditorLink
        placeholder={transLabel("enterDesc")}
        setValue={setValue}
        name="content_en"
        descriptions={descriptions}
        setError={setError}
        clearErrors={clearErrors}
        lable={transLabel("description")}
      />
      {errors?.content_en?.message && <Text fontSize="sm" color="#E53E3E">{errors?.content_en?.message}</Text>}
      <FormUpload
        label={transLabel("selectImage")}
        title={transLabel("banner")}
        name="banner_url_en"
        type='IMAGE'
        accept={"image/*"}
        textButton={nameImage}
        isRequired
        handleOnChange={(e) =>
          handleImageChange(e, "banner_url_en")
        }
        handleDragImage={(val) =>
          handleDragImage(val, "banner_url_en")
        }
        deleteImage={() => deleteImage("banner_url_en")}
        w='100%'
      />
      <Text fontSize='xs' mt={1} color="#000000">
          <span
            style={{
              color: 'red',
            }}
          >
            *
          </span>{' '}
          {transLabel("imgageType")}
        </Text>
    </Flex>
  )
}
