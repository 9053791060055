import * as yup from "yup";

const ratesSchema = () =>
  yup.object().shape({
    assist: yup
      .string(),
    numerator: yup
      .number()
      .nullable()
      .positive('The number must be positive')
      .transform((value, originalValue) => {
        if (originalValue === '') return null;
        return value;
      })
      .test(
        "is-required-with-assist",
        "Required",
        function (value) {
          const { assist } = this.parent;
          return assist !== "FRACTION" ? true : (assist === "FRACTION" && value !== null);
        }
      ),
    denominator: yup
      .number()
      .nullable()
      .positive('The number must be positive')
      .min(1, "Must be greater than or equal to 1")
      .transform((value, originalValue) => {
        if (originalValue === '') return null;
        return value;
      })
      .test(
        "is-required-with-assist",
        "Required",
        function (value) {
          const { assist } = this.parent;
          return assist !== "FRACTION" ? true : (assist === "FRACTION" && value !== null);
        }
      ),
    float_rate: yup
      .number()
      .nullable()
      .transform((value, originalValue) => {
        if (originalValue === '') return null;
        return value;
      })
      .test(
        "is-required-with-assist",
        "Required",
        function (value) {
          const { assist } = this.parent;
          return assist !== "FLOAT" ? true : (assist === "FLOAT" && value !== null);
        }
      ),
    description: yup
      .string()
      .trim()
      .notRequired()
      .max(255, "Maximum limit of 255 characters."),
  });

export default ratesSchema;
