import http from "./http";
import END_POINT from "./constants";
import { convertParams, omitParams } from "utils/object";

export const getPlayerLevelXp = (p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.PLAYER_LEVEL}?${params}`);
};

export const importPlayerLevelXp = (data) => {
  return http.put(`${END_POINT.PLAYER_LEVEL}/import-csv`, data, {
    headers: {
      "Content-type": "multipart/form-data",
    },
  });
};

export const getPlayerLevelAp = (p) => {
  const params = convertParams(omitParams(p));
  return http.get(`${END_POINT.AP_REWARD}?${params}`);
};

export const importPlayerLevelAp = (data) => {
  return http.put(`${END_POINT.AP_REWARD}/import-csv`, data, {
    headers: {
      "Content-type": "multipart/form-data",
    },
  });
};
