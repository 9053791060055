import CryptoJS from "crypto-js";

export function encryptKey(privateKey, secretKey, iv) {
  const key = CryptoJS.enc.Utf8.parse(secretKey);
  const ivParse = CryptoJS.enc.Utf8.parse(iv);

  return CryptoJS.AES.encrypt(privateKey, key, {
    iv: ivParse,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  }).toString();
}

export function decryptKey(privateKey, secretKey, iv) {
  const key = CryptoJS.enc.Utf8.parse(secretKey);
  const ivParse = CryptoJS.enc.Utf8.parse(iv);

  const decrypted = CryptoJS.AES.decrypt(privateKey, key, {
    iv: ivParse,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  return decrypted.toString(CryptoJS.enc.Utf8);
}
