import {
  Flex,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  Tabs,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import ButtonType from "components/Button";
import { NUMBER } from "constants/enum";
import { uploadImageToServer } from "utils/uploadImage";
import { convertToIsoString } from "views/Event/help";
import FormEng from "./formEng";
import FormJp from "./formJp";
import ModalSelectCountries from "./ModalSelectCountries";
import schemaNotice from "./schema";
import { createNotice, updateNotice } from "api/notice";
import styled from "styled-components";
import { getDayLLLL } from "utils/time";

const TextError = styled.div`
padding: 0 24px;
color: #E53E3E;
font-size: 16px;
`
const TRANS_LABEL = {
  en: {
    newNotice: "New Notice",
    publicDate: "Public Date",
    targetContries: "Target Countries",
    publicNow: "Public Now",
    or: "OR",
    all: "All",
    selected: "selected",
    category: "Category",
    EVENT: "Event Information",
    UPDATE: "Update",
    MAINTENANCE: "Maintenance",
    ANNOUNCEMENT: "Announcement",
    header: "Header",
    description: "Description",
    banner: "Banner (Ratio 1:6)",
    imgageType: "Currenly support .png .jpg less than 5mb",
    cancel: "Cancel",
    add: "Add",
    enterHeader: "Enter Header",
    enterDesc: "Enter Description",
    selectImage: "Select image file to upload",
    addContries: "Add Countries",
    selectDate: "Select a date",
  },
  jp: {
    newNotice: "新着情報",
    publicDate: "公開日",
    targetContries: "対象国",
    publicNow: "今すぐ公開",
    or: "OR",
    all: "All",
    selected: "選択しました",
    category: "カテゴリー",
    EVENT: "イベント情報",
    UPDATE: "アップデート",
    MAINTENANCE: "メンテナンス",
    ANNOUNCEMENT: "アナウンスメント",
    header: "ヘッダー",
    description: "説明",
    banner: "バナー (比率1:6)",
    imgageType: "現在5MB以下の.png .jpgをサポートしています",
    cancel: "キャンセル",
    add: "追加",
    enterHeader: "ヘッダーを入力する",
    enterDesc: "説明を入力する",
    selectImage: "アップロードする画像ファイルを選択する",
    selectDate: "日付を選択してください",
  },
};

export default function CreateAndUpdateNotice({
  isOpen,
  onClose,
  dataDetail,
  handleCreateOrUpdateSuccess,
  categories,
  countries,
}) {
  const dateFormat = "dd MMM. YYYY HH:mm:ss";
  const [tabIndex, setTabIndex] = useState(0);
  const [trans, setTrans] = useState("en");
  const [checkedIds, setCheckedIds] = useState([]);
  const {
    isOpen: isOpenCoutryItems,
    onOpen: onOpenCoutryItems,
    onClose: onCloseCoutryItems
  } = useDisclosure();

  const textColor = useColorModeValue("#000000", "white");
  const toast = useToast();
  const form = useForm({
    resolver: yupResolver(schemaNotice()),
    defaultValues: {
      restricted_countries: [],
      publish_at: null,
      type: []
    },
  });
  const {
    handleSubmit,
    reset,
    setValue,
    setError,
    clearErrors,
    watch,
    getValues,
    formState: { errors, isSubmitting },
  } = form;

  const disableWhenJP = useMemo(() => {
    return trans === "jp";
  }, [trans]);

  const idEdit = useMemo(() => {
    return dataDetail?.id;
  }, [dataDetail]);

  useEffect(() => {
    if (dataDetail) {
      reset({
        ...dataDetail,
        publish_at: getDayLLLL(dataDetail?.publish_at * 1000),
      });
    } else {
      reset();
    }
  }, [dataDetail]);

  const handleTabsChange = (index) => {
    setTabIndex(index);
    if (index === 1) {
      setTrans("jp");
    } else {
      setTrans("en");
    }
  };

  const transLabel = (label) => {
    return TRANS_LABEL[trans][label];
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();
    const dataSubmit = {
      publish_at: convertToIsoString(data?.publish_at),
      publish_immediately: data?.publish_immediately || false,
      restricted_countries: data?.restricted_countries,
      category_ids: data?.type,
      header_jp: data?.header_jp,
      content_jp: data?.content_jp,
      header_en: data?.header_en,
      content_en: data?.content_en,
      banner_url_jp: data?.banner_url_jp,
      banner_url_en: data?.banner_url_en
    }

    try {
      if (idEdit) {
        const res = await updateNotice(dataDetail?.id, dataSubmit);
        if (res?.data?.success) {
          toast({
            title: "Notice Edited Successfully.",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
          handleCreateOrUpdateSuccess();
          onClose()
        } else {
          toast({
            description: res?.data?.message || res?.data?.messages[0],
            status: "error",
            position: "bottom-right",
          });
        }
      } else {
        const res = await createNotice(dataSubmit);
        if (res?.data?.success) {
          toast({
            title: "Notice created.",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
          handleCreateOrUpdateSuccess();
          onClose()
        } else {
          toast({
            description: res?.data?.message || res?.data?.messages[0],
            status: "error",
            position: "bottom-right",
          });
        }
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const handleImageChange = async (event, name) => {
    const image = event[0];
    await uploadImageToServer(image, toast, setValue, name);
    clearErrors(name);
  };

  const handleDragImage = async (file, name) => {
    if (file.length > NUMBER.ONE) {
      toast({
        description: "The number of uploaded images is greater than one.",
        status: "error",
        position: "bottom-right",
      });
      return false;
    }
    const image = file && file[0];
    await uploadImageToServer(image, toast, setValue, name);
  };

  const deleteImage = (name) => {
    setValue(name, null);
    clearErrors(name);
  }

  const renderForm = () => {
    return (
      <FormProvider {...form}>
        <form id="notice-form" onSubmit={handleSubmit(onSubmit)}>
          <Tabs
            mb={4}
            index={tabIndex}
            variant="unstyled"
            onChange={handleTabsChange}
          >
            <TabList gap={2}>
              <Tab
                fontSize="16px"
                fontWeight="700"
                border="1px solid #EEEFF2"
                boxShadow="none !important"
                borderRadius="5px"
                color={textColor}
                _selected={{
                  color: "white",
                  backgroundImage: "linear-gradient(310deg,#2152ff,#21d4fd)",
                }}
              >
                English
              </Tab>
              <Tab
                fontSize="16px"
                fontWeight="700"
                border="1px solid #EEEFF2"
                boxShadow="none !important"
                borderRadius="5px"
                color={textColor}
                _selected={{
                  color: "white",
                  backgroundImage: "linear-gradient(310deg,#2152ff,#21d4fd)",
                }}
              >
                Japanese
              </Tab>
            </TabList>
          </Tabs>
          <Flex direction="column" w="100%" gap={4}>
            <Grid templateColumns="repeat(1, 1fr)" gap={4}>
              <Flex direction="column" gap={4}>
                {trans === "en" ? (
                  <FormEng
                    transLabel={transLabel}
                    handleImageChange={handleImageChange}
                    handleDragImage={handleDragImage}
                    deleteImage={deleteImage}
                    setValue={setValue}
                    setError={setError}
                    clearErrors={clearErrors}
                    dateFormat={dateFormat}
                    watch={watch}
                    onOpenCoutryItems={onOpenCoutryItems}
                    checkedIds={checkedIds}
                    setCheckedIds={setCheckedIds}
                    countries={countries}
                    categories={categories}
                    dataDetail={dataDetail}
                    getValues={getValues}
                    errors={errors}
                  />
                ) : (
                  <FormJp
                    transLabel={transLabel}
                    disableWhenJP={disableWhenJP}
                    handleImageChange={handleImageChange}
                    handleDragImage={handleDragImage}
                    deleteImage={deleteImage}
                    setValue={setValue}
                    dateFormat={dateFormat}
                    onOpenCoutryItems={onOpenCoutryItems}
                    checkedIds={checkedIds}
                    setCheckedIds={setCheckedIds}
                    countries={countries}
                    categories={categories}
                    dataDetail={dataDetail}
                    getValues={getValues}
                    errors={errors}
                    setError={setError}
                    clearErrors={clearErrors}
                  />
                )}
              </Flex>
            </Grid>
          </Flex>
        </form>
      </FormProvider>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxW="700px" overflowY="auto" maxHeight="90vh" overflow="hidden">
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >
          {idEdit ? "Edit Notice" : "New Notice"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px" height="calc(100% - 150px)" overflowY="auto">{renderForm()}</ModalBody>
        {errors?.header_jp?.message && trans === "en" && <TextError>Notice header Japan is {errors?.header_jp?.message}</TextError>}
        <ModalFooter
          borderTop="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          gap="20px"
        >
          <ButtonType
            mt={4}
            btnType="primary-new-outline"
            m="auto"
            w="100%"
            h="46px"
            borderRadius="5px"
            form="notice-form"
            onClick={onClose}
          >
            Cancel
          </ButtonType>
          <ButtonType
            mt={4}
            type="submit"
            m="auto"
            w="100%"
            h="46px"
            borderRadius="5px"
            form="notice-form"
            isLoading={isSubmitting}
          >
            Add
          </ButtonType>
        </ModalFooter>
      </ModalContent>
      {isOpenCoutryItems && (
        <ModalSelectCountries
          isOpen={isOpenCoutryItems}
          onClose={onCloseCoutryItems}
          setCheckedIds={setCheckedIds}
          checkedIds={checkedIds}
          setValue={setValue}
          clearErrors={clearErrors}
          watch={watch}
          countries={countries}
          fieldName="restricted_countries"
        />
      )}
    </Modal>
  );
}
