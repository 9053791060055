import { Icon } from "@chakra-ui/icons";
import {
  Flex,
  Td,
  Text,
  Tr,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { updateBlockAdminApi, updateUnblockAdminApi } from "api/admin.api";
import { EditIcon } from "components/Icons/EditIcon";
import { STATUS } from "constants/constants";
import useWithToast from "hooks/useWithToast";

export default function ExchangeRow({
  item,
  handleOpenEditModal,
  index,
  getAdmins,
  isHasPermissionEdit,
}) {
  const toast = useToast();
  const { showToastSuccess } = useWithToast();
  const textColor = useColorModeValue("#000000", "white");

  return (
    <>
      <Tr>
        <Td>
          <Flex gap="10px">
            {isHasPermissionEdit && (
              <Icon
                onClick={() => {
                  handleOpenEditModal(item);
                }}
                fontSize="30px"
                borderRadius="8px"
                p="7px"
                cursor="pointer"
                bg="#1480FF"
                as={EditIcon}
              />
            )}
          </Flex>
        </Td>
        <Td w="50px">
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {index}
          </Text>
        </Td>
        <Td>
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {item?.first_currency}
          </Text>
        </Td>
        <Td>
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {item?.second_currency}
          </Text>
        </Td>
        <Td>
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {(item?.numerator/item?.denominator).toFixed(2)}
          </Text>
        </Td>
        <Td>
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {item?.exchange_fee}%
          </Text>
        </Td>
        <Td>
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {item?.description}
          </Text>
        </Td>
      </Tr>
    </>
  );
}
