import * as yup from "yup";

const schemaPosition = () =>
  yup.object().shape({
    position: yup
      .number()
      .nullable(true)
      .min(1, "Number of value must be greater or equal to 1")
      .required("Required")
      .transform((_, val) => {
        if (val || val === 0) {
          return Number(val) || Number(val) === 0 ? Number(val) : null;
        }
        return null;
      })
      .integer("Must be an integer"),
    size: yup
      .string()
      .required("Required"),
    type: yup
      .string()
      .required("Required"),
  });

export default schemaPosition;
