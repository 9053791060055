import { Td, Tr, Image, Flex, Icon } from "@chakra-ui/react";
import FormInput from "components/form/FormInput";
import { DeleteIcon } from "components/Icons/DeleteIcon"

export default function RowValue ({
  item,
  index,
  cardData,
  setCardData,
  handleDeleteCardItem
}) {
const handleInputChange = (value) => {
  const updatedCardData = [...cardData];
  updatedCardData[index].amount = value;
  setCardData(updatedCardData);
};

  return (
    <Tr>
      <Td>
        <Flex gap={6}>
          <Icon
            onClick={() => handleDeleteCardItem(item)}
            fontSize="30px"
            borderRadius="8px"
            cursor="pointer"
            as={DeleteIcon}
          />
        </Flex>
      </Td>
      <Td width="10px">
        {item?.card_name}
      </Td>
      <Td>
        <Image
          src={item?.url_image}
          w="28px"
          h="28px"
          alt={item?.id}
        />
      </Td>
      <Td minWidth="150px">
        <FormInput
          type="number"
          min="1"
          step="1"
          maxW="100%"
          name="amount"
          value={item.amount}
          onChange={(e) =>
            handleInputChange(Number(e?.target?.value) >= 0 ? e?.target?.value : 1)
          }
        />
      </Td>
    </Tr>
  );
}