import * as yup from "yup";

const schemaReleaseBonusSetting = () =>
  yup.object().shape({
    premium_pass: yup
      .number()
      .min(1, "Reward must be greater or equal to 1")
      .required("Required")
      .max(10, "Maximum value is 10")
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : 0;
        }
        return null;
      })
      .integer("Must be an integer"),
    limit_per_day: yup
      .number()
      .min(0, "Plays limit per day must be greater or equal to 0")
      .required("Required")
      .max(9999999, "Maximum 7 digits allowed")
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : 0;
        }
        return null;
      })
      .integer("Must be an integer"),
    limit_peak_hour: yup
      .number()
      .min(0, "Plays limit during peak hours must be greater or equal to 0")
      .required("Required")
      .max(9999999, "Maximum 7 digits allowed")
      .transform((_, val) => {
        if (val) {
          return Number(val) || Number(val) === 0 ? Number(val) : 0;
        }
        return null;
      })
      .integer("Must be an integer")
      .test(
        "less-or-equal-limit-per-day",
        "Plays limit during peak hours must be less than or equal to plays limit per day",
        function (value) {
          const { limit_per_day } = this.parent
          return limit_per_day >= value;
        }
      ),
    receive_start_date: yup
      .date()
      .required("Required")
      .test(
        "is-future-minute",
        "Receive start date must be in the future",
        function (value) {
          const now = new Date();
          now.setSeconds(0, 0);
          const inputTime = new Date(value);
          inputTime.setSeconds(0, 0);
          return inputTime.getTime() >= now.getTime();
        }
      ),
    receive_end_date: yup
    .date()
    .required("Required")
    .test(
      "greater-or-equal-receive-start-date",
      "Receive end date must be greater than to receive start date",
      function (value) {
        const { receive_start_date } = this.parent
        const inputTime = new Date(value);
        inputTime.setSeconds(0, 0);
        return inputTime.getTime() > receive_start_date.getTime();
      }
    )
  });

export default schemaReleaseBonusSetting;
