export const OPTIONS_PLAY_FEE = [
  { label: "1 FreeTicket", value: "1_FREETICKET" },
  { label: "2 FreeTicket", value: "2_FREETICKET" },
];

export const OPTIONS_PLAY_FEE_PER_TURN = [
  { label: "50 BCoin", value: "50" },
  { label: "100 BCoin", value: "100" },
  { label: "150 BCoin", value: "150" },
  { label: "200 BCoin", value: "200" },
  { label: "250 BCoin", value: "250" },
  { label: "300 BCoin", value: "300" },
  { label: "400 BCoin", value: "400" },
  { label: "500 BCoin", value: "500" },
  { label: "1000 BCoin", value: "1000" },
  { label: "2000 BCoin", value: "2000" },
  { label: "3000 BCoin", value: "3000" },
  { label: "4000 BCoin", value: "4000" },
  { label: "5000 BCoin", value: "5000" },
  { label: "10000 BCoin", value: "10000" },
  { label: "20000 BCoin", value: "20000" },
  { label: "30000 BCoin", value: "30000" },
  { label: "40000 BCoin", value: "40000" },
  { label: "50000 BCoin", value: "50000" },
  { label: "60000 BCoin", value: "60000" },
  { label: "70000 BCoin", value: "70000" },
]

export const OPTIONS_SKILL_RANK = [
  { label: "SS", value: "SS" },
  { label: "S", value: "S" },
  { label: "A", value: "A" },
  { label: "B", value: "B" },
  { label: "C", value: "C" },
  { label: "D", value: "D" },
  { label: "E", value: "E" },
]

export const STATUS_BANNER = [
  { label: "Active", value: "ACTIVE" },
  { label: "Not Active", value: "NO_ACTIVE" },
]

export const ERROR_BANNER = {
  "PRIZE_IS_EXISTS_WITH_OTHER_CATEGORY_PRIZE": "Prize is exists with other category prize"
}

export const ERROR_GAME_MODE = {
  GAME_MODE_ALREADY_EXISTS: "Game mode already exists",
  GAME_MODE_NOT_FOUND: "Game mode not found",
  GAME_MODE_INVALID: "Game mode invalid",
  MODE_NAME_IS_INVALID: "Mode name is invalid",
  MODE_TYPE_IS_INVALID: "Mode type is invalid",
  PLAY_FEE_IS_INVALID: "Play fee is invalid",
  GAMEPLAY_TYPE_IS_INVALID: "GamePlay type is invalid",
  PRIZE_TYPE_IS_INVALID: "Prize type is invalid",
  LIFE_GAUGE_CONSUMPTION_IS_INVALID: "Life gauge consumption is invalid",
  MACHINE_TYPE_IS_INVALID: "Machine type is invalid",
  PRIZE_NOT_FOUND: "Prize not found",
  PLEASE_NO_ACTIVE_GAME_MODE_BEFORE_UPDATE: "Please no active game mode before update",
  PLEASE_NO_ACTIVE_GAME_MODE_BEFORE_DELETE: "Please no active game mode before delete",
  PRIZE_IS_USE: "Prize is use",
  ROOM_IS_PLAY_GAME_WITH_GAME_MODE: "Room is play game with game mode",
  PRIZE_ITEM_DF_IS_EMPTY: "Prize item DF is empty",
  PRIZE_ITEM_IS_LOCKED: "Prize item is locked",
  PRIZE_TYPE_INVALID: "Prize type invalid",
}
