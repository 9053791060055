import {
  Flex,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import { useEffect, useState } from "react";
import ButtonType from "components/Button";
import DrpChangeHistory from "./DrpChangeHistory";
import FormCommon from './formCommon';
import CardBody from "components/Card/CardBody";
import { getDailyRewardPool } from "api/dailyRewardPool";
import { usePermission } from "hooks/usePermission";
import { PERMISSIONS } from "constants/permissions";

export default function DailyRewardPool() {
  const toast = useToast();
  const { isHasPermissionEdit } = usePermission(PERMISSIONS.GAME_PARAMS_DAILY_REWARD_POOL);
  const textColor = useColorModeValue("#000000", "white");
  const [dataDailyReward, setDataDailyReward] = useState()
  const [modal, setModal] = useState({
    changeHistory: false,
  })
  
  const toggle = (type) => {
    setModal(prev => ({ ...prev, [type]: !prev[type] }))
  }

  const getListDailyReward = async () => {
    try {
      const { data } = await getDailyRewardPool();
      if (data?.success) {
        setDataDailyReward(data?.data?.records);
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    } 
  };

  useEffect(() => {
    getListDailyReward()
  }, [])

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <Card px="24px">
        {modal?.changeHistory ? (
          <DrpChangeHistory
            toggleModal={toggle}
          />
        ) : (
          <Card p="0px">
            <CardHeader px="24px" mb="12px">
              <Flex
                w="100%"
                gap={4}
                alignItems="center"
                justifyContent="space-between"
              >
                <Text color={textColor} fontSize="lg" fontWeight="bold">
                  Daily Reward Pool
                </Text>
                <ButtonType
                  text="Change History"
                  w="140px"
                  fontSize="16px"
                  btnType="primary"
                  onClick={() => toggle("changeHistory")}
                />
              </Flex>
            </CardHeader>
            <CardBody overflowX="auto" display="flex" flexDirection="column" gap="8px">
              {dataDailyReward?.map((reward, index) => (
                <FormCommon
                  key={index}
                  title={reward?.type}
                  reward={reward} 
                  type={reward?.type}
                  getListDailyReward={getListDailyReward}
                  isHasPermissionEdit={isHasPermissionEdit}
                />
              ))}
            </CardBody>
          </Card>
        )}
      </Card>
    </Flex>
  );
}
