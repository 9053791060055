import { Td, Tr, Icon, Flex } from "@chakra-ui/react";
import { DeleteIcon } from "components/Icons/DeleteIcon";
import { upperFirst } from "lodash";

export default function RowGift ({
  item,
  handleDelete,
  isDetail=false
}) {

  const pointName = (keyName) => {
    switch(keyName) {
      case "ticket_free":
        return "Free Ticket"
      case "ticket100_bc":
        return "Ticket 100 BC"
      case "ticket500_bc":
        return "Ticket 500 BC"
      case "ticket1000_bc":
        return "Ticket 1000 BC"
      case "ticket100000_bc":
        return "Ticket 100k BC"
      case "ticket_free_coop":
        return "Co-op FreeTicket"
      case "ticket_premium_pass":
        return "Premium Pass Ticket"
      default:
        return upperFirst(keyName);
    }
  }

  return (
    <Tr>
      <Td minWidth="150px">
        {pointName(item?.name)}
      </Td>
      <Td>
        {item?.type}
      </Td>
      <Td minWidth="150px">
        {pointName(item?.name)}
      </Td>
      <Td width="10px">
        {item?.value}
      </Td>
      {!isDetail && (
        <Td>
          <Flex gap={6} align="center">
            <Icon
              onClick={() => handleDelete()}
              fontSize="30px"
              borderRadius="8px"
              cursor="pointer"
              as={DeleteIcon}
            />
          </Flex>
        </Td>
      )}
    </Tr>
  );
}
