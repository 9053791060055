import * as yup from "yup";

const schemaAdminWallet = () =>
  yup.object().shape({
    AW_RECIPIENT_COLD_WALLET_ADDRESS: yup
      .string()
      .trim()
      .nullable()
      .max(255, "Maximum limit of 255 characters."),
    AW_PAYER_HOT_WALLET_ADDRESS: yup
      .string()
      .trim()
      .nullable()
      .max(255, "Maximum limit of 255 characters."),
    AW_PAYER_HOT_WALLET_PRIVATE_KEY: yup
      .string()
      .trim()
      .max(255, "Maximum limit of 255 characters."),
    AW_NFT_HOLDER_WALLET_ADDRESS: yup
      .string()
      .trim()
      .nullable()
      .max(255, "Maximum limit of 255 characters."),
    AW_NFT_HOLDER_PRIVATE_KEY: yup
      .string()
      .trim()
      .nullable()
      .max(255, "Maximum limit of 255 characters."),
    AW_PAYMASTER_ADDRESS_BICONOMI: yup
      .string()
      .trim()
      .nullable()
      .max(255, "Maximum limit of 255 characters."),
  });

export default schemaAdminWallet;
