import { Icon } from "@chakra-ui/icons";
import { Flex, Td, Text, Tr, useColorModeValue } from "@chakra-ui/react";
import { EditIcon } from "components/Icons/EditIcon";

export default function RoleManagementRow({
  item,
  index,
  handleOpenEditModal,
  isHasPermissionEdit,
}) {
  const textColor = useColorModeValue("#000000", "white");

  return (
    <Tr>
      <Td>
        <Flex gap="10px">
          {item?.name !== "SUPPER_ADMIN" && isHasPermissionEdit && (
            <Icon
              onClick={() => {
                handleOpenEditModal(item);
              }}
              fontSize="30px"
              borderRadius="8px"
              p="7px"
              cursor="pointer"
              bg="#1480FF"
              as={EditIcon}
            />
          )}
        </Flex>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {index}
        </Text>
      </Td>
      <Td>
        <Text color={textColor} fontSize="sm" fontWeight={400}>
          {item?.name}
        </Text>
      </Td>
    </Tr>
  );
}
