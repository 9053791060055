import { containSpecialUserNameRegex, containSpecialUserNameJapanRegex } from "utils/regex";
import { TRANS_LABEL } from "views/DigitalFigure/constants";
import * as yup from "yup";

const schemaViewBG = (transType) =>
  yup.object().shape({
    name: yup
      .string()
      .trim()
      .matches(containSpecialUserNameRegex, "Name should not contain special characters")
      .required(TRANS_LABEL[transType].required)
      .max(255, "Maximum limit of 255 characters."),
    name_jp: yup
      .string()
      .trim()
      .required(TRANS_LABEL[transType].required)
      .matches(containSpecialUserNameJapanRegex, "Name should not contain special characters")
      .max(255, "Maximum limit of 255 characters."),
    description: yup
      .string()
      .trim()
      .max(1000, "Maximum limit of 1000 characters."),
    description_jp: yup
      .string()
      .trim()
      .max(1000, "Maximum limit of 1000 characters."),
    asset_url: yup
      .string()
      .trim()
      .required(TRANS_LABEL[transType].required),
    licensor: yup.string().trim().required(TRANS_LABEL[transType].required),
    project: yup.string().trim().required(TRANS_LABEL[transType].required),
    count: yup
      .number()
      .min(0)
      .max(9999, "Number of Tickets must be at most 4 characters")
      .transform((_, originalValue) => {
        return originalValue === "" ? undefined : Number(originalValue);
      })
      .test("is-required", TRANS_LABEL[transType].required, function (value) {
        return (
          transType !== "en" || (typeof value === "number" && !isNaN(value))
        );
      })
      .integer("Must be an integer")
      .positive("Number of Tickets must be greater than or equal to 0"),
    country_targets: yup.array().when("specify_target_countries", {
      is: true,
      then: (schema) => schema.min(1, TRANS_LABEL[transType].required),
    }),
  });

export default schemaViewBG;
